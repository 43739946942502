import { Paper } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ palette, spacing }) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '0',
    paddingRight: '0',
    width: '100%'
  }
}))

const DesktopContentPaper = ({ children, className }) => {
  const styles = useStyles()

  return (
    <Paper
      className={clsx(styles.paper, className)}
      elevation={1}
      borderRadius={10}>
      {children}
    </Paper>
  )
}

export default DesktopContentPaper
