import React from 'react'
import './DesktopHeader.scss'
import fmLogo from 'src/assets/images/FamilyMartLogo.png'
import logo from 'src/assets/images/pudo-logo-3.png'
import userGrey from 'src/assets/images/user-grey.png'
import { Button, Typography } from '@material-ui/core'
import DesktopButton from '../../DesktopButton/DesktopButton'
import CustomIcon from 'src/components/CustomIcon/CustomIcon'
import { useHistory } from 'react-router'

const DesktopHeader = (props) => {
  const history = useHistory()

  return (
    <div className="container-header-desktop-view">
      <div className="header-desktop">
        <div
          className="logo-fm"
          onClick={() =>
            history.push({ pathname: '/', search: global.jwtToken })
          }>
          <img src={logo} height="70rem" width="100%" alt="fm-logo" />
        </div>
        {/* <DesktopButton
          title={<Typography variant="h4">Sign In/ Sign Up</Typography>}
          handleClick={() => console.log('test')}
          startIcon={
            <CustomIcon src={userGrey} name="user-icon" size="sm"></CustomIcon>
          }
        /> */}
      </div>
    </div>
  )
}

export default DesktopHeader
