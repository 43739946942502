import React, { useState, useCallback, useEffect } from 'react'
import Resizer from 'react-image-file-resizer'
import { API, graphqlOperation } from 'aws-amplify'
import { searchStoreList } from 'src/graphql/queries'
import {
  generateS3UploadLink,
  submitFeedback,
  submitStaffFeedbackForm
} from 'src/graphql/mutations'
import uuid from 'uuid'
import { uploadImgToS3 } from 'src/utils/helper'
import _ from 'lodash'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

const useInactivePageLogic = (location, history, type = null) => {
  const query = useQuery()
  const currentStoreId = query.get('id')

  const iosAppLink = `myfmcrm://feedback${
    currentStoreId ? `?id=${currentStoreId}` : ''
  }`
  const androidAppLink = `myfmcrm://feedback${
    currentStoreId ? `?id=${currentStoreId}` : ''
  }`

  const [originalLocation, setOriginalLocation] = useState('')
  const [consent, setConsent] = useState(false)
  const [isTextLoading, setIsTextLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [store, setStore] = useState('')
  const [images, setImages] = useState([{}, {}, {}])
  const [storeLocation, setStoreLocation] = useState([])
  const [openList, setOpenList] = useState(false)
  const [open, setOpen] = useState(false)
  const [isTngMp, setIsTngMp] = useState(
    sessionStorage.getItem('platform') === 'tngmp' ? true : false
  )
  const [subject, setSubject] = useState([
    'My FamilyMart loyalty program',
    'Staff service',
    'Products',
    'Promotions',
    'Store cleanliness and ambience',
    'Others'
  ])
  const [notify, setNotify] = useState({
    show: false,
    severity: '',
    message: ''
  })
  const [showImgModal, setShowImgModal] = useState({
    show: false,
    image: '',
    currIdx: null
  })
  const [feedback, setFeedback] = useState({
    message: '',
    searchLocation: '',
    dateOfVisit: moment(),
    timeOfVisit: moment(),
    title: currentStoreId ? 'Staff service' : 'My FamilyMart loyalty program',
    position: '',
    storeName: '',
    customerName: '',
    customerEmail: '',
    customerMobileNo: ''
  })
  const [showMessage, setShowMessage] = useState({
    show: false
  })

  const pudoParcelSubject = [
    'PUDO Parcel Website',
    'Shipment Creation',
    'Services',
    'Payment',
    'Parcel Drop-off / Collection',
    'Staff Service',
    'Parcel - Others'
  ]

  const employeeFeedbackSubject = [
    'Compensation and Benefits',
    'Superior',
    'Peers',
    'Work Environment',
    'SOP',
    'WhistleBlowing',
    'Others'
  ]
  const [feedbackType, setFeedbackType] = useState('')

  const isNoUser = () => {
    const noLogin = ['TOKEN_HERE', 'null', 'undefined'].includes(
      sessionStorage.getItem('token') ?? 'undefined'
    )

    if (type !== 'employeeFeedback' && !isTngMp && noLogin) {
      return true
    }

    return false
  }

  const isMobileApp = () => {
    return sessionStorage.getItem('platform') === 'ecommerce'
  }

  const shouldOpenApp = () => {
    const noLogin = ['null', 'TOKEN_HERE'].includes(
      sessionStorage.getItem('token')
    )

    const isEcApp = sessionStorage.getItem('platform') === 'ecommerce'

    if (type !== 'employeeFeedback' && !isTngMp && noLogin && !isEcApp) {
      return true
    }
    return false
  }

  const retrieveLocations = (data) => {
    let res = _.cloneDeep(data)
    let searchLocationList = []
    if (res.data.searchStoreList && store !== '') {
      res.data.searchStoreList.forEach((e) => {
        let storeName = e.storeName.replace('FamilyMart ', '')
        if (storeName.toLowerCase().indexOf(store.toLowerCase()) !== -1) {
          searchLocationList.push(storeName)
        }
      })
    } else {
      res.data.searchStoreList.filter((e) => {
        searchLocationList.push(e.storeName.replace('FamilyMart ', ''))
      })
    }

    searchLocationList = searchLocationList.sort(function (a, b) {
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
    })

    searchLocationList.unshift('Not Applicable')

    setStoreLocation(searchLocationList)
    setIsTextLoading(false)
    setOpenList(true)
  }

  async function getSearchLocation() {
    setIsTextLoading(true)
    try {
      let res = await API.graphql(
        graphqlOperation(searchStoreList, {
          keyword: 'ALL STORES'
        })
      )
      retrieveLocations(res)
      return res.data.searchStoreList
    } catch (err) {
      if (
        err &&
        err.data &&
        err.data.searchStoreList &&
        err.data.searchStoreList.length > 0
      ) {
        setIsTextLoading(false)
        retrieveLocations(err)
      }
    }
    return []
  }

  async function handleStore(store) {
    setStore(store)
    setFeedback({ ...feedback, searchLocation: store })
    setOpenList(false)
  }

  const onImageClick = useCallback((src, idx) => {
    setShowImgModal({
      show: true,
      image: src,
      currIdx: idx
    })
  })

  function removePreviewImg(currIdx) {
    let newArr = [...images]
    if (isTngMp) {
      newArr.splice(currIdx, 1)
    } else {
      newArr[currIdx] = {}
    }
    setImages(newArr)
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        600,
        'JPEG',
        90,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',')
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString })
  }

  function getToken() {
    return global.jwtToken
      ? global.jwtToken
      : sessionStorage.getItem('token') !== null &&
        sessionStorage.getItem('token') !== 'TOKEN_HERE'
      ? sessionStorage.getItem('token')
      : null
  }

  async function getS3Link(count, path) {
    let token = getToken()
    let phoneNumber = '60' + feedback.customerMobileNo
    let fileName = `image${count}.jpg`
    let params = {
      folderName: path,
      fileName: fileName,
      platform: sessionStorage.getItem('platform'),
      ...(['null', 'TOKEN_HERE', 'undefined'].includes(
        sessionStorage.getItem('token') ?? 'undefined'
      )
        ? {
            phoneNumber: phoneNumber
          }
        : {
            token: token
          })
    }
    let newImage
    if (isTngMp) {
      params['platform'] = 'tngmp'
      newImage = images[count]
    } else {
      const compressedImage = await resizeFile(images[count])
      newImage = dataURIToBlob(compressedImage)
    }

    const response = await API.graphql(
      graphqlOperation(generateS3UploadLink, params)
    )

    try {
      const uploadResponse = await uploadImgToS3(
        response.data.generateS3UploadLink,
        newImage,
        isTngMp,
        setNotify,
        handleTngRes
      )

      if (isTngMp) {
        localStorage.removeItem('currentUploadStatus')
      }

      if (!isTngMp) {
        if (uploadResponse.status == 204) {
          return true
        } else if (uploadResponse.status == 400) {
          return false
        }
      } else {
        return uploadResponse
      }
    } catch (error) {
      console.log(error)
    }
  }

  function handleTngRes(tngRes) {
    if (tngRes && (tngRes.statusCode === '200' || tngRes.statusCode === 200)) {
      return true
    }
    return false
  }

  function isInvalidMobileNo() {
    if (
      ['11', '15'].some((num) => feedback?.customerMobileNo?.startsWith(num))
    ) {
      return !/^\+?(?:601[0-9]\d{8})$/.test('60' + feedback?.customerMobileNo)
    }

    return !/^\+?(?:601[0-9]\d{7})$/.test('60' + feedback?.customerMobileNo)
  }

  function validateFields() {
    setOpen(false)
    setOpenList(false)
    var re = /^[a-zA-Z0-9!?@#$&*~_^<>%()`.+,/" -:;'/\n/]*$/

    if (!feedback?.searchLocation) {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Please select a store name'
      })
      return false
    } else if (isNoUser() && feedback.customerName.replace(/\s/g, '') == '') {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Please enter your name'
      })
      return false
    } else if (isNoUser() && feedback.customerEmail.replace(/\s/g, '') == '') {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Please enter your email'
      })
      return false
    } else if (
      isNoUser() &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(feedback.customerEmail)
    ) {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Please enter valid email'
      })
      return false
    } else if (
      isNoUser() &&
      feedback.customerMobileNo.replace(/\s/g, '') == ''
    ) {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Please enter your phone number'
      })
      return false
    } else if (isNoUser() && isInvalidMobileNo()) {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Please enter a valid phone number'
      })
      return false
    } else if (feedback.dateOfVisit === '') {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Please select a date'
      })
      return false
    } else if (feedback.timeOfVisit === '') {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Please select a time'
      })
      return false
    } else if (feedback.message.replace(/\s/g, '') == '') {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Please enter your feedback description'
      })
      return false
    } else if (feedback.message.length > 4000) {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Description must not exceed 4000 characters'
      })
      return false
    } else if (!re.test(feedback.message)) {
      setNotify({
        show: true,
        severity: 'error',
        message:
          'Please enter only alphabetical and numeral letters. Thank you.'
      })
      return false
    } else {
      return true
    }
  }

  function getSubmitMessage() {
    if (!isNoUser()) {
      return 'Your feedback is successfully submitted. It will be reviewed and updated as soon as possible. Thank you.'
    }

    return 'Your feedback is successfully submitted. It will be reviewed and updated via email as soon as possible. Thank you.'
  }

  async function handleSubmitFeedback() {
    let token = getToken()
    if (validateFields()) {
      try {
        let error = false
        if (
          moment(feedback.dateOfVisit).format('MM/DD/YY') ==
          moment().format('MM/DD/YY')
        ) {
          if (moment(feedback.timeOfVisit)._i > moment().format('HH:mm')) {
            error = true
          }
        }
        if (moment(feedback.dateOfVisit).isAfter(moment())) {
          error = true
        }
        if (moment(feedback.dateOfVisit).isBefore(moment('2016-11-11'))) {
          error = true
        }

        if (error == false) {
          setIsSaving(true)
          let feedbackId = uuid.v4()
          let imagepath = `feedback/${feedbackId}`
          let uploadPhoto = []
          for (let i = 0; i < images.length; i++) {
            if ((!isTngMp && images[i].preview) || (isTngMp && images[i])) {
              let uploadSuccess = await getS3Link(i, imagepath)
              if (!isTngMp && !uploadSuccess && isInvalidMobileNo()) {
                setNotify({
                  show: true,
                  severity: 'error',
                  message: 'Please enter a valid mobile number'
                })
                setIsSaving(false)
                return
              } else if (!isTngMp && !uploadSuccess && !isInvalidMobileNo()) {
                setNotify({
                  show: true,
                  severity: 'error',
                  message: 'Failed! Image size exceeds 15 MB.'
                })
                setIsSaving(false)
                return
              } else if (isTngMp && uploadSuccess !== true) {
                setIsSaving(false)
                return
              }
              uploadPhoto.push(`${imagepath}/image${i}.jpg`)
            }
          }

          let feedbackTitle = feedback.title
          if (
            feedbackType === 'pudoparcel' &&
            feedbackTitle === 'Parcel - Others'
          ) {
            feedbackTitle = 'Others'
          }
          const params = {
            searchLocation: feedback.searchLocation
              ? feedback.searchLocation
              : 'Not Applicable',
            dateOfVisit: moment(feedback.dateOfVisit).format('YYYY-MM-DD'),
            timeOfVisit: moment(feedback.timeOfVisit)._i
              ? moment(feedback.timeOfVisit)._i
              : moment(feedback.timeOfVisit).format('HH:mm'),
            title: feedbackTitle,
            description:
              feedback.message.charAt(0).toUpperCase() +
              feedback.message.slice(1),
            uploadPhoto: uploadPhoto,
            token: token ? token : '',
            platform: sessionStorage.getItem('platform'),
            feedbackType: feedbackType,
            customerName: feedback.customerName,
            customerEmail: feedback.customerEmail,
            customerMobileNo: '60' + feedback.customerMobileNo
          }

          const res = await API.graphql(
            graphqlOperation(submitFeedback, params)
          )
          if (res.data.submitFeedback.statusCode == 200) {
            setNotify({
              show: true,
              severity: 'success',
              message: getSubmitMessage()
            })
            setTimeout(() => {
              setImages([{}, {}, {}])
              setStore(originalLocation)
              setFeedback({
                ...feedback,
                message: '',
                title: currentStoreId
                  ? 'Staff service'
                  : 'My FamilyMart loyalty program',
                position: '',
                customerName: '',
                customerEmail: '',
                customerMobileNo: '',
                searchLocation: originalLocation
              })
              setIsSaving(false)
              if (!isNoUser()) {
                history.push({
                  pathname: '/chat',
                  state: {
                    platform: sessionStorage.getItem('platform')
                  },
                  platform: sessionStorage.getItem('platform')
                })
              } else if (sessionStorage.getItem('platform') === 'ecommerce') {
                history.push({
                  pathname: '/mobilehome'
                })
              }
            }, 2000)
          } else {
            setNotify({
              show: true,
              severity: 'error',
              message: 'Failed! Something went wrong, please try again later.'
            })
            setIsSaving(false)
          }
        } else if (moment(feedback.dateOfVisit).isAfter(moment())) {
          setNotify({
            show: true,
            severity: 'error',
            message: 'Date must not exceed current date'
          })
          setIsSaving(false)
        } else if (
          moment(feedback.dateOfVisit).isBefore(moment('2016-11-11'))
        ) {
          setNotify({
            show: true,
            severity: 'error',
            message: 'Please enter a valid date'
          })
          setIsSaving(false)
        } else {
          setNotify({
            show: true,
            severity: 'error',
            message: 'Time must not later than now'
          })
          setIsSaving(false)
        }
      } catch (error) {
        setNotify({
          show: true,
          severity: 'error',
          message: 'Please enter your Date and Time of Visit properly'
        })
        setIsSaving(false)
        console.log(error)
      }
    }
  }

  const handleSubmitEmployeeFeedback = async () => {
    setOpen(false)
    setOpenList(false)
    setIsSaving(true)
    let token = getToken()
    var regex = /^[a-zA-Z0-9!?@#$&*~_^<>%()`.+,/" -:;'/\n/]*$/
    if (feedback.position === '') {
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: 'Please enter your position'
      })
    }
    if (feedback.storeName === '') {
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: 'Please enter your store name'
      })
    }
    if (feedback.message.replace(/\s/g, '') == '') {
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: 'Please enter your feedback description'
      })
    }

    if (feedback.message.length > 4000) {
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: 'Description must not exceed 4000 characters'
      })
    }
    if (!regex.test(feedback.message)) {
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message:
          'Please enter only alphabetical and numeral letters. Thank you.'
      })
    }
    if (!consent) {
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: 'Kindly tick the checkbox to proceed.'
      })
    }
    try {
      let feedbackId = uuid.v4()
      let imagepath = `feedback/${feedbackId}`
      let uploadPhoto = []
      for (let i = 0; i < images.length; i++) {
        if ((!isTngMp && images[i].preview) || (isTngMp && images[i])) {
          let uploadSuccess = await getS3Link(i, imagepath)
          if (!isTngMp && !uploadSuccess) {
            setNotify({
              show: true,
              severity: 'error',
              message: 'Failed! Image size exceeds 15 MB.'
            })
            setIsSaving(false)
            return
          } else if (isTngMp && uploadSuccess !== true) {
            setIsSaving(false)
            return
          }
          uploadPhoto.push(`${imagepath}/image${i}.jpg`)
        }
      }
      const params = {
        title: feedback.title,
        description:
          feedback.message.charAt(0).toUpperCase() + feedback.message.slice(1),
        image: uploadPhoto,
        token: token ? token : '',
        position: feedback.position,
        storeName: feedback.storeName,
        platform: sessionStorage.getItem('platform')
      }
      const res = await API.graphql(
        graphqlOperation(submitStaffFeedbackForm, params)
      )
      if (res.data.submitStaffFeedbackForm.statusCode === 200) {
        setNotify({
          show: true,
          severity: 'success',
          message: res.data.submitStaffFeedbackForm.message
        })
        setTimeout(() => {
          setImages([{}, {}, {}])
          setFeedback({
            searchLocation: '',
            dateOfVisit: moment(),
            timeOfVisit: moment(),
            message: '',
            title: employeeFeedbackSubject[0]
          })
        }, 2000)
      } else {
        setNotify({
          show: true,
          severity: 'error',
          message: res.data.submitStaffFeedbackForm.message
        })
      }
      setIsSaving(false)
    } catch (error) {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Failed! Something went wrong, please try again later.'
      })
      setIsSaving(false)
      console.log(error)
    }
  }

  const openFM = () => {
    const isIpad =
      /Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 1

    if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
      window.location.replace(iosAppLink)
    }

    if (navigator.userAgent.toLowerCase().indexOf('ipad') > -1 || isIpad) {
      window.location.replace(iosAppLink)
    }

    if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      window.location.replace(androidAppLink)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const storeRes = await getSearchLocation()
      if (currentStoreId) {
        const currentStore = storeRes?.find((e) => e.storeId === currentStoreId)
          ?.storeName

        setStore(currentStore)
        setOriginalLocation(currentStore)
        setFeedback({
          ...feedback,
          searchLocation: currentStore
        })
      }
    }

    fetchData()

    if (sessionStorage.getItem('platform') === 'tngmp') {
      setImages([])
    } else {
      setImages([{}, {}, {}])
    }

    if (sessionStorage.getItem('platform') === 'pudoparcel') {
      setSubject(pudoParcelSubject)
      setFeedback({
        ...feedback,
        title: pudoParcelSubject[0]
      })
    }

    if (
      sessionStorage.getItem('platform') === 'ecommerce' &&
      type === 'employeeFeedback'
    ) {
      setSubject(employeeFeedbackSubject)
      setFeedback({
        ...feedback,
        title: employeeFeedbackSubject[0]
      })
    }

    if (shouldOpenApp()) {
      openFM()
    }
  }, [])

  useEffect(() => {
    if (pudoParcelSubject.includes(feedback.title)) {
      setFeedbackType('pudoparcel')
    }
  }, [feedback.title])

  return {
    consent,
    setConsent,
    isTextLoading,
    isSaving,
    store,
    setStore,
    images,
    setImages,
    storeLocation,
    openList,
    setOpenList,
    open,
    setOpen,
    isTngMp,
    setIsTngMp,
    subject,
    setSubject,
    notify,
    setNotify,
    showImgModal,
    setShowImgModal,
    feedback,
    setFeedback,
    showMessage,
    setShowMessage,
    getSearchLocation,
    handleStore,
    onImageClick,
    removePreviewImg,
    handleSubmitFeedback,
    handleSubmitEmployeeFeedback,
    feedbackType,
    isNoUser,
    isMobileApp
  }
}

function useQuery() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default useInactivePageLogic
