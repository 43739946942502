import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useDropzone } from 'react-dropzone'
import { Icon } from 'src/components'

const useStyles = makeStyles(({ palette }) => ({
  inactive: {
    border: `1px dashed ${palette.grey[100]}`,
    borderRadius: 10,
    backgroundColor: 'white'
  },
  active: {
    border: `1px dashed ${palette.grey[100]}`,
    borderRadius: 10,
    backgroundColor: 'white'
  },
  iconActive: {
    marginTop: 5,
    color: palette.grey[100]
  },
  iconInactive: {
    marginTop: 5,
    color: palette.grey[100]
  },
  cancel: {
    position: 'absolute',
    backgroundColor: '#fff',
    width: 22,
    color: palette.warning.main,
    right: -23,
    top: -15
  },
  image: {
    width: 28,
    height: 33
  }
}))

function ImageInput({
  setImages,
  images,
  idx,
  onImageClick,
  isTngMp,
  setNotify
}) {
  const styles = useStyles()

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      const acceptedFileTypes = ['jpg', 'png']

      const hasUnsupportedFiles = acceptedFiles?.some(
        (file) => !acceptedFileTypes.includes(file?.name?.split('.')?.pop())
      )

      if (hasUnsupportedFiles) {
        setNotify({
          show: true,
          severity: 'error',
          message:
            'Oops! This file type isn’t supported. Please upload a png or jpg image.'
        })
        return
      }

      const isImage = acceptedFiles.map(
        (item) => item.type.substring(0, 6) == 'image/'
      )
      if (isImage[0]) {
        setFileImage(acceptedFiles)
      }
    }
  })

  const handleTouchnGoImage = () => {
    if (isTngMp && !images[idx]) {
      try {
        /* eslint-disable no-undef */
        my.chooseImage({
          count: 1,
          success: (res) => {
            my.postMessage({
              action: 'compressImage',
              data: res.apFilePaths[0]
            })
          },
          fail: (res) => {
            if (res.error !== 11 && res.error !== '11') {
              setNotify({
                show: true,
                severity: 'error',
                message: JSON.stringify(res)
              })
            }
          }
        })

        my.onMessage = function (e) {
          if (e.action === 'compressSuccess') {
            const temp = [...images]
            temp[idx] = e.body.apFilePaths[0]
            setImages(temp)
          }
        }
        /* eslint-disable no-undef */
      } catch (error) {
        console.log('Error', error)
        setNotify({
          show: true,
          severity: 'error',
          message: JSON.stringify(error.message)
        })
      }
    }

    if (isTngMp && images[idx]) {
      onImageClick(images[idx], idx)
    }
  }

  const setFileImage = (acceptedFiles) => {
    const newArr = [...images]
    newArr[idx] = Object.assign(acceptedFiles[0], {
      preview: URL.createObjectURL(acceptedFiles[0])
    })
    setImages(newArr)
  }

  return (
    <>
      {isTngMp ? (
        <Box py={1.5} onClick={() => handleTouchnGoImage()}>
          <Box
            px={1.5}
            py={0.5}
            mr={1}
            {...getRootProps({
              className: clsx({
                [styles.inactive]: idx !== 0 && !images[idx],
                [styles.active]: idx === 0 || images[idx]
              })
            })}>
            {images[idx] ? (
              <Box>
                <img src={images[idx]} className={styles.image} />
              </Box>
            ) : (
              <Box>
                <Icon
                  name="camera"
                  style={{ marginTop: 5 }}
                  className={clsx({
                    [styles.iconActive]: idx === 0,
                    [styles.iconInactive]: idx !== 0
                  })}
                />
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box py={1.5}>
          <Box
            px={1.5}
            py={0.5}
            mr={1}
            {...getRootProps({
              className: clsx({
                [styles.inactive]: idx !== 0 && !images[idx].preview,
                [styles.active]: idx === 0 || images[idx].preview
              })
            })}>
            {!images[idx].preview && (
              <Icon
                name="camera"
                style={{ marginTop: 5 }}
                className={clsx({
                  [styles.iconActive]: idx === 0,
                  [styles.iconInactive]: idx !== 0
                })}
              />
            )}
            {images[idx].preview && (
              <Box onClick={() => onImageClick(images[idx].preview, idx)}>
                <img src={images[idx].preview} className={styles.image} />
              </Box>
            )}
          </Box>
          {!images[idx].preview && <input {...getInputProps()} />}
        </Box>
      )}
    </>
  )
}

export default React.memo(ImageInput)
