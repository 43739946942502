import { useEffect, useState } from 'react'

const useDeviceViewMode = () => {
  const [width, setWidth] = useState(window.innerWidth)

  // const handleWindowSizeChange = () => {
  //   setWidth(window.innerWidth)
  // }

  // useEffect(() => {
  //   window.addEventListener('resize', handleWindowSizeChange)
  //   return () => {
  //     window.removeEventListener('resize', handleWindowSizeChange)
  //   }
  // }, [])

  const viewMode = width <= 900 ? 'mobile' : 'desktop'

  return viewMode
}

export default useDeviceViewMode
