import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { common } from '@material-ui/core/colors'

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  backButton: {
    color: palette.primary.main
  },
  text: {
    color: palette.primary.main
  }
}))

export default function BackButton({
  history,
  handleBack = false,
  className = false,
  absolute = false,
  backTo = false
}) {
  const styles = useStyles({ absolute })
  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={() =>
        backTo
          ? history.push({
              pathname: backTo,
              state: { prevPath: history.location.pathname }
            })
          : handleBack
          ? handleBack()
          : history.goBack()
      }
      className={clsx(styles.root, {
        [className]: className
      })}>
      <Typography variant="h3" className={styles.text}>
        Back
      </Typography>
    </Box>
  )
}
