import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { getHelpCenterOrderList } from 'src/graphql/queries'

const useOrderListLogic = (route, token, platform, orderType = 'ecommerce') => {
  const [orderList, setOrderList] = useState([])
  const [isOrderListLoading, setIsOrderListLoading] = useState(true)
  const [alert, setAlert] = useState(false)
  const [isTngMp, setIsTngMp] = useState(false)
  const [userPlatform, setUserPlatform] = useState()

  useEffect(() => {
    if (route !== 'desktop') {
      if (orderType === 'ecommerce') {
        orderListData()
      } else {
        voucherOrderListData()
      }
    } else {
      orderListData()
    }
  }, [])

  function getUserPlatform() {
    if (platform == 'tngmp') {
      setIsTngMp(true)
    } else if (platform == 'pudoparcel') {
      platform = 'pudoparcel'
      setIsTngMp(false)
    } else {
      platform = 'ecommerce'
      setIsTngMp(false)
    }
    setUserPlatform(platform)
    return platform
  }

  async function voucherOrderListData() {
    let platform = getUserPlatform()

    const completed = {
      token: token,
      status: 'Voucher',
      platform: platform,
      nextToken: null
    }

    try {
      const completeRes = await API.graphql(
        graphqlOperation(getHelpCenterOrderList, completed)
      )

      let completedata = completeRes.data.getHelpCenterOrderList.voucherOrder
      console.log(completedata)
      let mergedList = []

      if (completedata.length > 0) {
        completedata.map((item) => {
          var details = {
            orderId: item.voucherOrderId,
            orderNumber: item.orderNumber,
            totalOrderItems: item.totalOrderItems,
            grandTotal: item.grandTotal,
            productImage: item.voucherImage,
            orderDateTime: item.orderDateTime,
            status: item.status,
            customerAccountNo: item.customerAccountNo,
            orderType: 'Voucher',
            platform: platform
          }
          mergedList.push(details)
        })
      }

      mergedList = mergedList.sort(
        (a, b) => new Date(b.orderDateTime) - new Date(a.orderDateTime)
      )

      if (mergedList.length < 1) {
        setIsOrderListLoading(false)
        setAlert(true)
      } else {
        setOrderList(mergedList)
        setTimeout(() => {
          setIsOrderListLoading(false)
        }, 500)
      }
    } catch (err) {
      setTimeout(() => {
        setIsOrderListLoading(false)
      }, 500)
      setAlert(true)
    }
  }

  async function orderListData() {
    let platform = getUserPlatform()

    const pending = {
      token: token,
      status: 'Pending',
      platform: platform,
      nextToken: null
    }
    const completed = {
      token: token,
      status: 'Completed',
      platform: platform,
      nextToken: null
    }

    try {
      const pendingRes = await API.graphql(
        graphqlOperation(getHelpCenterOrderList, pending)
      )
      const completeRes = await API.graphql(
        graphqlOperation(getHelpCenterOrderList, completed)
      )

      let pendingdata = pendingRes.data.getHelpCenterOrderList.order
      let pendingParcelData = pendingRes.data.getHelpCenterOrderList.pudoOrder
      let completedata = completeRes.data.getHelpCenterOrderList.order
      let completedParcelData =
        completeRes.data.getHelpCenterOrderList.pudoOrder
      let mergedList = []

      if (pendingdata.length > 0) {
        pendingdata.map((item) => {
          var details = {
            orderId: item.orderId,
            orderNumber: item.orderNumber,
            totalOrderItems: item.totalOrderItems,
            grandTotal: item.grandTotal,
            productImage: item.productImage,
            scheduledDateTime: item.scheduledDateTime,
            status: item.status,
            customerAccountNo: item.customerAccountNo,
            orderType: item.orderType,
            platform: platform
          }
          mergedList.push(details)
        })
      }
      if (pendingParcelData.length > 0) {
        pendingParcelData.map((item) => {
          var details = {
            orderId: item.parcelOrderId,
            orderNumber: item.orderNumber,
            totalOrderItems: item.totalQuantity,
            grandTotal: item.grandTotal,
            scheduledDateTime: item.orderDate,
            orderDate: item.orderDate,
            status: item.status,
            senderName: item.senderName,
            senderMobileNumber: item.senderMobileNumber,
            customerAccountNo: item.senderAccountNumber,
            dropOffStoreName: item.dropOffStoreName,
            orderType: 'Parcel',
            detail: item.parcelOrderDetail,
            platform: platform
          }
          mergedList.push(details)
        })
      }
      if (completedata.length > 0) {
        completedata.map((item) => {
          var details = {
            orderId: item.orderId,
            orderNumber: item.orderNumber,
            totalOrderItems: item.totalOrderItems,
            grandTotal: item.grandTotal,
            productImage: item.productImage,
            scheduledDateTime: item.scheduledDateTime,
            status: item.status,
            customerAccountNo: item.customerAccountNo,
            orderType: item.orderType,
            platform: platform
          }
          mergedList.push(details)
        })
      }
      if (completedParcelData.length > 0) {
        completedParcelData.map((item) => {
          var details = {
            orderId: item.parcelOrderId,
            orderNumber: item.orderNumber,
            totalOrderItems: item.totalQuantity,
            grandTotal: item.grandTotal,
            scheduledDateTime: item.orderDate,
            orderDate: item.orderDate,
            status: item.status,
            senderName: item.senderName,
            senderMobileNumber: item.senderMobileNumber,
            customerAccountNo: item.senderAccountNumber,
            dropOffStoreName: item.dropOffStoreName,
            orderType: 'Parcel',
            detail: item.parcelOrderDetail,
            platform: platform
          }
          mergedList.push(details)
        })
      }

      mergedList = mergedList.sort(
        (a, b) => new Date(b.scheduledDateTime) - new Date(a.scheduledDateTime)
      )

      if (mergedList.length < 1) {
        setIsOrderListLoading(false)
        setAlert(true)
      } else {
        setOrderList(mergedList)
        setTimeout(() => {
          setIsOrderListLoading(false)
        }, 500)
      }
    } catch (err) {
      setTimeout(() => {
        setIsOrderListLoading(false)
      }, 500)
      setAlert(true)
    }
  }

  return {
    orderList,
    isOrderListLoading,
    alert,
    setAlert,
    isTngMp,
    userPlatform
  }
}

export default useOrderListLogic
