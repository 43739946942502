import React, { useEffect, useState } from 'react'
import { Box, Typography, withStyles, makeStyles } from '@material-ui/core'
import { Paper, BackButton, Panel, Spinner, Icon } from 'src/components'
import { API, graphqlOperation } from 'aws-amplify'
import { listSupportStatements } from 'src/graphql/queries'
import theme from 'src/themes'
// import ExpandLessIcon from '@material-ui/icons/ExpandLess'

// put outside so that this code doesnt re-render if any state change
async function getInactiveStatement(
  setAllStatements,
  setParentStatements,
  setIsLoading,
  platform
) {
  const params = {
    filter: { statementType: { eq: 'InactiveOrder' } }
  }
  // try {
  const response = await API.graphql(
    graphqlOperation(listSupportStatements, params)
  )
  let array = []
  if (response.data.listSupportStatements) {
    response.data.listSupportStatements.items.map((item) => {
      var details = {
        parentId: item.parentId,
        statementId: item.statementId,
        sequence: item.sequence,
        statement: item.statement,
        messageToCustomer: item.messageToCustomer,
        supportType: item.supportType,
        platform: platform
      }
      array.push(details)
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    })
  }
  function compare_item(a, b) {
    if (a.sequence < b.sequence) {
      return -1
    } else if (a.sequence > b.sequence) {
      return 1
    } else {
      return 0
    }
  }
  array.sort(compare_item)
  setAllStatements(array)
  let parent = array.filter(function (e) {
    return e.parentId == null
  })
  setParentStatements(parent)
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  h1: {
    color: palette.primary.main,
    textAlign: 'center',
    width: 175
  },
  backButton: {
    spacing: 2,
    position: 'absolute'
  },
  backText: {
    color: palette.primary.main
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box)

function resetParents(allStatements, setIsChildStatement, setParentStatements) {
  let parent = allStatements.filter(function (e) {
    return e.parentId == null
  })
  setIsChildStatement(false)
  setParentStatements(parent)
}

export default function InactiveList(props) {
  const [parentIndex, setParentIndex] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [allStatements, setAllStatements] = useState([])
  const [parentStatements, setParentStatements] = useState([])
  const [isChildStatement, setIsChildStatement] = useState(false)
  const [currTopic, setCurrTopic] = useState(null)
  const [isTngMp, setIsTngMp] = useState(true)
  const [userPlatform, setUserPlatform] = useState()
  const styles = useStyles()

  console.log('props 1', props)
  useEffect(() => {
    let platform = ''
    if (props.location.platform == 'tngmp') {
      platform = 'tngmp'
      setIsTngMp(true)
    } else if (platform == 'pudoparcel') {
      platform = 'pudoparcel'
      setIsTngMp(false)
    } else {
      platform = 'ecommerce'
      setIsTngMp(false)
    }
    setUserPlatform(platform)
    getInactiveStatement(
      setAllStatements,
      setParentStatements,
      setIsLoading,
      platform
    )
  }, [])

  async function onSelectTopic(item, index) {
    let child = allStatements.filter(function (e) {
      return e.parentId == item.statementId
    })
    if (child.length != 0) {
      setParentIndex(index)
      setIsChildStatement(true)
      setParentStatements(child)
    } else {
      const pathway = `/f/${item.statementId}`
      // const showOrderNoField = checkCurrIdx(index)
      props.history.push({
        pathname: pathway,
        // state: showOrderNoField
        state: { platform: userPlatform },
        platform: userPlatform
      })
    }
  }

  // function checkCurrIdx(index) {
  //   if (isChildStatement) {
  //     switch (parentIndex) {
  //       case 0:
  //         return true
  //       case 1:
  //         return true
  //       case 2:
  //         return true
  //       default:
  //         return false
  //     }
  //   } else {
  //     switch (index) {
  //       case 0:
  //         return true
  //       case 1:
  //         return true
  //       case 2:
  //         return true
  //       default:
  //         return false
  //     }
  //   }
  // }

  return (
    <Box p={2}>
      {!isChildStatement && !isTngMp && <BackButton {...props} backTo={'/'} />}
      {isChildStatement && !isTngMp && (
        <Box
          onClick={() =>
            resetParents(
              allStatements,
              setIsChildStatement,
              setParentStatements
            )
          }>
          <Typography variant="h3" className={styles.backText}>
            Back
          </Typography>
        </Box>
      )}
      <Row height={100}>
        <Typography variant="h1" className={styles.h1}>
          Others
        </Typography>
      </Row>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Row my={1.5}>
          <Paper>
            {/* {isChildStatement && (
              <Box display="flex" justifyContent="flex-end" px={3}>
                <ExpandLessIcon
                  name="expand-less"
                  style={{ color: theme.palette.primary.main }}
                  onClick={() =>
                    resetParents(
                      allStatements,
                      setIsChildStatement,
                      setParentStatements
                    )
                  }
                />
              </Box>
            )} */}
            {!currTopic &&
              parentStatements.map((item, idx) => {
                return (
                  <Row
                    key={idx}
                    py={1.5}
                    px={3}
                    onClick={() => onSelectTopic(item, idx)}>
                    <Typography variant="body1" style={{ flex: 1 }}>
                      {item.statement}
                    </Typography>
                    <Icon
                      name="next"
                      style={{ color: theme.palette.primary.main }}
                    />
                  </Row>
                )
              })}
          </Paper>
        </Row>
      )}
    </Box>
  )
}
