import { createContext, useEffect, useState } from 'react'
import produce from 'immer'

import React from 'react'

const DesktopNavigationContext = createContext()

const DesktopNavigationContextProvider = ({ children }) => {
  const [navigationList, setNavigationList] = useState(['Home'])

  const handleUpdateBreadcrumbs = (navigationListToAppend) => {
    const updatedNavigationList = produce(navigationList, (draft) => {
      draft.splice(1, navigationList.length - 1)
      navigationListToAppend.forEach((item) => draft.push(item))
    })
    setNavigationList(updatedNavigationList)
  }

  useEffect(() => {
    console.log({ navigationList })
  }, [navigationList])

  return (
    <DesktopNavigationContext.Provider
      value={{ navigationList, handleUpdateBreadcrumbs }}>
      {children}
    </DesktopNavigationContext.Provider>
  )
}

export { DesktopNavigationContextProvider, DesktopNavigationContext }
