import React from 'react'
import { Box } from '@material-ui/core'
import {
  Order,
  BackButton,
  Spinner,
  Feedback,
  ParcelOrder
} from 'src/components'
import useOrderListLogic from '../hooks/useOrderListLogic'

export default function OrderList(props) {
  const token = global.jwtToken

  const {
    orderList,
    isOrderListLoading,
    alert,
    setAlert,
    isTngMp
  } = useOrderListLogic(
    'mobile',
    token,
    props.location.state.platform,
    props.orderType
  )

  const handleClose = () => {
    setAlert(false)
  }

  return (
    <Box p={2} mx={1}>
      {!isTngMp ? <BackButton {...props} backTo={'/'} /> : null}
      {isOrderListLoading && <Spinner />}
      {!isOrderListLoading && (
        <Box>
          {orderList.map((order, idx) => {
            if (order.orderType === 'Parcel') {
              return (
                <Box key={idx}>
                  <ParcelOrder
                    type={props.orderType}
                    order={order}
                    {...props}
                  />
                </Box>
              )
            } else {
              return (
                <Box py={1.5} key={idx}>
                  <Order type={props.orderType} order={order} {...props} />
                </Box>
              )
            }
          })}
          <Feedback
            show={alert}
            message="You have not made any order or your token has expired"
            handleClose={handleClose}
          />
        </Box>
      )}
    </Box>
  )
}
