import {
  Box,
  Button,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useQuery } from 'src/hooks/useQuery'
import FMLogo from 'src/assets/images/fmlogo.png'
import FMMobiles from 'src/assets/images/fmmobiles.png'
import PlayStoreImg from 'src/assets/images/downloadplaystore.png'
import AppStoreImg from 'src/assets/images/downloadappstore.png'
import './AppRedirect.scss'

const useStyles = makeStyles(({ palette, spacing }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh'
  },
  main: {
    justifySelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '20px',
    overflowY: 'auto'
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 400px',
    gap: '20px',
    padding: '20px'
  },
  imgBox: {
    flex: '1 400px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '16px'
  },
  text: {
    textAlign: 'center',
    fontSize: '16px',
    marginTop: '4px'
  },
  storeButton: {
    padding: 0
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px'
  },
  fbFrame: {
    border: '0px #FFFFFF none',
    margnBottom: '0',
    height: '70px'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    backgroundColor: '#73cde6',
    padding: '12px',
    color: '#FFFFFF',
    maxHeight: '80px',
    gap: '8px'
  },
  footerStart: {
    flexGrow: 0,
    alignSelf: 'center'
  },
  footerStartText: {
    textAlign: 'center',
    marginRight: 'auto'
  },
  footerEnd: {
    textAlign: 'center',
    marginLeft: 'auto'
  }
}))

export default function AppRedirect(props) {
  const query = useQuery()
  const styles = useStyles()

  const module = window.location.pathname.split('/')[1]
  const id = query.get('id')

  const appLinkWithId = `myfmcrm://${module}?id=${id}`
  const appLink = `myfmcrm://${module}`

  const appStoreLink =
    'https://apps.apple.com/my/app/my-familymart/id1447073062?l=en'
  const playStoreLink = 'market://details?id=com.maxincome.fmcrm'

  const openFM = () => {
    const isIpad =
      /Macintosh/i.test(navigator.userAgent) &&
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 1

    const isMobile =
      ['iphone', 'ipad', 'android'].findIndex(
        (item) => navigator.userAgent.toLowerCase().indexOf(item) > -1
      ) !== -1

    if (isMobile || isIpad) {
      window.location.replace(id ? appLinkWithId : appLink)
    }
  }

  useEffect(() => {
    openFM()
  }, [])

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.main}>
        <Box className={styles.mainBox}>
          <img width="80px" height="80px" src={FMLogo} alt="logo" />
          <Typography className={styles.title}>
            DOWNLOAD "MY FAMILYMART" APP NOW!
          </Typography>
          <Typography className={styles.text}>
            Be a FAM today to enjoy deals, rewards, and delivery convenience!
          </Typography>
          <Box className={styles.row}>
            <Button
              className={styles.storeButton}
              onClick={() => {
                window.open(appStoreLink, '_blank')
              }}>
              <img width="120px" src={AppStoreImg} />
            </Button>
            <Button
              className={styles.storeButton}
              onClick={() => {
                window.open(playStoreLink, '_blank')
              }}>
              <img width="120px" src={PlayStoreImg} />
            </Button>
          </Box>
          <iframe
            src="https://web.facebook.com/v3.1/plugins/page.php?adapt_container_width=true&app_id=&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df757373747379c%26domain%3Dwww.familymart.com.my%26is_canvas%3Dfalse%26origin%3Dhttp%253A%252F%252Fwww.familymart.com.my%252Ff1f5510844e72f8%26relation%3Dparent.parent&container_width=591&height=0&hide_cover=false&href=https%3A%2F%2Fwww.facebook.com%2FFamilyMartMY&locale=en_GB&sdk=joey&show_facepile=false&small_header=true&tabs=timeline&width=300"
            className={styles.fbFrame}
            name="fbFrame"
            scrolling="no"
            frameBorder="1"
          />
        </Box>
        <Box className={styles.imgBox}>
          <img width="100%" style={{ maxWidth: '600px' }} src={FMMobiles} />
        </Box>
      </Box>
      <Box className={styles.footer}>
        <a href="https://familymart.com.my/" className="lFooter">
          FAMILYMART.COM.MY
        </a>
        <Typography className="rFooter">
          © 2023 FamilyMart | By QL Maxincome Sdn. Bhd. [Registration No.
          199601010973 (383322-D)] | All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  )
}
