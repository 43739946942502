import Cookies from 'universal-cookie'

const useNavigationLogic = (
  route,
  setUserPlatform,
  setIsTngMp,
  setNotify,
  type,
  state,
  location
) => {
  const cookies = new Cookies()
  let tempToken = cookies.get('token')
  let tempPlatform = cookies.get('platform')

  //prioritize:
  // 1. session storage
  // 2. cookies
  // 3. query string (to be removed)

  function checkUserPlatform() {
    let platform = ''
    if (
      sessionStorage.getItem('platform') !== null &&
      sessionStorage.getItem('platform') !== 'PLATFORM_HERE'
    ) {
      //get platform from session storage if exist
      platform = sessionStorage.getItem('platform')
    } else if (type === 'faq' && state) {
      //get platform from props
      platform = state.platform
    } else if (tempPlatform !== undefined) {
      //get platform from cookies
      platform = tempPlatform
      sessionStorage.setItem('platform', tempPlatform)
    } else if (
      window.location.hostname ===
        process.env.REACT_APP_HELPCENTER_PUDOPARCEL ||
      window.location.hostname.includes('.pudoparcel.com')
    ) {
      platform = 'pudoparcel'
      sessionStorage.setItem('platform', platform)
    } else if (
      (type === 'faq' && route === 'desktop' && location) ||
      type !== 'faq'
    ) {
      //get platform from query string
      let search = location.search
      let searchParams = new URLSearchParams(search)
      platform = searchParams.get('platform')
      sessionStorage.setItem('platform', platform)
    }

    if (platform == 'tngmp') {
      platform = 'tngmp'
      setIsTngMp(true)
    } else if (platform == 'pudoparcel') {
      platform = 'pudoparcel'
      setIsTngMp(false)
    } else {
      platform = 'ecommerce'
      setIsTngMp(false)
    }
    setUserPlatform(platform)
    return platform
  }

  function getJwtToken(platform) {
    //get token from session storage if exist
    if (
      sessionStorage.getItem('token') !== null &&
      sessionStorage.getItem('token') !== 'TOKEN_HERE'
    ) {
      global.jwtToken = sessionStorage.getItem('token')
    } else {
      //TNG get token from MP
      if (platform === 'tngmp') {
        try {
          // eslint-disable-next-line no-undef
          my.postMessage({
            action: 'getToken'
          })
          // eslint-disable-next-line no-undef
          my.onMessage = function (e) {
            let response = JSON.parse(e.body)
            handleToken(response.token)
          }

          //temporarily handle token from query string (old MP version)
          getTokenFromQueryString()
        } catch (err) {
          setNotify({
            show: true,
            severity: 'error',
            message: 'Failed! Something went wrong, please try again later.'
          })
        }
      } else {
        //get token from cookies
        if (tempToken !== undefined) {
          handleToken(tempToken)
        } else {
          //get token from query string
          getTokenFromQueryString()
        }
      }
    }
  }

  function handleToken(token) {
    global.jwtToken = token
    sessionStorage.setItem('token', token)
  }

  function getTokenFromQueryString() {
    const queryString = require('query-string')
    const parsed = queryString.parse(window.location.search)
    if (parsed.token !== undefined) {
      handleToken(parsed.token)
    }
  }

  return {
    checkUserPlatform,
    getJwtToken
  }
}

export default useNavigationLogic
