import React from 'react'
import { Box, Typography, makeStyles, withStyles } from '@material-ui/core'
import HistoryPin from '../../components/Icon/history-pin-blue.png'
import LocationPin from '../../components/Icon/pin-green.png'
import { format } from 'date-fns'
import { Paper } from 'src/components'
import formatter from 'src/utils/formatter'
import helper from 'src/utils/helper'

const useStyles = makeStyles(({ palette, spacing }) => ({
  image: {
    width: 100,
    height: 100,
    borderRadius: '15px'
    // boxShadow: '1px 1px 1px 1px #9E9E9E'
  },
  status: {
    marginTop: spacing(2),
    marginBottom: spacing(0.5),
    color: palette.primary.main,
    textTransform: 'uppercase'
  },
  detailStatus: {
    color: palette.primary.main,
    textTransform: 'uppercase',
    width: '30%',
    textAlign: 'right',
    marginLeft: '5px'
  },
  icon: {
    marginRight: spacing(1)
  },
  parcelDetailBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%'
  },
  parcelDetailLocation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row'
  }
})(Box)

export default function ParcelOrder({ type, order, history }) {
  const styles = useStyles()

  return (
    <>
      {/* <Paper
        display="block"
        style={{ cursor: 'pointer' }}
        onClick={() =>
          history.push({
            pathname: `/order/parcel/${order.orderId}`,
            state: order,
            platform: order.platform
          })
        }>
        <Row>
          <Box ml={1.5} style={{ width: '100%' }}>
            <Row justifyContent="space-between">
              <Typography variant="h4">#{order.orderNumber}</Typography>
              <Typography variant="body2" className={styles.quantity}>
                {order.totalOrderItems} item[s]
              </Typography>
            </Row>
            <Typography variant="body2">{`${format(
              new Date(order.orderDate),
              'dd MMMM y hh:mm a'
            )}`}</Typography>
          </Box>
        </Row>
      </Paper> */}
      {order.detail.map((item, idx) => {
        item['senderName'] = order.senderName
        item['senderMobileNumber'] = order.senderMobileNumber
        return (
          <Box py={1.5} key={idx}>
            <Paper
              display="block"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                history.push({
                  pathname: `/order/parcel-detail/${item.parcelOrderDetailId}`,
                  state: item,
                  platform: order.platform
                })
              }>
              <Row>
                <Box ml={1.5} style={{ width: '100%' }}>
                  <Row justifyContent="space-between">
                    <Typography variant="h4">#{item.trackerNumber}</Typography>
                    <Typography variant="body2" className={styles.quantity}>
                      1 item[s]
                    </Typography>
                  </Row>
                  <Row
                    justifyContent="space-between"
                    alignItems="flex-start"
                    mt={1}>
                    <div className={styles.parcelDetailBox}>
                      <div className={styles.parcelDetailLocation}>
                        <div className={styles.icon}>
                          <img
                            src={HistoryPin}
                            alt="icon"
                            height="15px"
                            width="15px"
                          />
                        </div>
                        <Typography variant="body2">
                          {item.dropOffStoreName}
                        </Typography>
                      </div>
                      <div className={styles.parcelDetailLocation}>
                        <div className={styles.icon}>
                          <img
                            src={LocationPin}
                            alt="icon"
                            height="15px"
                            width="15px"
                          />
                        </div>
                        <Typography variant="body2">
                          {item.collectionStoreName}
                        </Typography>
                      </div>
                    </div>
                    <Typography variant="h4" className={styles.detailStatus}>
                      {item.status ? item.status : 'Pending Parcel Drop Off'}
                    </Typography>
                  </Row>
                  <Row mt={1}>
                    <Typography variant="body2">
                      {`${format(
                        new Date(order.orderDate),
                        'dd MMMM y hh:mm a'
                      )}`}
                    </Typography>
                  </Row>
                </Box>
              </Row>
            </Paper>
          </Box>
        )
      })}
    </>
  )
}
