import React from 'react'
import CorePaper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(({ spacing }) => ({
  root: (props) => ({
    padding: spacing(props.space),
    overflow: props.overflow,
    display: props.display,
    borderRadius: props.borderRadius
  })
}))
export default function Paper({
  space = 3,
  overflow = 'visible',
  display = 'inline-block',
  elevation = 3,
  borderRadius = 20,
  ...rest
}) {
  const classes = useStyles({
    space,
    overflow,
    display,
    borderRadius
  })
  return <CorePaper classes={classes} elevation={elevation} {...rest} />
}
