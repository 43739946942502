import React, { useState, useCallback } from 'react'
import {
  Box,
  Typography,
  makeStyles,
  withStyles,
  Backdrop,
  Link,
  Button as MuiButton
} from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import {
  Paper,
  TextFieldInput,
  Button,
  ImageInput,
  BackButton,
  Notification,
  Spinner
} from 'src/components'
import { format } from 'date-fns'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useHistory } from 'react-router-dom'
import useChatPageLogic from 'src/pages/Chat/hooks/useChatPageLogic'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: palette.secondary.light
  },
  image: {
    width: '100%',
    height: '40%',
    objectFit: 'cover'
  },
  commentImgBox: {
    justifyContent: (props) => (props === 1 && 'flex-end') || 'flex-start'
  },
  commentImg: {
    width: 50,
    height: 50,
    borderRadius: 10
    // marginLeft: spacing(2)
  },
  status: {
    color: ({ state }) => state && checkStatus(state.status),
    textTransform: 'uppercase'
  },
  icon: {
    marginLeft: 7.5,
    marginRight: 7.5,
    fontSize: '0.35rem',
    color: palette.grey[100],
    alignItems: 'center'
  },
  type: {
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  faq: {
    borderBottom: `2px solid ${palette.primary.light}`
  },
  lastReply: {
    padding: spacing(0, 3),
    marginTop: spacing(0.5),
    marginBottom: spacing(2),
    fontFamily: 'dinAlt',
    color: '#59595C'
  },
  bubbleDate: {
    float: (props) => (props === 1 && 'right') || 'left'
  },
  content: {
    width: '80%',
    float: (props) => (props === 1 && 'right') || 'left'
  },
  paper: {
    margin: spacing(0.75, 0),
    wordWrap: 'break-word',
    backgroundColor: (props) =>
      (props === 1 && palette.secondary.main) || palette.common.white
  },
  msg: {
    display: 'inline',
    whiteSpace: 'pre-line',
    color: (props) =>
      (props === 1 && palette.common.white) || palette.text.primary
  },
  backButton: {
    padding: spacing(0.5, 1.5),
    marginTop: '12px',
    marginLeft: '4px',
    position: 'absolute',
    background: 'white',
    borderRadius: '20px'
  },
  date: {
    fontFamily: 'dinAlt',
    color: '#59595C',
    whiteSpace: 'nowrap'
  },
  modalImgBox: {
    width: 300,
    maxHeight: 530
  },
  modalImg: {
    width: '100%',
    maxHeight: 530,
    objectFit: 'contain'
  },
  backdrop: {
    zIndex: 10,
    color: '#fff'
  },
  remove: {
    textAlign: 'center',
    marginTop: spacing(1),
    padding: spacing(1, 2),
    backgroundColor: palette.warning.main,
    color: palette.common.white
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chat_container: {
    marginTop: '60px'
  }
}))

const checkStatus = (status) => {
  switch (status) {
    case 'pending':
      return '#F96232'
    default:
      return '#1E91CF'
  }
}

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})(Box)

export default function ChatPage(props) {
  const { location } = props
  const { state } = location
  const history = useHistory()
  const [showImgModal, setShowImgModal] = useState({
    show: false,
    image: '',
    currIdx: null
  })

  const styles = useStyles({ state })

  const onImageClick = useCallback((src, idx = null) => {
    setShowImgModal({
      show: true,
      image: src,
      currIdx: idx
    })
  })

  function removePreviewImg(currIdx) {
    let newArr = [...images]
    if (isTngMp) {
      newArr.splice(currIdx, 1)
    } else {
      newArr[currIdx] = {}
    }
    setImages(newArr)
  }

  const isTngMp = props.location.platform === 'tngmp' ? true : false

  const {
    chatHistory,
    isLoadingChat,
    handleSubmit,
    date,
    data,
    setData,
    chatDetails,
    isSaving,
    images,
    setImages,
    issueClosed,
    notify,
    setNotify
  } = useChatPageLogic(
    props.location.state,
    props.match.params.id,
    props.location.platform,
    props.location.state.orderNumber
  )

  return (
    <Box className={styles.root}>
      {!isTngMp && <BackButton {...props} className={styles.backButton} />}

      <Box
        pb={4}
        position="absolute"
        mb={2}
        width="100%"
        display="flex"
        justifyContent="center"
        className={styles.chat_container}
        mt={-5}>
        <Paper display="block" space={0} style={{ width: '93%' }}>
          <Box pt={2}>
            <Row px={3} justifyContent="space-between">
              <Typography variant="h4">
                {' '}
                {state.orderNumber ? `Order #` + state.orderNumber : `Feedback`}
              </Typography>
              <Typography variant="body1" className={styles.date}>
                {format(new Date(state.createdAt), 'd MMMM y hh:mm a')}
              </Typography>
            </Row>
            <Row mt={2} px={3}>
              <Typography variant="h4" className={styles.type}>
                {state.title}
              </Typography>
              <FiberManualRecord className={styles.icon} />
              <Typography variant="h4" className={styles.status}>
                {chatDetails?.issueStatus}
              </Typography>
            </Row>
            <Typography variant="body1" className={styles.lastReply}>
              {`Last reply message: ${
                chatDetails?.updatedAt &&
                format(new Date(chatDetails?.updatedAt), 'd MMMM y hh:mm a')
              }`}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            p={1.5}
            mx={1.5}
            mt={1.5}
            mb={2}
            bgcolor="primary.light"
            borderRadius={10}>
            <Box style={{ height: '450px', overflow: 'auto' }}>
              {!isLoadingChat &&
                chatHistory.length > 0 &&
                chatHistory.map((chat, idx) => {
                  return (
                    <CommentSection
                      data={chat}
                      key={idx}
                      onImageClick={onImageClick}
                    />
                  )
                })}
            </Box>
            {!isLoadingChat && chatHistory.length <= 0 && (
              <Typography>
                Empty chat, start a new one by submitting a comment down below
              </Typography>
            )}
            {isLoadingChat && <Spinner className={styles.spinner} />}
          </Box>

          {chatDetails?.issueStatus === 'Closed' ? (
            <Box padding={'20px 20px 32px'} style={{ textAlign: 'center' }}>
              <Typography>This chat session has ended.</Typography>

              <Typography>
                Please submit a new feedback at{' '}
                <Link
                  style={{
                    textTransform: 'capitalize',
                    color: '#008CDB',
                    fontSize: '0.85rem',
                    fontWeight: 600,
                    padding: '4px'
                  }}
                  onClick={() => {
                    history.push({
                      pathname: `/`
                    })
                  }}>
                  Help Centre
                </Link>{' '}
                for further support.
              </Typography>
            </Box>
          ) : (
            <>
              <Typography
                variant="h4"
                style={{
                  color: '#1E91CF',
                  marginLeft: 24
                }}>
                COMMENTS
              </Typography>
              <TextFieldInput
                multiline
                disabled={state.issueStatus === 'Closed'}
                value={data.message}
                placeholder="Let us know about your order"
                onChange={(e) => setData({ ...data, message: e.target.value })}
              />
              <Row
                px={3}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  paddingTop: '12px'
                }}>
                <Typography
                  variant="h4"
                  style={{
                    color: '#1E91CF'
                  }}>
                  ATTACHMENTS
                </Typography>
                <Box display="flex" style={{ position: 'relative' }}>
                  {[0, 1, 2].map((i, idx) => {
                    return (
                      <ImageInput
                        setImages={setImages}
                        images={images}
                        key={idx}
                        idx={idx}
                        onImageClick={onImageClick}
                        setNotify={setNotify}
                        isTngMp={isTngMp}
                      />
                    )
                  })}
                </Box>
              </Row>
              <Row
                px={3}
                style={{
                  fontSize: '12px',
                  color: '#59595C'
                }}>
                <p style={{ margin: 0 }}>
                  <b>Note:</b> Only jpg or png files are supported (2MB limit)
                </p>
              </Row>
              <Box display="flex" justifyContent="center" mb={1.5} my={5}>
                <Button
                  title="Submit"
                  handleClick={() => handleSubmit()}
                  disabled={isSaving}
                />
              </Box>
            </>
          )}
        </Paper>
      </Box>
      <Notification
        show={notify.show}
        severity={notify.severity}
        message={notify.message}
        onClose={() => setNotify({ show: false, severity: '', message: '' })}
      />
      <Backdrop
        open={showImgModal.show}
        onClick={() =>
          setShowImgModal({ show: false, image: '', currIdx: null })
        }
        className={styles.backdrop}>
        <Box>
          <div className={styles.modalImgBox}>
            <img src={showImgModal.image} className={styles.modalImg} />
          </div>
          {showImgModal.currIdx !== null && (
            <Typography
              className={styles.remove}
              onClick={() => removePreviewImg(showImgModal.currIdx)}>
              remove image
            </Typography>
          )}
        </Box>
      </Backdrop>
    </Box>
  )
}

function CommentSection({ data, onImageClick }) {
  const styles = useStyles(data.isCustomerMessage)
  return (
    <Box display="block">
      <Box className={styles.content}>
        <Paper
          display="block"
          elevation={0}
          borderRadius={10}
          className={styles.paper}>
          {data.message && data.message.length > 0 ? (
            data.message.map((message, idx) => {
              return (
                <Typography variant="body2" className={styles.msg} key={idx}>
                  {message}
                </Typography>
              )
            })
          ) : (
            <Typography variant="body2" className={styles.msg}>
              {data.message}
            </Typography>
          )}
        </Paper>
        {data && data.image && data.image.length > 0 && (
          <Row className={styles.commentImgBox} my={1.5}>
            {data.image.map((src, idx) => {
              if (src !== '') {
                return (
                  <img
                    src={src}
                    className={styles.commentImg}
                    key={idx}
                    onClick={() => onImageClick(src)}
                  />
                )
              }
            })}
          </Row>
        )}
        <Typography variant="body2" className={styles.bubbleDate}>
          {`${format(new Date(data.issueDetailDateTime), 'd MMMM y hh:mm a')}`}
        </Typography>
      </Box>
    </Box>
  )
}
