import React from 'react'
import {
  Box,
  Typography,
  makeStyles,
  withStyles,
  Divider
} from '@material-ui/core'
import HistoryPin from 'src/components/Icon/history-pin-blue.png'
import LocationPin from 'src/components/Icon/pin-green.png'
import { format } from 'date-fns'
import formatter from 'src/utils/formatter'
import helper from 'src/utils/helper'

const useStyles = makeStyles(({ palette, spacing }) => ({
  image: {
    width: 100,
    height: 100,
    borderRadius: '15px'
    // boxShadow: '1px 1px 1px 1px #9E9E9E'
  },
  status: {
    marginTop: spacing(2),
    marginBottom: spacing(0.5),
    color: palette.primary.main,
    textTransform: 'uppercase'
  },
  detailStatus: {
    color: palette.primary.main,
    textTransform: 'uppercase',
    width: '40%',
    textAlign: 'right',
    marginLeft: '5px'
  },
  icon: {
    marginRight: spacing(1.5)
  },
  parcelDetailBox: {
    display: 'flex',
    flexDirection: 'column'
  },
  parcelDetailLocation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row'
  }
})(Box)

export default function ParcelOrderItem({ type, order, navigateTo }) {
  const styles = useStyles()

  return (
    <>
      <Row>
        <Box
          mt={1}
          mb={1.5}
          pl={1}
          style={{ width: '100%' }}
          onClick={() => navigateTo('parcelOrder', order)}>
          <Row justifyContent="space-between" mb={1}>
            <Typography variant="h4">Order #{order.orderNumber}</Typography>
            <Typography variant="body2">
              {order.totalOrderItems} item[s]
            </Typography>
          </Row>
          <Typography variant="body2">{`${format(
            new Date(order.orderDate),
            'dd MMMM y hh:mm a'
          )}`}</Typography>
        </Box>
      </Row>
      <Divider />
      {order.detail.map((item, idx) => {
        item['senderName'] = order.senderName
        item['senderMobileNumber'] = order.senderMobileNumber
        return (
          <div key={idx}>
            <Box
              mt={1}
              mb={1.5}
              pl={1}
              onClick={() => navigateTo('parcelOrderDetail', item)}>
              <Row justifyContent="space-between" mb={1}>
                <Typography variant="h4">#{item.trackerNumber}</Typography>
                <Typography variant="body2" className={styles.quantity}>
                  1 item[s]
                </Typography>
              </Row>
              <Row
                justifyContent="space-between"
                alignItems="flex-start"
                my={1}>
                <div className={styles.parcelDetailBox}>
                  <div className={styles.parcelDetailLocation}>
                    <div className={styles.icon}>
                      <img
                        src={HistoryPin}
                        alt="icon"
                        height="15px"
                        width="15px"
                      />
                    </div>
                    <Typography variant="body2">
                      {item.dropOffStoreName}
                    </Typography>
                  </div>
                  <div className={styles.parcelDetailLocation}>
                    <div className={styles.icon}>
                      <img
                        src={LocationPin}
                        alt="icon"
                        height="15px"
                        width="15px"
                      />
                    </div>
                    <Typography variant="body2">
                      {item.collectionStoreName}
                    </Typography>
                  </div>
                </div>
                <Typography variant="h4" className={styles.detailStatus}>
                  {item.status ? item.status : 'Pending Parcel Drop Off'}
                </Typography>
              </Row>
              <Row mt={1}>
                <Typography variant="body2">
                  {`${format(new Date(order.orderDate), 'dd MMMM y hh:mm a')}`}
                </Typography>
              </Row>
            </Box>
          </div>
        )
      })}
    </>
  )
}
