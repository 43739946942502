import React from 'react'
import {
  Box,
  Typography,
  makeStyles,
  withStyles,
  Divider
} from '@material-ui/core'
import {
  Order,
  BackButton,
  Icon,
  Spinner,
  Button,
  ImageInput,
  Feedback,
  TextFieldInput,
  ParcelOrder,
  ParcelDetail
} from 'src/components'
import theme from 'src/themes'
import { format } from 'date-fns'

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})(Box)

function RightDetail({
  selectedOrder,
  activeOrder,
  styles,
  currTopic,
  parentStatements,
  isChatSupport,
  isChildStatement,
  resetParents,
  resetState,
  data,
  setData,
  contact,
  setContact,
  images,
  setImages,
  isSaving,
  handleSubmit,
  onImageClick,
  onSelectTopic,
  isTngMp,
  setNotify,
  highPriority,
  message,
  navigateTo
}) {
  // console.log("1: ", selectedOrder)
  // console.log("2: ", activeOrder)
  return (
    <>
      {selectedOrder.orderType === 'Parcel' &&
      activeOrder.type !== 'parcelOrderDetail' ? (
        <Box py={2}>
          <Row px={3}>
            <Typography variant="h4">
              Order No #{activeOrder.orderInfo.orderNumber}
            </Typography>
            <Row mx={1.5} mt={0.25}>
              <Typography variant="body2">
                {activeOrder.orderInfo.totalOrderItems} item[s]
              </Typography>
            </Row>
          </Row>
          <Box mb={2} px={3}>
            <Typography variant="body2">
              {`${format(
                new Date(activeOrder.orderInfo.orderDate),
                'dd MMMM y hh:mm a'
              )}`}
            </Typography>
          </Box>
          <Box borderTop={`1px solid ${theme.palette.border.light}`} pt={1.5}>
            <Row
              justifyContent="space-between"
              alignItems="flex-start"
              mx={3}
              mb={1.5}>
              <div className={styles.orderDetailBox}>
                <Typography variant="body2" className={styles.orderDetailTitle}>
                  Sender details
                </Typography>
                <Typography variant="body2" className={styles.orderDetailDesc}>
                  {activeOrder.orderInfo.senderName}
                </Typography>
                <Typography
                  variant="body2"
                  className={styles.orderDetailBlueText}>
                  {activeOrder.orderInfo.senderMobileNumber}
                </Typography>
              </div>
              <div className={styles.orderDetailBox}>
                <Typography variant="body2" className={styles.orderDetailTitle}>
                  Origin
                </Typography>
                <Typography variant="body2" className={styles.orderDetailDesc}>
                  {activeOrder.orderInfo.detail[0].dropOffStoreName}
                </Typography>
              </div>
            </Row>
          </Box>
          <Box borderTop={`1px solid ${theme.palette.border.light}`} pt={1.5}>
            <Box mx={2} display="flex" flexDirection="column">
              <Typography variant="body2" className={styles.orderDetailTitle}>
                Orders
              </Typography>
              {activeOrder.orderInfo.detail.map((item, idx) => {
                item['senderName'] = activeOrder.orderInfo.senderName
                item['senderMobileNumber'] =
                  activeOrder.orderInfo.senderMobileNumber
                return (
                  <Box
                    border={`1px solid ${theme.palette.border.light}`}
                    mt={1}
                    borderRadius="10px"
                    boxShadow="10px"
                    key={idx}
                    onClick={() => navigateTo('parcelOrderDetail', item)}>
                    <Row
                      justifyContent="space-between"
                      alignItems="flex-start"
                      mx={1}
                      mb={1.5}>
                      <div className={styles.orderDetailBox}>
                        <Typography
                          variant="body2"
                          className={styles.recipientTitle}>
                          Recipient Name
                        </Typography>
                        <Typography
                          variant="body2"
                          className={styles.orderDetailDesc}>
                          {item.receiverName}
                        </Typography>
                      </div>
                      <div className={styles.orderDetailBox}>
                        <Typography
                          variant="body2"
                          className={styles.recipientTitle}>
                          Destination
                        </Typography>
                        <Typography
                          variant="body2"
                          className={styles.orderDetailDesc}>
                          {item.collectionStoreName}
                        </Typography>
                      </div>
                    </Row>
                    <Row
                      justifyContent="space-between"
                      alignItems="flex-start"
                      mx={1}
                      mb={1.5}>
                      <div className={styles.orderDetailBox}>
                        <Typography
                          variant="body2"
                          className={styles.orderDetailTitle}>
                          Tracking Number
                        </Typography>
                        <Typography
                          variant="body2"
                          className={styles.orderDetailBlueText}>
                          {item.trackerNumber}
                        </Typography>
                      </div>
                      <div className={styles.orderDetailBox}>
                        <Typography
                          variant="body2"
                          className={styles.orderDetailTitle}>
                          Status
                        </Typography>
                        <Typography
                          variant="body2"
                          className={styles.orderDetailStatus}>
                          {selectedOrder.orderType === 'Parcel' &&
                            (item.status
                              ? item.status
                              : 'Pending Parcel Drop Off')}
                          {selectedOrder.orderType !== 'Parcel' && item.status}
                        </Typography>
                      </div>
                    </Row>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          {selectedOrder.orderType === 'Parcel' &&
            activeOrder.type !== 'parcelOrderDetail' &&
            !isChildStatement &&
            !currTopic &&
            !isTngMp && (
              <Box
                py={2}
                className={styles.backButton}
                onClick={() => navigateTo('order', activeOrder.orderInfo)}>
                <Typography variant="h3" className={styles.backText}>
                  Back
                </Typography>
              </Box>
            )}
          {isChildStatement && !isTngMp && (
            <Box
              py={2}
              className={styles.backButton}
              onClick={() => resetParents()}>
              <Typography variant="h3" className={styles.backText}>
                Back
              </Typography>
            </Box>
          )}
          {currTopic && !isTngMp && (
            <Box
              py={2}
              className={styles.backButton}
              onClick={() => resetState(parentStatements)}>
              <Typography variant="h3" className={styles.backText}>
                Back
              </Typography>
            </Box>
          )}
          <Box py={2}>
            <Row px={3}>
              <Typography variant="h4">
                Order No #{activeOrder.orderInfo.orderNumber}
              </Typography>
              {selectedOrder.orderType !== 'Parcel' && (
                <Row mx={1.5} mt={0.25}>
                  <Typography variant="body1">
                    {activeOrder.orderInfo.totalOrderItems} item[s]
                  </Typography>
                </Row>
              )}
            </Row>
            <Box mb={2} px={3}>
              <Typography variant="h4" className={styles.status}>
                {selectedOrder.orderType === 'Parcel' &&
                  (activeOrder.orderInfo.status
                    ? activeOrder.orderInfo.status
                    : 'Pending Parcel Drop Off')}
                {selectedOrder.orderType !== 'Parcel' &&
                  activeOrder.orderInfo.status}
              </Typography>
              {selectedOrder.orderType === 'Voucher' ? (
                <Typography variant="body1">
                  {`${format(
                    new Date(activeOrder.orderInfo.orderDateTime),
                    'dd MMMM y hh:mm a'
                  )}`}
                </Typography>
              ) : selectedOrder.orderType === 'Parcel' ? (
                <Typography variant="body1">
                  {`${format(
                    new Date(activeOrder.orderInfo.orderDate),
                    'dd MMMM y hh:mm a'
                  )}`}
                </Typography>
              ) : (
                <Typography variant="body1">
                  {`${format(
                    new Date(activeOrder.orderInfo.scheduledDateTime),
                    'dd MMMM y hh:mm a'
                  )}`}
                </Typography>
              )}
            </Box>
            {selectedOrder.orderType === 'Parcel' && (
              <ParcelDetail
                state={activeOrder.orderInfo}
                currTopic={currTopic}
              />
            )}
            {!currTopic &&
              parentStatements.map((item, idx) => {
                return (
                  <Row
                    key={idx}
                    py={1.5}
                    px={3}
                    className={
                      selectedOrder.orderType === 'Parcel'
                        ? styles.parcelSupportStatementBox
                        : styles.faq
                    }
                    onClick={() => onSelectTopic(item)}>
                    <Typography
                      variant="body1"
                      style={{ flex: 1 }}
                      className={
                        selectedOrder.orderType === 'Parcel' &&
                        styles.parcelSupportStatement
                      }>
                      {item.statement}
                    </Typography>
                    <Icon
                      name="next"
                      style={{ color: theme.palette.primary.main }}
                    />
                  </Row>
                )
              })}
            {currTopic && (
              <Box
                borderTop={
                  selectedOrder.orderType === 'Parcel'
                    ? `1px solid ${theme.palette.border.light}`
                    : `3px solid ${theme.palette.primary.light}`
                }
                pt={1.5}>
                <Row justifyContent="space-between" mx={3} mb={1.5}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    {selectedOrder.orderType !== 'Voucher'
                      ? currTopic
                      : 'Voucher Order'}
                  </Typography>
                </Row>
                {message &&
                  message.length > 0 &&
                  message.map((str, idx) => {
                    return (
                      <Box key={idx} px={3}>
                        <Typography variant="body1" className={styles.faqMsg}>
                          {str}
                        </Typography>
                      </Box>
                    )
                  })}
              </Box>
            )}
            {isChatSupport && (
              <Box>
                <Typography
                  variant="h4"
                  style={{
                    color: '#1E91CF',
                    marginLeft: 24
                  }}>
                  COMMENTS
                </Typography>
                <TextFieldInput
                  placeholder="Let us know about your order"
                  value={data.message}
                  onChange={(e) =>
                    setData({ ...data, message: e.target.value })
                  }
                  multiline
                />
                {highPriority && (
                  <div>
                    <Typography
                      variant="h4"
                      style={{
                        color: '#1E91CF',
                        marginLeft: 24,
                        marginTop: 10
                      }}>
                      CONTACT
                    </Typography>
                    <TextFieldInput
                      placeholder="Please enter your phone number"
                      value={contact.message}
                      onChange={(e) =>
                        setContact({ ...contact, message: e.target.value })
                      }
                    />
                  </div>
                )}
                <Row px={3}>
                  <Typography
                    variant="h4"
                    style={{
                      color: '#1E91CF'
                    }}>
                    ATTACHMENTS
                  </Typography>
                  <Box
                    display="flex"
                    style={{ position: 'relative', top: 40, right: 90 }}>
                    {[0, 1, 2].map((i, idx) => {
                      return (
                        <ImageInput
                          setImages={setImages}
                          images={images}
                          idx={idx}
                          key={idx}
                          onImageClick={onImageClick}
                          isTngMp={isTngMp}
                          setNotify={setNotify}
                          isTngMp={isTngMp}
                        />
                      )
                    })}
                  </Box>
                </Row>
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{ marginTop: 40 }}>
                  <Button
                    title="Submit"
                    handleClick={() => handleSubmit()}
                    disabled={isSaving}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default RightDetail
