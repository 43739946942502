import React from 'react'
import {
  Box,
  Typography,
  makeStyles,
  withStyles,
  Card,
  CardContent
} from '@material-ui/core'
import LocationIcon from '../../components/Icon/Location.svg'
import theme from 'src/themes'
import { format } from 'date-fns'
import clsx from 'clsx'
import './ParcelDetail.scss'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: palette.secondary.light
  },
  orderDetailBox: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    width: '50%'
  },
  orderDetailBox2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  orderDetailTitle: {
    color: '#B1B1B1'
  },
  orderDetailDesc: {
    fontWeight: 'bold',
    marginTop: '5px'
  },
  orderStatus: {
    marginTop: '5px'
  },
  orderDetailBlueText: {
    color: palette.primary.main,
    marginTop: '5px'
  },
  steps: {
    // fontSize: "0.7rem",
    // fontFamily: 'din_bold, din_regular',
    color: '#6D6E71'
  },
  progressItem: {
    height: '2.5rem'
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})(Box)

export default function ParcelDetail(props) {
  const styles = useStyles()
  const { state, currTopic } = props
  const { parcelStatusLog } = state

  return (
    <>
      <Box borderTop={`1px solid ${theme.palette.border.light}`} pt={1.5}>
        <Row
          justifyContent="space-between"
          alignItems="flex-start"
          mx={3}
          mb={1.5}>
          <div className={styles.orderDetailBox}>
            <Typography variant="body2" className={styles.orderDetailTitle}>
              Sender details
            </Typography>
            <Typography variant="body2" className={styles.orderDetailDesc}>
              {state.senderName}
            </Typography>
            <Typography variant="body2" className={styles.orderDetailBlueText}>
              {state.senderMobileNumber}
            </Typography>
          </div>
          <div className={styles.orderDetailBox}>
            <Typography variant="body2" className={styles.orderDetailTitle}>
              Origin
            </Typography>
            <Typography variant="body2" className={styles.orderDetailDesc}>
              {state.dropOffStoreName}
            </Typography>
          </div>
        </Row>
      </Box>
      <Box borderTop={`1px solid ${theme.palette.border.light}`} pt={1.5}>
        <Row
          justifyContent="space-between"
          alignItems="flex-start"
          mx={3}
          mb={1.5}>
          <div className={styles.orderDetailBox}>
            <Typography variant="body2" className={styles.orderDetailTitle}>
              Receiver details
            </Typography>
            <Typography variant="body2" className={styles.orderDetailDesc}>
              {state.receiverName}
            </Typography>
            <Typography variant="body2" className={styles.orderDetailBlueText}>
              {state.receiverMobileNumber}
            </Typography>
          </div>
          <div className={styles.orderDetailBox}>
            <Typography variant="body2" className={styles.orderDetailTitle}>
              Destination
            </Typography>
            <Typography variant="body2" className={styles.orderDetailDesc}>
              {state.collectionStoreName}
            </Typography>
          </div>
        </Row>
      </Box>
      {!currTopic && (
        <Box borderTop={`1px solid ${theme.palette.border.light}`} pt={1.5}>
          <Row
            justifyContent="space-between"
            alignItems="flex-start"
            mx={3}
            mb={1.5}>
            <div className={styles.orderDetailBox}>
              <Typography variant="body2" className={styles.orderDetailTitle}>
                Tracking Number
              </Typography>
              <Typography variant="body2" className={styles.orderDetailDesc}>
                {state.trackerNumber}
              </Typography>
            </div>
          </Row>
          <Row
            justifyContent="flex-start"
            alignItems="flex-start"
            flexDirection="row"
            mx={3}>
            <div className={styles.orderDetailBox2}>
              <Typography variant="body2" className={styles.orderDetailTitle}>
                History
              </Typography>
            </div>
          </Row>
          <Row
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="row"
            mx={3}
            mb={2}>
            <Card elevation={0} className="parcel-history-section">
              <CardContent>
                <div className="parcel-progress">
                  {parcelStatusLog.map((item, i) => (
                    <React.Fragment key={i}>
                      <div className={styles.progressItem}>
                        <img
                          style={{ display: 'block' }}
                          src={LocationIcon}
                          alt="icon"
                          height="20px"
                          width="20px"
                        />
                      </div>

                      {i !== parcelStatusLog.length - 1 && (
                        <div className="dotted-line" />
                      )}
                    </React.Fragment>
                  ))}
                </div>
                <div style={{ flex: '1' }}>
                  {parcelStatusLog.map((item, i) => (
                    <React.Fragment key={i}>
                      <div className={styles.progressItem}>
                        <Typography
                          variant="body2"
                          className={styles.orderDetailTitle}>
                          {`${format(
                            new Date(item.createdAt),
                            'dd MMMM y hh:mm a'
                          )}`}
                        </Typography>

                        <Typography
                          variant="body1"
                          className={clsx(
                            styles.orderDetailDesc,
                            styles.steps
                          )}>
                          {item.location ? item.location : ''}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={clsx(styles.orderStatus, styles.steps)}>
                          {item.status}
                        </Typography>
                      </div>
                      {i !== parcelStatusLog.length - 1 && (
                        <div style={{ margin: '0.5rem 0' }} />
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </CardContent>
            </Card>
          </Row>
        </Box>
      )}
    </>
  )
}
