import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { getChatList } from 'src/graphql/queries'

const useChatList = (token, platform) => {
  const [chats, setChats] = useState([])
  const [isChatListLoading, setIsChatListLoading] = useState(true)
  const [isTngMp, setIsTngMp] = useState(true)
  const [userPlatform, setUserPlatform] = useState()

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    try {
      if (['TOKEN_HERE', 'null', 'undefined'].includes(token ?? 'undefined')) {
        throw new Error('No user')
      }

      if (platform == 'tngmp') {
        setIsTngMp(true)
      } else if (platform == 'pudoparcel') {
        platform = 'pudoparcel'
        setIsTngMp(false)
      } else {
        platform = 'ecommerce'
        setIsTngMp(false)
      }

      setUserPlatform(platform)
      const params = {
        token: token,
        platform: platform
      }

      const response = await API.graphql(graphqlOperation(getChatList, params))
      let chatlist = response.data.getChatList
      if (response && chatlist.length > 0) {
        chatlist = chatlist.sort((a, b) => b.hasNewMessage - a.hasNewMessage)
        setTimeout(() => {
          setChats(chatlist)
          setIsChatListLoading(false)
        }, 500)
      } else {
        setTimeout(() => {
          setIsChatListLoading(false)
        }, 500)
      }
    } catch (error) {
      console.log('err', error)
      setTimeout(() => {
        setIsChatListLoading(false)
      }, 500)
    }
  }

  return { chats, isChatListLoading }
}

export default useChatList
