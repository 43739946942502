import React from 'react'
import {
  Box,
  Typography,
  TextField as CoreTextField,
  makeStyles,
  withStyles,
  InputAdornment
} from '@material-ui/core'

const useStyles = makeStyles(({ palette }) => ({
  title: {
    textTransform: 'uppercase',
    color: palette.primary.main,
    fontWeight: 600
  }
}))

const TextField = withStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(1.5)
  }
}))(CoreTextField)

export default function TextFieldInput(props) {
  const {
    title,
    placeholder,
    multiline = false,
    value,
    onChange,
    boxStyles = { px: 3 },
    disabled = false,
    readOnly = false,
    isPhoneNumber = false
  } = props
  const styles = useStyles()
  return (
    <Box {...boxStyles}>
      <Typography variant="body2" className={styles.title}>
        {title}
      </Typography>
      <TextField
        fullWidth
        multiline={multiline}
        rows={4}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        spellCheck={false}
        style={{ backgroundColor: 'white' }}
        disabled={disabled}
        InputProps={{
          readOnly: readOnly,
          ...(isPhoneNumber && {
            startAdornment: (
              <InputAdornment position="start">
                <Typography style={{ color: '#59595C' }}>+60</Typography>
              </InputAdornment>
            )
          })
        }}
        {...(isPhoneNumber && {
          onKeyDown: (evt) => {
            if (['e', 'E', '+', '-', '.'].includes(evt.key))
              evt.preventDefault()
          }
        })}
      />
    </Box>
  )
}
