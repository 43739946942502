import React from 'react'
import { Box, Typography, makeStyles, withStyles } from '@material-ui/core'
import { Paper, BackButton } from 'src/components'
import { format } from 'date-fns'
import theme from 'src/themes'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: palette.secondary.light
  },

  backButton: {
    padding: spacing(0.5, 1.5),
    marginTop: '12px',
    marginLeft: '4px',
    position: 'absolute',
    background: 'white',
    borderRadius: '20px'
  },

  box_container: {
    marginTop: '60px'
  },
  orderDetailBox: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    width: '50%'
  },
  orderDetailTitle: {
    color: '#B1B1B1'
  },
  orderDetailDesc: {
    fontWeight: 'bold',
    marginTop: '5px'
  },
  orderDetailBlueText: {
    color: palette.primary.main,
    marginTop: '5px'
  },
  recipientTitle: {
    marginTop: '5px',
    color: '#B1B1B1'
  },
  orderDetailStatus: {
    color: palette.primary.main,
    marginTop: '5px',
    textTransform: 'uppercase'
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})(Box)

export default function ParcelOrderPage(props) {
  const styles = useStyles()
  const { location, history } = props
  const { state } = location

  return (
    <Box className={styles.root}>
      <BackButton {...props} className={styles.backButton} />
      <Box
        position="absolute"
        width="100%"
        display="flex"
        justifyContent="center"
        className={styles.box_container}
        mb={2}
        mt={-5}
        pb={4}>
        <Paper display="block" space={0} style={{ width: '93%' }}>
          <Box py={2}>
            <Row px={3}>
              <Typography variant="h4">
                Order No #{state.orderNumber}
              </Typography>
              <Row mx={1.5} mt={0.25}>
                <Typography variant="body2">
                  {state.totalOrderItems} item[s]
                </Typography>
              </Row>
            </Row>
            <Box mb={2} px={3}>
              <Typography variant="body2">
                {`${format(new Date(state.orderDate), 'dd MMMM y hh:mm a')}`}
              </Typography>
            </Box>
            <Box borderTop={`1px solid ${theme.palette.border.light}`} pt={1.5}>
              <Row
                justifyContent="space-between"
                alignItems="flex-start"
                mx={3}
                mb={1.5}>
                <div className={styles.orderDetailBox}>
                  <Typography
                    variant="body2"
                    className={styles.orderDetailTitle}>
                    Sender details
                  </Typography>
                  <Typography
                    variant="body2"
                    className={styles.orderDetailDesc}>
                    {state.senderName}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={styles.orderDetailBlueText}>
                    {state.senderMobileNumber}
                  </Typography>
                </div>
                <div className={styles.orderDetailBox}>
                  <Typography
                    variant="body2"
                    className={styles.orderDetailTitle}>
                    Origin
                  </Typography>
                  <Typography
                    variant="body2"
                    className={styles.orderDetailDesc}>
                    {state.dropOffStoreName}
                  </Typography>
                </div>
              </Row>
            </Box>
            <Box borderTop={`1px solid ${theme.palette.border.light}`} pt={1.5}>
              <Box mx={2} display="flex" flexDirection="column">
                <Typography variant="body2" className={styles.orderDetailTitle}>
                  Orders
                </Typography>
                {state.detail.map((item, idx) => {
                  item['senderName'] = state.senderName
                  item['senderMobileNumber'] = state.senderMobileNumber
                  return (
                    <Box
                      border={`1px solid ${theme.palette.border.light}`}
                      mt={1}
                      borderRadius="10px"
                      boxShadow="10px"
                      key={idx}
                      onClick={() =>
                        history.push({
                          pathname: `/order/parcel-detail/${item.parcelOrderDetailId}`,
                          state: item,
                          platform: props.location.platform
                        })
                      }>
                      <Row
                        justifyContent="space-between"
                        alignItems="flex-start"
                        mx={1}
                        mb={1.5}>
                        <div className={styles.orderDetailBox}>
                          <Typography
                            variant="body2"
                            className={styles.recipientTitle}>
                            Recipient Name
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.orderDetailDesc}>
                            {item.receiverName}
                          </Typography>
                        </div>
                        <div className={styles.orderDetailBox}>
                          <Typography
                            variant="body2"
                            className={styles.recipientTitle}>
                            Destination
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.orderDetailDesc}>
                            {item.collectionStoreName}
                          </Typography>
                        </div>
                      </Row>
                      <Row
                        justifyContent="space-between"
                        alignItems="flex-start"
                        mx={1}
                        mb={1.5}>
                        <div className={styles.orderDetailBox}>
                          <Typography
                            variant="body2"
                            className={styles.orderDetailTitle}>
                            Tracking Number
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.orderDetailBlueText}>
                            {item.trackerNumber}
                          </Typography>
                        </div>
                        <div className={styles.orderDetailBox}>
                          <Typography
                            variant="body2"
                            className={styles.orderDetailTitle}>
                            Status
                          </Typography>
                          <Typography
                            variant="body2"
                            className={styles.orderDetailStatus}>
                            {item.status}
                          </Typography>
                        </div>
                      </Row>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}
