import React, { useState, useEffect, useCallback } from 'react'
import Resizer from 'react-image-file-resizer'
import { API, graphqlOperation } from 'aws-amplify'
import {
  getFeedback,
  getIssue,
  getIssueDetailList,
  getS3FileUrl
} from 'src/graphql/queries'
import {
  addIssue,
  generateS3UploadLink,
  submitFeedback
} from 'src/graphql/mutations'
import { uploadImgToS3 } from 'src/utils/helper'
import uuid from 'uuid'
import _ from 'lodash'

const useChatPageLogic = (chat, issueId, platform, orderNumber) => {
  const [chatDetails, setChatDetails] = useState(null)
  const [date, setDate] = useState(null)
  const [images, setImages] = useState([{}, {}, {}])
  const [feedbackImages, setFeedBackImages] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [chatHistory, setChatHistory] = useState([])
  const [isLoadingChat, setIsLoadingChat] = useState(true)
  const [isTngMp, setIsTngMp] = useState(true)
  const [data, setData] = useState({
    message: ''
  })
  const [notify, setNotify] = useState({
    show: false,
    severity: '',
    message: ''
  })
  const [showImgModal, setShowImgModal] = useState({
    show: false,
    image: '',
    currIdx: null
  })

  useEffect(() => {
    if (!_.isEmpty(chat)) {
      setDate(chat.updatedAt)
      getIssueDetail()
      getIssueData()
    }
  }, [chat])

  useEffect(() => {
    const interval = setInterval(() => {
      getIssueData()
      getIssueDetail()
    }, 60000)
    return () => clearInterval(interval)
  }, [])

  function getToken() {
    if (global.jwtToken) {
      return global.jwtToken
    } else if (
      sessionStorage.getItem('token') !== null &&
      sessionStorage.getItem('token') !== 'TOKEN_HERE'
    ) {
      return sessionStorage.getItem('token')
    }

    return null
  }

  async function getIssueData() {
    try {
      if (orderNumber) {
        const { data } = await API.graphql(
          graphqlOperation(getIssue, { issueId: issueId })
        )
        setChatDetails(data?.getIssue)
      } else {
        const { data } = await API.graphql(
          graphqlOperation(getFeedback, { feedbackId: issueId })
        )
        setChatDetails(data?.getFeedback)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getIssueDetail() {
    try {
      setIsLoadingChat(true)
      if (platform == 'tngmp') {
        setIsTngMp(true)
      } else {
        setIsTngMp(false)
      }
      const id = issueId
      const params = {
        issueId: id
      }

      let array = []
      const response = await API.graphql(
        graphqlOperation(getIssueDetailList, params)
      )
      if (response.data.getIssueDetailList) {
        response.data.getIssueDetailList.map((item) => {
          var details = {
            message: item.message.split(/(\r\n|\n|\r)/gm),
            image: [item.image1, item.image2, item.image3],
            isCustomerMessage: item.isCustomerMessage,
            issueDetailDateTime: item.issueDetailDateTime,
            issueDetailId: item.issueDetailId
          }
          array.push(details)
        })
      }
      function compare_item(a, b) {
        if (a.issueDetailDateTime < b.issueDetailDateTime) {
          return -1
        } else if (a.issueDetailDateTime > b.issueDetailDateTime) {
          return 1
        } else {
          return 0
        }
      }
      array.sort(compare_item)
      for (let i = 0; i < array.length; i++) {
        let imageArr = []
        let newArr = [...array[i].image]
        for (let j = 0; j < newArr.length; j++) {
          if (newArr[j] !== '') {
            const res = await API.graphql(
              graphqlOperation(getS3FileUrl, {
                token: getToken(),
                key: newArr[j]
              })
            )
            if (res.data.getS3FileUrl) {
              imageArr.push(res.data.getS3FileUrl)
              setFeedBackImages(res.data.getS3FileUrl)
            }
          }
        }
        array[i].image = imageArr
      }
      setIsLoadingChat(false)
      setChatHistory(array)
    } catch (error) {
      console.log(error)
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        600,
        'JPEG',
        90,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',')
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString })
  }

  async function getS3Link(count, path) {
    let token = getToken()
    let fileName = `image${count}.jpg`
    let params = {
      token: token,
      folderName: path,
      fileName: fileName,
      platform: platform
    }
    let newImage
    if (isTngMp) {
      params['platform'] = 'tngmp'
      newImage = images[count]
    } else {
      const compressedImage = await resizeFile(images[count])
      newImage = dataURIToBlob(compressedImage)
    }

    const response = await API.graphql(
      graphqlOperation(generateS3UploadLink, params)
    )

    try {
      const uploadResponse = await uploadImgToS3(
        response.data.generateS3UploadLink,
        newImage,
        isTngMp,
        setNotify,
        handleTngRes
      )

      if (isTngMp) {
        localStorage.removeItem('currentUploadStatus')
        return uploadResponse
      }

      if (uploadResponse.status == 204) {
        return true
      } else if (uploadResponse.status == 400) {
        return false
      }
    } catch (error) {
      console.log(error)
    }
  }

  function handleTngRes(tngRes) {
    if (tngRes && (tngRes.statusCode === '200' || tngRes.statusCode === 200)) {
      return true
    }
    return false
  }

  async function handleAddIssue(params) {
    try {
      const { data } = await API.graphql(graphqlOperation(addIssue, params))
      if (data?.addIssue?.statusCode === '200') {
        setNotify({
          show: true,
          severity: 'success',
          message: 'Success! Added new comment successfully'
        })
        setDate(new Date())
        setTimeout(() => {
          setData({ ...data, message: '' })
          if (isTngMp) {
            setImages([])
          } else {
            setImages([{}, {}, {}])
          }
          getIssueDetail()
          setIsSaving(false)
        }, 2000)
      } else {
        throw new Error(
          data?.addIssue?.message ??
            'Failed! Something went wrong, please try again later.'
        )
      }
    } catch (error) {
      if (isTngMp) {
        // eslint-disable-next-line no-undef
        my.postMessage({
          action: 'submit err',
          data: error
        })
        // eslint-disable-next-line no-undef
      }
      setIsSaving(false)
      console.log(error)
    }
  }

  async function handleSubmitFeedback(params) {
    try {
      const { data } = await API.graphql(
        graphqlOperation(submitFeedback, params)
      )

      if (data?.submitFeedback?.statusCode === '200') {
        setNotify({
          show: true,
          severity: 'success',
          message: 'Success! Added new comment successfully'
        })
        setDate(new Date())
        setTimeout(() => {
          setData({ ...data, message: '' })
          if (isTngMp) {
            setImages([])
          } else {
            setImages([{}, {}, {}])
          }
          getIssueDetail()
          setIsSaving(false)
        }, 2000)
      } else {
        throw new Error(
          data?.submitFeedback?.message ??
            'Failed! Something went wrong, please try again later.'
        )
      }
    } catch (error) {
      if (isTngMp) {
        // eslint-disable-next-line no-undef
        my.postMessage({
          action: 'submit err',
          data: error
        })
        // eslint-disable-next-line no-undef
      }
      setIsSaving(false)
      console.log(error)
    }
  }

  async function handleSubmit() {
    let userPlatform = ''
    let token = getToken()
    var re = /^[a-zA-Z0-9!?@#$&*~_^<>%()`.+,/" -:;'/\n/]*$/

    if (isTngMp) {
      userPlatform = 'tngmp'
    } else {
      userPlatform = 'ecommerce'
    }

    if (data.message.replace(/\s/g, '') !== '' && re.test(data.message)) {
      try {
        setIsSaving(true)
        const id = issueId

        if (chat.orderNumber) {
          let imagepath = `issue/${chat.customerAccountNo}/${
            chat.orderNumber
          }/${uuid.v4()}`

          for (const i of images.keys()) {
            if ((!isTngMp && images[i].preview) || (isTngMp && images[i])) {
              let uploadSuccess = await getS3Link(i, imagepath)

              if (!isTngMp && !uploadSuccess) {
                setNotify({
                  show: true,
                  severity: 'error',
                  message: 'Failed! Image size exceeds 15 MB.'
                })

                setIsSaving(false)
              } else if (isTngMp && !uploadSuccess) {
                setIsSaving(false)
              }
            }
          }

          const params = {
            orderNumber: chat.orderNumber,
            title: chat.title,
            customerAccountNo: token,
            issueDateTime: new Date(),
            issueStatus: 'New',
            totalQuantity: chat.totalOrderItems,
            totalAmount: chat.grandTotal,
            totalMessage: 0,
            message: data.message,
            image1:
              (!isTngMp && images[0].preview) || (isTngMp && images[0])
                ? `${imagepath}/image0.jpg`
                : '',
            image2:
              (!isTngMp && images[1].preview) || (isTngMp && images[1])
                ? `${imagepath}/image1.jpg`
                : '',
            image3:
              (!isTngMp && images[2].preview) || (isTngMp && images[2])
                ? `${imagepath}/image2.jpg`
                : '',
            issueId: id,
            phoneNumber: '',
            platform: platform
          }

          await handleAddIssue(params)
        } else {
          let imagepath = `feedback/${id}`
          let uploadPhoto = []

          for (const i of images.keys()) {
            if ((!isTngMp && images[i].preview) || (isTngMp && images[i])) {
              let uploadSuccess = await getS3Link(i, imagepath)
              if (!isTngMp && !uploadSuccess) {
                setNotify({
                  show: true,
                  severity: 'error',
                  message: 'Failed! Image size exceeds 15 MB.'
                })
                setIsSaving(false)
                return
              } else if (isTngMp && uploadSuccess !== true) {
                setIsSaving(false)
                return
              }
              uploadPhoto.push(`${imagepath}/image${i}.jpg`)
            }
          }

          const params = {
            feedbackId: id,
            description: data.message,
            uploadPhoto: uploadPhoto,
            token: token ? token : '',
            platform: platform
          }

          await handleSubmitFeedback(params)
        }
      } catch (error) {
        if (isTngMp) {
          // eslint-disable-next-line no-undef
          my.postMessage({
            action: 'submit err',
            data: error
          })
        }
        setNotify({
          show: true,
          severity: 'error',
          message: 'Failed! Something went wrong, please try again later.'
        })
        setIsSaving(false)
        console.log(error)
      }
    } else {
      if (!re.test(data.message)) {
        setNotify({
          show: true,
          severity: 'error',
          message:
            'Please enter only alphabetical and numeral letters. Thank you.'
        })
      } else {
        setNotify({
          show: true,
          severity: 'error',
          message: 'Error! Comment field is required.'
        })
      }
    }
  }

  const onImageClick = useCallback((src, idx = null) => {
    setShowImgModal({
      show: true,
      image: src,
      currIdx: idx
    })
  })

  function removePreviewImg(currIdx) {
    let newArr = [...images]
    if (isTngMp) {
      newArr.splice(currIdx, 1)
    } else {
      newArr[currIdx] = {}
    }
    setImages(newArr)
  }

  useEffect(() => {
    if (platform == 'tngmp') {
      setImages([])
    } else {
      setImages([{}, {}, {}])
    }
  }, [])

  return {
    chatHistory,
    isLoadingChat,
    handleSubmit,
    date,
    data,
    setData,
    chatDetails,
    setChatDetails,
    isSaving,
    images,
    setImages,
    notify,
    setNotify,
    showImgModal,
    setShowImgModal,
    onImageClick,
    removePreviewImg
  }
}

export default useChatPageLogic
