import React from 'react'
import { Box, withStyles, Typography, Badge } from '@material-ui/core'
import { Icon } from 'src/components'

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  }
})(Box)

const StyledBadge = withStyles(({ palette }) => ({
  badge: {
    fontSize: '0.8rem',
    top: 10,
    right: -63,
    paddingBottom: '4px',
    marginRight: '5px',
    width: '100px',
    height: '25px',
    color: '#fff',
    background: palette.warning.main
  }
}))(Badge)

export default function Panel({
  icon,
  topic,
  path,
  history,
  item,
  chip,
  id = false,
  platform
}) {
  function navigateTo() {
    if (icon === 'info') {
      console.log('id', id)
      if (id) {
        console.log('contain id')
        history.push({
          pathname: `${path}/${id}`,
          state: item,
          platform: platform
        })
      } else {
        console.log('not contain')
        history.push({ pathname: path, state: item, platform: platform })
      }
    } else if (icon === undefined) {
      history.push({
        pathname: `${path}/${id}`,
        state: item,
        platform: platform
      })
    } else {
      history.push({
        pathname: path,
        state: { platform: platform },
        platform: platform
      })
    }
  }
  return (
    <Row width={275} py={2} onClick={() => navigateTo()}>
      {/* {icon == undefined && (
        <Box flex={0.2}>
          <Icon name={icon} />
        </Box>
      )} */}
      {!(icon == undefined) && (
        <Box flex={0.2}>
          {path === '/voucher-order' ||
          (path === '/order' && platform === 'pudoparcel') ? (
            <img src={icon} alt="icon" />
          ) : (
            <Icon name={icon} />
          )}
        </Box>
      )}
      {!(icon == undefined) && (
        <Box flex={0.8}>
          {chip ? (
            <StyledBadge badgeContent={chip.message}>
              <Typography variant="h3">{topic}</Typography>
            </StyledBadge>
          ) : (
            <Typography variant="h3">{topic}</Typography>
          )}
        </Box>
      )}
      {icon == undefined && (
        <Box flex={1} mx={1}>
          {chip ? (
            <StyledBadge badgeContent={chip.message}>
              <Typography variant="h3">{topic}</Typography>
            </StyledBadge>
          ) : (
            <Typography variant="h3">{topic}</Typography>
          )}
        </Box>
      )}
      <Icon name="next" />
    </Row>
  )
}
