import React, { useEffect, useContext } from 'react'
import {
  Box,
  Typography,
  makeStyles,
  withStyles,
  Link,
  List,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Backdrop
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Paper,
  Spinner,
  ImageInput,
  Button,
  Notification,
  BackButton
} from 'src/components'
import useInactivePageLogic from '../../hooks/useInactivePageLogic'
import { DesktopNavigationContext } from 'src/context/DesktopNavigationContext'
import moment from 'moment'
import _ from 'lodash'
import clsx from 'clsx'
import './InactivePage.scss'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    color: palette.grey[100],
    fontSize: '0.875rem'
  },
  paper: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '0',
    paddingRight: '0',
    margin: '0 3rem'
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F1F1F3'
  },
  modalimg: {
    width: 300,
    height: 530
  },
  backdrop: {
    zIndex: 10,
    color: '#fff'
  },
  remove: {
    textAlign: 'center',
    marginTop: spacing(1),
    padding: spacing(1, 2),
    backgroundColor: palette.warning.main,
    color: palette.common.white
  }
}))

export default function InactiveDesktopPage(props) {
  const NavigationContext = useContext(DesktopNavigationContext)
  const styles = useStyles()
  const { location, history } = props

  const {
    isTextLoading,
    isSaving,
    store,
    images,
    setImages,
    storeLocation,
    openList,
    setOpenList,
    open,
    setOpen,
    isTngMp,
    setIsTngMp,
    subject,
    setSubject,
    notify,
    setNotify,
    showImgModal,
    setShowImgModal,
    feedback,
    setFeedback,
    showMessage,
    setShowMessage,
    getSearchLocation,
    handleStore,
    onImageClick,
    removePreviewImg,
    handleSubmitFeedback
  } = useInactivePageLogic(location, history)

  // const { history } = props
  // const location = {
  //   state: {
  //     platform: 'ecommerce'
  //   }
  // }
  // const [isTngMp, setIsTngMp] = useState(false)

  useEffect(() => {
    if (NavigationContext.navigationList.length === 1) {
      NavigationContext.handleUpdateBreadcrumbs(['Feedback'])
    }
  }, [])

  return (
    <>
      {isTextLoading && <Spinner className={styles.spinner} />}
      <Box mx={5} py={5} display="flex" justifyContent="center">
        <Paper
          className={clsx(styles.paper, 'inactive-page-paper')}
          elevation={1}
          borderRadius={10}>
          <Box
            className="first-section"
            width="50%"
            display="flex"
            flexDirection="column">
            <div className="title-margin">
              <Typography variant="h4" className="title">
                Subject
              </Typography>
              <Box
                onClick={() => setOpen(true)}
                className="feedback-input subject">
                <p>{feedback.title}</p>
                <IconButton edge="end" aria-label="more">
                  <ExpandMoreIcon />
                </IconButton>
              </Box>
              {open && (
                <div className="display-box">
                  {subject.map((str, idx) => {
                    return (
                      <Box
                        key={idx}
                        onClick={() =>
                          setFeedback(
                            { ...feedback, title: str },
                            setOpen(false),
                            setOpenList(false)
                          )
                        }>
                        <List key={idx}>{str}</List>
                        <Divider />
                      </Box>
                    )
                  })}
                </div>
              )}
            </div>
            <div className="title-margin">
              <Typography variant="h4" className="title">
                Store Location
              </Typography>
              <Box onClick={() => getSearchLocation(store)}>
                <TextField
                  placeholder="Select a store*"
                  variant="outlined"
                  fullWidth
                  value={store}
                  onChange={(e) => getSearchLocation(e.target.value)}
                  className="text-input"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="more">
                          <ExpandMoreIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              {!isTextLoading && openList && (
                <div className="display-box">
                  {storeLocation.map((str, idx) => {
                    return (
                      <Box key={idx} onClick={() => handleStore(str)}>
                        <List key={idx}>{str}</List>
                        <Divider />
                      </Box>
                    )
                  })}
                </div>
              )}
            </div>
            <div className="bottom-box">
              <div className="left">
                <Typography variant="h4" className="title">
                  Date of Visit
                </Typography>
                <TextField
                  id="date"
                  type="date"
                  defaultValue={moment().format('YYYY-MM-DD')}
                  InputProps={{
                    className: 'feedback-input date',
                    inputProps: { max: moment().format('YYYY-MM-DD') }
                  }}
                  onChange={(date) =>
                    setFeedback(
                      { ...feedback, dateOfVisit: date.target.value },
                      setOpenList(false),
                      setOpen(false)
                    )
                  }
                />
              </div>
              <div className="right">
                <Typography variant="h4" className="title">
                  Time of Visit
                </Typography>
                <TextField
                  id="time"
                  type="time"
                  InputProps={{ className: 'feedback-input date' }}
                  defaultValue={moment(feedback.timeOfVisit).format('HH:mm')}
                  onChange={(time) =>
                    setFeedback(
                      { ...feedback, timeOfVisit: time.target.value },
                      setOpenList(false),
                      setOpen(false)
                    )
                  }
                />
              </div>
            </div>
          </Box>
          <Box
            className="second-section"
            width="50%"
            display="flex"
            flexDirection="column">
            <div className="title-margin">
              <Typography variant="h4" className="title">
                Description
              </Typography>
              <TextField
                fullWidth
                multiline={true}
                rows={8}
                variant="outlined"
                placeholder="Description*(Maximum 4000 characters)"
                value={feedback.message}
                className="text-input"
                onChange={(e) =>
                  setFeedback(
                    { ...feedback, message: e.target.value },
                    setOpenList(false),
                    setOpen(false)
                  )
                }
              />
            </div>
            <div className="bottom-box">
              <div className="left">
                <Typography variant="h4" className="title">
                  Attachments
                </Typography>
                <Box display="flex">
                  {[0, 1, 2].map((i, idx) => {
                    return (
                      <ImageInput
                        setImages={setImages}
                        images={images}
                        idx={idx}
                        key={idx}
                        onImageClick={onImageClick}
                        setNotify={setNotify}
                        isTngMp={isTngMp}
                      />
                    )
                  })}
                </Box>
              </div>
              <div className="right">
                <Typography
                  variant="h4"
                  style={{
                    color: '#1E91CF',
                    marginBottom: '25px'
                  }}>
                  &nbsp;
                </Typography>
                <Button
                  title="Submit"
                  handleClick={() => handleSubmitFeedback()}
                  disabled={isSaving}
                />
              </div>
            </div>
          </Box>
        </Paper>
        <Notification
          show={notify.show}
          severity={notify.severity}
          message={notify.message}
          onClose={() => setNotify({ show: false, severity: '', message: '' })}
        />
        <Backdrop
          open={showImgModal.show}
          onClick={() =>
            setShowImgModal({ show: false, image: '', currIdx: null })
          }
          className={styles.backdrop}>
          <Box>
            <img src={showImgModal.image} className={styles.modalimg} />
            <Typography
              className={styles.remove}
              onClick={() => removePreviewImg(showImgModal.currIdx)}>
              remove image
            </Typography>
          </Box>
        </Backdrop>
      </Box>
    </>
  )
}
