import React, { useState, useEffect, useCallback, useContext } from 'react'
import {
  Card,
  CardContent,
  Box,
  Typography,
  useMediaQuery,
  withStyles,
  Badge
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import FaqIcon from 'src/components/Icon/ic-contact-chat.svg'
import MessageIcon from 'src/components/Icon/ic-contact-message.svg'
import FeedbackIcon from 'src/components/Icon/ic-credit-card.svg'
import { Icon } from 'src/components'
import { DesktopNavigationContext } from 'src/context/DesktopNavigationContext'
import useLandingPageLogic from '../hooks/useLandingPageLogic'
import './LandingPage.scss'

const StyledBadge = withStyles(({ palette }) => ({
  badge: {
    fontSize: '0.8rem',
    color: '#fff',
    background: palette.warning.main
  }
}))(Badge)

export default function LandingDesktopPage(props) {
  const NavigationContext = useContext(DesktopNavigationContext)
  const theme = useTheme()
  const isLgAndUp = useMediaQuery(theme.breakpoints.up('md'))
  let history = useHistory()

  const { hasNewMessage, isTngMp, userPlatform } = useLandingPageLogic(
    'desktop',
    props
  )

  function navigateTo(path) {
    let breadcrumbPathName = ''
    switch (path) {
      case '/chat':
        breadcrumbPathName = 'Chat'
        break
      case '/faq':
        breadcrumbPathName = 'FAQ'
        break
      case '/order':
        breadcrumbPathName = 'Report Issue'
        break
      case '/f':
        breadcrumbPathName = 'Feedback'
        break
      default:
    }
    NavigationContext.handleUpdateBreadcrumbs([breadcrumbPathName])
    history.push({
      pathname: path,
      state: { platform: userPlatform },
      platform: userPlatform
    })
  }

  return (
    <Box
      mx={5}
      py={5}
      display="flex"
      justifyContent="center"
      className="landing-page-content">
      <div
        className={isLgAndUp ? 'lg-landing-item-card' : 'sm-landing-item-card'}
        style={{ justifyContent: !global.jwtToken && 'center' }}>
        {global.jwtToken && (
          <>
            <Card
              className="landing-item-card"
              onClick={() => navigateTo('/order')}>
              <CardContent className="landing-item-card-content">
                <div
                  className="icon-container"
                  style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <img src={EarthIcon} alt="dropOff-icon" /> */}
                  <Icon name="info" width="35px" height="35px" />
                </div>
                <Typography variant="body1" className="text-title">
                  Report Issue
                </Typography>
                <Typography variant="body1" className="text-desr">
                  Report issue for your order, food, voucher or parcel.
                </Typography>
              </CardContent>
            </Card>
            <Card
              className="landing-item-card"
              onClick={() => navigateTo('/chat')}>
              <CardContent className="landing-item-card-content">
                <div className="icon-container">
                  <img src={MessageIcon} alt="chat-icon" />
                </div>
                {hasNewMessage ? (
                  <StyledBadge
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    style={{ display: 'flex', flexDirection: 'column' }}
                    badgeContent={hasNewMessage.message}>
                    <Typography variant="body1" className="text-title">
                      Chat History
                    </Typography>
                    <Typography variant="body1" className="text-desr">
                      View your chat history.
                    </Typography>
                  </StyledBadge>
                ) : (
                  <>
                    <Typography variant="body1" className="text-title">
                      Chat History
                    </Typography>
                    <Typography variant="body1" className="text-desr">
                      View your chat history.
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
            <Card
              className="landing-item-card"
              onClick={() => navigateTo('/f')}>
              <CardContent className="landing-item-card-content">
                <div className="icon-container">
                  <img src={FeedbackIcon} alt="feedback-icon" />
                </div>
                <Typography variant="body1" className="text-title">
                  Feedback
                </Typography>
                <Typography variant="body1" className="text-desr">
                  I have other issue to feedback.
                </Typography>
              </CardContent>
            </Card>
          </>
        )}
        <Card className="landing-item-card" onClick={() => navigateTo('/faq')}>
          <CardContent className="landing-item-card-content">
            <div className="icon-container">
              <img src={FaqIcon} alt="faq-icon" />
            </div>
            <Typography variant="body1" className="text-title">
              Faq
            </Typography>
            <Typography variant="body1" className="text-desr">
              Find brief answers to some of the commonly asked questions.
            </Typography>
          </CardContent>
        </Card>
      </div>
    </Box>
  )
}
