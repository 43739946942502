import React, { useContext, useEffect } from 'react'
import ErrorPage from 'src/pages/ErrorPage/ErrorPage'
import LandingPage from 'src/pages/LandingPage/LandingPage'
import OrderList from 'src/pages/Order/List/OrderList'
import OrderPage from 'src/pages/Order/Page/OrderPage'
import ParcelOrderPage from 'src/pages/Order/Page/ParcelOrderPage'
import ChatList from 'src/pages/Chat/List/ChatList'
import ChatPage from 'src/pages/Chat/Page/ChatPage'
import InactiveList from 'src/pages/Inactive/List/InactiveList'
import InactivePage from 'src/pages/Inactive/Page/InactivePage'
import FAQPage from 'src/pages/FAQ/FAQPage'
import FAQPageDetail from 'src/pages/FAQ/FAQPageDetail'
import DesktopPageLayout from 'src/components/DesktopComponents/PageLayout/DesktopPageLayout'
import LandingDesktopPage from 'src/pages/LandingPage/Dektop/LandingPage'
import FAQDesktopPage from 'src/pages/FAQ/Desktop/FAQPage'
import InactiveDesktopPage from 'src/pages/Inactive/Page/Desktop/InactivePage'
import OrderPageDesktop from 'src/pages/Order/OrderDesktop/OrderPageDesktop'
import AppRedirect from 'src/pages/AppRedirect/AppRedirect'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import useDeviceViewMode from 'src/hooks/useDeviceViewMode'
import ChatPageDesktop from 'src/pages/Chat/ChatDesktop/ChatPageDesktop'
import {
  DesktopNavigationContextProvider,
  DesktopNavigationContext
} from 'src/context/DesktopNavigationContext'
// import CustomerSentimentDialog from 'src/components/Feedback/CustomerSentimentDialog'
import RequestAction from 'src/pages/RequestAction/RequestAction'
import CustomerSentimentDialog from 'src/components/Feedback/CustomerSentimentDialog'
import { API, graphqlOperation } from 'aws-amplify'
import { getPageUrl } from 'src/graphql/queries'
import { LANDING_DOMAIN } from 'src/constants/constants'

const redirectModule = [
  '/banner',
  '/banner/:id',
  '/news',
  '/news/:id',
  '/newslisting',
  '/newslisting/:id',
  '/homepagebanner',
  '/homepagebanner/:id',
  '/homepagebannerlist',
  '/homepagebannerlist/:id',
  '/posttxnsurvey',
  '/posttxnsurvey/:id',
  '/additionalprofile',
  '/additionalprofile/:id',
  '/employee-feedback',
  '/employee-feedback/:id',
  '/department',
  '/department/:id',
  '/producttagging',
  '/producttagging/:id',
  '/famidelivery',
  '/famideals',
  '/famirewards',
  '/famirewards/:id',
  '/famipoints'
]

const routes = [
  'order',
  'voucher-order',
  'chat',
  'f',
  'faq',
  'customer-sentiment',
  'employee-feedback',
  'request-deactivate',
  'request-edit',
  'error',
  'news',
  'newslisting',
  'homepagebanner',
  'homepagebannerlist',
  'posttxnsurvey',
  'additionalprofile',
  'employee-feedback',
  'department',
  'producttagging',
  'famidelivery',
  'famideals',
  'famirewards',
  'famipoints',
  'mobilehome'
]

export default function AppRouting() {
  const deviceViewMode = useDeviceViewMode()

  const linkpath = window.location.pathname.split('/')[1]

  function getCurrentLink() {
    if (window.location.href.indexOf('?') > -1) {
      return window.location.href.split('?')[0]
    } else {
      return window.location.href
    }
  }

  useEffect(() => {
    if (linkpath && !routes.includes(linkpath)) {
      const fetchData = async () => {
        const response = await API.graphql(
          graphqlOperation(getPageUrl, {
            shortLink: getCurrentLink()
          })
        )
        if (response.data.getPageUrl?.statusCode === 200) {
          window.open(response.data.getPageUrl?.body.pageLink, '_self')
        } else {
          window.open(window.location.origin, '_self')
        }
      }
      fetchData()
    }
  }, [])

  return (
    <Router>
      <Switch>
        <Route path="/error" component={ErrorPage} />
        <Route
          path="/"
          render={() =>
            isLoggedIn() ? (
              <ProtectedRoutes viewMode={deviceViewMode} />
            ) : (
              <Redirect to="/error" />
            )
          }
        />
      </Switch>
    </Router>
  )
}

function isLoggedIn() {
  return true
}

const MobileRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(props) => {
          return <LandingPage {...props} />
        }}
      />
      <Route
        exact
        path="/order"
        render={(props) => {
          return <OrderList orderType="ecommerce" {...props} />
        }}
      />
      <Route
        exact
        path="/order/:id"
        render={(props) => {
          return <OrderPage purchaseType="ecommerce" {...props} />
        }}
      />
      <Route
        exact
        path="/order/parcel/:id"
        render={(props) => {
          return <ParcelOrderPage purchaseType="ecommerce" {...props} />
        }}
      />
      <Route
        exact
        path="/order/parcel-detail/:id"
        render={(props) => {
          return <OrderPage purchaseType="parcel" {...props} />
        }}
      />
      <Route
        exact
        path="/voucher-order"
        render={(props) => {
          return <OrderList orderType="voucher" {...props} />
        }}
      />
      <Route
        exact
        path="/voucher-order/:id"
        render={(props) => {
          return <OrderPage purchaseType="voucher" {...props} />
        }}
      />
      <Route
        exact
        path="/chat"
        render={(props) => {
          return <ChatList {...props} />
        }}
      />
      <Route
        exact
        path="/chat/:id"
        render={(props) => {
          return <ChatPage {...props} />
        }}
      />
      <Route
        exact
        path="/f/:id"
        render={(props) => {
          return <InactiveList {...props} />
        }}
      />
      <Route
        exact
        path="/f"
        render={(props) => {
          return <InactivePage type={'customerFeedback'} {...props} />
        }}
      />
      <Route
        exact
        path="/faq"
        render={(props) => {
          return <FAQPage {...props} />
        }}
      />
      <Route
        exact
        path="/faq/:id"
        render={(props) => {
          return <FAQPageDetail {...props} />
        }}
      />
      <Route
        exact
        path="/customer-sentiment"
        render={(props) => {
          return <CustomerSentimentDialog {...props} />
        }}
      />
      <Route
        exact
        path="/employee-feedback"
        render={(props) => {
          return <InactivePage type={'employeeFeedback'} {...props} />
        }}
      />
      <Route
        exact
        path="/request-deactivate"
        render={(props) => {
          return <RequestAction type={'deactivate'} {...props} />
        }}
      />
      <Route
        exact
        path="/request-edit"
        render={(props) => {
          return <RequestAction type={'edit'} {...props} />
        }}
      />
    </Switch>
  )
}

const LandingPageRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(props) => {
          return <AppRedirect {...props} />
        }}
      />

      {redirectModule.map((item, index) => {
        return (
          <Route
            key={1}
            exact
            path={item}
            render={(props) => {
              return <AppRedirect key={index} {...props} />
            }}
          />
        )
      })}
    </Switch>
  )
}

const DesktopRoutes = () => {
  const NavigationContext = useContext(DesktopNavigationContext)
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(props) => {
          NavigationContext.handleUpdateBreadcrumbs([])
          return <LandingDesktopPage {...props} />
        }}
      />
      <Route
        exact
        path="/f"
        render={(props) => {
          return <InactiveDesktopPage {...props} />
        }}
      />
      <Route
        exact
        path="/faq"
        render={(props) => {
          return <FAQDesktopPage {...props} />
        }}
      />
      <Route
        exact
        path="/order"
        render={(props) => {
          return <OrderPageDesktop {...props} />
        }}
      />
      <Route
        exact
        path="/chat"
        render={(props) => {
          return <ChatPageDesktop {...props} />
        }}
      />
    </Switch>
  )
}

function ProtectedRoutes({ viewMode }) {
  if (window.location.hostname === LANDING_DOMAIN) {
    return <LandingPageRoutes />
  }

  return <MobileRoutes />
}
