import React, { useCallback, useState } from 'react'
import {
  Typography,
  makeStyles,
  withStyles,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
  Backdrop
} from '@material-ui/core'
import {
  Button,
  BackButton,
  Spinner,
  TextFieldInput,
  ImageInput,
  Notification
} from 'src/components'

const useStyles = makeStyles(({ palette, spacing }) => ({
  header: {
    color: palette.primary.main,
    margin: spacing(3, 0)
  },
  description: {
    fontSize: '0.9rem',
    margin: spacing(2.5, 0),
    textAlign: 'justify',
    textJustify: 'inter-word'
  },
  backButton: {
    color: palette.primary.main
  },
  consent: {
    margin: spacing(1, 0)
  },
  subtitles: {
    color: palette.primary.main,
    fontStyle: 'bold'
  },
  backdrop: {
    zIndex: 10,
    color: '#fff'
  },
  modalImg: {
    width: 300,
    height: 530
  },
  inputBlock: {
    margin: spacing(1, 0)
  },
  inputTitle: {
    color: palette.primary.main,
    paddingLeft: 24,
    paddingRight: 24
  },
  inputImage: {
    paddingLeft: 24,
    paddingRight: 24
  }
}))

const Forms = (props) => {
  const {
    header,
    description,
    part1,
    part2,
    part3,
    consent1,
    consent2,
    isTngMp,
    handleOnSubmit,
    isSaving,
    removePreviewImg
  } = props
  const styles = useStyles()

  const [showImgModal, setShowImgModal] = useState({
    show: false,
    image: '',
    currIdx: null
  })

  const onImageClick = useCallback((src, idx = null) => {
    setShowImgModal({
      show: true,
      image: src,
      currIdx: idx
    })
  })

  return (
    <>
      <Typography variant="h2" className={styles.header}>
        {header}
      </Typography>
      <Typography variant="body1" className={styles.description}>
        {description}
      </Typography>
      <FormGroup>
        {part1 && (
          <div>
            <Typography variant="subtitle2" className={styles.subtitles}>
              {part1.title}
            </Typography>
            {part1.object.map((item, idx) => (
              <div key={idx} className={styles.inputBlock}>
                <Typography variant="h4" className={styles.inputTitle}>
                  {item.title}
                </Typography>
                {item.type === 'text' && (
                  <TextFieldInput
                    value={item.value}
                    disabled={item.disabled}
                    onChange={item.onChange}
                  />
                )}
                {item.type === 'image' && (
                  <Box className={styles.inputImage}>
                    <ImageInput
                      setImages={item.onChange}
                      images={item.value}
                      idx={0}
                      onImageClick={onImageClick}
                      // setNotify={setNotify}
                      isTngMp={isTngMp}
                    />
                  </Box>
                )}
              </div>
            ))}
          </div>
        )}
        {part2 && (
          <div>
            <Typography variant="subtitle2" className={styles.subtitles}>
              {part2.title}
            </Typography>
            <TextFieldInput
              placeholder={part2.placeholder}
              value={part2.value}
              onChange={part2.onChange}
              multiline
            />
          </div>
        )}
        {part3 && (
          <div>
            <Typography variant="subtitle2" className={styles.subtitles}>
              {part3.title}
            </Typography>
            <TextFieldInput
              placeholder={part3.placeholder}
              value={part3.value}
              onChange={part3.onChange}
              multiline
            />
          </div>
        )}
        <FormControlLabel
          className={styles.consent}
          control={
            <Checkbox
              checked={consent1.value}
              onChange={consent1.onChange}
              name="consent1"
            />
          }
          label={consent1.text}
        />
        {consent2 && (
          <FormControlLabel
            className={styles.consent}
            control={
              <Checkbox
                checked={consent2?.value}
                onChange={consent2?.onChange}
                name="consent2"
              />
            }
            label={consent2?.text}
          />
        )}
      </FormGroup>
      <Box display="flex" justifyContent="center" my={8} marginTop="70px">
        <Button
          title="Submit"
          handleClick={handleOnSubmit}
          disabled={isSaving}
          loading={isSaving}
        />
      </Box>
      <Backdrop
        open={showImgModal.show}
        onClick={() =>
          setShowImgModal({ show: false, image: '', currIdx: null })
        }
        className={styles.backdrop}>
        <Box>
          <img src={showImgModal.image} className={styles.modalImg} />
          <Typography
            className={styles.remove}
            onClick={() => removePreviewImg(showImgModal.currIdx)}>
            remove image
          </Typography>
        </Box>
      </Backdrop>
    </>
  )
}

export default Forms
