import React, { useEffect, useState } from 'react'

import { Box, Typography, makeStyles } from '@material-ui/core'
import { ChatHistoryPanel, BackButton, Spinner } from 'src/components'
import fmIcon from './store.png'
import useChatList from 'src/pages/Chat/hooks/useChatList'

const useStyles = makeStyles(({ palette }) => ({
  noRecord: {
    position: 'absolute',
    top: '40%',
    left: '30%'
  },
  image: {
    height: '50px',
    position: 'relative',
    left: '25%',
    bottom: '5%'
  }
}))

export default function ChatList(props) {
  const styles = useStyles()
  const token = sessionStorage.getItem('token')
  let platform = sessionStorage.getItem('platform')
  const isTngMp = props.location.state.platform === 'tngmp' ? true : false
  const { chats, isChatListLoading } = useChatList(
    token,
    props.location.state.platform
  )

  return (
    <Box p={2} mx={2}>
      {!isTngMp && <BackButton {...props} backTo={'/'} />}
      {isChatListLoading && <Spinner />}
      {!isChatListLoading &&
        chats &&
        chats.length > 0 &&
        chats.map((chat, idx) => {
          return (
            <Box pt={2} key={idx}>
              <ChatHistoryPanel chat={chat} {...props} platform={platform} />
            </Box>
          )
        })}
      {!isChatListLoading && !chats.length > 0 && (
        <Box py={1} className={styles.noRecord}>
          <img src={fmIcon} className={styles.image} />
          <Typography>Oops! You haven't log any issue.</Typography>
        </Box>
      )}
    </Box>
  )
}
