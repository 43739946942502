import React from 'react'
import {
  Button as muiButton,
  Box,
  withStyles,
  makeStyles,
  CircularProgress
} from '@material-ui/core'

const useStyles = makeStyles({
  progress: {
    position: 'absolute',
    top: ' 50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -10
  }
})

const StyledButton = withStyles(({ spacing }) => ({
  root: {
    padding: spacing(2, 3),
    fontSize: '0.87rem'
  }
}))(muiButton)

export default function DesktopButton({
  title,
  handleClick,
  disabled = false,
  startIcon
}) {
  const styles = useStyles()
  return (
    <Box position="relative">
      <StyledButton
        disabled={disabled}
        variant="contained"
        color="primary"
        onClick={() => handleClick()}
        startIcon={startIcon}>
        {title}
      </StyledButton>
      {disabled && (
        <CircularProgress size={20} thickness={5} className={styles.progress} />
      )}
    </Box>
  )
}
