import numeral from 'numeral'
import { getS3BucketURL } from './helper'

export default {
  removeWhiteSpace(str) {
    return str.replace(/\s/g, '')
  },
  toS3Link(str) {
    if (str) {
      return getS3BucketURL() + str
    } else {
      return null
    }
  },
  capitalize(str, toLowerCase = true) {
    if (typeof str === 'string') {
      const words = str.trim().split(' ')
      return words
        .map(
          (word) =>
            word.charAt(0).toUpperCase() +
            (toLowerCase ? word.substring(1).toLowerCase() : word.substring(1))
        )
        .join(' ')
    }
    return ''
  },
  toPrice(number, isPoint = false, currency = 'RM') {
    if (typeof number === 'number') {
      return `${currency} ${numeral(number).format(isPoint ? '0,0' : '0,0.00')}`
    } else if (typeof number === 'string') {
      return `${currency} ${numeral(parseFloat(number)).format(
        isPoint ? '0,0' : '0,0.00'
      )}`
    }
    return 0
  }
}
