import React from 'react'
import { Box, withStyles, Typography } from '@material-ui/core'
import { Icon } from 'src/components'

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  }
})(Box)

export default function HomePagePanel({
  icon,
  topic,
  subTopic,
  nextPath,
  onChangePage
}) {
  return (
    <Row width={275} py={2} onClick={() => onChangePage(nextPath)}>
      <Box flex={0.2} mr={1}>
        <img width="50px" height="50px" src={icon} alt="icon" />
      </Box>

      <Box
        flex={0.8}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Typography variant="h3" style={{ fontWeight: 600 }}>
          {topic}
        </Typography>
        {subTopic && (
          <Typography variant="h3" style={{ fontWeight: 600 }}>
            {subTopic}
          </Typography>
        )}
      </Box>

      <Icon name="next" />
    </Row>
  )
}
