import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Resizer from 'react-image-file-resizer'
import { API, graphqlOperation } from 'aws-amplify'
import { addIssue, generateS3UploadLink } from 'src/graphql/mutations'
import { listSupportStatements } from 'src/graphql/queries'
import { uploadImgToS3 } from 'src/utils/helper'
import uuid from 'uuid'
import _ from 'lodash'

const useOrderPageLogic = (purchaseType, order, platform, location) => {
  const history = useHistory()
  const [currTopic, setCurrTopic] = useState(null)
  const [images, setImages] = useState([{}, {}, {}])
  const [data, setData] = useState({
    message: ''
  })
  const [contact, setContact] = useState({
    message: ''
  })
  const [isSaving, setIsSaving] = useState(false)
  const [allStatements, setAllStatements] = useState([])
  const [parentStatements, setParentStatements] = useState([])
  const [isMiddle, setIsMiddle] = useState(false)
  const [isChildStatement, setIsChildStatement] = useState(false)
  const [isChatSupport, setIsChatSupport] = useState(false)
  const [isCallSupport, setIsCallSupport] = useState(false)
  const [message, setMessage] = useState([])
  const [isTngMp, setIsTngMp] = useState(true)
  const [notify, setNotify] = useState({
    show: false,
    severity: '',
    message: ''
  })
  const [showImgModal, setShowImgModal] = useState({
    show: false,
    image: '',
    currIdx: null
  })
  const [highPriority, setHighPriority] = useState(false)
  const [issueType, setIssueType] = useState('')

  useEffect(() => {
    if (!_.isEmpty(order)) {
      getSupportStatement()
      if (order.hasOwnProperty('parcelOrderDetailId')) {
        setIssueType('pudoparcel')
      }
    }
  }, [order])

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        600,
        'JPEG',
        90,
        0,
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',')
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)
    return new Blob([ia], { type: mimeString })
  }

  async function getS3Link(count, path) {
    let token = global.jwtToken
    let fileName = `image${count}.jpg`
    let params = {
      token: token,
      folderName: path,
      fileName: fileName,
      platform: platform
    }
    let newImage
    if (isTngMp) {
      params['platform'] = 'tngmp'
      newImage = images[count]
    } else {
      const compressedImage = await resizeFile(images[count])
      newImage = dataURIToBlob(compressedImage)
    }

    const response = await API.graphql(
      graphqlOperation(generateS3UploadLink, params)
    )
    // console.log('response.data.generateS3UploadLink',response.data.generateS3UploadLink);
    try {
      const uploadResponse = await uploadImgToS3(
        response.data.generateS3UploadLink,
        newImage,
        isTngMp,
        setNotify,
        handleTngRes
      )

      if (isTngMp) {
        localStorage.removeItem('currentUploadStatus')
      }

      if (!isTngMp) {
        if (uploadResponse.status == 204) {
          return true
        } else if (uploadResponse.status == 400) {
          return false
        }
      } else {
        return uploadResponse
      }
    } catch (error) {
      console.log(error)
    }
  }

  function handleTngRes(tngRes) {
    if (tngRes && (tngRes.statusCode === '200' || tngRes.statusCode === 200)) {
      return true
    }
    return false
  }

  async function handleSubmit() {
    let token = global.jwtToken
    var re = /^[a-zA-Z0-9!?@#$&*~_^<>%()`.+,/" -:;'/\n/]*$/
    if (
      highPriority == false &&
      data.message.replace(/\s/g, '') !== '' &&
      re.test(data.message)
    ) {
      try {
        setIsSaving(true)
        let imagepath = `issue/${order.customerAccountNo}/${
          order.orderNumber
        }/${uuid.v4()}`

        if (issueType === 'pudoparcel' && platform !== 'pudoparcel') {
          imagepath = `issue/${order.originalSenderAccountNo}/${
            order.orderNumber
          }/${uuid.v4()}`
        } else if (issueType === 'pudoparcel' && platform === 'pudoparcel') {
          imagepath = `issue/${order.senderAccountNumber}/${
            order.orderNumber
          }/${uuid.v4()}`
        }

        for (let i = 0; i < images.length; i++) {
          if ((!isTngMp && images[i].preview) || (isTngMp && images[i])) {
            let uploadSuccess = await getS3Link(i, imagepath)
            if (!isTngMp && !uploadSuccess) {
              setNotify({
                show: true,
                severity: 'error',
                message: 'Failed! Image size exceeds 15 MB.'
              })
              setIsSaving(false)
              return
            } else if (isTngMp && uploadSuccess !== true) {
              setIsSaving(false)
              return
            }
          }
        }

        const params = {
          orderNumber: order.orderNumber,
          trackerNumber: order.trackerNumber,
          parcelAmount: order.parcelAmount,
          title: currTopic,
          customerAccountNo: token,
          issueDateTime: new Date(),
          issueStatus: 'New',
          totalQuantity: order.totalOrderItems,
          totalAmount: order.grandTotal,
          totalMessage: 0,
          issueId: '',
          message: data.message,
          image1:
            (!isTngMp && images[0].preview) || (isTngMp && images[0])
              ? `${imagepath}/image0.jpg`
              : '',
          image2:
            (!isTngMp && images[1].preview) || (isTngMp && images[1])
              ? `${imagepath}/image1.jpg`
              : '',
          image3:
            (!isTngMp && images[2].preview) || (isTngMp && images[2])
              ? `${imagepath}/image2.jpg`
              : '',
          productImage: order.productImage,
          phoneNumber: '',
          platform: platform,
          issueType: issueType
        }

        const res = await API.graphql(graphqlOperation(addIssue, params))
        if (res.data.addIssue.statusCode === '200') {
          setNotify({
            show: true,
            severity: 'success',
            message:
              'Success! Your report is received. A representative will be with you in a short while.'
          })
          setTimeout(() => {
            setIsSaving(false)
            setData({ ...data, message: '' })
            if (isTngMp) {
              setImages([])
            } else {
              setImages([{}, {}, {}])
            }
            // history.push('/chat')
            history.push({
              pathname: '/chat',
              state: { platform: platform },
              platform: platform
            })
          }, 2000)
        } else if (res.data.addIssue.statusCode === '300') {
          setNotify({
            show: true,
            severity: 'error',
            message: 'Order number is Invalid'
          })
          setIsSaving(false)
        } else {
          setNotify({
            show: true,
            severity: 'error',
            message: (
              <>
                Something went wrong!{' '}
                {!!res?.data?.addIssue?.status
                  ? res?.data?.addIssue?.status
                  : ''}
                <span style={{ fontWeight: 900 }}>
                  {' '}
                  please return to Help Center & submit new report if needed.
                </span>
              </>
            )
          })
          setIsSaving(false)
        }
      } catch (error) {
        setNotify({
          show: true,
          severity: 'error',
          message: 'Failed! Something went wrong, please try again later.'
        })
        setIsSaving(false)
        console.log(error)
      }
    } else if (
      highPriority == true &&
      data.message.replace(/\s/g, '') !== '' &&
      contact.message.replace(/\s/g, '') !== '' &&
      contact.message.length > 9 &&
      contact.message.length < 13 &&
      re.test(data.message)
    ) {
      try {
        setIsSaving(true)
        let imagepath = `issue/${order.customerAccountNo}/${
          order.orderNumber
        }/${uuid.v4()}`
        if (issueType === 'pudoparcel' && platform !== 'pudoparcel') {
          imagepath = `issue/${order.originalSenderAccountNo}/${
            order.orderNumber
          }/${uuid.v4()}`
        } else if (issueType === 'pudoparcel' && platform === 'pudoparcel') {
          imagepath = `issue/${order.senderAccountNumber}/${
            order.orderNumber
          }/${uuid.v4()}`
        }
        for (let i = 0; i < images.length; i++) {
          if ((!isTngMp && images[i].preview) || (isTngMp && images[i])) {
            let uploadSuccess = await getS3Link(i, imagepath)
            if (!isTngMp && !uploadSuccess) {
              setNotify({
                show: true,
                severity: 'error',
                message: 'Failed! Image size exceeds 15 MB.'
              })
              setIsSaving(false)
              return
            } else if (isTngMp && uploadSuccess !== true) {
              setIsSaving(false)
              return
            }
          }
        }
        let isnum = /^\d+$/.test(contact.message)
        if (isnum == false) {
          setNotify({
            show: true,
            severity: 'error',
            message: 'Error. Phone number should be all digits'
          })
          setIsSaving(false)
        } else {
          const params = {
            orderNumber: order.orderNumber,
            trackerNumber: order.trackerNumber,
            parcelAmount: order.parcelAmount,
            title: currTopic,
            customerAccountNo: token,
            issueDateTime: new Date(),
            issueStatus: 'New',
            totalQuantity: order.totalOrderItems,
            totalAmount: order.grandTotal,
            totalMessage: 0,
            issueId: '',
            message: data.message,
            image1:
              (!isTngMp && images[0].preview) || (isTngMp && images[0])
                ? `${imagepath}/image0.jpg`
                : '',
            image2:
              (!isTngMp && images[1].preview) || (isTngMp && images[1])
                ? `${imagepath}/image1.jpg`
                : '',
            image3:
              (!isTngMp && images[2].preview) || (isTngMp && images[2])
                ? `${imagepath}/image2.jpg`
                : '',
            productImage: order.productImage,
            phoneNumber: contact.message,
            platform: platform,
            issueType: issueType
          }
          console.log(params)
          const res = await API.graphql(graphqlOperation(addIssue, params))
          if (res.data.addIssue.statusCode === '200') {
            setNotify({
              show: true,
              severity: 'success',
              message:
                'Success! Your report is received. A representative will be with you in a short while.'
            })
            setTimeout(() => {
              setIsSaving(false)
              setData({ ...data, message: '' })
              if (isTngMp) {
                setImages([])
              } else {
                setImages([{}, {}, {}])
              }
              // history.push('/chat')
              history.push({
                pathname: '/chat',
                state: { platform: platform },
                platform: platform
              })
            }, 2000)
          } else {
            setNotify({
              show: true,
              severity: 'error',
              message: (
                <>
                  Something went wrong!{' '}
                  {!!res?.data?.addIssue?.status
                    ? res?.data?.addIssue?.status
                    : ''}
                  <span style={{ fontWeight: 900 }}>
                    {' '}
                    please return to Help Center & submit new report if needed.
                  </span>
                </>
              )
            })
            setIsSaving(false)
          }
        }
      } catch (error) {
        setNotify({
          show: true,
          severity: 'error',
          message: 'Failed! Something went wrong, please try again later.'
        })
        setIsSaving(false)
        console.log(error)
      }
    } else {
      if (
        contact.message.replace(/\s/g, '') != '' &&
        (contact.message.length < 10 || contact.message.length > 12)
      ) {
        setNotify({
          show: true,
          severity: 'error',
          message: 'Error! Phone number length should between 10 to 12 digits'
        })
      } else if (!re.test(data.message)) {
        setNotify({
          show: true,
          severity: 'error',
          message:
            'Please enter only alphabetical and numeral letters. Thank you.'
        })
      } else {
        setNotify({
          show: true,
          severity: 'error',
          message: 'Error! All field is required.'
        })
      }
    }
  }

  function onSelectTopic(item) {
    let child = allStatements.filter(function (e) {
      return e.parentId == item.statementId
    })
    if (child.length != 0) {
      setIsChildStatement(true)
      setIsMiddle(true)
      setParentStatements(child)
    } else {
      setIsChildStatement(false)
      setCurrTopic(item.statement)
      if (item.supportType === 'Chat') {
        setIsChatSupport(true)
        const msgArr = item.messageToCustomer.split('\n')
        setMessage(msgArr)
      } else if (item.supportType === 'Call') {
        setIsCallSupport(true)
        const msgArr = item.messageToCustomer.split('\n')
        setMessage(msgArr)
      } else {
        const msgArr = item.messageToCustomer.split('\n')
        setMessage(msgArr)
      }
    }
    if (
      item.priority === 'H' &&
      item.statement == 'My order is taking too long to reach me'
    ) {
      setHighPriority(true)
    } else if (
      item.priority === 'H' &&
      item.statement == "I can't contact my delivery partner"
    ) {
      setHighPriority(true)
    } else if (
      item.priority === 'H' &&
      item.statement == 'Outlet cannot retrieve my order'
    ) {
      setHighPriority(true)
    } else {
      setHighPriority(false)
    }
  }

  function resetParents() {
    let parent = allStatements.filter(function (e) {
      return e.parentId == null
    })
    setIsChildStatement(false)
    setParentStatements(parent)
    setIsMiddle(false)
  }

  function getStatementType() {
    if (purchaseType !== 'voucher' && purchaseType !== 'parcel') {
      if (
        order.status === 'Order Completed' ||
        order.status === 'No Rider Found' ||
        order.status === 'Order Completed, Unsuccessful Collection'
      ) {
        // return 'InactiveOrder'
        if (order.orderType === 'PickUp') {
          return 'InactiveOrder-PickUp'
        } else {
          return 'InactiveOrder-Delivery'
        }
      } else if (
        order.status === 'Order Cancelled' ||
        order.status === 'Order Rejected'
      ) {
        return 'InactiveOrder-Canceled/Rejected'
      } else {
        if (order.orderType === 'PickUp') {
          return 'ActiveOrder-PickUp'
        } else {
          return 'ActiveOrder-Delivery'
        }
      }
    } else if (purchaseType === 'voucher') {
      return 'Voucher Order'
    } else if (purchaseType === 'parcel' && order.status) {
      if (order.status.includes('Completed')) {
        return 'Parcel-Completed'
      } else if (order.status.includes('Cancelled')) {
        return 'Parcel-Cancelled'
      } else if (order.status.includes('Expired')) {
        return 'Parcel-Expired'
      } else if (order.status.includes('Pending Cancellation')) {
        return 'Parcel-PendingCancellation'
      } else {
        return 'Parcel-Ongoing'
      }
    } else if (purchaseType === 'parcel' && order.status === null) {
      return 'Parcel-Ongoing'
    }
  }

  async function getSupportStatement() {
    if (platform === 'tngmp') {
      setIsTngMp(true)
    } else if (platform === 'pudoparcel') {
      platform = 'pudoparcel'
      setIsTngMp(false)
    } else {
      platform = 'ecommerce'
      setIsTngMp(false)
    }

    const statementType = getStatementType()
    const params = {
      filter: {
        statementType: {
          eq: statementType
        }
      },
      limit: 100,
      nextToken: null
    }
    const response = await API.graphql(
      graphqlOperation(listSupportStatements, params)
    )
    let array = []
    if (response.data.listSupportStatements) {
      response.data.listSupportStatements.items.map((item) => {
        var details = {
          parentId: item.parentId,
          statementId: item.statementId,
          sequence: item.sequence,
          statement: item.statement,
          messageToCustomer: item.messageToCustomer,
          supportType: item.supportType,
          priority: item.priority,
          platform: platform
        }
        array.push(details)
      })
      function compare_item(a, b) {
        if (a.sequence < b.sequence) {
          return -1
        } else if (a.sequence > b.sequence) {
          return 1
        } else {
          return 0
        }
      }
      array.sort(compare_item)
      setAllStatements(array)
      let parent = array.filter(function (e) {
        return e.parentId == null
      })
      setParentStatements(parent)
    }
  }

  async function handleCallMe() {
    let token = global.jwtToken
    try {
      setIsSaving(true)
      const params = {
        orderNumber: order.orderNumber,
        title: 'Call me back',
        customerAccountNo: token,
        issueDateTime: new Date(),
        issueStatus: 'New',
        totalQuantity: order.totalOrderItems,
        totalAmount: order.grandTotal,
        totalMessage: 0,
        issueId: '',
        message: '',
        image1: '',
        image2: '',
        image3: '',
        productImage: order.productImage
      }
      console.log(params)
      const res = await API.graphql(graphqlOperation(addIssue, params))
      if (res.data.addIssue.statusCode === '200') {
        setNotify({
          show: true,
          severity: 'success',
          message:
            'Success! Your request is received. A representative will contact you in a short while.'
        })
        setTimeout(() => {
          setIsSaving(false)
          // history.push('/chat')
          history.push({
            pathname: '/chat',
            state: { platform: platform },
            platform: platform
          })
        }, 2000)
      } else {
        setNotify({
          show: true,
          severity: 'error',
          message: 'Failed! Something went wrong, please try again later.'
        })
        setIsSaving(false)
      }
    } catch (error) {
      setNotify({
        show: true,
        severity: 'error',
        message: 'Failed! Something went wrong, please try again later.'
      })
      setIsSaving(false)
      console.log(error)
    }
  }

  const onImageClick = useCallback((src, idx) => {
    setShowImgModal({
      show: true,
      image: src,
      currIdx: idx
    })
  })

  function removePreviewImg(currIdx) {
    let newArr = [...images]

    if (isTngMp) {
      newArr.splice(currIdx, 1)
    } else {
      newArr[currIdx] = {}
    }

    setImages(newArr)
  }

  function resetState(parents) {
    setIsSaving(false)
    setData({ ...data, message: '' })
    setContact({ ...contact, message: '' })
    setImages(isTngMp ? [] : [{}, {}, {}])
    if (isMiddle) {
      setCurrTopic(null)
      setIsCallSupport(false)
      setIsChatSupport(false)
      setIsChildStatement(true)
      setParentStatements(parents)
    } else {
      setCurrTopic(null)
      setIsCallSupport(false)
      setIsChatSupport(false)
      setIsChildStatement(false)
      setParentStatements(parents)
    }
  }

  useEffect(() => {
    if (isTngMp) {
      setImages([])
    } else {
      setImages([{}, {}, {}])
    }
  }, [isTngMp])

  return {
    currTopic,
    images,
    setImages,
    data,
    setData,
    contact,
    setContact,
    isSaving,
    parentStatements,
    isChildStatement,
    isChatSupport,
    message,
    notify,
    setNotify,
    highPriority,
    handleSubmit,
    onSelectTopic,
    resetParents,
    onImageClick,
    removePreviewImg,
    resetState,
    isTngMp,
    setIsTngMp,
    showImgModal,
    setShowImgModal
  }
}

export default useOrderPageLogic
