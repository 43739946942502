/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-southeast-1',
  aws_cognito_identity_pool_id:
    'ap-southeast-1:626a5cfe-0e9b-4549-8155-0cc94fb7915f',
  aws_cognito_region: 'ap-southeast-1',
  aws_user_pools_id: 'ap-southeast-1_pIzvNNLYi',
  aws_user_pools_web_client_id: '1k358egpicom6uaj2ok57v9ra4',
  oauth: {},
  aws_appsync_graphqlEndpoint:
    'https://25uslmrtdfay3bhkpbjjekkonu.appsync-api.ap-southeast-1.amazonaws.com/graphql',
  aws_appsync_region: 'ap-southeast-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: 'da2-uprhw2fbtzgq3fq27rpekqycg4',
  aws_user_files_s3_bucket: 'fmstorage153021-prod',
  aws_user_files_s3_bucket_region: 'ap-southeast-1'
}

export default awsmobile
