/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const shareVoucher = /* GraphQL */ `
  mutation ShareVoucher(
    $token: String
    $voucherNumber: String
    $receivePhoneNumber: String
    $quantityToShare: Int
    $expiryDate: String
  ) {
    shareVoucher(
      token: $token
      voucherNumber: $voucherNumber
      receivePhoneNumber: $receivePhoneNumber
      quantityToShare: $quantityToShare
      expiryDate: $expiryDate
    ) {
      statusCode
      message
      sharingMessage
      isReceiverMember
    }
  }
`
export const acceptShareVoucher = /* GraphQL */ `
  mutation AcceptShareVoucher(
    $token: String
    $voucherNumber: String
    $expiryDate: String
    $shareVoucherExpiryDate: String
    $acceptVoucher: Boolean
    $senderMobileNumber: String
    $memberLedgerEntryNo: String
  ) {
    acceptShareVoucher(
      token: $token
      voucherNumber: $voucherNumber
      expiryDate: $expiryDate
      shareVoucherExpiryDate: $shareVoucherExpiryDate
      acceptVoucher: $acceptVoucher
      senderMobileNumber: $senderMobileNumber
      memberLedgerEntryNo: $memberLedgerEntryNo
    ) {
      statusCode
      message
      sharingMessage
      isReceiverMember
    }
  }
`
export const cancelDeliveryPartnerOrder = /* GraphQL */ `
  mutation CancelDeliveryPartnerOrder(
    $orderId: String
    $merchantType: String
    $merchantId: String
    $cancelCode: String
  ) {
    cancelDeliveryPartnerOrder(
      orderId: $orderId
      merchantType: $merchantType
      merchantId: $merchantId
      cancelCode: $cancelCode
    ) {
      statusCode
      message
      orderCancelled
    }
  }
`
export const updateDeliveryPartnerOrderStatus = /* GraphQL */ `
  mutation UpdateDeliveryPartnerOrderStatus(
    $orderId: String
    $orderStatus: Int
    $orderComplete: Boolean
    $merchantId: String
  ) {
    updateDeliveryPartnerOrderStatus(
      orderId: $orderId
      orderStatus: $orderStatus
      orderComplete: $orderComplete
      merchantId: $merchantId
    ) {
      statusCode
      message
    }
  }
`
export const createStore = /* GraphQL */ `
  mutation CreateStore($input: StoreInput) {
    createStore(input: $input) {
      storeId
      driveThru
      visibleToEComm
      storeName
      address
      latitude
      longitude
      openHour
      closeHour
      deliveryDiscountType
      deliveryServiceAvailable
      pickupServiceAvailable
      minFoodPreparationDuration
      minDeliveryDuration
      deliveryFee
      isDisabled
      isOnline24Hour
      isOffline24Hour
      postalCode
      city
      state
      storeCode
      lastOrderTime
      minPurchaseAmount
      freeDeliveryWithMinPurchase
      acceptOrderWithMinPurchase
      maxOrderQty
      eCommStartDate
      eCommEndDate
      hasSeatingArea
      createdBy
      modifiedBy
      storeManagerName
      storeManagerContact
      priceGroupId
      storeGroup
      createdAt
      updatedAt
      minDistanceCharges
      minDistance
      pollingDeliveryTime
      riderTimeout
      deliveryGracePeriod
      routingOption
      priorityDeliveryPartner
      incrementDistanceCharges
      incrementDistanceUnit
      deliveryDiscountAmount
      deliveryDiscountBasketValue
      deliveryPartnerMaxRetry
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      operatingHour {
        items {
          storeOperatingHourId
          onlineStoreOpenHour
          onlineStoreCloseHour
          offlineStoreOpenHour
          offlineStoreCloseHour
          isOffline24Hour
          isOnline24Hour
          onlineIsOpen
          offlineIsOpen
          day
          modifiedBy
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      storeBlockedOutPeriods {
        items {
          storeBlockedOutPeriodId
          startDateTime
          endDateTime
          storeType
          createdBy
          modifiedBy
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      StoreDeliveryPartnerMapping {
        items {
          partnerId
          storeDeliveryPartnerStoreId
          partnerName
          sequence
          isPriority
          createdBy
          modifiedBy
          createdAt
          updatedAt
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const createStoreOperatingHour = /* GraphQL */ `
  mutation CreateStoreOperatingHour($input: StoreOperatingHourInput) {
    createStoreOperatingHour(input: $input) {
      storeOperatingHourId
      onlineStoreOpenHour
      onlineStoreCloseHour
      offlineStoreOpenHour
      offlineStoreCloseHour
      isOffline24Hour
      isOnline24Hour
      onlineIsOpen
      offlineIsOpen
      day
      modifiedBy
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createTrendingText = /* GraphQL */ `
  mutation CreateTrendingText($input: TrendingTextInput) {
    createTrendingText(input: $input) {
      trendingTextId
      title
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createLandingMenu = /* GraphQL */ `
  mutation CreateLandingMenu($input: LandingMenuInput) {
    createLandingMenu(input: $input) {
      landingMenuId
      actionType
      actionId
      title
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      modifiedBy
      updatedAt
      createdAt
      shortLinkUrl
    }
  }
`
export const createStoreBlockedOutPeriod = /* GraphQL */ `
  mutation CreateStoreBlockedOutPeriod($input: StoreBlockedOutPeriodInput) {
    createStoreBlockedOutPeriod(input: $input) {
      storeBlockedOutPeriodId
      startDateTime
      endDateTime
      storeType
      createdBy
      modifiedBy
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: ProductInput) {
    createProduct(input: $input) {
      productId
      sku
      name
      title
      image
      price
      discount
      discountPercentage
      discountedPrice
      promotionDescription
      discountStartDate
      discountEndDate
      pickupPrice
      pickupDiscount
      pickupDiscountPercentage
      pickupDiscountedPrice
      pickupPromotionDescription
      pickupDiscountStartDate
      pickupDiscountEndDate
      category
      categories
      departments
      taggings
      minFoodPreparationDuration
      minDeliveryDuration
      createdBy
      modifiedBy
      isDisabled
      description
      uom
      dimension
      temperature
      itemPublishStartDate
      itemPublishEndDate
      ecommerceMaximumQuantity
      pickupMaximumQuantity
      createdAt
      updatedAt
      divisionCode
      divisionDesc
      itemCategoryCode
      itemCategoryDesc
      productGroupCode
      productGroupDesc
      departmentLevel1
      departmentLevel2
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productCategory {
        items {
          productCategoryId
          category {
            categoryId
            title
            image
            code
            createdBy
            modifiedBy
            isDisabled
            parentId
            createdAt
            updatedAt
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderDetail {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productImage {
        items {
          productImageId
          image
          sequence
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerWishList {
        items {
          customerWishListId
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const createProductStoreDepartment = /* GraphQL */ `
  mutation CreateProductStoreDepartment($input: ProductStoreDepartmentInput) {
    createProductStoreDepartment(input: $input) {
      productStoreDepartmentId
      productStoreDepartmentProductUOMId
      productStoreDepartmentStoreId
      productStoreDepartmentSubDepartmentId
      productStoreDepartmentProductUOMPriceGroupId
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      department {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      subDepartment {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      isOutOfStock
      createdAt
      updatedAt
    }
  }
`
export const createProductStoreTagging = /* GraphQL */ `
  mutation CreateProductStoreTagging($input: ProductStoreTaggingInput) {
    createProductStoreTagging(input: $input) {
      productStoreTaggingId
      productStoreTaggingProductUOMId
      productStoreTaggingProductTaggingId
      productStoreTaggingStoreId
      productStoreTaggingProductUOMPriceGroupId
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      isOutOfStock
      createdAt
      updatedAt
    }
  }
`
export const createProductImage = /* GraphQL */ `
  mutation CreateProductImage($input: ProductImageInput) {
    createProductImage(input: $input) {
      productImageId
      image
      sequence
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createProductStoreTaggingMapping = /* GraphQL */ `
  mutation CreateProductStoreTaggingMapping($productStoreTaggingMapping: ID!) {
    createProductStoreTaggingMapping(
      productStoreTaggingMapping: $productStoreTaggingMapping
    ) {
      productStoreTaggingMapping
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createProductStore = /* GraphQL */ `
  mutation CreateProductStore($input: ProductStoreInput) {
    createProductStore(input: $input) {
      productStoreId
      productStoreProductId
      isOutOfStock
      modifiedBy
      productStoreProductUOMId
      productStoreStoreId
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createKDSDeviceToken = /* GraphQL */ `
  mutation CreateKDSDeviceToken($input: KDSDeviceTokenInput) {
    createKDSDeviceToken(input: $input) {
      id
      deviceEndpoint
      deviceToken
      storeCode
      createdAt
      updatedAt
    }
  }
`
export const createJwtToken = /* GraphQL */ `
  mutation CreateJwtToken($input: JWTTokenInput) {
    createJwtToken(input: $input) {
      sessionID
      signedToken
      token
      expiryDate
      accountNo
      createdAt
      updatedAt
    }
  }
`
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback($input: FeedbackInput) {
    createFeedback(input: $input) {
      feedbackId
      searchLocation
      dateOfVisit
      timeOfVisit
      title
      description
      uploadPhoto
      createdBy
      modifiedBy
      redmineTicketNumber
      platform
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      customerName
      customerMobileNo
      customerEmail
      issueStatus
    }
  }
`
export const createProductCategory = /* GraphQL */ `
  mutation CreateProductCategory($input: ProductCategoryInput) {
    createProductCategory(input: $input) {
      productCategoryId
      category {
        categoryId
        title
        image
        code
        createdBy
        modifiedBy
        isDisabled
        parentId
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createProductDepartment = /* GraphQL */ `
  mutation CreateProductDepartment($input: ProductDepartmentInput) {
    createProductDepartment(input: $input) {
      productDepartmentId
      productDepartmentProductUOMId
      department {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createProductTaggingMapping = /* GraphQL */ `
  mutation CreateProductTaggingMapping($input: ProductTaggingMappingInput) {
    createProductTaggingMapping(input: $input) {
      productTaggingMappingId
      productTaggingMappingProductId
      productTaggingMappingProductTaggingId
      productTaggingMappingProductUOMId
      modifiedBy
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CategoryInput) {
    createCategory(input: $input) {
      categoryId
      title
      image
      code
      createdBy
      modifiedBy
      isDisabled
      parentId
      productCategory {
        items {
          productCategoryId
          category {
            categoryId
            title
            image
            code
            createdBy
            modifiedBy
            isDisabled
            parentId
            createdAt
            updatedAt
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment($input: DepartmentInput) {
    createDepartment(input: $input) {
      departmentId
      title
      image
      createdBy
      modifiedBy
      updatedAt
      createdAt
      isDisabled
      effectiveStartDate
      effectiveEndDate
      parentId
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreSubDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const createProductTagging = /* GraphQL */ `
  mutation CreateProductTagging($input: ProductTaggingInput) {
    createProductTagging(input: $input) {
      productTaggingId
      title
      image
      code
      effectiveStartDate
      effectiveEndDate
      isDisabled
      createdBy
      modifiedBy
      updatedAt
      createdAt
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const createUserPointTransaction = /* GraphQL */ `
  mutation CreateUserPointTransaction($input: UserPointTransactionInput) {
    createUserPointTransaction(input: $input) {
      transactionId
      pointValue
      note
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createVoucherRewardSKU = /* GraphQL */ `
  mutation CreateVoucherRewardSKU($input: VoucherRewardSKUInput) {
    createVoucherRewardSKU(input: $input) {
      voucherRewardSKUId
      rewardId
      entityId
      entityType
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const createVoucherReward = /* GraphQL */ `
  mutation CreateVoucherReward($input: VoucherRewardInput) {
    createVoucherReward(input: $input) {
      rewardId
      image
      startDate
      endDate
      point
      description
      title
      tnc
      cashValueAmount
      skuCashDiscount
      voucherType
      voucherStore {
        items {
          voucherRewardStoreId
          rewardId
          latitude
          longitude
          storeId
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      voucherSKU {
        items {
          voucherRewardSKUId
          rewardId
          entityId
          entityType
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const createVoucherRewardStore = /* GraphQL */ `
  mutation CreateVoucherRewardStore($input: VoucherRewardStoreInput) {
    createVoucherRewardStore(input: $input) {
      voucherRewardStoreId
      rewardId
      latitude
      longitude
      storeId
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const createCustomerVoucher = /* GraphQL */ `
  mutation CreateCustomerVoucher($input: CustomerVoucherInput) {
    createCustomerVoucher(input: $input) {
      voucherId
      rewardId
      status
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createOrder = /* GraphQL */ `
  mutation CreateOrder($input: OrderInput) {
    createOrder(input: $input) {
      orderId
      orderNumber
      voucherNumber
      voucherTitle
      voucherEntryNumber
      orderDate
      paymentDateTime
      productImage
      grandTotal
      discountAmount
      deliveryFee
      voucherDiscount
      totalOrderItems
      status
      noteToRider
      floorOrUnit
      customerAccountNo
      customerFirstName
      customerLastName
      customerPhoneNumber
      storeName
      storeCode
      orderType
      deliveryAddress
      deliveryAddressLatitude
      deliveryAddressLongitude
      remarks
      routingOption
      reasonCode
      cancelReason
      cancelBy
      orderCancellationDateTime
      collectedDateTime
      createdAt
      updatedAt
      isAdvancedOrder
      scheduledDateTime
      driverName
      driverPhone
      driverPlateNumber
      lalamoveOrderId
      lalamoveOrderDate
      lalamoveTotalFee
      deliveryStatus
      intOrderNumber
      isRefunded
      refundAmount
      refundReason
      txnId
      refundCompleteDateTime
      orderCompleteDateTime
      cancelOrderDateTime
      requiredCutlery
      deliveryOrderDateTime
      deliveryOrderId
      deliveryPartner
      deliveryPartnerFee
      eGHLCardType
      eGHLIssuingBank
      eGHLPymtMethod
      riderJobNumber
      deliveryNumber
      totalOutOfStock
      partialFulfilmentAmount
      partialFulfilmentDiscount
      accumulatedRefundAmount
      promoDiscount
      promoCodeTitle
      promoCode
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      totalVoucherDiscount
      voucherRefunded
      promoCodeCampaignId
      netDiscountAmount
      oriTotalVoucherDiscount
      deliveryDiscount
      netDeliveryDiscount
      deliverySurchargeAmount
      orderDetails {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      orderReview {
        items {
          orderReviewId
          overallRating
          foodRating
          waitingTimeRating
          driverServiceRating
          staffServiceRating
          productAvailabilityRating
          comment
          attachment
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderPayment {
        items {
          orderPaymentId
          amount
          status
          paymentNumber
          paymentOrderNumber
          transactionType
          paymentMethod
          paymentResponseBase64
          eGHLTransactionType
          eGHLPymtMethod
          eGHLServiceID
          eGHLPaymentID
          eGHLOrderNumber
          eGHLAmount
          eGHLCurrencyCode
          eGHLHashValue
          eGHLHashValue2
          eGHLTxnID
          eGHLIssuingBank
          eGHLTxnStatus
          eGHLAuthCode
          eGHLBankRefNo
          eGHLRespTime
          eGHLTxnMessage
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      refundTransaction {
        items {
          refundTransactionId
          refundAmount
          automatedRefundStatus
          emailRefundStatus
          manualRefundStatus
          manualCSRefundStatus
          eGHLRefundStatus
          customerServiceStatus
          orderId
          refundTxnId
          orderNumber
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          paymentMethod
          paymentChannel
          refundReason
          paymentId
          completedBy
          refundDateTime
          redmineTicketNumber
          lastOrderTransitionDate
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const createOrderDetail = /* GraphQL */ `
  mutation CreateOrderDetail($input: OrderDetailInput) {
    createOrderDetail(input: $input) {
      orderDetailId
      orderDetailOrderId
      orderDetailPriceGroupId
      orderDetailProductId
      orderDetailProductUOMId
      orderDetailProductUOMPriceGroupId
      quantity
      subtotal
      discount
      promoDiscount
      mandatoryItem
      isFreeItem
      outOfStock
      itemStatus
      productName
      productDescription
      productImage
      createdAt
      updatedAt
      totalPromoCodeDiscount
      totalDiscountAmount
      totalVoucherDiscount
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      orderPayment {
        items {
          orderPaymentId
          amount
          status
          paymentNumber
          paymentOrderNumber
          transactionType
          paymentMethod
          paymentResponseBase64
          eGHLTransactionType
          eGHLPymtMethod
          eGHLServiceID
          eGHLPaymentID
          eGHLOrderNumber
          eGHLAmount
          eGHLCurrencyCode
          eGHLHashValue
          eGHLHashValue2
          eGHLTxnID
          eGHLIssuingBank
          eGHLTxnStatus
          eGHLAuthCode
          eGHLBankRefNo
          eGHLRespTime
          eGHLTxnMessage
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const createOrderPayment = /* GraphQL */ `
  mutation CreateOrderPayment($input: OrderPaymentInput) {
    createOrderPayment(input: $input) {
      orderPaymentId
      amount
      status
      paymentNumber
      paymentOrderNumber
      transactionType
      paymentMethod
      paymentResponseBase64
      eGHLTransactionType
      eGHLPymtMethod
      eGHLServiceID
      eGHLPaymentID
      eGHLOrderNumber
      eGHLAmount
      eGHLCurrencyCode
      eGHLHashValue
      eGHLHashValue2
      eGHLTxnID
      eGHLIssuingBank
      eGHLTxnStatus
      eGHLAuthCode
      eGHLBankRefNo
      eGHLRespTime
      eGHLTxnMessage
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createRefundTransaction = /* GraphQL */ `
  mutation CreateRefundTransaction($input: RefundTransactionInput) {
    createRefundTransaction(input: $input) {
      refundTransactionId
      refundAmount
      automatedRefundStatus
      emailRefundStatus
      manualRefundStatus
      manualCSRefundStatus
      eGHLRefundStatus
      customerServiceStatus
      orderId
      refundTxnId
      orderNumber
      customerAccountNo
      customerFirstName
      customerLastName
      customerPhoneNumber
      paymentMethod
      paymentChannel
      refundReason
      paymentId
      completedBy
      refundDateTime
      redmineTicketNumber
      lastOrderTransitionDate
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createRefundProcess = /* GraphQL */ `
  mutation CreateRefundProcess($input: RefundProcessInput) {
    createRefundProcess(input: $input) {
      refundProcessId
      paymentMethod
      paymentChannel
      isAutomatedRefund
      isEmailRefund
      isManualRefund
      isReverseRefundAvailable
      createdAt
      updatedAt
    }
  }
`
export const createCustomerWishList = /* GraphQL */ `
  mutation CreateCustomerWishList($input: CustomerWishListInput) {
    createCustomerWishList(input: $input) {
      customerWishListId
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createOrderReview = /* GraphQL */ `
  mutation CreateOrderReview($input: OrderReviewInput) {
    createOrderReview(input: $input) {
      orderReviewId
      overallRating
      foodRating
      waitingTimeRating
      driverServiceRating
      staffServiceRating
      productAvailabilityRating
      comment
      attachment
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createUserMatrix = /* GraphQL */ `
  mutation CreateUserMatrix($input: UserMatrixInput) {
    createUserMatrix(input: $input) {
      userMatrixId
      userGroup
      canEdit
      canList
      canView
      canDelete
      canAdd
      module
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateDriveThruProductOutOfStock = /* GraphQL */ `
  mutation UpdateDriveThruProductOutOfStock(
    $storeId: String
    $productId: String
    $isOutOfStock: Boolean
  ) {
    updateDriveThruProductOutOfStock(
      storeId: $storeId
      productId: $productId
      isOutOfStock: $isOutOfStock
    )
  }
`
export const updateProductOutOfStock = /* GraphQL */ `
  mutation UpdateProductOutOfStock(
    $storeId: String
    $productId: String
    $isOutOfStock: Boolean
  ) {
    updateProductOutOfStock(
      storeId: $storeId
      productId: $productId
      isOutOfStock: $isOutOfStock
    )
  }
`
export const createDriveThruConfig = /* GraphQL */ `
  mutation CreateDriveThruConfig(
    $input: CreateDriveThruConfigInput!
    $condition: ModelDriveThruConfigConditionInput
  ) {
    createDriveThruConfig(input: $input, condition: $condition) {
      driveThruConfigId
      driveThruConfigStoreId
      screenNumber
      sectionNumber
      sectionTitle
      layout
      isDisabled
      effectiveStart
      effectiveEnd
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateDriveThruConfig = /* GraphQL */ `
  mutation UpdateDriveThruConfig(
    $input: UpdateDriveThruConfigInput!
    $condition: ModelDriveThruConfigConditionInput
  ) {
    updateDriveThruConfig(input: $input, condition: $condition) {
      driveThruConfigId
      driveThruConfigStoreId
      screenNumber
      sectionNumber
      sectionTitle
      layout
      isDisabled
      effectiveStart
      effectiveEnd
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteDriveThruConfig = /* GraphQL */ `
  mutation DeleteDriveThruConfig(
    $input: DeleteDriveThruConfigInput!
    $condition: ModelDriveThruConfigConditionInput
  ) {
    deleteDriveThruConfig(input: $input, condition: $condition) {
      driveThruConfigId
      driveThruConfigStoreId
      screenNumber
      sectionNumber
      sectionTitle
      layout
      isDisabled
      effectiveStart
      effectiveEnd
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createDriveThruProduct = /* GraphQL */ `
  mutation CreateDriveThruProduct(
    $input: CreateDriveThruProductInput!
    $condition: ModelDriveThruProductConditionInput
  ) {
    createDriveThruProduct(input: $input, condition: $condition) {
      driveThruProductId
      driveThruProductProductUOMId
      driveThruProductConfigId
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateDriveThruProduct = /* GraphQL */ `
  mutation UpdateDriveThruProduct(
    $input: UpdateDriveThruProductInput!
    $condition: ModelDriveThruProductConditionInput
  ) {
    updateDriveThruProduct(input: $input, condition: $condition) {
      driveThruProductId
      driveThruProductProductUOMId
      driveThruProductConfigId
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteDriveThruProduct = /* GraphQL */ `
  mutation DeleteDriveThruProduct(
    $input: DeleteDriveThruProductInput!
    $condition: ModelDriveThruProductConditionInput
  ) {
    deleteDriveThruProduct(input: $input, condition: $condition) {
      driveThruProductId
      driveThruProductProductUOMId
      driveThruProductConfigId
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createDriveThruBanner = /* GraphQL */ `
  mutation CreateDriveThruBanner(
    $input: CreateDriveThruBannerInput!
    $condition: ModelDriveThruBannerConditionInput
  ) {
    createDriveThruBanner(input: $input, condition: $condition) {
      driveThruBannerId
      driveThruBannerConfigId
      image
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateDriveThruBanner = /* GraphQL */ `
  mutation UpdateDriveThruBanner(
    $input: UpdateDriveThruBannerInput!
    $condition: ModelDriveThruBannerConditionInput
  ) {
    updateDriveThruBanner(input: $input, condition: $condition) {
      driveThruBannerId
      driveThruBannerConfigId
      image
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteDriveThruBanner = /* GraphQL */ `
  mutation DeleteDriveThruBanner(
    $input: DeleteDriveThruBannerInput!
    $condition: ModelDriveThruBannerConditionInput
  ) {
    deleteDriveThruBanner(input: $input, condition: $condition) {
      driveThruBannerId
      driveThruBannerConfigId
      image
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createDriveThruProductStore = /* GraphQL */ `
  mutation CreateDriveThruProductStore(
    $input: CreateDriveThruProductStoreInput!
    $condition: ModelDriveThruProductStoreConditionInput
  ) {
    createDriveThruProductStore(input: $input, condition: $condition) {
      driveThruProductStoreId
      driveThruProductStoreProductUOMId
      driveThruProductStoreStoreId
      isOutOfStock
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateDriveThruProductStore = /* GraphQL */ `
  mutation UpdateDriveThruProductStore(
    $input: UpdateDriveThruProductStoreInput!
    $condition: ModelDriveThruProductStoreConditionInput
  ) {
    updateDriveThruProductStore(input: $input, condition: $condition) {
      driveThruProductStoreId
      driveThruProductStoreProductUOMId
      driveThruProductStoreStoreId
      isOutOfStock
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteDriveThruProductStore = /* GraphQL */ `
  mutation DeleteDriveThruProductStore(
    $input: DeleteDriveThruProductStoreInput!
    $condition: ModelDriveThruProductStoreConditionInput
  ) {
    deleteDriveThruProductStore(input: $input, condition: $condition) {
      driveThruProductStoreId
      driveThruProductStoreProductUOMId
      driveThruProductStoreStoreId
      isOutOfStock
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createDriveThruAppBanner = /* GraphQL */ `
  mutation CreateDriveThruAppBanner(
    $input: CreateDriveThruAppBannerInput!
    $condition: ModelDriveThruAppBannerConditionInput
  ) {
    createDriveThruAppBanner(input: $input, condition: $condition) {
      driveThruAppBannerId
      image
      sequenceOrder
      driveThruAppBannerStoreId
      createdBy
      createdAt
      modifiedBy
      updatedAt
    }
  }
`
export const updateDriveThruAppBanner = /* GraphQL */ `
  mutation UpdateDriveThruAppBanner(
    $input: UpdateDriveThruAppBannerInput!
    $condition: ModelDriveThruAppBannerConditionInput
  ) {
    updateDriveThruAppBanner(input: $input, condition: $condition) {
      driveThruAppBannerId
      image
      sequenceOrder
      driveThruAppBannerStoreId
      createdBy
      createdAt
      modifiedBy
      updatedAt
    }
  }
`
export const deleteDriveThruAppBanner = /* GraphQL */ `
  mutation DeleteDriveThruAppBanner(
    $input: DeleteDriveThruAppBannerInput!
    $condition: ModelDriveThruAppBannerConditionInput
  ) {
    deleteDriveThruAppBanner(input: $input, condition: $condition) {
      driveThruAppBannerId
      image
      sequenceOrder
      driveThruAppBannerStoreId
      createdBy
      createdAt
      modifiedBy
      updatedAt
    }
  }
`
export const createAppLaunchPromo = /* GraphQL */ `
  mutation CreateAppLaunchPromo($input: AppLaunchPromoInput) {
    createAppLaunchPromo(input: $input) {
      appLaunchPromoId
      title
      image
      startDate
      endDate
      promoType
      promoEntityId
      isDisabled
      createdBy
      modifiedBy
      sequenceOrder
      buttonLabel
      createdAt
      updatedAt
    }
  }
`
export const createLandingPageBanner = /* GraphQL */ `
  mutation CreateLandingPageBanner($input: LandingPageBannerInput) {
    createLandingPageBanner(input: $input) {
      landingPageBannerId
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      title
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
      promoEntityId
      promoType
      buttonLabel
      promoTitle
      platform
      shortLinkUrl
    }
  }
`
export const createHomePageBanner = /* GraphQL */ `
  mutation CreateHomePageBanner($input: HomePageBannerInput) {
    createHomePageBanner(input: $input) {
      homePageBannerId
      bannerId
      bannerNumber
      image
      startDate
      endDate
      isDisabled
      sequenceOrder
      listBanners {
        id
        image
        title
        description
        sequence
      }
      title
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      pageLayout
      description
      validationPeriodId
      shortLinkUrl
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createStoreGroup = /* GraphQL */ `
  mutation CreateStoreGroup($input: StoreGroupInput) {
    createStoreGroup(input: $input) {
      storeGroupId
      code
      description
      stores
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createLandingPageProduct = /* GraphQL */ `
  mutation CreateLandingPageProduct($input: LandingPageProductInput) {
    createLandingPageProduct(input: $input) {
      landingPageProductId
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      title
      productType
      productTypeId
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createDeliveryPartner = /* GraphQL */ `
  mutation CreateDeliveryPartner($input: DeliveryPartnerInput) {
    createDeliveryPartner(input: $input) {
      deliveryPartnerId
      deliveryPartnerName
      apiEndpoint
      isCancelAfterAcceptAllowed
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createStoreDeliveryPartner = /* GraphQL */ `
  mutation CreateStoreDeliveryPartner($input: StoreDeliveryPartnerInput) {
    createStoreDeliveryPartner(input: $input) {
      partnerId
      storeDeliveryPartnerStoreId
      partnerName
      sequence
      isPriority
      createdBy
      modifiedBy
      createdAt
      updatedAt
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const createOrderDeliveryOrder = /* GraphQL */ `
  mutation CreateOrderDeliveryOrder($input: OrderDeliveryOrderInput) {
    createOrderDeliveryOrder(input: $input) {
      orderDeliveryOrderId
      deliveryPartner
      deliveryNumber
      orderNumber
      status
      totalFee
      createdBy
      modifiedBy
      createdAt
      updatedAt
      deliveryOrderDateTime
    }
  }
`
export const createPushNotification = /* GraphQL */ `
  mutation CreatePushNotification($input: PushNotificationInput) {
    createPushNotification(input: $input) {
      pushNotificationId
      scheduleDateTime
      pushedDateTime
      messageSent
      newUserEndDate
      newUserStartDate
      body
      title
      bannerImage
      thumbnailImage
      createdBy
      modifiedBy
      createdAt
      updatedAt
      status
      actionType
      pageLayout
      actionId
      targetAudience {
        method
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      readNotification
      senderMobileNumber
      senderName
      voucherExpiryDate
      voucherStatus
      voucherNumber
      receiverName
      voucherTitle
      inviteLink
    }
  }
`
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: NotificationInput) {
    createNotification(input: $input) {
      notificationId
      customerId
      title
      thumbnailImage
      summary
      bannerImage
      description
      link
      pageLayout
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CustomerInput) {
    createCustomer(input: $input) {
      customerId
      accountNo
      clubCode
      firstName
      lastName
      primaryEmail
      secondaryEmail
      country
      address
      address2
      identityCard
      custClubCode
      custSchemeCode
      joinDate
      joinTime
      staffId
      staffJoinDate
      isBlocked
      actionDate
      actionReasonCode
      actionBy
      consentPersonalData
      city
      state
      postal
      mobileNumber
      password
      gender
      dateOfBirth
      maritalStatus
      race
      employmentStatus
      personalIncomeLevel
      activated
      facebookID
      isFacebook
      qrCode
      profilePicture
      nationality
      signedUpDate
      signedUpTime
      hasRewarded
      promoCode
      tagCode
      createdBy
      modifiedBy
      customerDeviceToken
      customerDeviceEndpoint
      areaOfInterest
      createdAt
      updatedAt
      hasCompletedProfile
      userPointTransaction {
        items {
          transactionId
          pointValue
          note
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerTagCode {
        items {
          customerTagCodeId
          tagCode
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      customerVouchers {
        voucherId
        rewardId
        status
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        createdAt
        updatedAt
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      wishList {
        items {
          customerWishListId
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderReview {
        items {
          orderReviewId
          overallRating
          foodRating
          waitingTimeRating
          driverServiceRating
          staffServiceRating
          productAvailabilityRating
          comment
          attachment
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerFavouriteAddress {
        items {
          customerFavouriteAddressId
          title
          country
          address
          address2
          city
          state
          postal
          isPrimary
          latitude
          longitude
          notes
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
        }
        nextToken
      }
      customerFavouritePayment {
        items {
          customerFavouritePaymentId
          creditCardNumber
          paymentToken
          paymentType
          creditCardType
          expiryDate
          country
          isPrimary
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
        }
        nextToken
      }
      customerFeedback {
        items {
          feedbackId
          searchLocation
          dateOfVisit
          timeOfVisit
          title
          description
          uploadPhoto
          createdBy
          modifiedBy
          redmineTicketNumber
          platform
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          customerName
          customerMobileNo
          customerEmail
          issueStatus
        }
        nextToken
      }
    }
  }
`
export const createCustomerCart = /* GraphQL */ `
  mutation CreateCustomerCart($input: CustomerCartInput) {
    createCustomerCart(input: $input) {
      customerCartId
      quantity
      price
      subtotal
      discountAmount
      productName
      productTitle
      productCategories
      productTaggings
      productCode
      productImage
      mandatoryItem
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createCustomerTagCode = /* GraphQL */ `
  mutation CreateCustomerTagCode($input: CustomerTagCodeInput) {
    createCustomerTagCode(input: $input) {
      customerTagCodeId
      tagCode
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createCustomerFavouriteAddress = /* GraphQL */ `
  mutation CreateCustomerFavouriteAddress(
    $input: CustomerFavouriteAddressInput
  ) {
    createCustomerFavouriteAddress(input: $input) {
      customerFavouriteAddressId
      title
      country
      address
      address2
      city
      state
      postal
      isPrimary
      latitude
      longitude
      notes
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
    }
  }
`
export const createCustomerFavouritePayment = /* GraphQL */ `
  mutation CreateCustomerFavouritePayment(
    $input: CustomerFavouritePaymentInput
  ) {
    createCustomerFavouritePayment(input: $input) {
      customerFavouritePaymentId
      creditCardNumber
      paymentToken
      paymentType
      creditCardType
      expiryDate
      country
      isPrimary
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
    }
  }
`
export const createAppConfig = /* GraphQL */ `
  mutation CreateAppConfig($input: AppConfigInput) {
    createAppConfig(input: $input) {
      appConfigId
      configKey
      configValue
      createdAt
      updatedAt
    }
  }
`
export const createIssue = /* GraphQL */ `
  mutation CreateIssue($input: IssueInput) {
    createIssue(input: $input) {
      issueId
      orderNumber
      title
      hasNewMessage
      totalQuantity
      totalAmount
      totalMessage
      customerAccountNo
      issueDateTime
      issueStatus
      redMineTicketNumber
      platform
      trackerNumber
      parcelAmount
      createdAt
      updatedAt
    }
  }
`
export const createIssueDetail = /* GraphQL */ `
  mutation CreateIssueDetail($input: IssueDetailInput) {
    createIssueDetail(input: $input) {
      issueDetailId
      message
      image1
      image2
      image3
      isCustomerMessage
      issueDetailDateTime
      issueDetailIssueId
      productImage
      platform
      createdAt
      updatedAt
    }
  }
`
export const createPromotionPartner = /* GraphQL */ `
  mutation CreatePromotionPartner($input: PromotionPartnerInput) {
    createPromotionPartner(input: $input) {
      promotionPartnerId
      image
      name
      defaultPrefix
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createPromoCodeCampaign = /* GraphQL */ `
  mutation CreatePromoCodeCampaign($input: PromoCodeCampaignInput) {
    createPromoCodeCampaign(input: $input) {
      promoCodeId
      title
      campaignId
      navPromoId
      promoCode
      promoType
      forPartner
      maxUserUsageLimit
      totalUsageLimit
      totalNumOfCodes
      forMultipleUsage
      orderType
      deliveryFree
      discountTypeDelivery
      discountTypeLevel
      discountDeliveryCap
      discountProductQuantityCap
      discountCap
      discountType
      discountValue
      discountOnProductType
      discountOnProductValue
      deliveryFeeDiscount
      promoStartDatetime
      promoEndDatetime
      promoRepeatDay
      promoRepeatStartTime
      promoRepeatEndTime
      totalCount
      activeCount
      redeemedCount
      deactivatedCount
      athenaQueryId
      promoCodeStatusFile
      usageCondition
      productCategoryCondition {
        type
        ids
      }
      productCategoryDiscount {
        type
        ids
      }
      stores
      freeProducts {
        productId
        quantity
      }
      status
      minimumSpent
      minimumQuantity
      jobStatus
      modifiedBy
      isPromoEnabled
      platform
      statusUpdatedAt
      codeUpdatedAt
      createdAt
      updatedAt
    }
  }
`
export const createPromoCodeTxn = /* GraphQL */ `
  mutation CreatePromoCodeTxn($input: PromoCodeTxnInput) {
    createPromoCodeTxn(input: $input) {
      promoCodeTxnId
      redeemedDatetime
      voidDatetime
      customerId
      promoCodeId
      promoCode
      promoCodeLength
      lastModifiedBy
      deactivatedAt
      status
      createdAt
      updatedAt
    }
  }
`
export const createPromoCodeGeneric = /* GraphQL */ `
  mutation CreatePromoCodeGeneric($input: PromoCodeGenericInput) {
    createPromoCodeGeneric(input: $input) {
      promoCodeGenericId
      campaignId
      promoCode
      promoCodeCampaignId
      lastModifiedBy
      deactivatedAt
      status
      redeemedCount
      createdAt
      updatedAt
    }
  }
`
export const createPromoCodeCampaignPartner = /* GraphQL */ `
  mutation CreatePromoCodeCampaignPartner(
    $input: PromoCodeCampaignPartnerInput
  ) {
    createPromoCodeCampaignPartner(input: $input) {
      promoCodeCampaignPartnerId
      promoCodeId
      partnerName
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement($input: AnnouncementInput) {
    createAnnouncement(input: $input) {
      announcementId
      title
      startDate
      endDate
      scheduleDatetime
      body
      actionType
      actionId
      bannerImage
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createCustomerTag = /* GraphQL */ `
  mutation CreateCustomerTag($input: CustomerTagInput) {
    createCustomerTag(input: $input) {
      tagId
      title
      tagMode
      startDate
      endDate
      ruleName
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createCustomerTagRule = /* GraphQL */ `
  mutation CreateCustomerTagRule($input: CustomerTagRuleInput) {
    createCustomerTagRule(input: $input) {
      ruleId
      tagId
      ruleType
      ruleField
      ruleOperator
      ruleValue
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createPriceGroup = /* GraphQL */ `
  mutation CreatePriceGroup($input: PriceGroupInput) {
    createPriceGroup(input: $input) {
      priceGroupId
      title
      modifiedBy
      createdAt
      updatedAt
      productUOMPriceGroup {
        items {
          productUOMPriceGroupId
          productUOMId
          priceGroupId
          promotionDescription
          pickupPromotionDescription
          discountedPrice
          discountPercentage
          discount
          pickupDiscountedPrice
          pickupPromotionDiscount
          pickupDiscountPercentage
          pickupDiscount
          pickupPrice
          pickupEffectiveStartDate
          pickupEffectiveEndDate
          price
          discountStartDate
          discountEndDate
          effectiveStartDate
          effectiveEndDate
          pickupDiscountStartDate
          pickupDiscountEndDate
          modifiedBy
          createdAt
          updatedAt
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          priceGroup {
            priceGroupId
            title
            modifiedBy
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`
export const createProductUOM = /* GraphQL */ `
  mutation CreateProductUOM($input: ProductUOMInput) {
    createProductUOM(input: $input) {
      productUOMId
      productId
      image
      isDisabled
      description
      minFoodPreparationDuration
      pickupMaximumQuantity
      sku
      ecommerceMaximumQuantity
      departmentLevel1
      departmentLevel2
      productGroupDesc
      productGroupCode
      divisionDesc
      itemCategoryDesc
      title
      uom
      minDeliveryDuration
      taggings
      itemPublishStartDate
      itemPublishEndDate
      modifiedBy
      createdAt
      updatedAt
      orderDetail {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productUOMPriceGroup {
        items {
          productUOMPriceGroupId
          productUOMId
          priceGroupId
          promotionDescription
          pickupPromotionDescription
          discountedPrice
          discountPercentage
          discount
          pickupDiscountedPrice
          pickupPromotionDiscount
          pickupDiscountPercentage
          pickupDiscount
          pickupPrice
          pickupEffectiveStartDate
          pickupEffectiveEndDate
          price
          discountStartDate
          discountEndDate
          effectiveStartDate
          effectiveEndDate
          pickupDiscountStartDate
          pickupDiscountEndDate
          modifiedBy
          createdAt
          updatedAt
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          priceGroup {
            priceGroupId
            title
            modifiedBy
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const createProductUOMPriceGroup = /* GraphQL */ `
  mutation CreateProductUOMPriceGroup($input: ProductUOMPriceGroupInput) {
    createProductUOMPriceGroup(input: $input) {
      productUOMPriceGroupId
      productUOMId
      priceGroupId
      promotionDescription
      pickupPromotionDescription
      discountedPrice
      discountPercentage
      discount
      pickupDiscountedPrice
      pickupPromotionDiscount
      pickupDiscountPercentage
      pickupDiscount
      pickupPrice
      pickupEffectiveStartDate
      pickupEffectiveEndDate
      price
      discountStartDate
      discountEndDate
      effectiveStartDate
      effectiveEndDate
      pickupDiscountStartDate
      pickupDiscountEndDate
      modifiedBy
      createdAt
      updatedAt
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      priceGroup {
        priceGroupId
        title
        modifiedBy
        createdAt
        updatedAt
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const createPromoCodeStatusJob = /* GraphQL */ `
  mutation CreatePromoCodeStatusJob($input: PromoCodeStatusJobInput) {
    createPromoCodeStatusJob(input: $input) {
      promoCodeStatusJobId
      messageId
      athenaQueryId
      promoCodeStatusFile
      promoCodeList {
        promoCodeGenericId
        promoCodeTxnId
        promoCode
        status
        createdAt
        redeemedAt
        deactivatedAt
        redeemedCount
      }
      status
      totalCount
      activeCount
      redeemedCount
      deactivatedCount
      statusUpdatedAt
      codeUpdatedAt
      createdAt
      updatedAt
    }
  }
`
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore($input: StoreInput) {
    deleteStore(input: $input) {
      storeId
      driveThru
      visibleToEComm
      storeName
      address
      latitude
      longitude
      openHour
      closeHour
      deliveryDiscountType
      deliveryServiceAvailable
      pickupServiceAvailable
      minFoodPreparationDuration
      minDeliveryDuration
      deliveryFee
      isDisabled
      isOnline24Hour
      isOffline24Hour
      postalCode
      city
      state
      storeCode
      lastOrderTime
      minPurchaseAmount
      freeDeliveryWithMinPurchase
      acceptOrderWithMinPurchase
      maxOrderQty
      eCommStartDate
      eCommEndDate
      hasSeatingArea
      createdBy
      modifiedBy
      storeManagerName
      storeManagerContact
      priceGroupId
      storeGroup
      createdAt
      updatedAt
      minDistanceCharges
      minDistance
      pollingDeliveryTime
      riderTimeout
      deliveryGracePeriod
      routingOption
      priorityDeliveryPartner
      incrementDistanceCharges
      incrementDistanceUnit
      deliveryDiscountAmount
      deliveryDiscountBasketValue
      deliveryPartnerMaxRetry
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      operatingHour {
        items {
          storeOperatingHourId
          onlineStoreOpenHour
          onlineStoreCloseHour
          offlineStoreOpenHour
          offlineStoreCloseHour
          isOffline24Hour
          isOnline24Hour
          onlineIsOpen
          offlineIsOpen
          day
          modifiedBy
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      storeBlockedOutPeriods {
        items {
          storeBlockedOutPeriodId
          startDateTime
          endDateTime
          storeType
          createdBy
          modifiedBy
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      StoreDeliveryPartnerMapping {
        items {
          partnerId
          storeDeliveryPartnerStoreId
          partnerName
          sequence
          isPriority
          createdBy
          modifiedBy
          createdAt
          updatedAt
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const deleteStoreOperatingHour = /* GraphQL */ `
  mutation DeleteStoreOperatingHour($input: StoreOperatingHourInput) {
    deleteStoreOperatingHour(input: $input) {
      storeOperatingHourId
      onlineStoreOpenHour
      onlineStoreCloseHour
      offlineStoreOpenHour
      offlineStoreCloseHour
      isOffline24Hour
      isOnline24Hour
      onlineIsOpen
      offlineIsOpen
      day
      modifiedBy
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteTrendingText = /* GraphQL */ `
  mutation DeleteTrendingText($input: TrendingTextInput) {
    deleteTrendingText(input: $input) {
      trendingTextId
      title
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteLandingMenu = /* GraphQL */ `
  mutation DeleteLandingMenu($input: LandingMenuInput) {
    deleteLandingMenu(input: $input) {
      landingMenuId
      actionType
      actionId
      title
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      modifiedBy
      updatedAt
      createdAt
      shortLinkUrl
    }
  }
`
export const deleteStoreBlockedOutPeriod = /* GraphQL */ `
  mutation DeleteStoreBlockedOutPeriod($input: StoreBlockedOutPeriodInput) {
    deleteStoreBlockedOutPeriod(input: $input) {
      storeBlockedOutPeriodId
      startDateTime
      endDateTime
      storeType
      createdBy
      modifiedBy
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($input: ProductInput) {
    deleteProduct(input: $input) {
      productId
      sku
      name
      title
      image
      price
      discount
      discountPercentage
      discountedPrice
      promotionDescription
      discountStartDate
      discountEndDate
      pickupPrice
      pickupDiscount
      pickupDiscountPercentage
      pickupDiscountedPrice
      pickupPromotionDescription
      pickupDiscountStartDate
      pickupDiscountEndDate
      category
      categories
      departments
      taggings
      minFoodPreparationDuration
      minDeliveryDuration
      createdBy
      modifiedBy
      isDisabled
      description
      uom
      dimension
      temperature
      itemPublishStartDate
      itemPublishEndDate
      ecommerceMaximumQuantity
      pickupMaximumQuantity
      createdAt
      updatedAt
      divisionCode
      divisionDesc
      itemCategoryCode
      itemCategoryDesc
      productGroupCode
      productGroupDesc
      departmentLevel1
      departmentLevel2
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productCategory {
        items {
          productCategoryId
          category {
            categoryId
            title
            image
            code
            createdBy
            modifiedBy
            isDisabled
            parentId
            createdAt
            updatedAt
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderDetail {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productImage {
        items {
          productImageId
          image
          sequence
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerWishList {
        items {
          customerWishListId
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const deleteProductStoreDepartment = /* GraphQL */ `
  mutation DeleteProductStoreDepartment($input: ProductStoreDepartmentInput) {
    deleteProductStoreDepartment(input: $input) {
      productStoreDepartmentId
      productStoreDepartmentProductUOMId
      productStoreDepartmentStoreId
      productStoreDepartmentSubDepartmentId
      productStoreDepartmentProductUOMPriceGroupId
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      department {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      subDepartment {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      isOutOfStock
      createdAt
      updatedAt
    }
  }
`
export const deleteProductStoreTagging = /* GraphQL */ `
  mutation DeleteProductStoreTagging($input: ProductStoreTaggingInput) {
    deleteProductStoreTagging(input: $input) {
      productStoreTaggingId
      productStoreTaggingProductUOMId
      productStoreTaggingProductTaggingId
      productStoreTaggingStoreId
      productStoreTaggingProductUOMPriceGroupId
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      isOutOfStock
      createdAt
      updatedAt
    }
  }
`
export const deleteProductImage = /* GraphQL */ `
  mutation DeleteProductImage($input: ProductImageInput) {
    deleteProductImage(input: $input) {
      productImageId
      image
      sequence
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteProductStoreTaggingMapping = /* GraphQL */ `
  mutation DeleteProductStoreTaggingMapping($productStoreTaggingMapping: ID!) {
    deleteProductStoreTaggingMapping(
      productStoreTaggingMapping: $productStoreTaggingMapping
    ) {
      productStoreTaggingMapping
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteProductStore = /* GraphQL */ `
  mutation DeleteProductStore($input: ProductStoreInput) {
    deleteProductStore(input: $input) {
      productStoreId
      productStoreProductId
      isOutOfStock
      modifiedBy
      productStoreProductUOMId
      productStoreStoreId
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteKDSDeviceToken = /* GraphQL */ `
  mutation DeleteKDSDeviceToken($input: KDSDeviceTokenInput) {
    deleteKDSDeviceToken(input: $input) {
      id
      deviceEndpoint
      deviceToken
      storeCode
      createdAt
      updatedAt
    }
  }
`
export const deleteJwtToken = /* GraphQL */ `
  mutation DeleteJwtToken($input: JWTTokenInput) {
    deleteJwtToken(input: $input) {
      sessionID
      signedToken
      token
      expiryDate
      accountNo
      createdAt
      updatedAt
    }
  }
`
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback($input: FeedbackInput) {
    deleteFeedback(input: $input) {
      feedbackId
      searchLocation
      dateOfVisit
      timeOfVisit
      title
      description
      uploadPhoto
      createdBy
      modifiedBy
      redmineTicketNumber
      platform
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      customerName
      customerMobileNo
      customerEmail
      issueStatus
    }
  }
`
export const deleteProductCategory = /* GraphQL */ `
  mutation DeleteProductCategory($input: ProductCategoryInput) {
    deleteProductCategory(input: $input) {
      productCategoryId
      category {
        categoryId
        title
        image
        code
        createdBy
        modifiedBy
        isDisabled
        parentId
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteProductDepartment = /* GraphQL */ `
  mutation DeleteProductDepartment($input: ProductDepartmentInput) {
    deleteProductDepartment(input: $input) {
      productDepartmentId
      productDepartmentProductUOMId
      department {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteProductTaggingMapping = /* GraphQL */ `
  mutation DeleteProductTaggingMapping($input: ProductTaggingMappingInput) {
    deleteProductTaggingMapping(input: $input) {
      productTaggingMappingId
      productTaggingMappingProductId
      productTaggingMappingProductTaggingId
      productTaggingMappingProductUOMId
      modifiedBy
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($input: CategoryInput) {
    deleteCategory(input: $input) {
      categoryId
      title
      image
      code
      createdBy
      modifiedBy
      isDisabled
      parentId
      productCategory {
        items {
          productCategoryId
          category {
            categoryId
            title
            image
            code
            createdBy
            modifiedBy
            isDisabled
            parentId
            createdAt
            updatedAt
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment($input: DepartmentInput) {
    deleteDepartment(input: $input) {
      departmentId
      title
      image
      createdBy
      modifiedBy
      updatedAt
      createdAt
      isDisabled
      effectiveStartDate
      effectiveEndDate
      parentId
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreSubDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const deleteProductTagging = /* GraphQL */ `
  mutation DeleteProductTagging($input: ProductTaggingInput) {
    deleteProductTagging(input: $input) {
      productTaggingId
      title
      image
      code
      effectiveStartDate
      effectiveEndDate
      isDisabled
      createdBy
      modifiedBy
      updatedAt
      createdAt
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const deleteUserPointTransaction = /* GraphQL */ `
  mutation DeleteUserPointTransaction($input: UserPointTransactionInput) {
    deleteUserPointTransaction(input: $input) {
      transactionId
      pointValue
      note
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteVoucherRewardSKU = /* GraphQL */ `
  mutation DeleteVoucherRewardSKU($input: VoucherRewardSKUInput) {
    deleteVoucherRewardSKU(input: $input) {
      voucherRewardSKUId
      rewardId
      entityId
      entityType
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteVoucherReward = /* GraphQL */ `
  mutation DeleteVoucherReward($input: VoucherRewardInput) {
    deleteVoucherReward(input: $input) {
      rewardId
      image
      startDate
      endDate
      point
      description
      title
      tnc
      cashValueAmount
      skuCashDiscount
      voucherType
      voucherStore {
        items {
          voucherRewardStoreId
          rewardId
          latitude
          longitude
          storeId
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      voucherSKU {
        items {
          voucherRewardSKUId
          rewardId
          entityId
          entityType
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteVoucherRewardStore = /* GraphQL */ `
  mutation DeleteVoucherRewardStore($input: VoucherRewardStoreInput) {
    deleteVoucherRewardStore(input: $input) {
      voucherRewardStoreId
      rewardId
      latitude
      longitude
      storeId
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteCustomerVoucher = /* GraphQL */ `
  mutation DeleteCustomerVoucher($input: CustomerVoucherInput) {
    deleteCustomerVoucher(input: $input) {
      voucherId
      rewardId
      status
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder($input: OrderInput) {
    deleteOrder(input: $input) {
      orderId
      orderNumber
      voucherNumber
      voucherTitle
      voucherEntryNumber
      orderDate
      paymentDateTime
      productImage
      grandTotal
      discountAmount
      deliveryFee
      voucherDiscount
      totalOrderItems
      status
      noteToRider
      floorOrUnit
      customerAccountNo
      customerFirstName
      customerLastName
      customerPhoneNumber
      storeName
      storeCode
      orderType
      deliveryAddress
      deliveryAddressLatitude
      deliveryAddressLongitude
      remarks
      routingOption
      reasonCode
      cancelReason
      cancelBy
      orderCancellationDateTime
      collectedDateTime
      createdAt
      updatedAt
      isAdvancedOrder
      scheduledDateTime
      driverName
      driverPhone
      driverPlateNumber
      lalamoveOrderId
      lalamoveOrderDate
      lalamoveTotalFee
      deliveryStatus
      intOrderNumber
      isRefunded
      refundAmount
      refundReason
      txnId
      refundCompleteDateTime
      orderCompleteDateTime
      cancelOrderDateTime
      requiredCutlery
      deliveryOrderDateTime
      deliveryOrderId
      deliveryPartner
      deliveryPartnerFee
      eGHLCardType
      eGHLIssuingBank
      eGHLPymtMethod
      riderJobNumber
      deliveryNumber
      totalOutOfStock
      partialFulfilmentAmount
      partialFulfilmentDiscount
      accumulatedRefundAmount
      promoDiscount
      promoCodeTitle
      promoCode
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      totalVoucherDiscount
      voucherRefunded
      promoCodeCampaignId
      netDiscountAmount
      oriTotalVoucherDiscount
      deliveryDiscount
      netDeliveryDiscount
      deliverySurchargeAmount
      orderDetails {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      orderReview {
        items {
          orderReviewId
          overallRating
          foodRating
          waitingTimeRating
          driverServiceRating
          staffServiceRating
          productAvailabilityRating
          comment
          attachment
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderPayment {
        items {
          orderPaymentId
          amount
          status
          paymentNumber
          paymentOrderNumber
          transactionType
          paymentMethod
          paymentResponseBase64
          eGHLTransactionType
          eGHLPymtMethod
          eGHLServiceID
          eGHLPaymentID
          eGHLOrderNumber
          eGHLAmount
          eGHLCurrencyCode
          eGHLHashValue
          eGHLHashValue2
          eGHLTxnID
          eGHLIssuingBank
          eGHLTxnStatus
          eGHLAuthCode
          eGHLBankRefNo
          eGHLRespTime
          eGHLTxnMessage
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      refundTransaction {
        items {
          refundTransactionId
          refundAmount
          automatedRefundStatus
          emailRefundStatus
          manualRefundStatus
          manualCSRefundStatus
          eGHLRefundStatus
          customerServiceStatus
          orderId
          refundTxnId
          orderNumber
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          paymentMethod
          paymentChannel
          refundReason
          paymentId
          completedBy
          refundDateTime
          redmineTicketNumber
          lastOrderTransitionDate
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const deleteOrderDetail = /* GraphQL */ `
  mutation DeleteOrderDetail($input: OrderDetailInput) {
    deleteOrderDetail(input: $input) {
      orderDetailId
      orderDetailOrderId
      orderDetailPriceGroupId
      orderDetailProductId
      orderDetailProductUOMId
      orderDetailProductUOMPriceGroupId
      quantity
      subtotal
      discount
      promoDiscount
      mandatoryItem
      isFreeItem
      outOfStock
      itemStatus
      productName
      productDescription
      productImage
      createdAt
      updatedAt
      totalPromoCodeDiscount
      totalDiscountAmount
      totalVoucherDiscount
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      orderPayment {
        items {
          orderPaymentId
          amount
          status
          paymentNumber
          paymentOrderNumber
          transactionType
          paymentMethod
          paymentResponseBase64
          eGHLTransactionType
          eGHLPymtMethod
          eGHLServiceID
          eGHLPaymentID
          eGHLOrderNumber
          eGHLAmount
          eGHLCurrencyCode
          eGHLHashValue
          eGHLHashValue2
          eGHLTxnID
          eGHLIssuingBank
          eGHLTxnStatus
          eGHLAuthCode
          eGHLBankRefNo
          eGHLRespTime
          eGHLTxnMessage
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const deleteOrderPayment = /* GraphQL */ `
  mutation DeleteOrderPayment($input: OrderPaymentInput) {
    deleteOrderPayment(input: $input) {
      orderPaymentId
      amount
      status
      paymentNumber
      paymentOrderNumber
      transactionType
      paymentMethod
      paymentResponseBase64
      eGHLTransactionType
      eGHLPymtMethod
      eGHLServiceID
      eGHLPaymentID
      eGHLOrderNumber
      eGHLAmount
      eGHLCurrencyCode
      eGHLHashValue
      eGHLHashValue2
      eGHLTxnID
      eGHLIssuingBank
      eGHLTxnStatus
      eGHLAuthCode
      eGHLBankRefNo
      eGHLRespTime
      eGHLTxnMessage
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteRefundTransaction = /* GraphQL */ `
  mutation DeleteRefundTransaction($input: RefundTransactionInput) {
    deleteRefundTransaction(input: $input) {
      refundTransactionId
      refundAmount
      automatedRefundStatus
      emailRefundStatus
      manualRefundStatus
      manualCSRefundStatus
      eGHLRefundStatus
      customerServiceStatus
      orderId
      refundTxnId
      orderNumber
      customerAccountNo
      customerFirstName
      customerLastName
      customerPhoneNumber
      paymentMethod
      paymentChannel
      refundReason
      paymentId
      completedBy
      refundDateTime
      redmineTicketNumber
      lastOrderTransitionDate
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteRefundProcess = /* GraphQL */ `
  mutation DeleteRefundProcess($input: RefundProcessInput) {
    deleteRefundProcess(input: $input) {
      refundProcessId
      paymentMethod
      paymentChannel
      isAutomatedRefund
      isEmailRefund
      isManualRefund
      isReverseRefundAvailable
      createdAt
      updatedAt
    }
  }
`
export const deleteCustomerWishList = /* GraphQL */ `
  mutation DeleteCustomerWishList($input: CustomerWishListInput) {
    deleteCustomerWishList(input: $input) {
      customerWishListId
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteOrderReview = /* GraphQL */ `
  mutation DeleteOrderReview($input: OrderReviewInput) {
    deleteOrderReview(input: $input) {
      orderReviewId
      overallRating
      foodRating
      waitingTimeRating
      driverServiceRating
      staffServiceRating
      productAvailabilityRating
      comment
      attachment
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteUserMatrix = /* GraphQL */ `
  mutation DeleteUserMatrix($input: UserMatrixInput) {
    deleteUserMatrix(input: $input) {
      userMatrixId
      userGroup
      canEdit
      canList
      canView
      canDelete
      canAdd
      module
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteAppLaunchPromo = /* GraphQL */ `
  mutation DeleteAppLaunchPromo($input: AppLaunchPromoInput) {
    deleteAppLaunchPromo(input: $input) {
      appLaunchPromoId
      title
      image
      startDate
      endDate
      promoType
      promoEntityId
      isDisabled
      createdBy
      modifiedBy
      sequenceOrder
      buttonLabel
      createdAt
      updatedAt
    }
  }
`
export const deleteLandingPageBanner = /* GraphQL */ `
  mutation DeleteLandingPageBanner($input: LandingPageBannerInput) {
    deleteLandingPageBanner(input: $input) {
      landingPageBannerId
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      title
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
      promoEntityId
      promoType
      buttonLabel
      promoTitle
      platform
      shortLinkUrl
    }
  }
`
export const deleteHomePageBanner = /* GraphQL */ `
  mutation DeleteHomePageBanner($input: HomePageBannerInput) {
    deleteHomePageBanner(input: $input) {
      homePageBannerId
      bannerId
      bannerNumber
      image
      startDate
      endDate
      isDisabled
      sequenceOrder
      listBanners {
        id
        image
        title
        description
        sequence
      }
      title
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      pageLayout
      description
      validationPeriodId
      shortLinkUrl
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteStoreGroup = /* GraphQL */ `
  mutation DeleteStoreGroup($input: StoreGroupInput) {
    deleteStoreGroup(input: $input) {
      storeGroupId
      code
      description
      stores
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteLandingPageProduct = /* GraphQL */ `
  mutation DeleteLandingPageProduct($input: LandingPageProductInput) {
    deleteLandingPageProduct(input: $input) {
      landingPageProductId
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      title
      productType
      productTypeId
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteDeliveryPartner = /* GraphQL */ `
  mutation DeleteDeliveryPartner($input: DeliveryPartnerInput) {
    deleteDeliveryPartner(input: $input) {
      deliveryPartnerId
      deliveryPartnerName
      apiEndpoint
      isCancelAfterAcceptAllowed
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteStoreDeliveryPartner = /* GraphQL */ `
  mutation DeleteStoreDeliveryPartner($input: StoreDeliveryPartnerInput) {
    deleteStoreDeliveryPartner(input: $input) {
      partnerId
      storeDeliveryPartnerStoreId
      partnerName
      sequence
      isPriority
      createdBy
      modifiedBy
      createdAt
      updatedAt
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const deleteOrderDeliveryOrder = /* GraphQL */ `
  mutation DeleteOrderDeliveryOrder($input: OrderDeliveryOrderInput) {
    deleteOrderDeliveryOrder(input: $input) {
      orderDeliveryOrderId
      deliveryPartner
      deliveryNumber
      orderNumber
      status
      totalFee
      createdBy
      modifiedBy
      createdAt
      updatedAt
      deliveryOrderDateTime
    }
  }
`
export const deletePushNotification = /* GraphQL */ `
  mutation DeletePushNotification($input: PushNotificationInput) {
    deletePushNotification(input: $input) {
      pushNotificationId
      scheduleDateTime
      pushedDateTime
      messageSent
      newUserEndDate
      newUserStartDate
      body
      title
      bannerImage
      thumbnailImage
      createdBy
      modifiedBy
      createdAt
      updatedAt
      status
      actionType
      pageLayout
      actionId
      targetAudience {
        method
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      readNotification
      senderMobileNumber
      senderName
      voucherExpiryDate
      voucherStatus
      voucherNumber
      receiverName
      voucherTitle
      inviteLink
    }
  }
`
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: NotificationInput) {
    deleteNotification(input: $input) {
      notificationId
      customerId
      title
      thumbnailImage
      summary
      bannerImage
      description
      link
      pageLayout
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($input: CustomerInput) {
    deleteCustomer(input: $input) {
      customerId
      accountNo
      clubCode
      firstName
      lastName
      primaryEmail
      secondaryEmail
      country
      address
      address2
      identityCard
      custClubCode
      custSchemeCode
      joinDate
      joinTime
      staffId
      staffJoinDate
      isBlocked
      actionDate
      actionReasonCode
      actionBy
      consentPersonalData
      city
      state
      postal
      mobileNumber
      password
      gender
      dateOfBirth
      maritalStatus
      race
      employmentStatus
      personalIncomeLevel
      activated
      facebookID
      isFacebook
      qrCode
      profilePicture
      nationality
      signedUpDate
      signedUpTime
      hasRewarded
      promoCode
      tagCode
      createdBy
      modifiedBy
      customerDeviceToken
      customerDeviceEndpoint
      areaOfInterest
      createdAt
      updatedAt
      hasCompletedProfile
      userPointTransaction {
        items {
          transactionId
          pointValue
          note
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerTagCode {
        items {
          customerTagCodeId
          tagCode
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      customerVouchers {
        voucherId
        rewardId
        status
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        createdAt
        updatedAt
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      wishList {
        items {
          customerWishListId
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderReview {
        items {
          orderReviewId
          overallRating
          foodRating
          waitingTimeRating
          driverServiceRating
          staffServiceRating
          productAvailabilityRating
          comment
          attachment
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerFavouriteAddress {
        items {
          customerFavouriteAddressId
          title
          country
          address
          address2
          city
          state
          postal
          isPrimary
          latitude
          longitude
          notes
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
        }
        nextToken
      }
      customerFavouritePayment {
        items {
          customerFavouritePaymentId
          creditCardNumber
          paymentToken
          paymentType
          creditCardType
          expiryDate
          country
          isPrimary
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
        }
        nextToken
      }
      customerFeedback {
        items {
          feedbackId
          searchLocation
          dateOfVisit
          timeOfVisit
          title
          description
          uploadPhoto
          createdBy
          modifiedBy
          redmineTicketNumber
          platform
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          customerName
          customerMobileNo
          customerEmail
          issueStatus
        }
        nextToken
      }
    }
  }
`
export const deleteCustomerCart = /* GraphQL */ `
  mutation DeleteCustomerCart($input: CustomerCartInput) {
    deleteCustomerCart(input: $input) {
      customerCartId
      quantity
      price
      subtotal
      discountAmount
      productName
      productTitle
      productCategories
      productTaggings
      productCode
      productImage
      mandatoryItem
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteCustomerTagCode = /* GraphQL */ `
  mutation DeleteCustomerTagCode($input: CustomerTagCodeInput) {
    deleteCustomerTagCode(input: $input) {
      customerTagCodeId
      tagCode
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteCustomerFavouriteAddress = /* GraphQL */ `
  mutation DeleteCustomerFavouriteAddress(
    $input: CustomerFavouriteAddressInput
  ) {
    deleteCustomerFavouriteAddress(input: $input) {
      customerFavouriteAddressId
      title
      country
      address
      address2
      city
      state
      postal
      isPrimary
      latitude
      longitude
      notes
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
    }
  }
`
export const deleteCustomerFavouritePayment = /* GraphQL */ `
  mutation DeleteCustomerFavouritePayment(
    $input: CustomerFavouritePaymentInput
  ) {
    deleteCustomerFavouritePayment(input: $input) {
      customerFavouritePaymentId
      creditCardNumber
      paymentToken
      paymentType
      creditCardType
      expiryDate
      country
      isPrimary
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
    }
  }
`
export const deleteAppConfig = /* GraphQL */ `
  mutation DeleteAppConfig($input: AppConfigInput) {
    deleteAppConfig(input: $input) {
      appConfigId
      configKey
      configValue
      createdAt
      updatedAt
    }
  }
`
export const deleteIssue = /* GraphQL */ `
  mutation DeleteIssue($input: IssueInput) {
    deleteIssue(input: $input) {
      issueId
      orderNumber
      title
      hasNewMessage
      totalQuantity
      totalAmount
      totalMessage
      customerAccountNo
      issueDateTime
      issueStatus
      redMineTicketNumber
      platform
      trackerNumber
      parcelAmount
      createdAt
      updatedAt
    }
  }
`
export const deleteIssueDetail = /* GraphQL */ `
  mutation DeleteIssueDetail($input: IssueDetailInput) {
    deleteIssueDetail(input: $input) {
      issueDetailId
      message
      image1
      image2
      image3
      isCustomerMessage
      issueDetailDateTime
      issueDetailIssueId
      productImage
      platform
      createdAt
      updatedAt
    }
  }
`
export const deletePromotionPartner = /* GraphQL */ `
  mutation DeletePromotionPartner($input: PromotionPartnerInput) {
    deletePromotionPartner(input: $input) {
      promotionPartnerId
      image
      name
      defaultPrefix
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deletePromoCodeCampaign = /* GraphQL */ `
  mutation DeletePromoCodeCampaign($input: PromoCodeCampaignInput) {
    deletePromoCodeCampaign(input: $input) {
      promoCodeId
      title
      campaignId
      navPromoId
      promoCode
      promoType
      forPartner
      maxUserUsageLimit
      totalUsageLimit
      totalNumOfCodes
      forMultipleUsage
      orderType
      deliveryFree
      discountTypeDelivery
      discountTypeLevel
      discountDeliveryCap
      discountProductQuantityCap
      discountCap
      discountType
      discountValue
      discountOnProductType
      discountOnProductValue
      deliveryFeeDiscount
      promoStartDatetime
      promoEndDatetime
      promoRepeatDay
      promoRepeatStartTime
      promoRepeatEndTime
      totalCount
      activeCount
      redeemedCount
      deactivatedCount
      athenaQueryId
      promoCodeStatusFile
      usageCondition
      productCategoryCondition {
        type
        ids
      }
      productCategoryDiscount {
        type
        ids
      }
      stores
      freeProducts {
        productId
        quantity
      }
      status
      minimumSpent
      minimumQuantity
      jobStatus
      modifiedBy
      isPromoEnabled
      platform
      statusUpdatedAt
      codeUpdatedAt
      createdAt
      updatedAt
    }
  }
`
export const deletePromoCodeTxn = /* GraphQL */ `
  mutation DeletePromoCodeTxn($input: PromoCodeTxnInput) {
    deletePromoCodeTxn(input: $input) {
      promoCodeTxnId
      redeemedDatetime
      voidDatetime
      customerId
      promoCodeId
      promoCode
      promoCodeLength
      lastModifiedBy
      deactivatedAt
      status
      createdAt
      updatedAt
    }
  }
`
export const deletePromoCodeGeneric = /* GraphQL */ `
  mutation DeletePromoCodeGeneric($input: PromoCodeGenericInput) {
    deletePromoCodeGeneric(input: $input) {
      promoCodeTxnId
      redeemedDatetime
      voidDatetime
      customerId
      promoCodeId
      promoCode
      promoCodeLength
      lastModifiedBy
      deactivatedAt
      status
      createdAt
      updatedAt
    }
  }
`
export const deletePromoCodeCampaignPartner = /* GraphQL */ `
  mutation DeletePromoCodeCampaignPartner(
    $input: PromoCodeCampaignPartnerInput
  ) {
    deletePromoCodeCampaignPartner(input: $input) {
      promoCodeCampaignPartnerId
      promoCodeId
      partnerName
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement($input: AnnouncementInput) {
    deleteAnnouncement(input: $input) {
      announcementId
      title
      startDate
      endDate
      scheduleDatetime
      body
      actionType
      actionId
      bannerImage
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteCustomerTag = /* GraphQL */ `
  mutation DeleteCustomerTag($input: CustomerTagInput) {
    deleteCustomerTag(input: $input) {
      tagId
      title
      tagMode
      startDate
      endDate
      ruleName
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteCustomerTagRule = /* GraphQL */ `
  mutation DeleteCustomerTagRule($input: CustomerTagRuleInput) {
    deleteCustomerTagRule(input: $input) {
      ruleId
      tagId
      ruleType
      ruleField
      ruleOperator
      ruleValue
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deletePriceGroup = /* GraphQL */ `
  mutation DeletePriceGroup($input: PriceGroupInput) {
    deletePriceGroup(input: $input) {
      priceGroupId
      title
      modifiedBy
      createdAt
      updatedAt
      productUOMPriceGroup {
        items {
          productUOMPriceGroupId
          productUOMId
          priceGroupId
          promotionDescription
          pickupPromotionDescription
          discountedPrice
          discountPercentage
          discount
          pickupDiscountedPrice
          pickupPromotionDiscount
          pickupDiscountPercentage
          pickupDiscount
          pickupPrice
          pickupEffectiveStartDate
          pickupEffectiveEndDate
          price
          discountStartDate
          discountEndDate
          effectiveStartDate
          effectiveEndDate
          pickupDiscountStartDate
          pickupDiscountEndDate
          modifiedBy
          createdAt
          updatedAt
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          priceGroup {
            priceGroupId
            title
            modifiedBy
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`
export const deleteProductUOM = /* GraphQL */ `
  mutation DeleteProductUOM($input: ProductUOMInput) {
    deleteProductUOM(input: $input) {
      productUOMId
      productId
      image
      isDisabled
      description
      minFoodPreparationDuration
      pickupMaximumQuantity
      sku
      ecommerceMaximumQuantity
      departmentLevel1
      departmentLevel2
      productGroupDesc
      productGroupCode
      divisionDesc
      itemCategoryDesc
      title
      uom
      minDeliveryDuration
      taggings
      itemPublishStartDate
      itemPublishEndDate
      modifiedBy
      createdAt
      updatedAt
      orderDetail {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productUOMPriceGroup {
        items {
          productUOMPriceGroupId
          productUOMId
          priceGroupId
          promotionDescription
          pickupPromotionDescription
          discountedPrice
          discountPercentage
          discount
          pickupDiscountedPrice
          pickupPromotionDiscount
          pickupDiscountPercentage
          pickupDiscount
          pickupPrice
          pickupEffectiveStartDate
          pickupEffectiveEndDate
          price
          discountStartDate
          discountEndDate
          effectiveStartDate
          effectiveEndDate
          pickupDiscountStartDate
          pickupDiscountEndDate
          modifiedBy
          createdAt
          updatedAt
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          priceGroup {
            priceGroupId
            title
            modifiedBy
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const deleteProductUOMPriceGroup = /* GraphQL */ `
  mutation DeleteProductUOMPriceGroup($input: ProductUOMPriceGroupInput) {
    deleteProductUOMPriceGroup(input: $input) {
      productUOMPriceGroupId
      productUOMId
      priceGroupId
      promotionDescription
      pickupPromotionDescription
      discountedPrice
      discountPercentage
      discount
      pickupDiscountedPrice
      pickupPromotionDiscount
      pickupDiscountPercentage
      pickupDiscount
      pickupPrice
      pickupEffectiveStartDate
      pickupEffectiveEndDate
      price
      discountStartDate
      discountEndDate
      effectiveStartDate
      effectiveEndDate
      pickupDiscountStartDate
      pickupDiscountEndDate
      modifiedBy
      createdAt
      updatedAt
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      priceGroup {
        priceGroupId
        title
        modifiedBy
        createdAt
        updatedAt
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const deletePromoCodeStatusJob = /* GraphQL */ `
  mutation DeletePromoCodeStatusJob($input: PromoCodeStatusJobInput) {
    deletePromoCodeStatusJob(input: $input) {
      promoCodeStatusJobId
      messageId
      athenaQueryId
      promoCodeStatusFile
      promoCodeList {
        promoCodeGenericId
        promoCodeTxnId
        promoCode
        status
        createdAt
        redeemedAt
        deactivatedAt
        redeemedCount
      }
      status
      totalCount
      activeCount
      redeemedCount
      deactivatedCount
      statusUpdatedAt
      codeUpdatedAt
      createdAt
      updatedAt
    }
  }
`
export const updateStoreOperatingHour = /* GraphQL */ `
  mutation UpdateStoreOperatingHour($input: StoreOperatingHourInput) {
    updateStoreOperatingHour(input: $input) {
      storeOperatingHourId
      onlineStoreOpenHour
      onlineStoreCloseHour
      offlineStoreOpenHour
      offlineStoreCloseHour
      isOffline24Hour
      isOnline24Hour
      onlineIsOpen
      offlineIsOpen
      day
      modifiedBy
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateTrendingText = /* GraphQL */ `
  mutation UpdateTrendingText($input: TrendingTextInput) {
    updateTrendingText(input: $input) {
      trendingTextId
      title
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateStore = /* GraphQL */ `
  mutation UpdateStore($input: StoreInput) {
    updateStore(input: $input) {
      storeId
      driveThru
      visibleToEComm
      storeName
      address
      latitude
      longitude
      openHour
      closeHour
      deliveryDiscountType
      deliveryServiceAvailable
      pickupServiceAvailable
      minFoodPreparationDuration
      minDeliveryDuration
      deliveryFee
      isDisabled
      isOnline24Hour
      isOffline24Hour
      postalCode
      city
      state
      storeCode
      lastOrderTime
      minPurchaseAmount
      freeDeliveryWithMinPurchase
      acceptOrderWithMinPurchase
      maxOrderQty
      eCommStartDate
      eCommEndDate
      hasSeatingArea
      createdBy
      modifiedBy
      storeManagerName
      storeManagerContact
      priceGroupId
      storeGroup
      createdAt
      updatedAt
      minDistanceCharges
      minDistance
      pollingDeliveryTime
      riderTimeout
      deliveryGracePeriod
      routingOption
      priorityDeliveryPartner
      incrementDistanceCharges
      incrementDistanceUnit
      deliveryDiscountAmount
      deliveryDiscountBasketValue
      deliveryPartnerMaxRetry
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      operatingHour {
        items {
          storeOperatingHourId
          onlineStoreOpenHour
          onlineStoreCloseHour
          offlineStoreOpenHour
          offlineStoreCloseHour
          isOffline24Hour
          isOnline24Hour
          onlineIsOpen
          offlineIsOpen
          day
          modifiedBy
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      storeBlockedOutPeriods {
        items {
          storeBlockedOutPeriodId
          startDateTime
          endDateTime
          storeType
          createdBy
          modifiedBy
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      StoreDeliveryPartnerMapping {
        items {
          partnerId
          storeDeliveryPartnerStoreId
          partnerName
          sequence
          isPriority
          createdBy
          modifiedBy
          createdAt
          updatedAt
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const updateLandingMenu = /* GraphQL */ `
  mutation UpdateLandingMenu($input: LandingMenuInput) {
    updateLandingMenu(input: $input) {
      landingMenuId
      actionType
      actionId
      title
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      modifiedBy
      updatedAt
      createdAt
      shortLinkUrl
    }
  }
`
export const updateStoreBlockedOutPeriod = /* GraphQL */ `
  mutation UpdateStoreBlockedOutPeriod($input: StoreBlockedOutPeriodInput) {
    updateStoreBlockedOutPeriod(input: $input) {
      storeBlockedOutPeriodId
      startDateTime
      endDateTime
      storeType
      createdBy
      modifiedBy
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: ProductInput) {
    updateProduct(input: $input) {
      productId
      sku
      name
      title
      image
      price
      discount
      discountPercentage
      discountedPrice
      promotionDescription
      discountStartDate
      discountEndDate
      pickupPrice
      pickupDiscount
      pickupDiscountPercentage
      pickupDiscountedPrice
      pickupPromotionDescription
      pickupDiscountStartDate
      pickupDiscountEndDate
      category
      categories
      departments
      taggings
      minFoodPreparationDuration
      minDeliveryDuration
      createdBy
      modifiedBy
      isDisabled
      description
      uom
      dimension
      temperature
      itemPublishStartDate
      itemPublishEndDate
      ecommerceMaximumQuantity
      pickupMaximumQuantity
      createdAt
      updatedAt
      divisionCode
      divisionDesc
      itemCategoryCode
      itemCategoryDesc
      productGroupCode
      productGroupDesc
      departmentLevel1
      departmentLevel2
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productCategory {
        items {
          productCategoryId
          category {
            categoryId
            title
            image
            code
            createdBy
            modifiedBy
            isDisabled
            parentId
            createdAt
            updatedAt
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderDetail {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productImage {
        items {
          productImageId
          image
          sequence
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerWishList {
        items {
          customerWishListId
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const updateProductStoreDepartment = /* GraphQL */ `
  mutation UpdateProductStoreDepartment($input: ProductStoreDepartmentInput) {
    updateProductStoreDepartment(input: $input) {
      productStoreDepartmentId
      productStoreDepartmentProductUOMId
      productStoreDepartmentStoreId
      productStoreDepartmentSubDepartmentId
      productStoreDepartmentProductUOMPriceGroupId
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      department {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      subDepartment {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      isOutOfStock
      createdAt
      updatedAt
    }
  }
`
export const updateProductStoreTagging = /* GraphQL */ `
  mutation UpdateProductStoreTagging($input: ProductStoreTaggingInput) {
    updateProductStoreTagging(input: $input) {
      productStoreTaggingId
      productStoreTaggingProductUOMId
      productStoreTaggingProductTaggingId
      productStoreTaggingStoreId
      productStoreTaggingProductUOMPriceGroupId
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      isOutOfStock
      createdAt
      updatedAt
    }
  }
`
export const updateProductImage = /* GraphQL */ `
  mutation UpdateProductImage($input: ProductImageInput) {
    updateProductImage(input: $input) {
      productImageId
      image
      sequence
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateProductStoreTaggingMapping = /* GraphQL */ `
  mutation UpdateProductStoreTaggingMapping($productStoreTaggingMapping: ID!) {
    updateProductStoreTaggingMapping(
      productStoreTaggingMapping: $productStoreTaggingMapping
    ) {
      productStoreTaggingMapping
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateProductStore = /* GraphQL */ `
  mutation UpdateProductStore($input: ProductStoreInput) {
    updateProductStore(input: $input) {
      productStoreId
      productStoreProductId
      isOutOfStock
      modifiedBy
      productStoreProductUOMId
      productStoreStoreId
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateKDSDeviceToken = /* GraphQL */ `
  mutation UpdateKDSDeviceToken($input: KDSDeviceTokenInput) {
    updateKDSDeviceToken(input: $input) {
      id
      deviceEndpoint
      deviceToken
      storeCode
      createdAt
      updatedAt
    }
  }
`
export const updateJwtToken = /* GraphQL */ `
  mutation UpdateJwtToken($input: JWTTokenInput) {
    updateJwtToken(input: $input) {
      sessionID
      signedToken
      token
      expiryDate
      accountNo
      createdAt
      updatedAt
    }
  }
`
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback($input: FeedbackInput) {
    updateFeedback(input: $input) {
      feedbackId
      searchLocation
      dateOfVisit
      timeOfVisit
      title
      description
      uploadPhoto
      createdBy
      modifiedBy
      redmineTicketNumber
      platform
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      customerName
      customerMobileNo
      customerEmail
      issueStatus
    }
  }
`
export const updateProductCategory = /* GraphQL */ `
  mutation UpdateProductCategory($input: ProductCategoryInput) {
    updateProductCategory(input: $input) {
      productCategoryId
      category {
        categoryId
        title
        image
        code
        createdBy
        modifiedBy
        isDisabled
        parentId
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateProductDepartment = /* GraphQL */ `
  mutation UpdateProductDepartment($input: ProductDepartmentInput) {
    updateProductDepartment(input: $input) {
      productDepartmentId
      productDepartmentProductUOMId
      department {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateProductTaggingMapping = /* GraphQL */ `
  mutation UpdateProductTaggingMapping($input: ProductTaggingMappingInput) {
    updateProductTaggingMapping(input: $input) {
      productTaggingMappingId
      productTaggingMappingProductId
      productTaggingMappingProductTaggingId
      productTaggingMappingProductUOMId
      modifiedBy
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($input: CategoryInput) {
    updateCategory(input: $input) {
      categoryId
      title
      image
      code
      createdBy
      modifiedBy
      isDisabled
      parentId
      productCategory {
        items {
          productCategoryId
          category {
            categoryId
            title
            image
            code
            createdBy
            modifiedBy
            isDisabled
            parentId
            createdAt
            updatedAt
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment($input: DepartmentInput) {
    updateDepartment(input: $input) {
      departmentId
      title
      image
      createdBy
      modifiedBy
      updatedAt
      createdAt
      isDisabled
      effectiveStartDate
      effectiveEndDate
      parentId
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreSubDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const updateProductTagging = /* GraphQL */ `
  mutation UpdateProductTagging($input: ProductTaggingInput) {
    updateProductTagging(input: $input) {
      productTaggingId
      title
      image
      code
      effectiveStartDate
      effectiveEndDate
      isDisabled
      createdBy
      modifiedBy
      updatedAt
      createdAt
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const updateUserPointTransaction = /* GraphQL */ `
  mutation UpdateUserPointTransaction($input: UserPointTransactionInput) {
    updateUserPointTransaction(input: $input) {
      transactionId
      pointValue
      note
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateVoucherRewardSKU = /* GraphQL */ `
  mutation UpdateVoucherRewardSKU($input: VoucherRewardSKUInput) {
    updateVoucherRewardSKU(input: $input) {
      voucherRewardSKUId
      rewardId
      entityId
      entityType
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const updateVoucherReward = /* GraphQL */ `
  mutation UpdateVoucherReward($input: VoucherRewardInput) {
    updateVoucherReward(input: $input) {
      rewardId
      image
      startDate
      endDate
      point
      description
      title
      tnc
      cashValueAmount
      skuCashDiscount
      voucherType
      voucherStore {
        items {
          voucherRewardStoreId
          rewardId
          latitude
          longitude
          storeId
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      voucherSKU {
        items {
          voucherRewardSKUId
          rewardId
          entityId
          entityType
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const updateVoucherRewardStore = /* GraphQL */ `
  mutation UpdateVoucherRewardStore($input: VoucherRewardStoreInput) {
    updateVoucherRewardStore(input: $input) {
      voucherRewardStoreId
      rewardId
      latitude
      longitude
      storeId
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const updateCustomerVoucher = /* GraphQL */ `
  mutation UpdateCustomerVoucher($input: CustomerVoucherInput) {
    updateCustomerVoucher(input: $input) {
      voucherId
      rewardId
      status
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder($input: OrderInput) {
    updateOrder(input: $input) {
      orderId
      orderNumber
      voucherNumber
      voucherTitle
      voucherEntryNumber
      orderDate
      paymentDateTime
      productImage
      grandTotal
      discountAmount
      deliveryFee
      voucherDiscount
      totalOrderItems
      status
      noteToRider
      floorOrUnit
      customerAccountNo
      customerFirstName
      customerLastName
      customerPhoneNumber
      storeName
      storeCode
      orderType
      deliveryAddress
      deliveryAddressLatitude
      deliveryAddressLongitude
      remarks
      routingOption
      reasonCode
      cancelReason
      cancelBy
      orderCancellationDateTime
      collectedDateTime
      createdAt
      updatedAt
      isAdvancedOrder
      scheduledDateTime
      driverName
      driverPhone
      driverPlateNumber
      lalamoveOrderId
      lalamoveOrderDate
      lalamoveTotalFee
      deliveryStatus
      intOrderNumber
      isRefunded
      refundAmount
      refundReason
      txnId
      refundCompleteDateTime
      orderCompleteDateTime
      cancelOrderDateTime
      requiredCutlery
      deliveryOrderDateTime
      deliveryOrderId
      deliveryPartner
      deliveryPartnerFee
      eGHLCardType
      eGHLIssuingBank
      eGHLPymtMethod
      riderJobNumber
      deliveryNumber
      totalOutOfStock
      partialFulfilmentAmount
      partialFulfilmentDiscount
      accumulatedRefundAmount
      promoDiscount
      promoCodeTitle
      promoCode
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      totalVoucherDiscount
      voucherRefunded
      promoCodeCampaignId
      netDiscountAmount
      oriTotalVoucherDiscount
      deliveryDiscount
      netDeliveryDiscount
      deliverySurchargeAmount
      orderDetails {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      orderReview {
        items {
          orderReviewId
          overallRating
          foodRating
          waitingTimeRating
          driverServiceRating
          staffServiceRating
          productAvailabilityRating
          comment
          attachment
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderPayment {
        items {
          orderPaymentId
          amount
          status
          paymentNumber
          paymentOrderNumber
          transactionType
          paymentMethod
          paymentResponseBase64
          eGHLTransactionType
          eGHLPymtMethod
          eGHLServiceID
          eGHLPaymentID
          eGHLOrderNumber
          eGHLAmount
          eGHLCurrencyCode
          eGHLHashValue
          eGHLHashValue2
          eGHLTxnID
          eGHLIssuingBank
          eGHLTxnStatus
          eGHLAuthCode
          eGHLBankRefNo
          eGHLRespTime
          eGHLTxnMessage
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      refundTransaction {
        items {
          refundTransactionId
          refundAmount
          automatedRefundStatus
          emailRefundStatus
          manualRefundStatus
          manualCSRefundStatus
          eGHLRefundStatus
          customerServiceStatus
          orderId
          refundTxnId
          orderNumber
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          paymentMethod
          paymentChannel
          refundReason
          paymentId
          completedBy
          refundDateTime
          redmineTicketNumber
          lastOrderTransitionDate
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const updateOrderDetail = /* GraphQL */ `
  mutation UpdateOrderDetail($input: OrderDetailInput) {
    updateOrderDetail(input: $input) {
      orderDetailId
      orderDetailOrderId
      orderDetailPriceGroupId
      orderDetailProductId
      orderDetailProductUOMId
      orderDetailProductUOMPriceGroupId
      quantity
      subtotal
      discount
      promoDiscount
      mandatoryItem
      isFreeItem
      outOfStock
      itemStatus
      productName
      productDescription
      productImage
      createdAt
      updatedAt
      totalPromoCodeDiscount
      totalDiscountAmount
      totalVoucherDiscount
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      orderPayment {
        items {
          orderPaymentId
          amount
          status
          paymentNumber
          paymentOrderNumber
          transactionType
          paymentMethod
          paymentResponseBase64
          eGHLTransactionType
          eGHLPymtMethod
          eGHLServiceID
          eGHLPaymentID
          eGHLOrderNumber
          eGHLAmount
          eGHLCurrencyCode
          eGHLHashValue
          eGHLHashValue2
          eGHLTxnID
          eGHLIssuingBank
          eGHLTxnStatus
          eGHLAuthCode
          eGHLBankRefNo
          eGHLRespTime
          eGHLTxnMessage
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const updateOrderPayment = /* GraphQL */ `
  mutation UpdateOrderPayment($input: OrderPaymentInput) {
    updateOrderPayment(input: $input) {
      orderPaymentId
      amount
      status
      paymentNumber
      paymentOrderNumber
      transactionType
      paymentMethod
      paymentResponseBase64
      eGHLTransactionType
      eGHLPymtMethod
      eGHLServiceID
      eGHLPaymentID
      eGHLOrderNumber
      eGHLAmount
      eGHLCurrencyCode
      eGHLHashValue
      eGHLHashValue2
      eGHLTxnID
      eGHLIssuingBank
      eGHLTxnStatus
      eGHLAuthCode
      eGHLBankRefNo
      eGHLRespTime
      eGHLTxnMessage
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateRefundTransaction = /* GraphQL */ `
  mutation UpdateRefundTransaction($input: RefundTransactionInput) {
    updateRefundTransaction(input: $input) {
      refundTransactionId
      refundAmount
      automatedRefundStatus
      emailRefundStatus
      manualRefundStatus
      manualCSRefundStatus
      eGHLRefundStatus
      customerServiceStatus
      orderId
      refundTxnId
      orderNumber
      customerAccountNo
      customerFirstName
      customerLastName
      customerPhoneNumber
      paymentMethod
      paymentChannel
      refundReason
      paymentId
      completedBy
      refundDateTime
      redmineTicketNumber
      lastOrderTransitionDate
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateRefundProcess = /* GraphQL */ `
  mutation UpdateRefundProcess($input: RefundProcessInput) {
    updateRefundProcess(input: $input) {
      refundProcessId
      paymentMethod
      paymentChannel
      isAutomatedRefund
      isEmailRefund
      isManualRefund
      isReverseRefundAvailable
      createdAt
      updatedAt
    }
  }
`
export const updateCustomerWishList = /* GraphQL */ `
  mutation UpdateCustomerWishList($input: CustomerWishListInput) {
    updateCustomerWishList(input: $input) {
      customerWishListId
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateOrderReview = /* GraphQL */ `
  mutation UpdateOrderReview($input: OrderReviewInput) {
    updateOrderReview(input: $input) {
      orderReviewId
      overallRating
      foodRating
      waitingTimeRating
      driverServiceRating
      staffServiceRating
      productAvailabilityRating
      comment
      attachment
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateUserMatrix = /* GraphQL */ `
  mutation UpdateUserMatrix($input: UserMatrixInput) {
    updateUserMatrix(input: $input) {
      userMatrixId
      userGroup
      canEdit
      canList
      canView
      canDelete
      canAdd
      module
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateAppLaunchPromo = /* GraphQL */ `
  mutation UpdateAppLaunchPromo($input: AppLaunchPromoInput) {
    updateAppLaunchPromo(input: $input) {
      appLaunchPromoId
      title
      image
      startDate
      endDate
      promoType
      promoEntityId
      isDisabled
      createdBy
      modifiedBy
      sequenceOrder
      buttonLabel
      createdAt
      updatedAt
    }
  }
`
export const updateLandingPageBanner = /* GraphQL */ `
  mutation UpdateLandingPageBanner($input: LandingPageBannerInput) {
    updateLandingPageBanner(input: $input) {
      landingPageBannerId
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      title
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
      promoEntityId
      promoType
      buttonLabel
      promoTitle
      platform
      shortLinkUrl
    }
  }
`
export const updateHomePageBanner = /* GraphQL */ `
  mutation UpdateHomePageBanner($input: HomePageBannerInput) {
    updateHomePageBanner(input: $input) {
      homePageBannerId
      bannerId
      bannerNumber
      image
      startDate
      endDate
      isDisabled
      sequenceOrder
      listBanners {
        id
        image
        title
        description
        sequence
      }
      title
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      pageLayout
      description
      validationPeriodId
      shortLinkUrl
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateStoreGroup = /* GraphQL */ `
  mutation UpdateStoreGroup($input: StoreGroupInput) {
    updateStoreGroup(input: $input) {
      storeGroupId
      code
      description
      stores
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateLandingPageProduct = /* GraphQL */ `
  mutation UpdateLandingPageProduct($input: LandingPageProductInput) {
    updateLandingPageProduct(input: $input) {
      landingPageProductId
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      title
      productType
      productTypeId
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateDeliveryPartner = /* GraphQL */ `
  mutation UpdateDeliveryPartner($input: DeliveryPartnerInput) {
    updateDeliveryPartner(input: $input) {
      deliveryPartnerId
      deliveryPartnerName
      apiEndpoint
      isCancelAfterAcceptAllowed
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateStoreDeliveryPartner = /* GraphQL */ `
  mutation UpdateStoreDeliveryPartner($input: StoreDeliveryPartnerInput) {
    updateStoreDeliveryPartner(input: $input) {
      partnerId
      storeDeliveryPartnerStoreId
      partnerName
      sequence
      isPriority
      createdBy
      modifiedBy
      createdAt
      updatedAt
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const updateOrderDeliveryOrder = /* GraphQL */ `
  mutation UpdateOrderDeliveryOrder($input: OrderDeliveryOrderInput) {
    updateOrderDeliveryOrder(input: $input) {
      orderDeliveryOrderId
      deliveryPartner
      deliveryNumber
      orderNumber
      status
      totalFee
      createdBy
      modifiedBy
      createdAt
      updatedAt
      deliveryOrderDateTime
    }
  }
`
export const updatePushNotification = /* GraphQL */ `
  mutation UpdatePushNotification($input: PushNotificationInput) {
    updatePushNotification(input: $input) {
      pushNotificationId
      scheduleDateTime
      pushedDateTime
      messageSent
      newUserEndDate
      newUserStartDate
      body
      title
      bannerImage
      thumbnailImage
      createdBy
      modifiedBy
      createdAt
      updatedAt
      status
      actionType
      pageLayout
      actionId
      targetAudience {
        method
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      readNotification
      senderMobileNumber
      senderName
      voucherExpiryDate
      voucherStatus
      voucherNumber
      receiverName
      voucherTitle
      inviteLink
    }
  }
`
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: NotificationInput) {
    updateNotification(input: $input) {
      notificationId
      customerId
      title
      thumbnailImage
      summary
      bannerImage
      description
      link
      pageLayout
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: CustomerInput) {
    updateCustomer(input: $input) {
      customerId
      accountNo
      clubCode
      firstName
      lastName
      primaryEmail
      secondaryEmail
      country
      address
      address2
      identityCard
      custClubCode
      custSchemeCode
      joinDate
      joinTime
      staffId
      staffJoinDate
      isBlocked
      actionDate
      actionReasonCode
      actionBy
      consentPersonalData
      city
      state
      postal
      mobileNumber
      password
      gender
      dateOfBirth
      maritalStatus
      race
      employmentStatus
      personalIncomeLevel
      activated
      facebookID
      isFacebook
      qrCode
      profilePicture
      nationality
      signedUpDate
      signedUpTime
      hasRewarded
      promoCode
      tagCode
      createdBy
      modifiedBy
      customerDeviceToken
      customerDeviceEndpoint
      areaOfInterest
      createdAt
      updatedAt
      hasCompletedProfile
      userPointTransaction {
        items {
          transactionId
          pointValue
          note
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerTagCode {
        items {
          customerTagCodeId
          tagCode
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      customerVouchers {
        voucherId
        rewardId
        status
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        createdAt
        updatedAt
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      wishList {
        items {
          customerWishListId
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderReview {
        items {
          orderReviewId
          overallRating
          foodRating
          waitingTimeRating
          driverServiceRating
          staffServiceRating
          productAvailabilityRating
          comment
          attachment
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerFavouriteAddress {
        items {
          customerFavouriteAddressId
          title
          country
          address
          address2
          city
          state
          postal
          isPrimary
          latitude
          longitude
          notes
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
        }
        nextToken
      }
      customerFavouritePayment {
        items {
          customerFavouritePaymentId
          creditCardNumber
          paymentToken
          paymentType
          creditCardType
          expiryDate
          country
          isPrimary
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
        }
        nextToken
      }
      customerFeedback {
        items {
          feedbackId
          searchLocation
          dateOfVisit
          timeOfVisit
          title
          description
          uploadPhoto
          createdBy
          modifiedBy
          redmineTicketNumber
          platform
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          customerName
          customerMobileNo
          customerEmail
          issueStatus
        }
        nextToken
      }
    }
  }
`
export const updateCustomerCart = /* GraphQL */ `
  mutation UpdateCustomerCart($input: CustomerCartInput) {
    updateCustomerCart(input: $input) {
      customerCartId
      quantity
      price
      subtotal
      discountAmount
      productName
      productTitle
      productCategories
      productTaggings
      productCode
      productImage
      mandatoryItem
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateCustomerTagCode = /* GraphQL */ `
  mutation UpdateCustomerTagCode($input: CustomerTagCodeInput) {
    updateCustomerTagCode(input: $input) {
      customerTagCodeId
      tagCode
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateCustomerFavouriteAddress = /* GraphQL */ `
  mutation UpdateCustomerFavouriteAddress(
    $input: CustomerFavouriteAddressInput
  ) {
    updateCustomerFavouriteAddress(input: $input) {
      customerFavouriteAddressId
      title
      country
      address
      address2
      city
      state
      postal
      isPrimary
      latitude
      longitude
      notes
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
    }
  }
`
export const updateCustomerFavouritePayment = /* GraphQL */ `
  mutation UpdateCustomerFavouritePayment(
    $input: CustomerFavouritePaymentInput
  ) {
    updateCustomerFavouritePayment(input: $input) {
      customerFavouritePaymentId
      creditCardNumber
      paymentToken
      paymentType
      creditCardType
      expiryDate
      country
      isPrimary
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
    }
  }
`
export const updateAppConfig = /* GraphQL */ `
  mutation UpdateAppConfig($input: AppConfigInput) {
    updateAppConfig(input: $input) {
      appConfigId
      configKey
      configValue
      createdAt
      updatedAt
    }
  }
`
export const updateIssue = /* GraphQL */ `
  mutation UpdateIssue($input: IssueInput) {
    updateIssue(input: $input) {
      issueId
      orderNumber
      title
      hasNewMessage
      totalQuantity
      totalAmount
      totalMessage
      customerAccountNo
      issueDateTime
      issueStatus
      redMineTicketNumber
      platform
      trackerNumber
      parcelAmount
      createdAt
      updatedAt
    }
  }
`
export const updateIssueDetail = /* GraphQL */ `
  mutation UpdateIssueDetail($input: IssueDetailInput) {
    updateIssueDetail(input: $input) {
      issueDetailId
      message
      image1
      image2
      image3
      isCustomerMessage
      issueDetailDateTime
      issueDetailIssueId
      productImage
      platform
      createdAt
      updatedAt
    }
  }
`
export const updatePromotionPartner = /* GraphQL */ `
  mutation UpdatePromotionPartner($input: PromotionPartnerInput) {
    updatePromotionPartner(input: $input) {
      promotionPartnerId
      image
      name
      defaultPrefix
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updatePromoCodeCampaign = /* GraphQL */ `
  mutation UpdatePromoCodeCampaign($input: PromoCodeCampaignInput) {
    updatePromoCodeCampaign(input: $input) {
      promoCodeId
      title
      campaignId
      navPromoId
      promoCode
      promoType
      forPartner
      maxUserUsageLimit
      totalUsageLimit
      totalNumOfCodes
      forMultipleUsage
      orderType
      deliveryFree
      discountTypeDelivery
      discountTypeLevel
      discountDeliveryCap
      discountProductQuantityCap
      discountCap
      discountType
      discountValue
      discountOnProductType
      discountOnProductValue
      deliveryFeeDiscount
      promoStartDatetime
      promoEndDatetime
      promoRepeatDay
      promoRepeatStartTime
      promoRepeatEndTime
      totalCount
      activeCount
      redeemedCount
      deactivatedCount
      athenaQueryId
      promoCodeStatusFile
      usageCondition
      productCategoryCondition {
        type
        ids
      }
      productCategoryDiscount {
        type
        ids
      }
      stores
      freeProducts {
        productId
        quantity
      }
      status
      minimumSpent
      minimumQuantity
      jobStatus
      modifiedBy
      isPromoEnabled
      platform
      statusUpdatedAt
      codeUpdatedAt
      createdAt
      updatedAt
    }
  }
`
export const updatePromoCodeTxn = /* GraphQL */ `
  mutation UpdatePromoCodeTxn($input: PromoCodeTxnInput) {
    updatePromoCodeTxn(input: $input) {
      promoCodeTxnId
      redeemedDatetime
      voidDatetime
      customerId
      promoCodeId
      promoCode
      promoCodeLength
      lastModifiedBy
      deactivatedAt
      status
      createdAt
      updatedAt
    }
  }
`
export const updatePromoCodeGeneric = /* GraphQL */ `
  mutation UpdatePromoCodeGeneric($input: PromoCodeGenericInput) {
    updatePromoCodeGeneric(input: $input) {
      promoCodeTxnId
      redeemedDatetime
      voidDatetime
      customerId
      promoCodeId
      promoCode
      promoCodeLength
      lastModifiedBy
      deactivatedAt
      status
      createdAt
      updatedAt
    }
  }
`
export const updatePromoCodeCampaignPartner = /* GraphQL */ `
  mutation UpdatePromoCodeCampaignPartner(
    $input: PromoCodeCampaignPartnerInput
  ) {
    updatePromoCodeCampaignPartner(input: $input) {
      promoCodeCampaignPartnerId
      promoCodeId
      partnerName
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement($input: AnnouncementInput) {
    updateAnnouncement(input: $input) {
      announcementId
      title
      startDate
      endDate
      scheduleDatetime
      body
      actionType
      actionId
      bannerImage
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateCustomerTag = /* GraphQL */ `
  mutation UpdateCustomerTag($input: CustomerTagInput) {
    updateCustomerTag(input: $input) {
      tagId
      title
      tagMode
      startDate
      endDate
      ruleName
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateCustomerTagRule = /* GraphQL */ `
  mutation UpdateCustomerTagRule($input: CustomerTagRuleInput) {
    updateCustomerTagRule(input: $input) {
      ruleId
      tagId
      ruleType
      ruleField
      ruleOperator
      ruleValue
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updatePriceGroup = /* GraphQL */ `
  mutation UpdatePriceGroup($input: PriceGroupInput) {
    updatePriceGroup(input: $input) {
      priceGroupId
      title
      modifiedBy
      createdAt
      updatedAt
      productUOMPriceGroup {
        items {
          productUOMPriceGroupId
          productUOMId
          priceGroupId
          promotionDescription
          pickupPromotionDescription
          discountedPrice
          discountPercentage
          discount
          pickupDiscountedPrice
          pickupPromotionDiscount
          pickupDiscountPercentage
          pickupDiscount
          pickupPrice
          pickupEffectiveStartDate
          pickupEffectiveEndDate
          price
          discountStartDate
          discountEndDate
          effectiveStartDate
          effectiveEndDate
          pickupDiscountStartDate
          pickupDiscountEndDate
          modifiedBy
          createdAt
          updatedAt
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          priceGroup {
            priceGroupId
            title
            modifiedBy
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`
export const updateProductUOM = /* GraphQL */ `
  mutation UpdateProductUOM($input: ProductUOMInput) {
    updateProductUOM(input: $input) {
      productUOMId
      productId
      image
      isDisabled
      description
      minFoodPreparationDuration
      pickupMaximumQuantity
      sku
      ecommerceMaximumQuantity
      departmentLevel1
      departmentLevel2
      productGroupDesc
      productGroupCode
      divisionDesc
      itemCategoryDesc
      title
      uom
      minDeliveryDuration
      taggings
      itemPublishStartDate
      itemPublishEndDate
      modifiedBy
      createdAt
      updatedAt
      orderDetail {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productUOMPriceGroup {
        items {
          productUOMPriceGroupId
          productUOMId
          priceGroupId
          promotionDescription
          pickupPromotionDescription
          discountedPrice
          discountPercentage
          discount
          pickupDiscountedPrice
          pickupPromotionDiscount
          pickupDiscountPercentage
          pickupDiscount
          pickupPrice
          pickupEffectiveStartDate
          pickupEffectiveEndDate
          price
          discountStartDate
          discountEndDate
          effectiveStartDate
          effectiveEndDate
          pickupDiscountStartDate
          pickupDiscountEndDate
          modifiedBy
          createdAt
          updatedAt
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          priceGroup {
            priceGroupId
            title
            modifiedBy
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const updateProductUOMPriceGroup = /* GraphQL */ `
  mutation UpdateProductUOMPriceGroup($input: ProductUOMPriceGroupInput) {
    updateProductUOMPriceGroup(input: $input) {
      productUOMPriceGroupId
      productUOMId
      priceGroupId
      promotionDescription
      pickupPromotionDescription
      discountedPrice
      discountPercentage
      discount
      pickupDiscountedPrice
      pickupPromotionDiscount
      pickupDiscountPercentage
      pickupDiscount
      pickupPrice
      pickupEffectiveStartDate
      pickupEffectiveEndDate
      price
      discountStartDate
      discountEndDate
      effectiveStartDate
      effectiveEndDate
      pickupDiscountStartDate
      pickupDiscountEndDate
      modifiedBy
      createdAt
      updatedAt
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      priceGroup {
        priceGroupId
        title
        modifiedBy
        createdAt
        updatedAt
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const updatePromoCodeStatusJob = /* GraphQL */ `
  mutation UpdatePromoCodeStatusJob($input: PromoCodeStatusJobInput) {
    updatePromoCodeStatusJob(input: $input) {
      promoCodeStatusJobId
      messageId
      athenaQueryId
      promoCodeStatusFile
      promoCodeList {
        promoCodeGenericId
        promoCodeTxnId
        promoCode
        status
        createdAt
        redeemedAt
        deactivatedAt
        redeemedCount
      }
      status
      totalCount
      activeCount
      redeemedCount
      deactivatedCount
      statusUpdatedAt
      codeUpdatedAt
      createdAt
      updatedAt
    }
  }
`
export const updateUserSurveyResponse = /* GraphQL */ `
  mutation UpdateUserSurveyResponse(
    $token: String
    $surveyId: String
    $answer: [UserSurveyAnswer]
  ) {
    updateUserSurveyResponse(
      token: $token
      surveyId: $surveyId
      answer: $answer
    ) {
      statusCode
      status
      message
    }
  }
`
export const submitPostTransactionSurvey = /* GraphQL */ `
  mutation SubmitPostTransactionSurvey(
    $token: String
    $answer: [String]
    $orderDate: String
    $storeName: String
    $txnId: String
  ) {
    submitPostTransactionSurvey(
      token: $token
      answer: $answer
      orderDate: $orderDate
      storeName: $storeName
      txnId: $txnId
    ) {
      statusCode
      status
      message
    }
  }
`
export const submitStaffFeedbackForm = /* GraphQL */ `
  mutation SubmitStaffFeedbackForm(
    $token: String
    $title: String
    $description: String
    $image: [String]
    $storeName: String
    $position: String
  ) {
    submitStaffFeedbackForm(
      token: $token
      title: $title
      description: $description
      image: $image
      storeName: $storeName
      position: $position
    ) {
      statusCode
      status
      message
    }
  }
`
export const requestMemberAccountModification = /* GraphQL */ `
  mutation RequestMemberAccountModification(
    $token: String
    $dataSubjectPart1: DataSubjectPart1ForHelpCenterForm
    $dataSubjectPart2: String
    $dataSubjectPart3: String
    $consentValue1: Boolean
    $consentValue2: Boolean
    $platform: String
    $type: String
  ) {
    requestMemberAccountModification(
      token: $token
      dataSubjectPart1: $dataSubjectPart1
      dataSubjectPart2: $dataSubjectPart2
      dataSubjectPart3: $dataSubjectPart3
      consentValue1: $consentValue1
      consentValue2: $consentValue2
      platform: $platform
      type: $type
    ) {
      statusCode
      status
      message
    }
  }
`
export const updatePointAdjustment = /* GraphQL */ `
  mutation UpdatePointAdjustment(
    $customerId: String
    $point: Float
    $description: String
    $operation: String
    $orderNumber: String
    $storeCode: String
    $postTerminal: String
  ) {
    updatePointAdjustment(
      customerId: $customerId
      point: $point
      description: $description
      operation: $operation
      orderNumber: $orderNumber
      storeCode: $storeCode
      postTerminal: $postTerminal
    ) {
      customerId
      messageOutput
    }
  }
`
export const updateCustomerStatus = /* GraphQL */ `
  mutation UpdateCustomerStatus(
    $customerId: String
    $operation: String
    $reasonCode: String
    $reasonDesc: String
  ) {
    updateCustomerStatus(
      customerId: $customerId
      operation: $operation
      reasonCode: $reasonCode
      reasonDesc: $reasonDesc
    ) {
      customerId
      messageOutput
      user
    }
  }
`
export const customerBlockAudit = /* GraphQL */ `
  mutation CustomerBlockAudit($customerId: String) {
    customerBlockAudit(customerId: $customerId) {
      items {
        customerBlockAuditId
        customerId
        action
        reasonCode
        reasonDesc
        createdAt
        createdBy
      }
    }
  }
`
export const updateAndGetSequenceNumber = /* GraphQL */ `
  mutation UpdateAndGetSequenceNumber($sequenceKey: String) {
    updateAndGetSequenceNumber(sequenceKey: $sequenceKey)
  }
`
export const resetUserMFA = /* GraphQL */ `
  mutation ResetUserMFA($email: String, $platform: String) {
    resetUserMFA(email: $email, platform: $platform) {
      statusCode
      status
      message
    }
  }
`
export const enableUserAccount = /* GraphQL */ `
  mutation EnableUserAccount($email: String, $platform: String) {
    enableUserAccount(email: $email, platform: $platform) {
      statusCode
      status
      message
    }
  }
`
export const invalidateRefreshToken = /* GraphQL */ `
  mutation InvalidateRefreshToken($username: String) {
    invalidateRefreshToken(username: $username)
  }
`
export const extendTimeout = /* GraphQL */ `
  mutation ExtendTimeout($username: String) {
    extendTimeout(username: $username)
  }
`
export const updateUserIpAndDeviceKey = /* GraphQL */ `
  mutation UpdateUserIpAndDeviceKey($username: String, $accessToken: String) {
    updateUserIpAndDeviceKey(username: $username, accessToken: $accessToken)
  }
`
export const trackGoogleAutoComplete = /* GraphQL */ `
  mutation TrackGoogleAutoComplete(
    $token: String
    $accountNo: String
    $apiType: String
    $placeId: String
    $sessionToken: String
    $keyword: String
  ) {
    trackGoogleAutoComplete(
      token: $token
      accountNo: $accountNo
      apiType: $apiType
      placeId: $placeId
      sessionToken: $sessionToken
      keyword: $keyword
    ) {
      statusCode
      message
    }
  }
`
export const disableUserAccount = /* GraphQL */ `
  mutation DisableUserAccount($username: String) {
    disableUserAccount(username: $username)
  }
`
export const disableCustomer = /* GraphQL */ `
  mutation DisableCustomer($customerId: String) {
    disableCustomer(customerId: $customerId) {
      statusCode
      status
      message
    }
  }
`
export const generateShortLinkURL = /* GraphQL */ `
  mutation GenerateShortLinkURL($domain: String, $type: String, $id: String) {
    generateShortLinkURL(domain: $domain, type: $type, id: $id) {
      statusCode
      body {
        message
        shortLink
      }
    }
  }
`
export const generatePromoCode = /* GraphQL */ `
  mutation GeneratePromoCode(
    $generateDetail: [GeneratePromoCodeDetail]
    $promoCodeId: String
    $promoType: String
  ) {
    generatePromoCode(
      generateDetail: $generateDetail
      promoCodeId: $promoCodeId
      promoType: $promoType
    ) {
      statusCode
      message
    }
  }
`
export const initPromoCodeStatusFile = /* GraphQL */ `
  mutation InitPromoCodeStatusFile($promoCodeId: String) {
    initPromoCodeStatusFile(promoCodeId: $promoCodeId) {
      totalCount
      activeCount
      redeemedCount
      deactivatedCount
      statusUpdatedAt
      promoCodeList {
        promoCodeGenericId
        promoCodeTxnId
        promoCode
        status
        createdAt
        redeemedAt
        deactivatedAt
        redeemedCount
      }
    }
  }
`
export const initPromoCodeStatus = /* GraphQL */ `
  mutation InitPromoCodeStatus($promoCodeId: String) {
    initPromoCodeStatus(promoCodeId: $promoCodeId) {
      messageId
      totalCount
      activeCount
      redeemedCount
      deactivatedCount
      statusUpdatedAt
      promoCodeList {
        promoCodeGenericId
        promoCodeTxnId
        promoCode
        status
        createdAt
        redeemedAt
        deactivatedAt
        redeemedCount
      }
    }
  }
`
export const initPromoCodeStatusSQS = /* GraphQL */ `
  mutation InitPromoCodeStatusSQS($promoCodeId: String) {
    initPromoCodeStatusSQS(promoCodeId: $promoCodeId) {
      messageId
      totalCount
      activeCount
      redeemedCount
      deactivatedCount
      statusUpdatedAt
      promoCodeList {
        promoCodeGenericId
        promoCodeTxnId
        promoCode
        status
        createdAt
        redeemedAt
        deactivatedAt
        redeemedCount
      }
    }
  }
`
export const reduceNumberOfPromoCode = /* GraphQL */ `
  mutation ReduceNumberOfPromoCode($promoCodeId: String, $numOfCodes: Int) {
    reduceNumberOfPromoCode(
      promoCodeId: $promoCodeId
      numOfCodes: $numOfCodes
    ) {
      statusCode
      message
    }
  }
`
export const downloadPromoCodeReport = /* GraphQL */ `
  mutation DownloadPromoCodeReport($promoCodeId: String) {
    downloadPromoCodeReport(promoCodeId: $promoCodeId)
  }
`
export const updateNoOfPromoCode = /* GraphQL */ `
  mutation UpdateNoOfPromoCode($promoCodeId: String, $numOfCodes: Int) {
    updateNoOfPromoCode(promoCodeId: $promoCodeId, numOfCodes: $numOfCodes)
  }
`
export const generateUniquePromoCode = /* GraphQL */ `
  mutation GenerateUniquePromoCode(
    $generateDetail: [GenerateUniquePromoCodeDetail]
    $promoCodeId: String
  ) {
    generateUniquePromoCode(
      generateDetail: $generateDetail
      promoCodeId: $promoCodeId
    ) {
      statusCode
      message
    }
  }
`
export const reorderProduct = /* GraphQL */ `
  mutation ReorderProduct(
    $token: String
    $orderId: String
    $storeId: String
    $orderType: String
  ) {
    reorderProduct(
      token: $token
      orderId: $orderId
      storeId: $storeId
      orderType: $orderType
    ) {
      statusCode
      errorMessage
      storeStatus
      storeStatusMessage
      returnTotalNumber
      deliveryFee
      basketValue
      deliveryDiscountAmount
      products {
        quantity
        price
        discountedPrice
        subtotal
        discountAmount
        customerCartProductId
        title
        pickupMaximumQuantity
        ecommerceMaximumQuantity
        isOutOfStock
        image
        status
        mandatoryItem
        isFreeItem
        promoDiscount
        voucherDiscount
      }
      promoApplicable
      promoCode
      promoTitle
      promoDiscount
      promoDeliveryDiscount
      freeDeliveryMessage
      orderSubtotal
      deliverySurchargeAmount
      deliverySurchargeMessage
      indicationMessage
      deliveryFree
      discountTypeLevel
      discountDeliveryCap
      discountProductQuantityCap
      discountCap
      totalDiscountAmount
      totalPromoDiscount
      totalVoucherDiscount
      voucherDeliveryDiscount
      voucherApplicable
      voucherNumber
      voucherTitle
    }
  }
`
export const registerDevice = /* GraphQL */ `
  mutation RegisterDevice(
    $customerDeviceToken: String
    $customerDeviceType: String
    $token: String
  ) {
    registerDevice(
      customerDeviceToken: $customerDeviceToken
      customerDeviceType: $customerDeviceType
      token: $token
    )
  }
`
export const addSystemUser = /* GraphQL */ `
  mutation AddSystemUser($username: String, $role: String) {
    addSystemUser(username: $username, role: $role) {
      StatusCode
      id
    }
  }
`
export const updateSystemUser = /* GraphQL */ `
  mutation UpdateSystemUser(
    $username: String
    $role: String
    $enabled: Boolean
    $code: String
    $password: String
    $isSendCode: Boolean
  ) {
    updateSystemUser(
      username: $username
      role: $role
      enabled: $enabled
      code: $code
      password: $password
      isSendCode: $isSendCode
    ) {
      StatusCode
      id
    }
  }
`
export const updateKDSConfig = /* GraphQL */ `
  mutation UpdateKDSConfig(
    $id: String
    $overallSLA: Int
    $newOrderSLA: Int
    $newOrderBeepSLA: Int
    $newOrderCallAMSLA: Int
    $pendingSLA: Int
    $pendingBeepSLA: Int
    $packingSLA: Int
    $packingBeepSLA: Int
    $readyPickupSLA: Int
    $readyPickupBeepSLA: Int
    $KDSCancelMsg: String
  ) {
    updateKDSConfig(
      id: $id
      overallSLA: $overallSLA
      newOrderSLA: $newOrderSLA
      newOrderBeepSLA: $newOrderBeepSLA
      newOrderCallAMSLA: $newOrderCallAMSLA
      pendingSLA: $pendingSLA
      pendingBeepSLA: $pendingBeepSLA
      packingSLA: $packingSLA
      packingBeepSLA: $packingBeepSLA
      readyPickupSLA: $readyPickupSLA
      readyPickupBeepSLA: $readyPickupBeepSLA
      KDSCancelMsg: $KDSCancelMsg
    ) {
      statusCode
      status
    }
  }
`
export const updatePromoCodeStatus = /* GraphQL */ `
  mutation UpdatePromoCodeStatus(
    $promoCodeTypeId: String
    $promoCodeId: String
    $promoCodeType: String
    $lastModifiedBy: String
    $status: String
  ) {
    updatePromoCodeStatus(
      promoCodeTypeId: $promoCodeTypeId
      promoCodeId: $promoCodeId
      promoCodeType: $promoCodeType
      lastModifiedBy: $lastModifiedBy
      status: $status
    ) {
      status
      statusCode
      message
    }
  }
`
export const submitCustomerSentimentSurvey = /* GraphQL */ `
  mutation SubmitCustomerSentimentSurvey(
    $token: String
    $issueId: String
    $rating: String
    $additionalFeedback: String
    $phoneNumber: String
    $platform: String
  ) {
    submitCustomerSentimentSurvey(
      token: $token
      issueId: $issueId
      rating: $rating
      additionalFeedback: $additionalFeedback
      phoneNumber: $phoneNumber
      platform: $platform
    ) {
      statusCode
      message
    }
  }
`
export const submitOrderReview = /* GraphQL */ `
  mutation SubmitOrderReview(
    $overallRating: Int
    $foodRating: Int
    $waitingTimeRating: Int
    $staffServiceRating: Int
    $productAvailabilityRating: Int
    $comment: String
    $attactment: [String]
    $token: String
    $orderId: String
  ) {
    submitOrderReview(
      overallRating: $overallRating
      foodRating: $foodRating
      waitingTimeRating: $waitingTimeRating
      staffServiceRating: $staffServiceRating
      productAvailabilityRating: $productAvailabilityRating
      comment: $comment
      attactment: $attactment
      token: $token
      orderId: $orderId
    ) {
      statusCode
      message
    }
  }
`
export const submitFeedback = /* GraphQL */ `
  mutation SubmitFeedback(
    $feedbackId: String
    $searchLocation: String
    $dateOfVisit: String
    $timeOfVisit: String
    $title: String
    $description: String
    $uploadPhoto: [String]
    $token: String
    $platform: String
    $feedbackType: String
    $customerName: String
    $customerMobileNo: String
    $customerEmail: String
  ) {
    submitFeedback(
      feedbackId: $feedbackId
      searchLocation: $searchLocation
      dateOfVisit: $dateOfVisit
      timeOfVisit: $timeOfVisit
      title: $title
      description: $description
      uploadPhoto: $uploadPhoto
      token: $token
      platform: $platform
      feedbackType: $feedbackType
      customerName: $customerName
      customerMobileNo: $customerMobileNo
      customerEmail: $customerEmail
    ) {
      statusCode
      message
    }
  }
`
export const addIssue = /* GraphQL */ `
  mutation AddIssue(
    $orderNumber: String
    $title: String
    $customerAccountNo: String
    $issueDateTime: String
    $issueStatus: String
    $totalQuantity: Int
    $totalAmount: Float
    $totalMessage: Int
    $message: String
    $image1: String
    $image2: String
    $image3: String
    $issueId: String
    $productImage: String
    $phoneNumber: String
    $platform: String
    $issueType: String
    $trackerNumber: String
    $parcelAmount: Float
  ) {
    addIssue(
      orderNumber: $orderNumber
      title: $title
      customerAccountNo: $customerAccountNo
      issueDateTime: $issueDateTime
      issueStatus: $issueStatus
      totalQuantity: $totalQuantity
      totalAmount: $totalAmount
      totalMessage: $totalMessage
      message: $message
      image1: $image1
      image2: $image2
      image3: $image3
      issueId: $issueId
      productImage: $productImage
      phoneNumber: $phoneNumber
      platform: $platform
      issueType: $issueType
      trackerNumber: $trackerNumber
      parcelAmount: $parcelAmount
    ) {
      status
      statusCode
    }
  }
`
export const cancelOrder = /* GraphQL */ `
  mutation CancelOrder(
    $orderId: String
    $reasonCode: String
    $cancelReason: String
    $redmineTicketNumber: String
  ) {
    cancelOrder(
      orderId: $orderId
      reasonCode: $reasonCode
      cancelReason: $cancelReason
      redmineTicketNumber: $redmineTicketNumber
    ) {
      statusCode
      message
    }
  }
`
export const refundOrder = /* GraphQL */ `
  mutation RefundOrder(
    $orderId: String
    $refundReason: String
    $refundAmount: Float
    $refundBy: String
    $redmineTicketNumber: String
  ) {
    refundOrder(
      orderId: $orderId
      refundReason: $refundReason
      refundAmount: $refundAmount
      refundBy: $refundBy
      redmineTicketNumber: $redmineTicketNumber
    ) {
      statusCode
      message
    }
  }
`
export const sendOrderReceipt = /* GraphQL */ `
  mutation SendOrderReceipt($orderId: String, $token: String) {
    sendOrderReceipt(orderId: $orderId, token: $token) {
      statusCode
      message
    }
  }
`
export const sendVoucherOrderReceipt = /* GraphQL */ `
  mutation SendVoucherOrderReceipt($voucherOrderId: String, $token: String) {
    sendVoucherOrderReceipt(voucherOrderId: $voucherOrderId, token: $token) {
      statusCode
      message
    }
  }
`
export const sendFeedback = /* GraphQL */ `
  mutation SendFeedback($feedbackId: String, $token: String) {
    sendFeedback(feedbackId: $feedbackId, token: $token) {
      statusCode
      message
    }
  }
`
export const downloadRefundReport = /* GraphQL */ `
  mutation DownloadRefundReport(
    $status: String
    $method: String
    $DateFrom: String
    $DateTo: String
    $updateStatus: Boolean
    $Keyword: String
    $SearchCategory: String
    $refundType: String
  ) {
    downloadRefundReport(
      status: $status
      method: $method
      DateFrom: $DateFrom
      DateTo: $DateTo
      updateStatus: $updateStatus
      Keyword: $Keyword
      SearchCategory: $SearchCategory
      refundType: $refundType
    )
  }
`
export const downloadOrderReport = /* GraphQL */ `
  mutation DownloadOrderReport(
    $DateFrom: String
    $DateTo: String
    $Keyword: String
    $SearchCategory: String
    $SelectedDateType: String
  ) {
    downloadOrderReport(
      DateFrom: $DateFrom
      DateTo: $DateTo
      Keyword: $Keyword
      SearchCategory: $SearchCategory
      SelectedDateType: $SelectedDateType
    )
  }
`
export const downloadStoreProductReport = /* GraphQL */ `
  mutation DownloadStoreProductReport($fileName: String) {
    downloadStoreProductReport(fileName: $fileName)
  }
`
export const generateStoreProductReport = /* GraphQL */ `
  mutation GenerateStoreProductReport($fileName: String) {
    generateStoreProductReport(fileName: $fileName) {
      fileName
      s3FileLink
    }
  }
`
export const updateProductImages = /* GraphQL */ `
  mutation UpdateProductImages($images: String) {
    updateProductImages(images: $images)
  }
`
export const addVoucherReward = /* GraphQL */ `
  mutation AddVoucherReward(
    $rewardId: String
    $image: String
    $startDate: String
    $endDate: String
    $point: Int
    $cashValueAmount: Float
    $skuCashDiscount: Float
    $title: String
    $description: String
    $tnc: String
    $voucherType: String
    $storeList: String
    $productList: String
  ) {
    addVoucherReward(
      rewardId: $rewardId
      image: $image
      startDate: $startDate
      endDate: $endDate
      point: $point
      cashValueAmount: $cashValueAmount
      skuCashDiscount: $skuCashDiscount
      title: $title
      description: $description
      tnc: $tnc
      voucherType: $voucherType
      storeList: $storeList
      productList: $productList
    )
  }
`
export const applyVoucher = /* GraphQL */ `
  mutation ApplyVoucher(
    $customerId: String
    $voucherId: String
    $storeId: String
    $productList: String
  ) {
    applyVoucher(
      customerId: $customerId
      voucherId: $voucherId
      storeId: $storeId
      productList: $productList
    )
  }
`
export const placeOrder = /* GraphQL */ `
  mutation PlaceOrder(
    $token: String
    $voucherId: String
    $storeId: String
    $productList: String
    $orderType: String
  ) {
    placeOrder(
      token: $token
      voucherId: $voucherId
      storeId: $storeId
      productList: $productList
      orderType: $orderType
    ) {
      status
      orderId
      statusCode
      paymentUrl
      body
      httpMethod
      msgId
      message
      promoCodeMessage
    }
  }
`
export const placeUserOrder = /* GraphQL */ `
  mutation PlaceUserOrder(
    $token: String
    $voucherId: String
    $storeId: String
    $productList: String
    $orderType: String
    $paymentMethod: String
    $isAdvancedOrder: Boolean
    $scheduledDateTime: String
    $favDeliveryAddressId: String
    $remarks: String
    $deliveryAddress: String
    $noteToRider: String
    $floorOrUnit: String
    $deliveryLatitude: String
    $deliveryLongitude: String
    $favouritePaymentId: String
    $contactNumber: String
    $requiredCutlery: Boolean
    $deliveryFee: Float
    $promoCode: String
    $voucherNumber: String
    $voucherEntryNumber: String
    $voucherExpiryDate: String
    $isSharedVoucher: Boolean
  ) {
    placeUserOrder(
      token: $token
      voucherId: $voucherId
      storeId: $storeId
      productList: $productList
      orderType: $orderType
      paymentMethod: $paymentMethod
      isAdvancedOrder: $isAdvancedOrder
      scheduledDateTime: $scheduledDateTime
      favDeliveryAddressId: $favDeliveryAddressId
      remarks: $remarks
      deliveryAddress: $deliveryAddress
      noteToRider: $noteToRider
      floorOrUnit: $floorOrUnit
      deliveryLatitude: $deliveryLatitude
      deliveryLongitude: $deliveryLongitude
      favouritePaymentId: $favouritePaymentId
      contactNumber: $contactNumber
      requiredCutlery: $requiredCutlery
      deliveryFee: $deliveryFee
      promoCode: $promoCode
      voucherNumber: $voucherNumber
      voucherEntryNumber: $voucherEntryNumber
      voucherExpiryDate: $voucherExpiryDate
      isSharedVoucher: $isSharedVoucher
    ) {
      status
      orderId
      statusCode
      paymentUrl
      body
      httpMethod
      msgId
      message
      promoCodeMessage
    }
  }
`
export const placeUserOrderSqs = /* GraphQL */ `
  mutation PlaceUserOrderSqs(
    $token: String
    $voucherId: String
    $storeId: String
    $productList: String
    $orderType: String
    $paymentMethod: String
    $isAdvancedOrder: Boolean
    $scheduledDateTime: String
    $favDeliveryAddressId: String
    $remarks: String
    $floorOrUnit: String
    $deliveryAddress: String
    $deliveryLatitude: String
    $deliveryLongitude: String
    $favouritePaymentId: String
    $contactNumber: String
    $requiredCutlery: Boolean
    $noteToRider: String
    $deliveryFee: Float
    $version: String
    $platform: String
    $promoCode: String
    $voucherNumber: String
    $voucherEntryNumber: String
    $voucherExpiryDate: String
    $isSharedVoucher: Boolean
  ) {
    placeUserOrderSqs(
      token: $token
      voucherId: $voucherId
      storeId: $storeId
      productList: $productList
      orderType: $orderType
      paymentMethod: $paymentMethod
      isAdvancedOrder: $isAdvancedOrder
      scheduledDateTime: $scheduledDateTime
      favDeliveryAddressId: $favDeliveryAddressId
      remarks: $remarks
      floorOrUnit: $floorOrUnit
      deliveryAddress: $deliveryAddress
      deliveryLatitude: $deliveryLatitude
      deliveryLongitude: $deliveryLongitude
      favouritePaymentId: $favouritePaymentId
      contactNumber: $contactNumber
      requiredCutlery: $requiredCutlery
      noteToRider: $noteToRider
      deliveryFee: $deliveryFee
      version: $version
      platform: $platform
      promoCode: $promoCode
      voucherNumber: $voucherNumber
      voucherEntryNumber: $voucherEntryNumber
      voucherExpiryDate: $voucherExpiryDate
      isSharedVoucher: $isSharedVoucher
    ) {
      status
      orderId
      statusCode
      paymentUrl
      body
      httpMethod
      msgId
      message
      promoCodeMessage
    }
  }
`
export const purchaseVoucherSQS = /* GraphQL */ `
  mutation PurchaseVoucherSQS(
    $token: String
    $voucherList: [VoucherCartInput]
    $favouritePaymentId: String
    $paymentMethod: String
    $version: String
    $platform: String
  ) {
    purchaseVoucherSQS(
      token: $token
      voucherList: $voucherList
      favouritePaymentId: $favouritePaymentId
      paymentMethod: $paymentMethod
      version: $version
      platform: $platform
    ) {
      status
      statusCode
      paymentUrl
      body
      httpMethod
      msgId
      message
    }
  }
`
export const purchaseVoucher = /* GraphQL */ `
  mutation PurchaseVoucher(
    $token: String
    $voucherList: [VoucherCartInput]
    $favouritePaymentId: String
    $paymentMethod: String
    $version: String
    $platform: String
  ) {
    purchaseVoucher(
      token: $token
      voucherList: $voucherList
      favouritePaymentId: $favouritePaymentId
      paymentMethod: $paymentMethod
      version: $version
      platform: $platform
    ) {
      status
      statusCode
      paymentUrl
      body
      httpMethod
      msgId
      message
    }
  }
`
export const redeemVoucherByPoints = /* GraphQL */ `
  mutation RedeemVoucherByPoints(
    $token: String
    $benefitTriggerId: String
    $version: String
    $platform: String
  ) {
    redeemVoucherByPoints(
      token: $token
      benefitTriggerId: $benefitTriggerId
      version: $version
      platform: $platform
    ) {
      statusCode
      status
      message
    }
  }
`
export const pointRedemption = /* GraphQL */ `
  mutation PointRedemption($customerId: String, $rewardId: String) {
    pointRedemption(customerId: $customerId, rewardId: $rewardId)
  }
`
export const userSignIn = /* GraphQL */ `
  mutation UserSignIn(
    $username: String
    $password: String
    $version: String
    $platform: String
    $partnerId: String
    $configValue: String
  ) {
    userSignIn(
      username: $username
      password: $password
      version: $version
      platform: $platform
      partnerId: $partnerId
      configValue: $configValue
    ) {
      Status
      Message
      Token
      SignedToken
      ExpiredAt
      sessionID
      isFreeItem
      promoDiscount
      otpRequired
      firstUserLogin
      redirectionLink
    }
  }
`
export const userSignUp = /* GraphQL */ `
  mutation UserSignUp(
    $mobileNo: String
    $code: String
    $firstName: String
    $lastName: String
    $primaryEmail: String
    $secondaryEmail: String
    $password: String
    $gender: String
    $dateOfBirth: String
    $race: String
    $nationality: String
    $promoCode: String
    $version: String
    $platform: String
  ) {
    userSignUp(
      mobileNo: $mobileNo
      code: $code
      firstName: $firstName
      lastName: $lastName
      primaryEmail: $primaryEmail
      secondaryEmail: $secondaryEmail
      password: $password
      gender: $gender
      dateOfBirth: $dateOfBirth
      race: $race
      nationality: $nationality
      promoCode: $promoCode
      version: $version
      platform: $platform
    ) {
      AccountNo
      Status
      Message
    }
  }
`
export const userSignOut = /* GraphQL */ `
  mutation UserSignOut($token: String, $refreshToken: String) {
    userSignOut(token: $token, refreshToken: $refreshToken) {
      AccountNo
      Status
      Message
    }
  }
`
export const userUpdateProfile = /* GraphQL */ `
  mutation UserUpdateProfile(
    $mobileNo: String
    $code: String
    $firstName: String
    $lastName: String
    $primaryEmail: String
    $password: String
    $token: String
    $gender: String
    $dateOfBirth: String
    $race: String
    $nationality: String
    $country: String
    $address: String
    $address2: String
    $city: String
    $state: String
    $postal: String
    $maritalStatus: String
    $employmentStatus: String
    $personalIncomeLevel: String
    $areaOfInterest: String
    $image: String
    $platform: String
    $version: String
  ) {
    userUpdateProfile(
      mobileNo: $mobileNo
      code: $code
      firstName: $firstName
      lastName: $lastName
      primaryEmail: $primaryEmail
      password: $password
      token: $token
      gender: $gender
      dateOfBirth: $dateOfBirth
      race: $race
      nationality: $nationality
      country: $country
      address: $address
      address2: $address2
      city: $city
      state: $state
      postal: $postal
      maritalStatus: $maritalStatus
      employmentStatus: $employmentStatus
      personalIncomeLevel: $personalIncomeLevel
      areaOfInterest: $areaOfInterest
      image: $image
      platform: $platform
      version: $version
    ) {
      statusCode
      AccountNo
      Status
      Message
    }
  }
`
export const userForgotPassword = /* GraphQL */ `
  mutation UserForgotPassword(
    $mobileNo: String
    $accountNo: String
    $password: String
    $code: String
    $platform: String
    $version: String
  ) {
    userForgotPassword(
      mobileNo: $mobileNo
      accountNo: $accountNo
      password: $password
      code: $code
      platform: $platform
      version: $version
    ) {
      AccountNo
      Status
      Message
    }
  }
`
export const userOtpVerification = /* GraphQL */ `
  mutation UserOtpVerification(
    $mobileNo: String
    $code: String
    $platform: String
    $version: String
  ) {
    userOtpVerification(
      mobileNo: $mobileNo
      code: $code
      platform: $platform
      version: $version
    ) {
      AccountNo
      FirstName
      LastName
      Code
      PrimaryEmail
      SecondaryEmail
      MobileNo
      ReturnType
      Message
      Status
    }
  }
`
export const userSendSmsOtp = /* GraphQL */ `
  mutation UserSendSmsOtp(
    $mobileNo: String
    $forgotPassword: Boolean
    $platform: String
    $version: String
  ) {
    userSendSmsOtp(
      mobileNo: $mobileNo
      forgotPassword: $forgotPassword
      platform: $platform
      version: $version
    ) {
      AccountNo
      FirstName
      LastName
      Code
      PrimaryEmail
      SecondaryEmail
      MobileNo
      ReturnType
      Message
      Status
    }
  }
`
export const userRefreshToken = /* GraphQL */ `
  mutation UserRefreshToken($token: String, $refreshToken: String) {
    userRefreshToken(token: $token, refreshToken: $refreshToken) {
      Status
      Message
      Token
      SignedToken
      ExpiredAt
    }
  }
`
export const addUserWishList = /* GraphQL */ `
  mutation AddUserWishList($productId: String, $token: String) {
    addUserWishList(productId: $productId, token: $token) {
      status
      statusCode
      customerWishListId
      message
    }
  }
`
export const removeUserWishList = /* GraphQL */ `
  mutation RemoveUserWishList(
    $customerWishListId: String
    $productId: String
    $token: String
  ) {
    removeUserWishList(
      customerWishListId: $customerWishListId
      productId: $productId
      token: $token
    ) {
      status
      statusCode
      customerWishListId
      message
    }
  }
`
export const addUserFavAddressList = /* GraphQL */ `
  mutation AddUserFavAddressList(
    $token: String
    $title: String
    $country: String
    $address: String
    $address2: String
    $city: String
    $state: String
    $postal: String
    $latitude: String
    $longitude: String
    $notes: String
  ) {
    addUserFavAddressList(
      token: $token
      title: $title
      country: $country
      address: $address
      address2: $address2
      city: $city
      state: $state
      postal: $postal
      latitude: $latitude
      longitude: $longitude
      notes: $notes
    ) {
      status
      statusCode
      customerFavouriteAddressId
      message
    }
  }
`
export const removeUserFavAddressList = /* GraphQL */ `
  mutation RemoveUserFavAddressList(
    $token: String
    $customerFavouriteAddressId: String
  ) {
    removeUserFavAddressList(
      token: $token
      customerFavouriteAddressId: $customerFavouriteAddressId
    ) {
      status
      statusCode
      customerFavouriteAddressId
      message
    }
  }
`
export const updateUserFavAddressList = /* GraphQL */ `
  mutation UpdateUserFavAddressList(
    $token: String
    $customerFavouriteAddressId: String
    $title: String
    $country: String
    $address: String
    $address2: String
    $city: String
    $state: String
    $postal: String
    $isPrimary: Boolean
    $latitude: String
    $longitude: String
    $notes: String
  ) {
    updateUserFavAddressList(
      token: $token
      customerFavouriteAddressId: $customerFavouriteAddressId
      title: $title
      country: $country
      address: $address
      address2: $address2
      city: $city
      state: $state
      postal: $postal
      isPrimary: $isPrimary
      latitude: $latitude
      longitude: $longitude
      notes: $notes
    ) {
      status
      statusCode
      customerFavouriteAddressId
      message
    }
  }
`
export const addUserFavPaymentList = /* GraphQL */ `
  mutation AddUserFavPaymentList(
    $token: String
    $creditCardNumber: String
    $paymentToken: String
    $creditCardType: String
    $expiryDate: String
    $country: String
  ) {
    addUserFavPaymentList(
      token: $token
      creditCardNumber: $creditCardNumber
      paymentToken: $paymentToken
      creditCardType: $creditCardType
      expiryDate: $expiryDate
      country: $country
    ) {
      status
      statusCode
      customerFavouritePaymentId
    }
  }
`
export const removeUserFavPaymentList = /* GraphQL */ `
  mutation RemoveUserFavPaymentList(
    $customerFavouritePaymentId: String
    $token: String
  ) {
    removeUserFavPaymentList(
      customerFavouritePaymentId: $customerFavouritePaymentId
      token: $token
    ) {
      status
      statusCode
      customerFavouritePaymentId
    }
  }
`
export const updateUserFavPaymentList = /* GraphQL */ `
  mutation UpdateUserFavPaymentList(
    $token: String
    $customerFavouritePaymentId: ID!
    $creditCardNumber: String
    $paymentToken: String
    $creditCardType: String
    $expiryDate: String
    $country: String
    $isPrimary: Boolean
  ) {
    updateUserFavPaymentList(
      token: $token
      customerFavouritePaymentId: $customerFavouritePaymentId
      creditCardNumber: $creditCardNumber
      paymentToken: $paymentToken
      creditCardType: $creditCardType
      expiryDate: $expiryDate
      country: $country
      isPrimary: $isPrimary
    ) {
      status
      statusCode
      customerFavouritePaymentId
    }
  }
`
export const addCart = /* GraphQL */ `
  mutation AddCart(
    $token: String
    $productId: String
    $storeId: String
    $quantity: Int
    $mandatoryItem: Boolean
  ) {
    addCart(
      token: $token
      productId: $productId
      storeId: $storeId
      quantity: $quantity
      mandatoryItem: $mandatoryItem
    ) {
      statusCode
      message
    }
  }
`
export const editCart = /* GraphQL */ `
  mutation EditCart(
    $token: String
    $productId: String
    $storeId: String
    $quantity: Int
    $mandatoryItem: Boolean
  ) {
    editCart(
      token: $token
      productId: $productId
      storeId: $storeId
      quantity: $quantity
      mandatoryItem: $mandatoryItem
    ) {
      statusCode
      message
    }
  }
`
export const removeCart = /* GraphQL */ `
  mutation RemoveCart($token: String, $productId: String) {
    removeCart(token: $token, productId: $productId) {
      statusCode
      message
    }
  }
`
export const userCancelOrder = /* GraphQL */ `
  mutation UserCancelOrder($token: String, $orderId: String) {
    userCancelOrder(token: $token, orderId: $orderId) {
      statusCode
      message
    }
  }
`
export const cancelVoucherOrder = /* GraphQL */ `
  mutation CancelVoucherOrder($token: String, $voucherOrderId: String) {
    cancelVoucherOrder(token: $token, voucherOrderId: $voucherOrderId) {
      statusCode
      message
    }
  }
`
export const updateProductRecord = /* GraphQL */ `
  mutation UpdateProductRecord(
    $productId: String
    $productTaggings: [String]
    $productDepartments: [String]
  ) {
    updateProductRecord(
      productId: $productId
      productTaggings: $productTaggings
      productDepartments: $productDepartments
    ) {
      statusCode
      message
    }
  }
`
export const generateS3UploadLink = /* GraphQL */ `
  mutation GenerateS3UploadLink(
    $token: String
    $folderName: String
    $phoneNumber: String
    $fileName: String
    $platform: String
  ) {
    generateS3UploadLink(
      token: $token
      folderName: $folderName
      phoneNumber: $phoneNumber
      fileName: $fileName
      platform: $platform
    )
  }
`
export const refreshCache = /* GraphQL */ `
  mutation RefreshCache($key: String) {
    refreshCache(key: $key)
  }
`
export const updateSequence = /* GraphQL */ `
  mutation UpdateSequence($module: String, $id: String, $sequenceOrder: Int) {
    updateSequence(module: $module, id: $id, sequenceOrder: $sequenceOrder) {
      statusCode
      message
    }
  }
`
export const updateKDSOutOfStock = /* GraphQL */ `
  mutation UpdateKDSOutOfStock(
    $isOutOfStock: Boolean
    $productId: String
    $storeId: String
  ) {
    updateKDSOutOfStock(
      isOutOfStock: $isOutOfStock
      productId: $productId
      storeId: $storeId
    )
  }
`
export const updateDownloadJob = /* GraphQL */ `
  mutation UpdateDownloadJob($input: DownloadJobInput) {
    updateDownloadJob(input: $input) {
      downloadJobId
      dateFrom
      dateTo
      keyword
      searchCategory
      module
      objectPresignedURL
      s3Bucket
      s3ObjectPath
      status
      targetTable
      createdAt
      updatedAt
      validFrom
      validTo
      requestor
    }
  }
`
export const createUploadJob = /* GraphQL */ `
  mutation CreateUploadJob($input: UploadJobInput) {
    createUploadJob(input: $input) {
      uploadJobId
      module
      s3Bucket
      s3ObjectPath
      status
      recordsProcessed
      targetTable
      createdAt
      updatedAt
      requestor
      errorMessage
    }
  }
`
export const exportCustomerData = /* GraphQL */ `
  mutation ExportCustomerData(
    $Requestor: String
    $Keyword: String
    $SearchCategory: String
  ) {
    exportCustomerData(
      Requestor: $Requestor
      Keyword: $Keyword
      SearchCategory: $SearchCategory
    )
  }
`
export const exportEcommerceOrderData = /* GraphQL */ `
  mutation ExportEcommerceOrderData(
    $Requestor: String
    $Keyword: String
    $SearchCategory: String
    $DateFrom: String
    $DateTo: String
  ) {
    exportEcommerceOrderData(
      Requestor: $Requestor
      Keyword: $Keyword
      SearchCategory: $SearchCategory
      DateFrom: $DateFrom
      DateTo: $DateTo
    )
  }
`
export const exportInStoreOrderData = /* GraphQL */ `
  mutation ExportInStoreOrderData(
    $Requestor: String
    $Keyword: String
    $SearchCategory: String
    $DateFrom: String
    $DateTo: String
  ) {
    exportInStoreOrderData(
      Requestor: $Requestor
      Keyword: $Keyword
      SearchCategory: $SearchCategory
      DateFrom: $DateFrom
      DateTo: $DateTo
    )
  }
`
export const exportPointsHistoryData = /* GraphQL */ `
  mutation ExportPointsHistoryData(
    $filter: ModelPointsHistoryFilterInput
    $sort: ElasticSearchSortDirection
    $Requestor: String
    $SearchCategory: String
  ) {
    exportPointsHistoryData(
      filter: $filter
      sort: $sort
      Requestor: $Requestor
      SearchCategory: $SearchCategory
    )
  }
`
export const exportVoucherOrderData = /* GraphQL */ `
  mutation ExportVoucherOrderData(
    $Requestor: String
    $Keyword: String
    $SearchCategory: String
    $DateFrom: String
    $DateTo: String
  ) {
    exportVoucherOrderData(
      Requestor: $Requestor
      Keyword: $Keyword
      SearchCategory: $SearchCategory
      DateFrom: $DateFrom
      DateTo: $DateTo
    )
  }
`
export const generateVoucherQRCode = /* GraphQL */ `
  mutation GenerateVoucherQRCode(
    $jwtToken: String
    $voucherCode: String
    $entryNo: [String]
    $expiryDate: String
    $version: String
    $platform: String
  ) {
    generateVoucherQRCode(
      jwtToken: $jwtToken
      voucherCode: $voucherCode
      entryNo: $entryNo
      expiryDate: $expiryDate
      version: $version
      platform: $platform
    ) {
      statusCode
      message
      voucherQrString
      limitPerTransaction
      reservedEntryNumber
      remainingSeconds
      validFrom
      validTo
    }
  }
`
export const validationPeriodIdChecking = /* GraphQL */ `
  mutation ValidationPeriodIdChecking(
    $validationPeriodId: String!
    $returnBoolean: Boolean
  ) {
    validationPeriodIdChecking(
      validationPeriodId: $validationPeriodId
      returnBoolean: $returnBoolean
    ) {
      statusCode
      errorMessage
      validationPeriodUsage
      validationPeriodUsageList {
        type
        title
      }
    }
  }
`
export const createValidationPeriod = /* GraphQL */ `
  mutation CreateValidationPeriod(
    $input: CreateValidationPeriodInput!
    $condition: ModelValidationPeriodConditionInput
  ) {
    createValidationPeriod(input: $input, condition: $condition) {
      validationPeriodId
      title
      startDate
      endDate
      startTime
      endTime
      timeWithinBounds
      daysTimeList {
        day
        startTime
        endTime
        timeWithinBounds
        endAfterMidnight
      }
      endAfterMidnight
      createdAt
      updatedAt
    }
  }
`
export const updateValidationPeriod = /* GraphQL */ `
  mutation UpdateValidationPeriod(
    $input: UpdateValidationPeriodInput!
    $condition: ModelValidationPeriodConditionInput
  ) {
    updateValidationPeriod(input: $input, condition: $condition) {
      validationPeriodId
      title
      startDate
      endDate
      startTime
      endTime
      timeWithinBounds
      daysTimeList {
        day
        startTime
        endTime
        timeWithinBounds
        endAfterMidnight
      }
      endAfterMidnight
      createdAt
      updatedAt
    }
  }
`
export const deleteValidationPeriod = /* GraphQL */ `
  mutation DeleteValidationPeriod(
    $input: DeleteValidationPeriodInput!
    $condition: ModelValidationPeriodConditionInput
  ) {
    deleteValidationPeriod(input: $input, condition: $condition) {
      validationPeriodId
      title
      startDate
      endDate
      startTime
      endTime
      timeWithinBounds
      daysTimeList {
        day
        startTime
        endTime
        timeWithinBounds
        endAfterMidnight
      }
      endAfterMidnight
      createdAt
      updatedAt
    }
  }
`
export const createNews = /* GraphQL */ `
  mutation CreateNews(
    $input: CreateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    createNews(input: $input, condition: $condition) {
      newsId
      pageLayout
      title
      description
      sequenceOrder
      isEnable
      image
      startDate
      endDate
      listNews {
        id
        image
        title
        description
        sequence
        buttonType
        buttonAction
        buttonLabel
        actionTitle
        detailImage
        startDate
        endDate
        enable
      }
      listNewsTwo
      listNewsThree
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      referenceId
      validationPeriodId
      createdBy
      createdByRole
      modifiedBy
      createdAt
      updatedAt
      schemeGroup
      status
      message
      shortLinkUrl
    }
  }
`
export const updateNews = /* GraphQL */ `
  mutation UpdateNews(
    $input: UpdateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    updateNews(input: $input, condition: $condition) {
      newsId
      pageLayout
      title
      description
      sequenceOrder
      isEnable
      image
      startDate
      endDate
      listNews {
        id
        image
        title
        description
        sequence
        buttonType
        buttonAction
        buttonLabel
        actionTitle
        detailImage
        startDate
        endDate
        enable
      }
      listNewsTwo
      listNewsThree
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      referenceId
      validationPeriodId
      createdBy
      createdByRole
      modifiedBy
      createdAt
      updatedAt
      schemeGroup
      status
      message
      shortLinkUrl
    }
  }
`
export const deleteNews = /* GraphQL */ `
  mutation DeleteNews(
    $input: DeleteNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    deleteNews(input: $input, condition: $condition) {
      newsId
      pageLayout
      title
      description
      sequenceOrder
      isEnable
      image
      startDate
      endDate
      listNews {
        id
        image
        title
        description
        sequence
        buttonType
        buttonAction
        buttonLabel
        actionTitle
        detailImage
        startDate
        endDate
        enable
      }
      listNewsTwo
      listNewsThree
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      referenceId
      validationPeriodId
      createdBy
      createdByRole
      modifiedBy
      createdAt
      updatedAt
      schemeGroup
      status
      message
      shortLinkUrl
    }
  }
`
export const createHomePageLanding = /* GraphQL */ `
  mutation CreateHomePageLanding(
    $input: CreateHomePageLandingInput!
    $condition: ModelHomePageLandingConditionInput
  ) {
    createHomePageLanding(input: $input, condition: $condition) {
      homePageLandingId
      title
      startDate
      endDate
      sequenceOrder
      isEnable
      validationPeriodId
      linkTo {
        id
        title
        sequence
        type
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateHomePageLanding = /* GraphQL */ `
  mutation UpdateHomePageLanding(
    $input: UpdateHomePageLandingInput!
    $condition: ModelHomePageLandingConditionInput
  ) {
    updateHomePageLanding(input: $input, condition: $condition) {
      homePageLandingId
      title
      startDate
      endDate
      sequenceOrder
      isEnable
      validationPeriodId
      linkTo {
        id
        title
        sequence
        type
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteHomePageLanding = /* GraphQL */ `
  mutation DeleteHomePageLanding(
    $input: DeleteHomePageLandingInput!
    $condition: ModelHomePageLandingConditionInput
  ) {
    deleteHomePageLanding(input: $input, condition: $condition) {
      homePageLandingId
      title
      startDate
      endDate
      sequenceOrder
      isEnable
      validationPeriodId
      linkTo {
        id
        title
        sequence
        type
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createHomePageLandingNews = /* GraphQL */ `
  mutation CreateHomePageLandingNews(
    $input: CreateHomePageLandingNewsInput!
    $condition: ModelHomePageLandingNewsConditionInput
  ) {
    createHomePageLandingNews(input: $input, condition: $condition) {
      homePageLandingNewsId
      homePageLandingId
      schemeGroup
      landingImage
      detailImage
      actionTitle
      title
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
      buttonType
      buttonAction
      buttonLabel
    }
  }
`
export const updateHomePageLandingNews = /* GraphQL */ `
  mutation UpdateHomePageLandingNews(
    $input: UpdateHomePageLandingNewsInput!
    $condition: ModelHomePageLandingNewsConditionInput
  ) {
    updateHomePageLandingNews(input: $input, condition: $condition) {
      homePageLandingNewsId
      homePageLandingId
      schemeGroup
      landingImage
      detailImage
      actionTitle
      title
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
      buttonType
      buttonAction
      buttonLabel
    }
  }
`
export const deleteHomePageLandingNews = /* GraphQL */ `
  mutation DeleteHomePageLandingNews(
    $input: DeleteHomePageLandingNewsInput!
    $condition: ModelHomePageLandingNewsConditionInput
  ) {
    deleteHomePageLandingNews(input: $input, condition: $condition) {
      homePageLandingNewsId
      homePageLandingId
      schemeGroup
      landingImage
      detailImage
      actionTitle
      title
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
      buttonType
      buttonAction
      buttonLabel
    }
  }
`
export const createMembershipPoint = /* GraphQL */ `
  mutation CreateMembershipPoint(
    $input: CreateMembershipPointInput!
    $condition: ModelMembershipPointConditionInput
  ) {
    createMembershipPoint(input: $input, condition: $condition) {
      membershipPointId
      club
      filterType
      schemeFilterType
      filterCode
      unitRate
      points
      schemeGroup
      minTransactionAmount
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`
export const updateMembershipPoint = /* GraphQL */ `
  mutation UpdateMembershipPoint(
    $input: UpdateMembershipPointInput!
    $condition: ModelMembershipPointConditionInput
  ) {
    updateMembershipPoint(input: $input, condition: $condition) {
      membershipPointId
      club
      filterType
      schemeFilterType
      filterCode
      unitRate
      points
      schemeGroup
      minTransactionAmount
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`
export const deleteMembershipPoint = /* GraphQL */ `
  mutation DeleteMembershipPoint(
    $input: DeleteMembershipPointInput!
    $condition: ModelMembershipPointConditionInput
  ) {
    deleteMembershipPoint(input: $input, condition: $condition) {
      membershipPointId
      club
      filterType
      schemeFilterType
      filterCode
      unitRate
      points
      schemeGroup
      minTransactionAmount
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`
export const createVoucher = /* GraphQL */ `
  mutation CreateVoucher(
    $input: CreateVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    createVoucher(input: $input, condition: $condition) {
      voucherId
      voucherNumber
      image
      mobileImage
      startDate
      endDate
      isEnabled
      voucherTitle
      description
      validationPeriodId
      departmentTaggingList {
        id
        title
      }
      productExemption
      usageCondition
      orderType
      orderTypeCombined
      schemeGroup
      allowVoucherGrouping
      allowVoucherSharing
      shareToNonMember
      limitPerTransaction
      limitPerDay
      productExemptionList {
        id
        sku
        title
      }
      productConditionList {
        id
        productCondition
        title
        sku
        uom
        itemCategoryDesc
        exclude
      }
      targetOnlineStore
      targetOnlineStoresCombined
      targetOfflineStore
      targetOfflineStoresCombined
      targetOnlineStoreList {
        storeGroupId
        code
      }
      targetOfflineStoreList {
        storeGroupId
        code
      }
      discountType
      productDiscountList {
        id
        sku
        title
        uom
        itemCategoryDesc
        productCondition
        exclude
      }
      discountOnProduct
      productMechanics
      productMechanicsValue
      totalTransactionType
      totalTransactionValue
      maxDiscountType
      maxDiscountValue
      maxDiscountAmount
      discountOnDelivery
      deliveryDiscountType
      deliveryDiscountValue
      createdBy
      modifiedBy
      createdAt
      updatedAt
      voucherValue
      basketCondition
      basketValue
    }
  }
`
export const updateVoucher = /* GraphQL */ `
  mutation UpdateVoucher(
    $input: UpdateVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    updateVoucher(input: $input, condition: $condition) {
      voucherId
      voucherNumber
      image
      mobileImage
      startDate
      endDate
      isEnabled
      voucherTitle
      description
      validationPeriodId
      departmentTaggingList {
        id
        title
      }
      productExemption
      usageCondition
      orderType
      orderTypeCombined
      schemeGroup
      allowVoucherGrouping
      allowVoucherSharing
      shareToNonMember
      limitPerTransaction
      limitPerDay
      productExemptionList {
        id
        sku
        title
      }
      productConditionList {
        id
        productCondition
        title
        sku
        uom
        itemCategoryDesc
        exclude
      }
      targetOnlineStore
      targetOnlineStoresCombined
      targetOfflineStore
      targetOfflineStoresCombined
      targetOnlineStoreList {
        storeGroupId
        code
      }
      targetOfflineStoreList {
        storeGroupId
        code
      }
      discountType
      productDiscountList {
        id
        sku
        title
        uom
        itemCategoryDesc
        productCondition
        exclude
      }
      discountOnProduct
      productMechanics
      productMechanicsValue
      totalTransactionType
      totalTransactionValue
      maxDiscountType
      maxDiscountValue
      maxDiscountAmount
      discountOnDelivery
      deliveryDiscountType
      deliveryDiscountValue
      createdBy
      modifiedBy
      createdAt
      updatedAt
      voucherValue
      basketCondition
      basketValue
    }
  }
`
export const deleteVoucher = /* GraphQL */ `
  mutation DeleteVoucher(
    $input: DeleteVoucherInput!
    $condition: ModelVoucherConditionInput
  ) {
    deleteVoucher(input: $input, condition: $condition) {
      voucherId
      voucherNumber
      image
      mobileImage
      startDate
      endDate
      isEnabled
      voucherTitle
      description
      validationPeriodId
      departmentTaggingList {
        id
        title
      }
      productExemption
      usageCondition
      orderType
      orderTypeCombined
      schemeGroup
      allowVoucherGrouping
      allowVoucherSharing
      shareToNonMember
      limitPerTransaction
      limitPerDay
      productExemptionList {
        id
        sku
        title
      }
      productConditionList {
        id
        productCondition
        title
        sku
        uom
        itemCategoryDesc
        exclude
      }
      targetOnlineStore
      targetOnlineStoresCombined
      targetOfflineStore
      targetOfflineStoresCombined
      targetOnlineStoreList {
        storeGroupId
        code
      }
      targetOfflineStoreList {
        storeGroupId
        code
      }
      discountType
      productDiscountList {
        id
        sku
        title
        uom
        itemCategoryDesc
        productCondition
        exclude
      }
      discountOnProduct
      productMechanics
      productMechanicsValue
      totalTransactionType
      totalTransactionValue
      maxDiscountType
      maxDiscountValue
      maxDiscountAmount
      discountOnDelivery
      deliveryDiscountType
      deliveryDiscountValue
      createdBy
      modifiedBy
      createdAt
      updatedAt
      voucherValue
      basketCondition
      basketValue
    }
  }
`
export const createSurveyQuestion = /* GraphQL */ `
  mutation CreateSurveyQuestion(
    $input: CreateSurveyQuestionInput!
    $condition: ModelSurveyQuestionConditionInput
  ) {
    createSurveyQuestion(input: $input, condition: $condition) {
      surveyQuestionId
      sequence
      questionImage
      surveyId
      description
      required
      questionType
      questionChoice
      question
      inputType
      choiceLabel {
        choice
        type
        label
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
    }
  }
`
export const updateSurveyQuestion = /* GraphQL */ `
  mutation UpdateSurveyQuestion(
    $input: UpdateSurveyQuestionInput!
    $condition: ModelSurveyQuestionConditionInput
  ) {
    updateSurveyQuestion(input: $input, condition: $condition) {
      surveyQuestionId
      sequence
      questionImage
      surveyId
      description
      required
      questionType
      questionChoice
      question
      inputType
      choiceLabel {
        choice
        type
        label
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
    }
  }
`
export const deleteSurveyQuestion = /* GraphQL */ `
  mutation DeleteSurveyQuestion(
    $input: DeleteSurveyQuestionInput!
    $condition: ModelSurveyQuestionConditionInput
  ) {
    deleteSurveyQuestion(input: $input, condition: $condition) {
      surveyQuestionId
      sequence
      questionImage
      surveyId
      description
      required
      questionType
      questionChoice
      question
      inputType
      choiceLabel {
        choice
        type
        label
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
    }
  }
`
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      surveyId
      surveyName
      createdAt
      updatedAt
      createdBy
      updatedBy
    }
  }
`
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      surveyId
      surveyName
      createdAt
      updatedAt
      createdBy
      updatedBy
    }
  }
`
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      surveyId
      surveyName
      createdAt
      updatedAt
      createdBy
      updatedBy
    }
  }
`
export const createBenefitTrigger = /* GraphQL */ `
  mutation CreateBenefitTrigger(
    $input: CreateBenefitTriggerInput!
    $condition: ModelBenefitTriggerConditionInput
  ) {
    createBenefitTrigger(input: $input, condition: $condition) {
      benefitTriggerId
      title
      image
      mobileImage
      voucherList
      description
      triggerType
      rewardType
      expirationType
      expirationValue
      expirationDateValue
      startDate
      endDate
      isEnabled
      validationPeriodId
      price
      points
      reasonCode
      purchaseLimit
      redeemLimit
      voucher {
        voucherId
        voucherTitle
        voucherNumber
        quantity
      }
      targetAudience {
        method
        audience {
          firstName
          lastName
          accountNo
        }
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      limited
      voucherQuantity
      filter
      flashDeal
      signUpVoucher {
        voucherId
        voucherNumber
        voucherTitle
        broadcastType
        broadcastValue
      }
      signUpPoints {
        amount
        reasonCode
        broadcastType
        broadcastValue
      }
      famiDealsVoucher {
        voucherId
        voucherTitle
        voucherNumber
        quantity
      }
      initialMinExpirationType
      initialMinExpirationValue
      minTransactionAmount
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateBenefitTrigger = /* GraphQL */ `
  mutation UpdateBenefitTrigger(
    $input: UpdateBenefitTriggerInput!
    $condition: ModelBenefitTriggerConditionInput
  ) {
    updateBenefitTrigger(input: $input, condition: $condition) {
      benefitTriggerId
      title
      image
      mobileImage
      voucherList
      description
      triggerType
      rewardType
      expirationType
      expirationValue
      expirationDateValue
      startDate
      endDate
      isEnabled
      validationPeriodId
      price
      points
      reasonCode
      purchaseLimit
      redeemLimit
      voucher {
        voucherId
        voucherTitle
        voucherNumber
        quantity
      }
      targetAudience {
        method
        audience {
          firstName
          lastName
          accountNo
        }
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      limited
      voucherQuantity
      filter
      flashDeal
      signUpVoucher {
        voucherId
        voucherNumber
        voucherTitle
        broadcastType
        broadcastValue
      }
      signUpPoints {
        amount
        reasonCode
        broadcastType
        broadcastValue
      }
      famiDealsVoucher {
        voucherId
        voucherTitle
        voucherNumber
        quantity
      }
      initialMinExpirationType
      initialMinExpirationValue
      minTransactionAmount
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteBenefitTrigger = /* GraphQL */ `
  mutation DeleteBenefitTrigger(
    $input: DeleteBenefitTriggerInput!
    $condition: ModelBenefitTriggerConditionInput
  ) {
    deleteBenefitTrigger(input: $input, condition: $condition) {
      benefitTriggerId
      title
      image
      mobileImage
      voucherList
      description
      triggerType
      rewardType
      expirationType
      expirationValue
      expirationDateValue
      startDate
      endDate
      isEnabled
      validationPeriodId
      price
      points
      reasonCode
      purchaseLimit
      redeemLimit
      voucher {
        voucherId
        voucherTitle
        voucherNumber
        quantity
      }
      targetAudience {
        method
        audience {
          firstName
          lastName
          accountNo
        }
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      limited
      voucherQuantity
      filter
      flashDeal
      signUpVoucher {
        voucherId
        voucherNumber
        voucherTitle
        broadcastType
        broadcastValue
      }
      signUpPoints {
        amount
        reasonCode
        broadcastType
        broadcastValue
      }
      famiDealsVoucher {
        voucherId
        voucherTitle
        voucherNumber
        quantity
      }
      initialMinExpirationType
      initialMinExpirationValue
      minTransactionAmount
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createAdHocSurveyQuestion = /* GraphQL */ `
  mutation CreateAdHocSurveyQuestion(
    $input: CreateAdHocSurveyQuestionInput!
    $condition: ModelAdHocSurveyQuestionConditionInput
  ) {
    createAdHocSurveyQuestion(input: $input, condition: $condition) {
      adHocSurveyQuestionId
      title
      broadcastMessageSchedule
      isEnable
      targetAudience {
        method
        audience {
          name
          accountNo
        }
        numberOfAudience
        csvFilePath
      }
      questions {
        questionNo
        question
        answerType
        multipleChoice {
          option
          question
        }
        ranking {
          rankFrom
          rankTo
          question
        }
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateAdHocSurveyQuestion = /* GraphQL */ `
  mutation UpdateAdHocSurveyQuestion(
    $input: UpdateAdHocSurveyQuestionInput!
    $condition: ModelAdHocSurveyQuestionConditionInput
  ) {
    updateAdHocSurveyQuestion(input: $input, condition: $condition) {
      adHocSurveyQuestionId
      title
      broadcastMessageSchedule
      isEnable
      targetAudience {
        method
        audience {
          name
          accountNo
        }
        numberOfAudience
        csvFilePath
      }
      questions {
        questionNo
        question
        answerType
        multipleChoice {
          option
          question
        }
        ranking {
          rankFrom
          rankTo
          question
        }
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteAdHocSurveyQuestion = /* GraphQL */ `
  mutation DeleteAdHocSurveyQuestion(
    $input: DeleteAdHocSurveyQuestionInput!
    $condition: ModelAdHocSurveyQuestionConditionInput
  ) {
    deleteAdHocSurveyQuestion(input: $input, condition: $condition) {
      adHocSurveyQuestionId
      title
      broadcastMessageSchedule
      isEnable
      targetAudience {
        method
        audience {
          name
          accountNo
        }
        numberOfAudience
        csvFilePath
      }
      questions {
        questionNo
        question
        answerType
        multipleChoice {
          option
          question
        }
        ranking {
          rankFrom
          rankTo
          question
        }
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createAdHocSurveyResponse = /* GraphQL */ `
  mutation CreateAdHocSurveyResponse(
    $input: CreateAdHocSurveyResponseInput!
    $condition: ModelAdHocSurveyResponseConditionInput
  ) {
    createAdHocSurveyResponse(input: $input, condition: $condition) {
      adHocSurveyResponseId
      adHocSurveyQuestionId
      customerId
      responses {
        questionNo
        openTextAnswer
        multipleChoiceAnswer {
          option
          answer
        }
        rankingAnswer {
          option
          rank
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const updateAdHocSurveyResponse = /* GraphQL */ `
  mutation UpdateAdHocSurveyResponse(
    $input: UpdateAdHocSurveyResponseInput!
    $condition: ModelAdHocSurveyResponseConditionInput
  ) {
    updateAdHocSurveyResponse(input: $input, condition: $condition) {
      adHocSurveyResponseId
      adHocSurveyQuestionId
      customerId
      responses {
        questionNo
        openTextAnswer
        multipleChoiceAnswer {
          option
          answer
        }
        rankingAnswer {
          option
          rank
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteAdHocSurveyResponse = /* GraphQL */ `
  mutation DeleteAdHocSurveyResponse(
    $input: DeleteAdHocSurveyResponseInput!
    $condition: ModelAdHocSurveyResponseConditionInput
  ) {
    deleteAdHocSurveyResponse(input: $input, condition: $condition) {
      adHocSurveyResponseId
      adHocSurveyQuestionId
      customerId
      responses {
        questionNo
        openTextAnswer
        multipleChoiceAnswer {
          option
          answer
        }
        rankingAnswer {
          option
          rank
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const createInStoreOrder = /* GraphQL */ `
  mutation CreateInStoreOrder(
    $input: CreateInStoreOrderInput!
    $condition: ModelInStoreOrderConditionInput
  ) {
    createInStoreOrder(input: $input, condition: $condition) {
      inStoreOrderId
      orderDate
      orderNumber
      storeCode
      posTerminal
      grandTotal
      pointsCollected
      pointsRedeemed
      orderId
      customerId
      customerAccountNo
      transactionDateTime
      transactionNo
      receiptNo
      createdAt
      updatedAt
    }
  }
`
export const updateInStoreOrder = /* GraphQL */ `
  mutation UpdateInStoreOrder(
    $input: UpdateInStoreOrderInput!
    $condition: ModelInStoreOrderConditionInput
  ) {
    updateInStoreOrder(input: $input, condition: $condition) {
      inStoreOrderId
      orderDate
      orderNumber
      storeCode
      posTerminal
      grandTotal
      pointsCollected
      pointsRedeemed
      orderId
      customerId
      customerAccountNo
      transactionDateTime
      transactionNo
      receiptNo
      createdAt
      updatedAt
    }
  }
`
export const deleteInStoreOrder = /* GraphQL */ `
  mutation DeleteInStoreOrder(
    $input: DeleteInStoreOrderInput!
    $condition: ModelInStoreOrderConditionInput
  ) {
    deleteInStoreOrder(input: $input, condition: $condition) {
      inStoreOrderId
      orderDate
      orderNumber
      storeCode
      posTerminal
      grandTotal
      pointsCollected
      pointsRedeemed
      orderId
      customerId
      customerAccountNo
      transactionDateTime
      transactionNo
      receiptNo
      createdAt
      updatedAt
    }
  }
`
export const createInStoreOrderDetail = /* GraphQL */ `
  mutation CreateInStoreOrderDetail(
    $input: CreateInStoreOrderDetailInput!
    $condition: ModelInStoreOrderDetailConditionInput
  ) {
    createInStoreOrderDetail(input: $input, condition: $condition) {
      inStoreOrderDetailId
      customerAccountNo
      divisionCode
      createdAt
      updatedAt
      receiptNo
      transactionNo
      txnReceiptNo
      transactionDate
      storeCode
      posTerminal
      itemCode
      quantity
      pricePerQuantity
      subTotal
      pointEarned
      refundedQuantity
      lineNo
      refundedTransactionNo
      refundedPOSTerminal
      refundedStoreCode
    }
  }
`
export const updateInStoreOrderDetail = /* GraphQL */ `
  mutation UpdateInStoreOrderDetail(
    $input: UpdateInStoreOrderDetailInput!
    $condition: ModelInStoreOrderDetailConditionInput
  ) {
    updateInStoreOrderDetail(input: $input, condition: $condition) {
      inStoreOrderDetailId
      customerAccountNo
      divisionCode
      createdAt
      updatedAt
      receiptNo
      transactionNo
      txnReceiptNo
      transactionDate
      storeCode
      posTerminal
      itemCode
      quantity
      pricePerQuantity
      subTotal
      pointEarned
      refundedQuantity
      lineNo
      refundedTransactionNo
      refundedPOSTerminal
      refundedStoreCode
    }
  }
`
export const deleteInStoreOrderDetail = /* GraphQL */ `
  mutation DeleteInStoreOrderDetail(
    $input: DeleteInStoreOrderDetailInput!
    $condition: ModelInStoreOrderDetailConditionInput
  ) {
    deleteInStoreOrderDetail(input: $input, condition: $condition) {
      inStoreOrderDetailId
      customerAccountNo
      divisionCode
      createdAt
      updatedAt
      receiptNo
      transactionNo
      txnReceiptNo
      transactionDate
      storeCode
      posTerminal
      itemCode
      quantity
      pricePerQuantity
      subTotal
      pointEarned
      refundedQuantity
      lineNo
      refundedTransactionNo
      refundedPOSTerminal
      refundedStoreCode
    }
  }
`
export const createStamping = /* GraphQL */ `
  mutation CreateStamping(
    $input: CreateStampingInput!
    $condition: ModelStampingConditionInput
  ) {
    createStamping(input: $input, condition: $condition) {
      stampingId
      stampId
      startDate
      endDate
      image
      schemeGroup
      isEnabled
      stampTitle
      description
      stampType
      stampValue
      minTransactionAmount
      collectionPeriodType
      collectionPeriodValue
      benefitExpirationType
      benefitExpirationValue
      targetOnlineStore
      targetOfflineStore
      targetOnlineStoreList {
        storeGroupId
        code
      }
      targetOfflineStoreList {
        storeGroupId
        code
      }
      stampRepeat
      validationPeriodId
      voucher {
        voucherId
        voucherNumber
        voucherTitle
        milestone
      }
      stampPromotionLines {
        filterType
        filterCode
        title
        exclude
        numberOfStamp
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateStamping = /* GraphQL */ `
  mutation UpdateStamping(
    $input: UpdateStampingInput!
    $condition: ModelStampingConditionInput
  ) {
    updateStamping(input: $input, condition: $condition) {
      stampingId
      stampId
      startDate
      endDate
      image
      schemeGroup
      isEnabled
      stampTitle
      description
      stampType
      stampValue
      minTransactionAmount
      collectionPeriodType
      collectionPeriodValue
      benefitExpirationType
      benefitExpirationValue
      targetOnlineStore
      targetOfflineStore
      targetOnlineStoreList {
        storeGroupId
        code
      }
      targetOfflineStoreList {
        storeGroupId
        code
      }
      stampRepeat
      validationPeriodId
      voucher {
        voucherId
        voucherNumber
        voucherTitle
        milestone
      }
      stampPromotionLines {
        filterType
        filterCode
        title
        exclude
        numberOfStamp
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteStamping = /* GraphQL */ `
  mutation DeleteStamping(
    $input: DeleteStampingInput!
    $condition: ModelStampingConditionInput
  ) {
    deleteStamping(input: $input, condition: $condition) {
      stampingId
      stampId
      startDate
      endDate
      image
      schemeGroup
      isEnabled
      stampTitle
      description
      stampType
      stampValue
      minTransactionAmount
      collectionPeriodType
      collectionPeriodValue
      benefitExpirationType
      benefitExpirationValue
      targetOnlineStore
      targetOfflineStore
      targetOnlineStoreList {
        storeGroupId
        code
      }
      targetOfflineStoreList {
        storeGroupId
        code
      }
      stampRepeat
      validationPeriodId
      voucher {
        voucherId
        voucherNumber
        voucherTitle
        milestone
      }
      stampPromotionLines {
        filterType
        filterCode
        title
        exclude
        numberOfStamp
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createStampPromotion = /* GraphQL */ `
  mutation CreateStampPromotion(
    $input: CreateStampPromotionInput!
    $condition: ModelStampPromotionConditionInput
  ) {
    createStampPromotion(input: $input, condition: $condition) {
      stampPromotionId
      filterType
      filterCode
      numberOfStamp
      stampId
      validationId
      startDate
      endDate
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateStampPromotion = /* GraphQL */ `
  mutation UpdateStampPromotion(
    $input: UpdateStampPromotionInput!
    $condition: ModelStampPromotionConditionInput
  ) {
    updateStampPromotion(input: $input, condition: $condition) {
      stampPromotionId
      filterType
      filterCode
      numberOfStamp
      stampId
      validationId
      startDate
      endDate
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteStampPromotion = /* GraphQL */ `
  mutation DeleteStampPromotion(
    $input: DeleteStampPromotionInput!
    $condition: ModelStampPromotionConditionInput
  ) {
    deleteStampPromotion(input: $input, condition: $condition) {
      stampPromotionId
      filterType
      filterCode
      numberOfStamp
      stampId
      validationId
      startDate
      endDate
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createNotificationSetup = /* GraphQL */ `
  mutation CreateNotificationSetup(
    $input: CreateNotificationSetupInput!
    $condition: ModelNotificationSetupConditionInput
  ) {
    createNotificationSetup(input: $input, condition: $condition) {
      notificationSetupId
      notificationTitle
      pushNotificationMessage
      notificationType
      isEnabled
      entryType
      entryCode
      schedulerJobType
      expirationForecastType
      expirationForecastValue
      broadcastMessageSchedule
      showToNewUser
      targetAudience {
        method
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      bannerImage
      thumbnailImage
      actionType
      actionId
      scheduleTime
      pageLayout
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateNotificationSetup = /* GraphQL */ `
  mutation UpdateNotificationSetup(
    $input: UpdateNotificationSetupInput!
    $condition: ModelNotificationSetupConditionInput
  ) {
    updateNotificationSetup(input: $input, condition: $condition) {
      notificationSetupId
      notificationTitle
      pushNotificationMessage
      notificationType
      isEnabled
      entryType
      entryCode
      schedulerJobType
      expirationForecastType
      expirationForecastValue
      broadcastMessageSchedule
      showToNewUser
      targetAudience {
        method
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      bannerImage
      thumbnailImage
      actionType
      actionId
      scheduleTime
      pageLayout
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteNotificationSetup = /* GraphQL */ `
  mutation DeleteNotificationSetup(
    $input: DeleteNotificationSetupInput!
    $condition: ModelNotificationSetupConditionInput
  ) {
    deleteNotificationSetup(input: $input, condition: $condition) {
      notificationSetupId
      notificationTitle
      pushNotificationMessage
      notificationType
      isEnabled
      entryType
      entryCode
      schedulerJobType
      expirationForecastType
      expirationForecastValue
      broadcastMessageSchedule
      showToNewUser
      targetAudience {
        method
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      bannerImage
      thumbnailImage
      actionType
      actionId
      scheduleTime
      pageLayout
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createNavItemList = /* GraphQL */ `
  mutation CreateNavItemList(
    $input: CreateNavItemListInput!
    $condition: ModelNavItemListConditionInput
  ) {
    createNavItemList(input: $input, condition: $condition) {
      navItemListId
      sku
      description
      uom
      divisionCode
      itemCategoryCode
      productGroupCode
      createdAt
      updatedAt
    }
  }
`
export const updateNavItemList = /* GraphQL */ `
  mutation UpdateNavItemList(
    $input: UpdateNavItemListInput!
    $condition: ModelNavItemListConditionInput
  ) {
    updateNavItemList(input: $input, condition: $condition) {
      navItemListId
      sku
      description
      uom
      divisionCode
      itemCategoryCode
      productGroupCode
      createdAt
      updatedAt
    }
  }
`
export const deleteNavItemList = /* GraphQL */ `
  mutation DeleteNavItemList(
    $input: DeleteNavItemListInput!
    $condition: ModelNavItemListConditionInput
  ) {
    deleteNavItemList(input: $input, condition: $condition) {
      navItemListId
      sku
      description
      uom
      divisionCode
      itemCategoryCode
      productGroupCode
      createdAt
      updatedAt
    }
  }
`
export const createFaq = /* GraphQL */ `
  mutation CreateFaq(
    $input: CreateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    createFaq(input: $input, condition: $condition) {
      faqId
      faqCategory
      question
      parentId
      answer
      faqType
      sequence
      platform
      createdAt
      updatedAt
    }
  }
`
export const updateFaq = /* GraphQL */ `
  mutation UpdateFaq(
    $input: UpdateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    updateFaq(input: $input, condition: $condition) {
      faqId
      faqCategory
      question
      parentId
      answer
      faqType
      sequence
      platform
      createdAt
      updatedAt
    }
  }
`
export const deleteFaq = /* GraphQL */ `
  mutation DeleteFaq(
    $input: DeleteFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    deleteFaq(input: $input, condition: $condition) {
      faqId
      faqCategory
      question
      parentId
      answer
      faqType
      sequence
      platform
      createdAt
      updatedAt
    }
  }
`
export const createSupportStatement = /* GraphQL */ `
  mutation CreateSupportStatement(
    $input: CreateSupportStatementInput!
    $condition: ModelSupportStatementConditionInput
  ) {
    createSupportStatement(input: $input, condition: $condition) {
      statementId
      parentId
      statement
      messageToCustomer
      supportType
      statementType
      priority
      sequence
      platform
      senderView
      receiverView
      createdAt
      updatedAt
    }
  }
`
export const updateSupportStatement = /* GraphQL */ `
  mutation UpdateSupportStatement(
    $input: UpdateSupportStatementInput!
    $condition: ModelSupportStatementConditionInput
  ) {
    updateSupportStatement(input: $input, condition: $condition) {
      statementId
      parentId
      statement
      messageToCustomer
      supportType
      statementType
      priority
      sequence
      platform
      senderView
      receiverView
      createdAt
      updatedAt
    }
  }
`
export const deleteSupportStatement = /* GraphQL */ `
  mutation DeleteSupportStatement(
    $input: DeleteSupportStatementInput!
    $condition: ModelSupportStatementConditionInput
  ) {
    deleteSupportStatement(input: $input, condition: $condition) {
      statementId
      parentId
      statement
      messageToCustomer
      supportType
      statementType
      priority
      sequence
      platform
      senderView
      receiverView
      createdAt
      updatedAt
    }
  }
`
export const createUserSignInAttempt = /* GraphQL */ `
  mutation CreateUserSignInAttempt(
    $input: CreateUserSignInAttemptInput!
    $condition: ModelUserSignInAttemptConditionInput
  ) {
    createUserSignInAttempt(input: $input, condition: $condition) {
      id
      username
      userId
      attemptCount
      loginStatus
      loginTimeout
      lastLoginTime
      accessToken
      ipAddress
      deviceKey
      createdAt
      updatedAt
    }
  }
`
export const updateUserSignInAttempt = /* GraphQL */ `
  mutation UpdateUserSignInAttempt(
    $input: UpdateUserSignInAttemptInput!
    $condition: ModelUserSignInAttemptConditionInput
  ) {
    updateUserSignInAttempt(input: $input, condition: $condition) {
      id
      username
      userId
      attemptCount
      loginStatus
      loginTimeout
      lastLoginTime
      accessToken
      ipAddress
      deviceKey
      createdAt
      updatedAt
    }
  }
`
export const deleteUserSignInAttempt = /* GraphQL */ `
  mutation DeleteUserSignInAttempt(
    $input: DeleteUserSignInAttemptInput!
    $condition: ModelUserSignInAttemptConditionInput
  ) {
    deleteUserSignInAttempt(input: $input, condition: $condition) {
      id
      username
      userId
      attemptCount
      loginStatus
      loginTimeout
      lastLoginTime
      accessToken
      ipAddress
      deviceKey
      createdAt
      updatedAt
    }
  }
`
export const createDivision = /* GraphQL */ `
  mutation CreateDivision(
    $input: CreateDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    createDivision(input: $input, condition: $condition) {
      divisionId
      divisionCode
      divisionName
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateDivision = /* GraphQL */ `
  mutation UpdateDivision(
    $input: UpdateDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    updateDivision(input: $input, condition: $condition) {
      divisionId
      divisionCode
      divisionName
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteDivision = /* GraphQL */ `
  mutation DeleteDivision(
    $input: DeleteDivisionInput!
    $condition: ModelDivisionConditionInput
  ) {
    deleteDivision(input: $input, condition: $condition) {
      divisionId
      divisionCode
      divisionName
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createItemCategory = /* GraphQL */ `
  mutation CreateItemCategory(
    $input: CreateItemCategoryInput!
    $condition: ModelItemCategoryConditionInput
  ) {
    createItemCategory(input: $input, condition: $condition) {
      itemCategoryId
      itemCategoryCode
      itemCategoryName
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateItemCategory = /* GraphQL */ `
  mutation UpdateItemCategory(
    $input: UpdateItemCategoryInput!
    $condition: ModelItemCategoryConditionInput
  ) {
    updateItemCategory(input: $input, condition: $condition) {
      itemCategoryId
      itemCategoryCode
      itemCategoryName
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteItemCategory = /* GraphQL */ `
  mutation DeleteItemCategory(
    $input: DeleteItemCategoryInput!
    $condition: ModelItemCategoryConditionInput
  ) {
    deleteItemCategory(input: $input, condition: $condition) {
      itemCategoryId
      itemCategoryCode
      itemCategoryName
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const createProductGroup = /* GraphQL */ `
  mutation CreateProductGroup(
    $input: CreateProductGroupInput!
    $condition: ModelProductGroupConditionInput
  ) {
    createProductGroup(input: $input, condition: $condition) {
      productGroupId
      productGroupCode
      productGroupDescription
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const updateProductGroup = /* GraphQL */ `
  mutation UpdateProductGroup(
    $input: UpdateProductGroupInput!
    $condition: ModelProductGroupConditionInput
  ) {
    updateProductGroup(input: $input, condition: $condition) {
      productGroupId
      productGroupCode
      productGroupDescription
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteProductGroup = /* GraphQL */ `
  mutation DeleteProductGroup(
    $input: DeleteProductGroupInput!
    $condition: ModelProductGroupConditionInput
  ) {
    deleteProductGroup(input: $input, condition: $condition) {
      productGroupId
      productGroupCode
      productGroupDescription
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteFutureNotification = /* GraphQL */ `
  mutation DeleteFutureNotification($notificationId: String!) {
    deleteFutureNotification(notificationId: $notificationId) {
      status
      statusCode
    }
  }
`
export const createNavItemExclusion = /* GraphQL */ `
  mutation CreateNavItemExclusion(
    $input: CreateNavItemExclusionInput!
    $condition: ModelNavItemExclusionConditionInput
  ) {
    createNavItemExclusion(input: $input, condition: $condition) {
      navItemExclusionId
      excludeFrom
      itemExclusionId
      itemExclusionProductCondition
      itemExclusionCode
      itemExclusionTitle
      itemExclusionItemCategoryDesc
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`
export const updateNavItemExclusion = /* GraphQL */ `
  mutation UpdateNavItemExclusion(
    $input: UpdateNavItemExclusionInput!
    $condition: ModelNavItemExclusionConditionInput
  ) {
    updateNavItemExclusion(input: $input, condition: $condition) {
      navItemExclusionId
      excludeFrom
      itemExclusionId
      itemExclusionProductCondition
      itemExclusionCode
      itemExclusionTitle
      itemExclusionItemCategoryDesc
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`
export const deleteNavItemExclusion = /* GraphQL */ `
  mutation DeleteNavItemExclusion(
    $input: DeleteNavItemExclusionInput!
    $condition: ModelNavItemExclusionConditionInput
  ) {
    deleteNavItemExclusion(input: $input, condition: $condition) {
      navItemExclusionId
      excludeFrom
      itemExclusionId
      itemExclusionProductCondition
      itemExclusionCode
      itemExclusionTitle
      itemExclusionItemCategoryDesc
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`
export const patchOrderStatus = /* GraphQL */ `
  mutation PatchOrderStatus($orderId: String, $orderCompleteDateTime: String) {
    patchOrderStatus(
      orderId: $orderId
      orderCompleteDateTime: $orderCompleteDateTime
    ) {
      statusCode
      status
      message
    }
  }
`
export const duplicateNotificationSetup = /* GraphQL */ `
  mutation DuplicateNotificationSetup($notificationSetupId: String) {
    duplicateNotificationSetup(notificationSetupId: $notificationSetupId) {
      statusCode
      status
      message
      notificationSetupId
    }
  }
`
export const triggerDownloadPointHistoryReport = /* GraphQL */ `
  mutation TriggerDownloadPointHistoryReport(
    $filter: ModelPointsHistoryFilterInput
    $sort: ElasticSearchSortDirection
  ) {
    triggerDownloadPointHistoryReport(filter: $filter, sort: $sort) {
      downloadJobId
      statusCode
      message
    }
  }
`
export const triggerDownloadPromoCodeCampaignReport = /* GraphQL */ `
  mutation TriggerDownloadPromoCodeCampaignReport(
    $keyword: String
    $status: String
    $sort: ElasticSearchSortDirection
  ) {
    triggerDownloadPromoCodeCampaignReport(
      keyword: $keyword
      status: $status
      sort: $sort
    ) {
      downloadJobId
      statusCode
      message
    }
  }
`
export const triggerDownloadVoucherHistoryReport = /* GraphQL */ `
  mutation TriggerDownloadVoucherHistoryReport(
    $filter: ModelVoucherHistoryFilterInput
    $sort: ElasticSearchSortDirection
  ) {
    triggerDownloadVoucherHistoryReport(filter: $filter, sort: $sort) {
      downloadJobId
      statusCode
      message
    }
  }
`
export const tNGCheckMember = /* GraphQL */ `
  mutation TNGCheckMember($authCode: String, $email: String) {
    tNGCheckMember(authCode: $authCode, email: $email) {
      Status
      Message
      Token
      SignedToken
      ExpiredAt
      sessionID
      isFreeItem
      promoDiscount
      otpRequired
      firstUserLogin
      redirectionLink
    }
  }
`
export const triggerDownloadVoucherOrderReport = /* GraphQL */ `
  mutation TriggerDownloadVoucherOrderReport(
    $searchField: String
    $searchKeyword: String
    $dateFrom: String
    $dateTo: String
    $sort: ElasticSearchSortDirection
  ) {
    triggerDownloadVoucherOrderReport(
      searchField: $searchField
      searchKeyword: $searchKeyword
      dateFrom: $dateFrom
      dateTo: $dateTo
      sort: $sort
    ) {
      downloadJobId
      statusCode
      message
    }
  }
`
export const triggerDownloadOrderReport = /* GraphQL */ `
  mutation TriggerDownloadOrderReport(
    $searchField: String
    $searchKeyword: String
    $dateField: String
    $dateFrom: String
    $dateTo: String
    $sort: ElasticSearchSortDirection
  ) {
    triggerDownloadOrderReport(
      searchField: $searchField
      searchKeyword: $searchKeyword
      dateField: $dateField
      dateFrom: $dateFrom
      dateTo: $dateTo
      sort: $sort
    ) {
      downloadJobId
      statusCode
      message
    }
  }
`
export const triggerDownloadEcommerceOrderReport = /* GraphQL */ `
  mutation TriggerDownloadEcommerceOrderReport(
    $searchKeyword: String
    $dateFrom: String
    $dateTo: String
    $sort: ElasticSearchSortDirection
  ) {
    triggerDownloadEcommerceOrderReport(
      searchKeyword: $searchKeyword
      dateFrom: $dateFrom
      dateTo: $dateTo
      sort: $sort
    ) {
      downloadJobId
      statusCode
      message
    }
  }
`
export const generateAdminPortalS3UploadLink = /* GraphQL */ `
  mutation GenerateAdminPortalS3UploadLink($fileName: String) {
    generateAdminPortalS3UploadLink(fileName: $fileName)
  }
`
export const generateAdminPortalS3DownloadLink = /* GraphQL */ `
  mutation GenerateAdminPortalS3DownloadLink($fileName: String) {
    generateAdminPortalS3DownloadLink(fileName: $fileName)
  }
`
export const createStampingCampaign = /* GraphQL */ `
  mutation CreateStampingCampaign(
    $stampingCampaignTitle: String
    $stampingCampaignCode: String
    $stampingCampaignDescription: String
    $platform: [String]
    $orderType: [String]
    $schemeCode: String
    $isEnable: Boolean
    $stampingCampaignListingImage: String
    $stampingCampaignImage: String
    $startDate: String
    $endDate: String
    $stampingCampaignType: String
    $minSpend: String
    $storeGroup: String
    $storeGroupCode: String
    $rewardVoucher: [StampingRewardVoucherInput]
    $stampingCampaignCycle: Int
    $stampingCampaignCycleDurationType: String
    $stampingCampaignCycleDurationValue: String
    $voucherExpiryDateType: String
    $voucherExpiryDateValue: String
    $stampingMilestone: Int
    $productCondition: [StampingProductConditionInput]
    $extraProductCondition: [ExtraStampingProductConditionInput]
    $extraProductStamp: Int
    $stampingReminder: [StampingReminderInput]
    $modifiedBy: String
  ) {
    createStampingCampaign(
      stampingCampaignTitle: $stampingCampaignTitle
      stampingCampaignCode: $stampingCampaignCode
      stampingCampaignDescription: $stampingCampaignDescription
      platform: $platform
      orderType: $orderType
      schemeCode: $schemeCode
      isEnable: $isEnable
      stampingCampaignListingImage: $stampingCampaignListingImage
      stampingCampaignImage: $stampingCampaignImage
      startDate: $startDate
      endDate: $endDate
      stampingCampaignType: $stampingCampaignType
      minSpend: $minSpend
      storeGroup: $storeGroup
      storeGroupCode: $storeGroupCode
      rewardVoucher: $rewardVoucher
      stampingCampaignCycle: $stampingCampaignCycle
      stampingCampaignCycleDurationType: $stampingCampaignCycleDurationType
      stampingCampaignCycleDurationValue: $stampingCampaignCycleDurationValue
      voucherExpiryDateType: $voucherExpiryDateType
      voucherExpiryDateValue: $voucherExpiryDateValue
      stampingMilestone: $stampingMilestone
      productCondition: $productCondition
      extraProductCondition: $extraProductCondition
      extraProductStamp: $extraProductStamp
      stampingReminder: $stampingReminder
      modifiedBy: $modifiedBy
    ) {
      statusCode
      message
      stampingCampaignId
    }
  }
`
export const updateStampingCampaign = /* GraphQL */ `
  mutation UpdateStampingCampaign(
    $stampingCampaignId: String
    $stampingCampaignTitle: String
    $stampingCampaignCode: String
    $stampingCampaignDescription: String
    $platform: [String]
    $orderType: [String]
    $schemeCode: String
    $isEnable: Boolean
    $stampingCampaignListingImage: String
    $stampingCampaignImage: String
    $startDate: String
    $endDate: String
    $stampingCampaignType: String
    $minSpend: String
    $storeGroup: String
    $storeGroupCode: String
    $rewardVoucher: [StampingRewardVoucherInput]
    $stampingCampaignCycle: Int
    $stampingCampaignCycleDurationType: String
    $stampingCampaignCycleDurationValue: String
    $voucherExpiryDateType: String
    $voucherExpiryDateValue: String
    $stampingMilestone: Int
    $productCondition: [StampingProductConditionInput]
    $extraProductCondition: [ExtraStampingProductConditionInput]
    $extraProductStamp: Int
    $stampingReminder: [StampingReminderInput]
    $modifiedBy: String
  ) {
    updateStampingCampaign(
      stampingCampaignId: $stampingCampaignId
      stampingCampaignTitle: $stampingCampaignTitle
      stampingCampaignCode: $stampingCampaignCode
      stampingCampaignDescription: $stampingCampaignDescription
      platform: $platform
      orderType: $orderType
      schemeCode: $schemeCode
      isEnable: $isEnable
      stampingCampaignListingImage: $stampingCampaignListingImage
      stampingCampaignImage: $stampingCampaignImage
      startDate: $startDate
      endDate: $endDate
      stampingCampaignType: $stampingCampaignType
      minSpend: $minSpend
      storeGroup: $storeGroup
      storeGroupCode: $storeGroupCode
      rewardVoucher: $rewardVoucher
      stampingCampaignCycle: $stampingCampaignCycle
      stampingCampaignCycleDurationType: $stampingCampaignCycleDurationType
      stampingCampaignCycleDurationValue: $stampingCampaignCycleDurationValue
      voucherExpiryDateType: $voucherExpiryDateType
      voucherExpiryDateValue: $voucherExpiryDateValue
      stampingMilestone: $stampingMilestone
      productCondition: $productCondition
      extraProductCondition: $extraProductCondition
      extraProductStamp: $extraProductStamp
      stampingReminder: $stampingReminder
      modifiedBy: $modifiedBy
    ) {
      statusCode
      message
      stampingCampaignId
    }
  }
`
export const deleteStampingCampaign = /* GraphQL */ `
  mutation DeleteStampingCampaign($stampingCampaignId: String) {
    deleteStampingCampaign(stampingCampaignId: $stampingCampaignId) {
      statusCode
      message
    }
  }
`
export const adminUpdateUserStamp = /* GraphQL */ `
  mutation AdminUpdateUserStamp(
    $action: String
    $memberId: String
    $stampingCode: String
    $receiptNo: String
    $stampValue: Int
    $dateIssue: String
    $createdBy: String
  ) {
    adminUpdateUserStamp(
      action: $action
      memberId: $memberId
      stampingCode: $stampingCode
      receiptNo: $receiptNo
      stampValue: $stampValue
      dateIssue: $dateIssue
      createdBy: $createdBy
    ) {
      statusCode
      message
    }
  }
`
export const redeemVoucherByStamp = /* GraphQL */ `
  mutation RedeemVoucherByStamp(
    $token: String
    $platform: String
    $stampingCampaignId: String
    $userStampingProgressId: String
    $voucherId: String
  ) {
    redeemVoucherByStamp(
      token: $token
      platform: $platform
      stampingCampaignId: $stampingCampaignId
      userStampingProgressId: $userStampingProgressId
      voucherId: $voucherId
    ) {
      statusCode
      message
    }
  }
`
export const triggerDownloadStampingHistoryReport = /* GraphQL */ `
  mutation TriggerDownloadStampingHistoryReport(
    $filter: SearchableStampingCampaignLogFilterInput
    $sort: ElasticSearchSortDirection
  ) {
    triggerDownloadStampingHistoryReport(filter: $filter, sort: $sort) {
      downloadJobId
      statusCode
      message
    }
  }
`
export const downloadOrderSyncHeaderListingReport = /* GraphQL */ `
  mutation DownloadOrderSyncHeaderListingReport(
    $filter: SearchableOrderSyncHeaderFilterInput
    $sort: ElasticSearchSortDirection
  ) {
    downloadOrderSyncHeaderListingReport(filter: $filter, sort: $sort) {
      downloadJobId
      statusCode
      message
    }
  }
`
export const downloadOrderSyncDetailsListingReport = /* GraphQL */ `
  mutation DownloadOrderSyncDetailsListingReport(
    $filter: SearchableOrderSyncDetailsFilterInput
    $sort: ElasticSearchSortDirection
  ) {
    downloadOrderSyncDetailsListingReport(filter: $filter, sort: $sort) {
      downloadJobId
      statusCode
      message
    }
  }
`
export const downloadDeliveryPartnerOrderReport = /* GraphQL */ `
  mutation DownloadDeliveryPartnerOrderReport(
    $deliveryPartner: String
    $filter: SearchableDeliveryPartnerOrderFilterInput
    $sort: ElasticSearchSortDirection
  ) {
    downloadDeliveryPartnerOrderReport(
      deliveryPartner: $deliveryPartner
      filter: $filter
      sort: $sort
    ) {
      downloadJobId
      statusCode
      message
    }
  }
`
export const downloadDeliveryPartnerOrderDetailReport = /* GraphQL */ `
  mutation DownloadDeliveryPartnerOrderDetailReport(
    $deliveryPartner: String
    $filter: SearchableDeliveryPartnerOrderDetailFilterInput
    $sort: ElasticSearchSortDirection
  ) {
    downloadDeliveryPartnerOrderDetailReport(
      deliveryPartner: $deliveryPartner
      filter: $filter
      sort: $sort
    ) {
      downloadJobId
      statusCode
      message
    }
  }
`
