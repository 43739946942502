import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export default function Next(props) {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
      />
    </SvgIcon>
  )
}
