import { stubFalse } from 'lodash'
import Resizer from 'react-image-file-resizer'
import { API, graphqlOperation } from 'aws-amplify'
import { generateS3UploadLink } from 'src/graphql/mutations'

export const getToken = () => {
  return global.jwtToken
    ? global.jwtToken
    : sessionStorage.getItem('token') !== null &&
      sessionStorage.getItem('token') !== 'TOKEN_HERE'
    ? sessionStorage.getItem('token')
    : null
}
export const handleTngRes = (tngRes) => {
  if (tngRes && (tngRes.statusCode === '200' || tngRes.statusCode === 200)) {
    return true
  } else {
    return false
  }
}
export const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',')
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i)
  return new Blob([ia], { type: mimeString })
}
export const getS3Link = async (count, path, isTngMp, images, setNotify) => {
  let token = getToken()
  let fileName = `image${count}.jpg`
  let params = {
    token: token,
    folderName: path,
    fileName: fileName,
    // platform: sessionStorage.getItem('platform')
    platform: 'ecommerce'
  }
  let newImage
  if (isTngMp) {
    params['platform'] = 'tngmp'
    newImage = images[count]
  } else {
    const compressedImage = await resizeFile(images[count])
    newImage = dataURIToBlob(compressedImage)
  }

  const response = await API.graphql(
    graphqlOperation(generateS3UploadLink, params)
  )

  try {
    const uploadResponse = await uploadImgToS3(
      response.data.generateS3UploadLink,
      newImage,
      isTngMp,
      setNotify,
      handleTngRes
    )
    const generatedPath = JSON.parse(response.data.generateS3UploadLink)
    if (isTngMp) {
      localStorage.removeItem('currentUploadStatus')
    }

    if (!isTngMp) {
      if (uploadResponse.status == 204) {
        return { status: true, generatedPath: generatedPath.fields.key }
      } else if (uploadResponse.status == 400) {
        return { status: false, generatedPath: null }
      }
    } else {
      return { status: uploadResponse, generatedPath: generatedPath.fields.key }
    }
  } catch (error) {
    console.log(error)
    return { status: false, generatedPath: null }
  }
}

export const uploadImgToS3 = (
  signedUrl,
  image,
  isTngMp = false,
  setNotify,
  handleTngRes
) => {
  if (!isTngMp) {
    let form = new FormData()
    const newData = JSON.parse(signedUrl)
    Object.keys(newData.fields).forEach((key) =>
      form.append(key, newData.fields[key])
    )
    form.append('file', image)
    return fetch(newData.url, { method: 'POST', body: form })
  } else {
    try {
      // eslint-disable-next-line no-undef
      my.postMessage({
        action: 'uploadFile',
        body: { signedUrl, filePath: image }
      })
      // eslint-disable-next-line no-undef
      my.onMessage = function (e) {
        const response = JSON.parse(e.body)
        let result = handleTngRes(response)

        localStorage.setItem('currentUploadStatus', result)
        let uploadResult = localStorage.getItem('currentUploadStatus')

        if (!uploadResult) {
          throw 'uploadError'
        }
      }

      return true
    } catch (err) {
      console.log(err)
      if (err === 'uploadError') {
        setNotify({
          show: true,
          severity: 'error',
          message: 'Failed to upload image, please try again later.'
        })
      } else {
        setNotify({
          show: true,
          severity: 'error',
          message: 'Failed! Something went wrong, please try again later.'
        })
      }
      return false
    }
  }
}
export const getS3BucketURL = () => {
  if (process.env.REACT_APP_ENV === 'dev') {
    return 'https://d18jz5vfkg70br.cloudfront.net/public/'
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://d102dno33o9pa9.cloudfront.net/public/'
  } else if (process.env.REACT_APP_ENV === 'uattwo') {
    return 'https://d1t8fk86lxxaeg.cloudfront.net/public/'
  } else {
    return 'https://d1ik50pimaryk.cloudfront.net/public/'
  }
}
export const resizeFile = async (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      600,
      'JPEG',
      90,
      0,
      (uri) => {
        resolve(uri)
      },
      'base64'
    )
  })
}
