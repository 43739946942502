import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  Fab,
  Box,
  IconButton,
  useMediaQuery
} from '@material-ui/core'
import { Button, TextFieldInput } from 'src/components'
import { useTheme } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import classes from './CustomerSentimentDialog.module.scss'
import unselectedSentiment1 from 'src/assets/sentimentIcons/unselected/unselect-sentiment1.png'
import unselectedSentiment2 from 'src/assets/sentimentIcons/unselected/unselect-sentiment2.png'
import unselectedSentiment3 from 'src/assets/sentimentIcons/unselected/unselect-sentiment3.png'
import unselectedSentiment4 from 'src/assets/sentimentIcons/unselected/unselect-sentiment4.png'
import selectedSentiment1 from 'src/assets/sentimentIcons/selected/sentiment1.png'
import selectedSentiment2 from 'src/assets/sentimentIcons/selected/sentiment2.png'
import selectedSentiment3 from 'src/assets/sentimentIcons/selected/sentiment3.png'
import selectedSentiment4 from 'src/assets/sentimentIcons/selected/sentiment4.png'
import submitCustomerSentimentForm from 'src/apis/submitCustomerSentimentForm'
import fmLogo from 'src/assets/images/FamilyMartLogo.png'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function CustomerSentimentDialog() {
  const theme = useTheme()
  const query = useQuery()
  const isBetweenXsAndSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  // const isBetweenSmAndMd = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isSmAndUp = useMediaQuery(theme.breakpoints.up('sm'))
  const [sentimentDialogDisplay, setSentimentDialogDisplay] = useState(true)
  const [messageDialogContent, setMessageDialogContent] = useState({
    display: false,
    status: null,
    message: ''
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [sentimentLevel, setSentimentLevel] = useState([
    {
      unselected: unselectedSentiment1,
      selected: selectedSentiment1,
      chosen: false,
      value: 1
    },
    {
      unselected: unselectedSentiment2,
      selected: selectedSentiment2,
      chosen: false,
      value: 2
    },
    {
      unselected: unselectedSentiment3,
      selected: selectedSentiment3,
      chosen: false,
      value: 3
    },
    {
      unselected: unselectedSentiment4,
      selected: selectedSentiment4,
      chosen: false,
      value: 4
    }
  ])
  // to be continue for state and upload
  const [formState, setFormState] = useState({
    rating: null,
    additionalFeedback: '',
    issueId: sessionStorage.getItem('issueId'),
    token: sessionStorage.getItem('token')
  })

  const handleOnClickSentimentIcon = (index) => {
    let tmpArray = [...sentimentLevel]
    let newArray = tmpArray.map((item, idx) => {
      if (idx === index) {
        return { ...item, chosen: !item.chosen }
      } else {
        return { ...item, chosen: false }
      }
    })
    let filteredArray = newArray.filter((item) => item.chosen)
    if (filteredArray.length > 0) {
      setFormState({ ...formState, rating: filteredArray[0].value })
    } else {
      setFormState({ ...formState, rating: null })
    }
    setSentimentLevel(newArray)
  }

  const responsiveIconWidthAndHeight = () => {
    switch (true) {
      case isSmAndUp:
        return '62'
      // case isBetweenSmAndMd:
      //   return '60'
      case isBetweenXsAndSm:
        return '40'
      default:
        return '35'
    }
  }

  const handleOnSubmitSentimentForm = async () => {
    const phoneNo = query.get('phoneNumber')
    const externalIssueId = query.get('issueId')
    const platform = query.get('platform')

    setIsSubmitting(true)

    const { token, issueId, ...formStateProps } = formState

    const response = await submitCustomerSentimentForm({
      ...formStateProps,
      issueId: externalIssueId ? externalIssueId : issueId,
      ...(!phoneNo && { token: token }),
      ...(phoneNo && { phoneNumber: phoneNo }),
      ...(platform && { platform: platform })
    })

    if (response.status) {
      setSentimentDialogDisplay(false)
    }
    setMessageDialogContent({ display: true, ...response })
    setIsSubmitting(false)
  }

  return (
    <>
      {formState.token === 'TOKEN_HERE' && (
        <div
          style={{
            backgroundColor: 'white',
            height: '100vh'
          }}>
          <Box
            display="flex"
            flexDirection="column"
            // maxWidth={550}
            marginX={'20px'}>
            <Box marginTop={2} width={100} height={30} marginX={'auto'}>
              <img
                src={fmLogo}
                width={'100%'}
                height={'100%'}
                style={{
                  objectFit: 'contain'
                }}
                alt="fm-logo"
              />
            </Box>
            <h3
              id="responsive-dialog-title"
              className={classes['dialog-title']}>
              We Want to Hear from You
            </h3>
            <p className={classes['dialog-subtitle']}>
              {
                'Thank you for contacting our Help Center! How would you rate the overall chat experience?'
              }
            </p>
            <div className={classes['dialog-icons']}>
              {sentimentLevel.map((item, index) => {
                return (
                  <IconButton
                    key={`${item.value}`}
                    // style={{ paddingLeft: 0, paddingRight: 0 }}
                    onClick={() => {
                      handleOnClickSentimentIcon(index)
                    }}>
                    <img
                      src={item.chosen ? item.selected : item.unselected}
                      width={responsiveIconWidthAndHeight()}
                      height={responsiveIconWidthAndHeight()}
                    />
                  </IconButton>
                )
              })}
            </div>
            <div className={classes['dialog-additional-feedback']}>
              <p style={{ textAlign: 'left' }}>Additional Feedback (If Any)</p>
            </div>

            <TextFieldInput
              placeholder={
                'Leave some feedback to let us know how we are doing'
              }
              multiline={true}
              boxStyles={{
                width: '100%'
              }}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  additionalFeedback: e.target.value
                })
              }
            />

            <div className={classes['dialog-submit']}>
              <Button
                title={'Submit'}
                disabled={formState.rating === null || isSubmitting}
                loading={isSubmitting}
                handleClick={handleOnSubmitSentimentForm}
              />
            </div>
          </Box>
        </div>
      )}
      {formState.token !== 'TOKEN_HERE' && (
        <>
          <Dialog
            PaperProps={{
              style: { borderRadius: 10 }
            }}
            open={sentimentDialogDisplay}
            aria-labelledby="responsive-dialog-title">
            <h3
              id="responsive-dialog-title"
              className={classes['dialog-title']}>
              We Want to Hear from You
            </h3>
            <DialogContent>
              <p className={classes['dialog-subtitle']}>
                {
                  'Thank you for contacting our Help Center! How would you rate the overall chat experience?'
                }
              </p>
              <div className={classes['dialog-icons']}>
                {sentimentLevel.map((item, index) => {
                  return (
                    <IconButton
                      key={index}
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      onClick={() => {
                        handleOnClickSentimentIcon(index)
                      }}>
                      <img
                        src={item.chosen ? item.selected : item.unselected}
                        width={responsiveIconWidthAndHeight()}
                        height={responsiveIconWidthAndHeight()}
                      />
                    </IconButton>
                  )
                })}
              </div>
              <div className={classes['dialog-additional-feedback']}>
                <p>Additional Feedback (If Any)</p>
              </div>
              <TextFieldInput
                placeholder={
                  'Leave some feedback to let us know how we are doing'
                }
                multiline={true}
                boxStyles={{}}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    additionalFeedback: e.target.value
                  })
                }
              />
              <div className={classes['dialog-submit']}>
                <Button
                  title={'Submit'}
                  disabled={formState.rating === null || isSubmitting}
                  loading={isSubmitting}
                  handleClick={handleOnSubmitSentimentForm}
                />
              </div>
            </DialogContent>
          </Dialog>

          <Fab
            onClick={() => setSentimentDialogDisplay(false)}
            color="default"
            aria-label="add"
            style={{
              zIndex: 10000,
              position: 'absolute',
              bottom: '10px',
              right: '0px',
              left: '0px',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: !sentimentDialogDisplay && 'none'
            }}>
            <CloseIcon color="primary" />
          </Fab>
        </>
      )}
      <DialogMessageComponent
        content={messageDialogContent}
        setContent={setMessageDialogContent}
      />
    </>
  )
}

const DialogMessageComponent = ({ content, setContent }) => {
  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 10 }
      }}
      // fullWidth={true}
      open={content.display}
      aria-labelledby="responsive-dialog-title">
      <DialogContent>
        <p style={{ textAlign: 'center' }}>{content.message}</p>

        <div className={classes['dialog-submit']}>
          <Button
            title={'Done'}
            handleClick={() => {
              setContent({ ...content, display: false })
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
