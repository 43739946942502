import React, { useState, useCallback, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { userGetProfile } from 'src/graphql/queries'
import { requestMemberAccountModification } from 'src/graphql/mutations'
import { getS3Link, getToken } from 'src/utils/helper'
import uuid from 'uuid'
import _ from 'lodash'

const useRequestActionLogic = (type = null, isTngMp, history) => {
  const [part1Value, setPart1Value] = useState({
    fullName: '',
    registeredName: '',
    mobileNumber: '',
    accountNo: '',
    emailAddress: '',
    kycImage: [{}]
  })
  const [part2Value, setPart2Value] = useState('')
  const [part3Value, setPart3Value] = useState('')
  const [consent1Value, setConsent1Value] = useState(false)
  const [consent2Value, setConsent2Value] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [notify, setNotify] = useState({
    show: false,
    severity: '',
    message: ''
  })

  const validationMap = new Map([
    [
      'deactivate',
      {
        part1: {
          object: [
            {
              errorMessage: 'Please enter your full name as per IC',
              valid: part1Value.fullName.trim() !== ''
            }
          ]
        },
        part2: {
          valid: part2Value.trim() !== '',
          errorMessage: 'Please enter description for part 2'
        },
        part3: {
          valid: part3Value.trim() !== '',
          errorMessage: 'Please enter description for part 3'
        },
        consent1: {
          valid: consent1Value,
          errorMessage: 'Kindly tick both of the checkbox to proceed.'
        },
        consent2: {
          valid: consent2Value,
          errorMessage: 'Kindly tick both of the checkbox to proceed.'
        }
      }
    ],
    [
      'edit',
      {
        part1: {
          object: [
            {
              errorMessage: 'Please enter your full name as per IC',
              valid: part1Value.fullName.trim() !== ''
            },
            {
              errorMessage: 'Please attach your NRIC/Passport image copy',
              valid:
                part1Value?.kycImage?.length > 0 &&
                !_.isEmpty(part1Value?.kycImage[0])
            }
          ]
        },
        part2: {
          valid: part2Value.trim() !== '',
          errorMessage: 'Please enter description for part 2'
        },
        consent1: {
          valid: consent1Value,
          errorMessage: 'Kindly tick the checkbox to proceed.'
        }
      }
    ]
  ])
  const handleOnSubmit = async () => {
    setIsSaving(true)
    delete part1Value.registeredName

    const validationCheckPart1Response = validationMap
      .get(type)
      .part1.object.find((item) => !item.valid)
    const validationCheckPart2Response = validationMap.get(type).part2
    const validationCheckPart3Response = validationMap.get(type)?.part3
    const validationCheckConsent1Response = validationMap.get(type).consent1
    const validationCheckConsent2Response = validationMap.get(type)?.consent2

    if (validationCheckPart1Response?.valid === false) {
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: validationCheckPart1Response.errorMessage
      })
    } else if (validationCheckPart2Response?.valid === false) {
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: validationCheckPart2Response.errorMessage
      })
    } else if (
      validationCheckPart3Response?.valid === false &&
      type === 'deactivate'
    ) {
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: validationCheckPart3Response?.errorMessage
      })
    } else if (validationCheckConsent1Response?.valid === false) {
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: validationCheckConsent1Response?.errorMessage
      })
    } else if (
      validationCheckConsent2Response?.valid === false &&
      type === 'deactivate'
    ) {
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: validationCheckConsent2Response?.errorMessage
      })
    }

    try {
      if (type === 'edit') {
        let imagepath = `request-action/${uuid.v4()}`
        let uploadResponse = await getS3Link(
          0,
          imagepath,
          isTngMp,
          part1Value.kycImage,
          setNotify
        )
        if (!isTngMp && !uploadResponse.status) {
          setNotify({
            show: true,
            severity: 'error',
            message: 'Failed! Image size exceeds 15 MB.'
          })
          setIsSaving(false)
          return
        } else if (isTngMp && uploadResponse.status !== true) {
          return setIsSaving(false)
        }
        part1Value.kycImage = uploadResponse.generatedPath
      } else if (type === 'deactivate') {
        part1Value.kycImage = null
      }

      let res = await API.graphql(
        graphqlOperation(requestMemberAccountModification, {
          token: getToken(),
          dataSubjectPart1: part1Value,
          dataSubjectPart2: part2Value,
          dataSubjectPart3: part3Value,
          consentValue1: consent1Value,
          consentValue2: consent2Value,
          platform:
            sessionStorage.getItem('platform') === 'tngmp'
              ? 'tng'
              : 'ecommerce',
          type: type
        })
      )
      let responseObj = res.data.requestMemberAccountModification
      setNotify({
        show: true,
        severity: responseObj.statusCode === 200 ? 'success' : 'error',
        message: responseObj.message
      })
      return setTimeout(() => {
        history.push('/')
      }, 3000)
    } catch (err) {
      console.log('err', err)
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: 'Failed! Something went wrong, please try again later.'
      })
    }
  }

  const getMemberProfile = async () => {
    try {
      let res = await API.graphql(
        graphqlOperation(userGetProfile, {
          token: getToken()
        })
      )
      let responseObj = res.data.userGetProfile
      if (responseObj.statusCode === 200) {
        setPart1Value({
          ...part1Value,
          registeredName: `${responseObj.FirstName} ${responseObj.LastName}`,
          mobileNumber: responseObj.MobileNumber,
          accountNo: responseObj.AccountNo,
          emailAddress: responseObj.PrimaryEmail
        })
      }
    } catch (err) {
      console.log('err', err)
      setIsSaving(false)
      return setNotify({
        show: true,
        severity: 'error',
        message: 'Failed! Something went wrong, please try again later.'
      })
    }
  }

  const removePreviewImg = () => {
    setPart1Value({ ...part1Value, kycImg: [{}] })
  }

  useEffect(() => {
    getMemberProfile()
  }, [])

  return {
    part1Value,
    setPart1Value,
    part2Value,
    setPart2Value,
    part3Value,
    setPart3Value,
    consent1Value,
    setConsent1Value,
    consent2Value,
    setConsent2Value,
    handleOnSubmit,
    removePreviewImg,
    isSaving,
    notify,
    setNotify
  }
}

export default useRequestActionLogic
