import React, { useState } from 'react'
import Forms from 'src/components/Forms/Forms'
import {
  Box,
  Typography,
  makeStyles,
  Link,
  withStyles
} from '@material-ui/core'
import { Paper, BackButton, Notification } from 'src/components'
import useRequestActionLogic from './hooks/useRequestActionLogic'

const useStyles = makeStyles(({ palette, spacing }) => ({
  backButton: {
    color: palette.primary.main
  }
}))

const RequestAction = (props) => {
  const { location, history, type } = props
  const [isTngMp, setIsTngMp] = useState(
    sessionStorage.getItem('platform') === 'tngmp' ? true : false
  )
  const {
    part1Value,
    setPart1Value,
    part1ImgValue,
    setPart1ImgValue,
    part2Value,
    setPart2Value,
    part3Value,
    setPart3Value,
    consent1Value,
    setConsent1Value,
    consent2Value,
    setConsent2Value,
    isSaving,
    handleOnSubmit,
    removePreviewImg,
    notify,
    setNotify
  } = useRequestActionLogic(type, isTngMp, history)
  const moduleTypeAttributes = new Map([
    [
      'deactivate',
      {
        header: 'PERSONAL DATA DELETION REQUEST FORM:',
        description: `For the purpose of this form, Data Subject (as defined under the Personal Data Protection Act 2010) must provide any relevant supporting document as required by us. Please note that, we may not be able to process your request in the event of the personal data provided is inaccurate, incomplete, misleading or not up to date in the first place. 
        A request to delete personal data is subject to the requirements under Personal Data Protection Act 2010.

        If you still would like to delete your membership, please fill up the details below:
        `,
        part1: {
          title: 'PART 1: DETAILS OF DATA SUBJECT',
          object: [
            {
              title: 'FULL NAME as per NRIC / Passport:',
              type: 'text',
              value: part1Value.fullName,
              onChange: (e) =>
                setPart1Value({ ...part1Value, fullName: e.target.value }),
              disabled: false
            },
            {
              title: 'NAME as per Registered in Mobile App:',
              type: 'text',
              value: part1Value.registeredName,
              disabled: true
            },
            {
              title: 'Member ID:',
              type: 'text',
              value: part1Value.accountNo,
              disabled: true
            },
            {
              title: 'Mobile Phone Number as per Registered in Mobile App:',
              type: 'text',
              value: part1Value.mobileNumber,
              disabled: true
            },
            {
              title: 'Email Address as per Registered in Mobile App:',
              type: 'text',
              value: part1Value.emailAddress,
              disabled: true
            }
          ]
        },
        part2: {
          title: "PART 2: DELETION OF DATA SUBJECT'S PERSONAL DATA",
          placeholder:
            'Please provide a description of the personal data to be deleted.',
          type: 'textarea',
          value: part2Value,
          onChange: (e) => setPart2Value(e.target.value)
        },
        part3: {
          title: 'PART 3: REASONS FOR REQUEST',
          type: 'textarea',
          placeholder: 'Please indicate below reasons for deletion',
          value: part3Value,
          onChange: (e) => setPart3Value(e.target.value)
        },
        consent1: {
          text: `The information submitted is true and accurate. I understand that it is necessary for QL Maxincome Sdn. Bhd. (“FamilyMart Malaysia”) to confirm my identity and it may be necessary to obtain more detailed information in order to locate and delete the correct personal data.`,
          value: consent1Value,
          onChange: () => setConsent1Value(!consent1Value)
        },
        consent2: {
          text: `I agree that the deletion of personal data will result in the deactivation of account and the inability to continue to provide me with the services.`,
          value: consent2Value,
          onChange: () => setConsent2Value(!consent2Value)
        }
      }
    ],
    [
      'edit',
      {
        header: 'PERSONAL DATA CORRECTION REQUEST FORM:',
        description: `For the purpose of this form, Data Subject/Relevant Person (as defined under the Personal Data Protection Act 2010) must provide a copy of the identification card (NRIC) or passport, authorisation letter by the Data Subject and other relevant supporting document as required by us. Please note that, we may not be able to process your request in the event of the personal data provided is inaccurate, incomplete, misleading or not up to date in the first place. 
        A request to correct personal data is subject to the requirements under Personal Data Protection Act 2010.`,
        part1: {
          title: 'PART 1: DETAILS OF DATA SUBJECT',
          object: [
            {
              title: 'FULL NAME as per NRIC / Passport:',
              type: 'text',
              value: part1Value.fullName,
              onChange: (e) =>
                setPart1Value({ ...part1Value, fullName: e.target.value })
            },
            {
              title: 'NRIC / Passport number (attach copy):',
              type: 'image',
              value: part1Value.kycImage,
              onChange: (path) => {
                setPart1Value({ ...part1Value, kycImage: path })
              }
            },
            {
              title: 'Member ID:',
              type: 'text',
              value: part1Value.accountNo,
              disabled: true
            },
            {
              title: 'Mobile Phone Number:',
              type: 'text',
              value: part1Value.mobileNumber,
              disabled: true
            },
            {
              title: 'Email Address:',
              type: 'text',
              value: part1Value.emailAddress,
              disabled: true
            }
          ]
        },
        part2: {
          title: 'PART 2: DETAILS OF DATA SUBJECT',
          placeholder:
            'Please provide description of the personal data to be corrected.',
          value: part2Value,
          onChange: (e) => setPart2Value(e.target.value)
        },
        consent1: {
          text: `I hereby certify that the information given in this form and any documents submitted are true and accurate.`,
          value: consent1Value,
          onChange: () => setConsent1Value(!consent1Value)
        }
      }
    ]
  ])

  return (
    <Box p={2}>
      <BackButton {...props} />
      <Forms
        header={moduleTypeAttributes.get(type).header}
        description={moduleTypeAttributes.get(type).description}
        consent1={moduleTypeAttributes.get(type).consent1}
        consent2={moduleTypeAttributes.get(type).consent2}
        part1={moduleTypeAttributes.get(type).part1}
        part2={moduleTypeAttributes.get(type)?.part2}
        part3={moduleTypeAttributes.get(type)?.part3}
        isTngMp={isTngMp}
        handleOnSubmit={handleOnSubmit}
        isSaving={isSaving}
        removePreviewImg={removePreviewImg}
      />
      <Notification
        show={notify.show}
        severity={notify.severity}
        message={notify.message}
        onClose={() => setNotify({ show: false, severity: '', message: '' })}
      />
    </Box>
  )
}

export default RequestAction
