import React from 'react'
import { Snackbar, makeStyles, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem',
    height: 50,
    top: 0
  }
})

export default function Notification({ show, severity, message, onClose }) {
  const styles = useStyles()
  if (severity != '') {
    return (
      <Snackbar
        open={show}
        autoHideDuration={3000}
        onClose={onClose}
        className={styles.root}>
        <Alert severity={severity} elevation={6} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    )
  } else {
    return (
      <Snackbar
        open={show}
        autoHideDuration={2000}
        onClose={onClose}
        className={styles.root}></Snackbar>
    )
  }
}
