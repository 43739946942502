import { createMuiTheme } from '@material-ui/core/styles'
import variables from 'src/scss/_variables.scss'

let theme = createMuiTheme({
  typography: {
    fontFamily: ['din'].join(', '),
    fontSize: 16,
    h1: {
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: 'normal'
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    h3: {
      fontSize: '1rem',
      lineHeight: 'normal'
    },
    h4: {
      fontSize: '0.85rem',
      fontWeight: 600,
      lineHeight: 'normal'
    },
    body1: {
      fontSize: '0.85rem',
      lineHeight: 'normal'
    },
    body2: {
      fontSize: '0.80rem',
      lineHeight: 'normal'
    }
  },
  MuiButtonBase: {
    contained: {
      background: `${variables.primary} !important`,
      padding: '1rem',
      width: '100%',
      color: '#fff !important',
      border: 'none !important',
      fontWeight: 'bold !important',
      lineHeight: '1.3rem',
      overflow: 'hidden'
    }
  },
  spacing: (factor) => `${0.5 * factor}rem`,
  palette: {
    common: {
      white: '#fff',
      black: '#000'
    },
    primary: {
      main: '#008CDB',
      light: '#F4F9FC'
    },
    secondary: {
      main: '#1E91CF',
      light: '#f1f1f2'
    },
    warning: {
      main: '#F96232'
    },
    text: {
      primary: '#59595C',
      secondary: '#1E91CF'
    },
    grey: {
      100: '#6D6E71'
    },
    border: {
      light: '#00000029'
    }
  }
})

export default theme
