import React from 'react'
import {
  Box,
  withStyles,
  Typography,
  Badge,
  makeStyles
} from '@material-ui/core'
import { Icon } from 'src/components'

const useStyles = makeStyles(({ palette, spacing }) => ({
  active: {
    color: palette.primary.main
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
  }
})(Box)

const StyledBadge = withStyles(({ palette }) => ({
  badge: {
    fontSize: '0.8rem',
    top: 10,
    right: -63,
    paddingBottom: '4px',
    marginRight: '5px',
    width: '100px',
    height: '25px',
    color: '#fff',
    background: palette.warning.main
  }
}))(Badge)

const DesktopPanel = ({
  icon,
  topic,
  history,
  item,
  chip,
  path,
  id = false,
  platform,
  category,
  setCategory
}) => {
  const styles = useStyles()
  function navigateTo() {
    if (category !== topic) {
      setCategory(topic)
    }
  }

  return (
    <Row width={275} py={2} onClick={() => navigateTo()}>
      {/* {icon == undefined && (
        <Box flex={0.2}>
          <Icon name={icon} />
        </Box>
      )} */}
      {!(icon == undefined) && (
        <Box flex={0.2}>
          {path === '/voucher-order' ? (
            <img src={icon} alt="voucher" />
          ) : (
            <Icon name={icon} />
          )}
        </Box>
      )}
      {!(icon == undefined) && (
        <Box flex={0.8}>
          {chip ? (
            <StyledBadge badgeContent={chip.message}>
              <Typography
                variant="h3"
                className={category === topic ? styles.active : null}>
                {topic}
              </Typography>
            </StyledBadge>
          ) : (
            <Typography
              variant="h3"
              className={category === topic ? styles.active : null}>
              {topic}
            </Typography>
          )}
        </Box>
      )}
      {icon == undefined && (
        <Box flex={1} mx={1}>
          {chip ? (
            <StyledBadge badgeContent={chip.message}>
              <Typography variant="h3">{topic}</Typography>
            </StyledBadge>
          ) : (
            <Typography variant="h3">{topic}</Typography>
          )}
        </Box>
      )}
      <Icon name="next" />
    </Row>
  )
}

export default DesktopPanel
