import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import Routes from 'src/infrastructure/routes'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from '@material-ui/core'
import awsconfig from './aws-exports'
import Amplify from 'aws-amplify'
import theme from 'src/themes'
import './index.css'

awsconfig.API = {
  graphql_endpoint: 'https://helpcenterapi.qlecommapi.com/graphql',
  graphql_headers: async () => {
    return {
      'X-Frame-Options': 'SAMEORIGIN',
      'Content-Security-Policy': 'default-src self',
      'x-api-key': 'da2-uprhw2fbtzgq3fq27rpekqycg4'
    }
  }
}
Amplify.configure(awsconfig)

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
