import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { submitCustomerSentimentSurvey } from 'src/graphql/mutations'

const submitCustomerSentimentForm = async (data) => {
  try {
    const res = await API.graphql(
      graphqlOperation(submitCustomerSentimentSurvey, data)
    )
    if (res.data.submitCustomerSentimentSurvey.statusCode === 200)
      return {
        status: true,
        message: res.data.submitCustomerSentimentSurvey.message
      }
    else
      return {
        status: false,
        message: res.data.submitCustomerSentimentSurvey.message
      }
  } catch (e) {
    return {
      status: false,
      message: 'Something went wrong. Please try again later.'
    }
  }
}

export default submitCustomerSentimentForm
