import { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { getHelpCenterFaqCache } from 'src/graphql/queries'
import useNavigationLogic from '../../../hooks/useNavigationLogic'

const useFaqPageLogic = (route, location, NavigationContext) => {
  const { state } = location
  const [faqList, setFaqList] = useState([])
  const [isTngMp, setIsTngMp] = useState(true)
  const [userPlatform, setUserPlatform] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [category, setCategory] = useState('')
  const [filteredFaq, setFilteredFaq] = useState([])
  const [faq, setFaq] = useState([])
  const [notify, setNotify] = useState({
    show: false,
    severity: '',
    message: ''
  })

  const { checkUserPlatform, getJwtToken } = useNavigationLogic(
    route,
    setUserPlatform,
    setIsTngMp,
    setNotify,
    'faq',
    state,
    location
  )

  useEffect(() => {
    if (route === 'mobile') {
      getFaqList()
    } else {
      getFaqData()
    }
    if (global.jwtToken === undefined) {
      getJwtToken()
    }
  }, [])

  async function getFaqList() {
    let platform = checkUserPlatform()
    const cat = state.category

    const params = {
      filter: {
        faqCategory: {
          eq: cat
        }
      },
      limit: 100,
      nextToken: null,
      platform: platform
    }
    const response = await API.graphql(
      graphqlOperation(getHelpCenterFaqCache, params)
    )

    let faqList = []
    if (response.data.getHelpCenterFaqCache) {
      response.data.getHelpCenterFaqCache.items.map((item) => {
        var details = {
          icon: 'info',
          faqId: item.faqId,
          parentId: item.parentId,
          topic: item.question,
          sequence: item.sequence,
          path: '/faq',
          answer: item.answer,
          category: item.faqCategory,
          platform: item.platform
        }
        if (item.faqCategory == cat) {
          faqList.push(details)
        }
      })
    }

    function sortList(currentFaqList) {
      currentFaqList.sort((a, b) => {
        return a.platform === b.platform ? a.sequence - b.sequence : null
      })
      return currentFaqList
    }
    faqList = sortList(faqList)
    setFaqList(faqList)
  }

  async function getFaqData() {
    let platform = checkUserPlatform()
    const params = {
      filter: null,
      limit: 100,
      nextToken: null,
      platform: platform
    }
    const inactiveparams = {
      filter: { statementType: { eq: 'InactiveOrder' } }
      // filter: {
      //   and: [{ statementType: { eq: 'Parcel-Completed' } },  { platform: { eq: 'pudoparcel' } }],
      // },
    }
    try {
      const response = await API.graphql(
        graphqlOperation(getHelpCenterFaqCache, params)
      )

      if (response.data.getHelpCenterFaqCache) {
        response.data.getHelpCenterFaqCache.items.map((item) => {
          var details = {
            icon: 'info',
            faqId: item.faqId,
            parentId: item.parentId,
            topic: item.question,
            sequence: item.sequence,
            path: '/faq',
            answer: item.answer,
            category: item.faqCategory,
            platform: item.platform
          }
          faqList.push(details)
        })
      }

      function sortList(currentFaqList) {
        currentFaqList.sort((a, b) => {
          return a.platform === b.platform ? b.sequence - a.sequence : null
        })
        return currentFaqList
      }

      let uniqueFaqListByCat = []

      for (let i = 0; i < faqList.length; i++) {
        const element = faqList[i]

        let duplicate = false
        uniqueFaqListByCat.forEach((e) => {
          if (e.category == element.category) {
            duplicate = true
          }
        })

        if (!duplicate) {
          uniqueFaqListByCat.push(element)
        }
      }

      uniqueFaqListByCat = sortList(uniqueFaqListByCat)
      setFaq(uniqueFaqListByCat)
      // get faq answer for first section
      let tempFaqList = sortList(faqList)
      setFaqList(tempFaqList)
      let firstCat = uniqueFaqListByCat[0].category
      handleSelectCategory(firstCat)
      setFilteredFaq(tempFaqList.filter((item) => item.category === firstCat))

      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    } catch (e) {
      console.log('err', e)
    }
  }

  const handleSelectCategory = (category) => {
    NavigationContext.handleUpdateBreadcrumbs(['FAQ', category])
    setCategory(category)
  }

  return {
    faqList,
    isTngMp,
    userPlatform,
    isLoading,
    setIsLoading,
    category,
    setCategory,
    filteredFaq,
    setFilteredFaq,
    faq,
    setFaq,
    handleSelectCategory
  }
}

export default useFaqPageLogic
