import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, withStyles } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { format } from 'date-fns'
import { Paper, ChatItem } from 'src/components'

export default function ChartHistoryPanel({ chat, history, platform }) {
  return (
    <Paper
      display="block"
      style={{ cursor: 'pointer' }}
      onClick={() =>
        history.push({
          pathname: `/chat/${chat.issueId}`,
          state: chat,
          platform: platform
        })
      }>
      <ChatItem chat={chat} />
    </Paper>
  )
}
