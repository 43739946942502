import React from 'react'
import { Box, makeStyles, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: spacing(10)
  }
}))

export default function Spinner({ className = false }) {
  const styles = useStyles()
  return (
    <Box
      className={clsx({
        [styles.root]: !className,
        [className]: className
      })}>
      <CircularProgress />
    </Box>
  )
}
