/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminGetNavCategory = /* GraphQL */ `
  query AdminGetNavCategory {
    adminGetNavCategory {
      statusCode
      message
      divisionList {
        code
        title
      }
      itemCategoryList {
        code
        title
      }
      productGroupList {
        code
        title
      }
    }
  }
`
export const getPageUrl = /* GraphQL */ `
  query GetPageUrl($shortLink: String) {
    getPageUrl(shortLink: $shortLink) {
      statusCode
      body {
        pageLink
        message
      }
    }
  }
`
export const getStore = /* GraphQL */ `
  query GetStore($storeId: ID!) {
    getStore(storeId: $storeId) {
      storeId
      driveThru
      visibleToEComm
      storeName
      address
      latitude
      longitude
      openHour
      closeHour
      deliveryDiscountType
      deliveryServiceAvailable
      pickupServiceAvailable
      minFoodPreparationDuration
      minDeliveryDuration
      deliveryFee
      isDisabled
      isOnline24Hour
      isOffline24Hour
      postalCode
      city
      state
      storeCode
      lastOrderTime
      minPurchaseAmount
      freeDeliveryWithMinPurchase
      acceptOrderWithMinPurchase
      maxOrderQty
      eCommStartDate
      eCommEndDate
      hasSeatingArea
      createdBy
      modifiedBy
      storeManagerName
      storeManagerContact
      priceGroupId
      storeGroup
      createdAt
      updatedAt
      minDistanceCharges
      minDistance
      pollingDeliveryTime
      riderTimeout
      deliveryGracePeriod
      routingOption
      priorityDeliveryPartner
      incrementDistanceCharges
      incrementDistanceUnit
      deliveryDiscountAmount
      deliveryDiscountBasketValue
      deliveryPartnerMaxRetry
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      operatingHour {
        items {
          storeOperatingHourId
          onlineStoreOpenHour
          onlineStoreCloseHour
          offlineStoreOpenHour
          offlineStoreCloseHour
          isOffline24Hour
          isOnline24Hour
          onlineIsOpen
          offlineIsOpen
          day
          modifiedBy
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      storeBlockedOutPeriods {
        items {
          storeBlockedOutPeriodId
          startDateTime
          endDateTime
          storeType
          createdBy
          modifiedBy
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      StoreDeliveryPartnerMapping {
        items {
          partnerId
          storeDeliveryPartnerStoreId
          partnerName
          sequence
          isPriority
          createdBy
          modifiedBy
          createdAt
          updatedAt
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const getDeliveryPartnerOrder = /* GraphQL */ `
  query GetDeliveryPartnerOrder(
    $filter: SearchableDeliveryPartnerOrderFilterInput
    $sort: SearchableDeliveryPartnerOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    getDeliveryPartnerOrder(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      statusCode
      message
      nextToken
      total
      deliveryPartnerOrderList {
        deliveryPartnerOrderId
        status
        platform
        memberNo
        orderNo
        shortOrderNo
        orderType
        storeNo
        terminalId
        tenderId
        transactionId
        amount
        createdAt
        updatedAt
        orderCancelReason
        orderCancelledDateTime
        orderDateTime
        orderId
        orderCreatedDateTime
        orderCancelDateTime
        orderCompletedDateTime
        lastStatusUpdatedAt
        navisionSent
        oriTransNo
        fullRefundInd
      }
    }
  }
`
export const getDeliveryPartnerOrderDetail = /* GraphQL */ `
  query GetDeliveryPartnerOrderDetail(
    $filter: SearchableDeliveryPartnerOrderDetailFilterInput
    $sort: SearchableDeliveryPartnerOrderDetailSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    getDeliveryPartnerOrderDetail(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      statusCode
      message
      nextToken
      total
      deliveryPartnerOrderDetailList {
        deliveryPartnerOrderDetailId
        discAmount
        gstAmount
        itemLineNo
        itemNo
        memberNo
        qty
        receiptNo
        storeNo
        tenderId
        transactionId
        unitOfMeasure
        createdAt
        updatedAt
        orderDateTime
        isOutOfStock
        status
        shortOrderNo
        orderId
        orderCompletedDateTime
        price
        netAmount
        navPromoId
        promoDiscAmount
        navisionSent
      }
    }
  }
`
export const getCustomerMemberStatus = /* GraphQL */ `
  query GetCustomerMemberStatus(
    $token: String
    $receiverMobileNumber: String
    $voucherNumber: String
  ) {
    getCustomerMemberStatus(
      token: $token
      receiverMobileNumber: $receiverMobileNumber
      voucherNumber: $voucherNumber
    ) {
      statusCode
      message
      isReceiverMember
      isShareable
    }
  }
`
export const getStoreOperatingHour = /* GraphQL */ `
  query GetStoreOperatingHour($storeOperatingHourId: ID!) {
    getStoreOperatingHour(storeOperatingHourId: $storeOperatingHourId) {
      storeOperatingHourId
      onlineStoreOpenHour
      onlineStoreCloseHour
      offlineStoreOpenHour
      offlineStoreCloseHour
      isOffline24Hour
      isOnline24Hour
      onlineIsOpen
      offlineIsOpen
      day
      modifiedBy
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getLandingMenu = /* GraphQL */ `
  query GetLandingMenu($landingMenuId: ID!) {
    getLandingMenu(landingMenuId: $landingMenuId) {
      landingMenuId
      actionType
      actionId
      title
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      modifiedBy
      updatedAt
      createdAt
      shortLinkUrl
    }
  }
`
export const getStoreBlockedOutPeriod = /* GraphQL */ `
  query GetStoreBlockedOutPeriod($storeBlockedOutPeriodId: ID!) {
    getStoreBlockedOutPeriod(
      storeBlockedOutPeriodId: $storeBlockedOutPeriodId
    ) {
      storeBlockedOutPeriodId
      startDateTime
      endDateTime
      storeType
      createdBy
      modifiedBy
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getProduct = /* GraphQL */ `
  query GetProduct($productId: ID!) {
    getProduct(productId: $productId) {
      productId
      sku
      name
      title
      image
      price
      discount
      discountPercentage
      discountedPrice
      promotionDescription
      discountStartDate
      discountEndDate
      pickupPrice
      pickupDiscount
      pickupDiscountPercentage
      pickupDiscountedPrice
      pickupPromotionDescription
      pickupDiscountStartDate
      pickupDiscountEndDate
      category
      categories
      departments
      taggings
      minFoodPreparationDuration
      minDeliveryDuration
      createdBy
      modifiedBy
      isDisabled
      description
      uom
      dimension
      temperature
      itemPublishStartDate
      itemPublishEndDate
      ecommerceMaximumQuantity
      pickupMaximumQuantity
      createdAt
      updatedAt
      divisionCode
      divisionDesc
      itemCategoryCode
      itemCategoryDesc
      productGroupCode
      productGroupDesc
      departmentLevel1
      departmentLevel2
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productCategory {
        items {
          productCategoryId
          category {
            categoryId
            title
            image
            code
            createdBy
            modifiedBy
            isDisabled
            parentId
            createdAt
            updatedAt
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderDetail {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productImage {
        items {
          productImageId
          image
          sequence
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerWishList {
        items {
          customerWishListId
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const getProductStoreDepartment = /* GraphQL */ `
  query GetProductStoreDepartment($productStoreDepartmentId: ID!) {
    getProductStoreDepartment(
      productStoreDepartmentId: $productStoreDepartmentId
    ) {
      productStoreDepartmentId
      productStoreDepartmentProductUOMId
      productStoreDepartmentStoreId
      productStoreDepartmentSubDepartmentId
      productStoreDepartmentProductUOMPriceGroupId
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      department {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      subDepartment {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      isOutOfStock
      createdAt
      updatedAt
    }
  }
`
export const getProductStoreTagging = /* GraphQL */ `
  query GetProductStoreTagging($productStoreTaggingId: ID!) {
    getProductStoreTagging(productStoreTaggingId: $productStoreTaggingId) {
      productStoreTaggingId
      productStoreTaggingProductUOMId
      productStoreTaggingProductTaggingId
      productStoreTaggingStoreId
      productStoreTaggingProductUOMPriceGroupId
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      isOutOfStock
      createdAt
      updatedAt
    }
  }
`
export const getProductImage = /* GraphQL */ `
  query GetProductImage($productImageId: ID!) {
    getProductImage(productImageId: $productImageId) {
      productImageId
      image
      sequence
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getProductStoreTaggingMapping = /* GraphQL */ `
  query GetProductStoreTaggingMapping($productStoreTaggingMapping: ID!) {
    getProductStoreTaggingMapping(
      productStoreTaggingMapping: $productStoreTaggingMapping
    ) {
      productStoreTaggingMapping
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getProductStore = /* GraphQL */ `
  query GetProductStore($productStoreId: ID!) {
    getProductStore(productStoreId: $productStoreId) {
      productStoreId
      productStoreProductId
      isOutOfStock
      modifiedBy
      productStoreProductUOMId
      productStoreStoreId
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getKDSDeviceToken = /* GraphQL */ `
  query GetKDSDeviceToken($id: ID!) {
    getKDSDeviceToken(id: $id) {
      id
      deviceEndpoint
      deviceToken
      storeCode
      createdAt
      updatedAt
    }
  }
`
export const getJwtToken = /* GraphQL */ `
  query GetJwtToken($sessionID: ID!) {
    getJwtToken(sessionID: $sessionID) {
      sessionID
      signedToken
      token
      expiryDate
      accountNo
      createdAt
      updatedAt
    }
  }
`
export const getFeedback = /* GraphQL */ `
  query GetFeedback($feedbackId: ID!) {
    getFeedback(feedbackId: $feedbackId) {
      feedbackId
      searchLocation
      dateOfVisit
      timeOfVisit
      title
      description
      uploadPhoto
      createdBy
      modifiedBy
      redmineTicketNumber
      platform
      createdAt
      updatedAt
      customerName
      customerMobileNo
      customerEmail
      issueStatus
    }
  }
`
export const getProductCategory = /* GraphQL */ `
  query GetProductCategory($productCategoryId: ID!) {
    getProductCategory(productCategoryId: $productCategoryId) {
      productCategoryId
      category {
        categoryId
        title
        image
        code
        createdBy
        modifiedBy
        isDisabled
        parentId
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getProductDepartment = /* GraphQL */ `
  query GetProductDepartment($productDepartmentId: ID!) {
    getProductDepartment(productDepartmentId: $productDepartmentId) {
      productDepartmentId
      productDepartmentProductUOMId
      department {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getProductTaggingMapping = /* GraphQL */ `
  query GetProductTaggingMapping($productTaggingMappingId: ID!) {
    getProductTaggingMapping(
      productTaggingMappingId: $productTaggingMappingId
    ) {
      productTaggingMappingId
      productTaggingMappingProductId
      productTaggingMappingProductTaggingId
      productTaggingMappingProductUOMId
      modifiedBy
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productTagging {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getCategory = /* GraphQL */ `
  query GetCategory($categoryId: ID!) {
    getCategory(categoryId: $categoryId) {
      categoryId
      title
      image
      code
      createdBy
      modifiedBy
      isDisabled
      parentId
      productCategory {
        items {
          productCategoryId
          category {
            categoryId
            title
            image
            code
            createdBy
            modifiedBy
            isDisabled
            parentId
            createdAt
            updatedAt
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const getDepartment = /* GraphQL */ `
  query GetDepartment($departmentId: ID!) {
    getDepartment(departmentId: $departmentId) {
      departmentId
      title
      image
      createdBy
      modifiedBy
      updatedAt
      createdAt
      isDisabled
      effectiveStartDate
      effectiveEndDate
      parentId
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreSubDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const getProductTagging = /* GraphQL */ `
  query GetProductTagging($productTaggingId: ID!) {
    getProductTagging(productTaggingId: $productTaggingId) {
      productTaggingId
      title
      image
      code
      effectiveStartDate
      effectiveEndDate
      isDisabled
      createdBy
      modifiedBy
      updatedAt
      createdAt
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const getUserPointTransaction = /* GraphQL */ `
  query GetUserPointTransaction($transactionId: ID!) {
    getUserPointTransaction(transactionId: $transactionId) {
      transactionId
      pointValue
      note
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getVoucherRewardSKU = /* GraphQL */ `
  query GetVoucherRewardSKU($voucherRewardSKUId: ID!) {
    getVoucherRewardSKU(voucherRewardSKUId: $voucherRewardSKUId) {
      voucherRewardSKUId
      rewardId
      entityId
      entityType
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const getVoucherReward = /* GraphQL */ `
  query GetVoucherReward($rewardId: ID!) {
    getVoucherReward(rewardId: $rewardId) {
      rewardId
      image
      startDate
      endDate
      point
      description
      title
      tnc
      cashValueAmount
      skuCashDiscount
      voucherType
      voucherStore {
        items {
          voucherRewardStoreId
          rewardId
          latitude
          longitude
          storeId
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      voucherSKU {
        items {
          voucherRewardSKUId
          rewardId
          entityId
          entityType
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const getVoucherRewardStore = /* GraphQL */ `
  query GetVoucherRewardStore($voucherRewardStoreId: ID!) {
    getVoucherRewardStore(voucherRewardStoreId: $voucherRewardStoreId) {
      voucherRewardStoreId
      rewardId
      latitude
      longitude
      storeId
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const getCustomerVoucher = /* GraphQL */ `
  query GetCustomerVoucher($voucherId: ID!) {
    getCustomerVoucher(voucherId: $voucherId) {
      voucherId
      rewardId
      status
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getOrder = /* GraphQL */ `
  query GetOrder($orderId: ID!) {
    getOrder(orderId: $orderId) {
      orderId
      orderNumber
      voucherNumber
      voucherTitle
      voucherEntryNumber
      orderDate
      paymentDateTime
      productImage
      grandTotal
      discountAmount
      deliveryFee
      voucherDiscount
      totalOrderItems
      status
      noteToRider
      floorOrUnit
      customerAccountNo
      customerFirstName
      customerLastName
      customerPhoneNumber
      storeName
      storeCode
      orderType
      deliveryAddress
      deliveryAddressLatitude
      deliveryAddressLongitude
      remarks
      routingOption
      reasonCode
      cancelReason
      cancelBy
      orderCancellationDateTime
      collectedDateTime
      createdAt
      updatedAt
      isAdvancedOrder
      scheduledDateTime
      driverName
      driverPhone
      driverPlateNumber
      lalamoveOrderId
      lalamoveOrderDate
      lalamoveTotalFee
      deliveryStatus
      intOrderNumber
      isRefunded
      refundAmount
      refundReason
      txnId
      refundCompleteDateTime
      orderCompleteDateTime
      cancelOrderDateTime
      requiredCutlery
      deliveryOrderDateTime
      deliveryOrderId
      deliveryPartner
      deliveryPartnerFee
      eGHLCardType
      eGHLIssuingBank
      eGHLPymtMethod
      riderJobNumber
      deliveryNumber
      totalOutOfStock
      partialFulfilmentAmount
      partialFulfilmentDiscount
      accumulatedRefundAmount
      promoDiscount
      promoCodeTitle
      promoCode
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      voucher {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      totalVoucherDiscount
      voucherRefunded
      promoCodeCampaignId
      netDiscountAmount
      oriTotalVoucherDiscount
      deliveryDiscount
      netDeliveryDiscount
      deliverySurchargeAmount
      orderDetails {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      orderReview {
        items {
          orderReviewId
          overallRating
          foodRating
          waitingTimeRating
          driverServiceRating
          staffServiceRating
          productAvailabilityRating
          comment
          attachment
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderPayment {
        items {
          orderPaymentId
          amount
          status
          paymentNumber
          paymentOrderNumber
          transactionType
          paymentMethod
          paymentResponseBase64
          eGHLTransactionType
          eGHLPymtMethod
          eGHLServiceID
          eGHLPaymentID
          eGHLOrderNumber
          eGHLAmount
          eGHLCurrencyCode
          eGHLHashValue
          eGHLHashValue2
          eGHLTxnID
          eGHLIssuingBank
          eGHLTxnStatus
          eGHLAuthCode
          eGHLBankRefNo
          eGHLRespTime
          eGHLTxnMessage
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      refundTransaction {
        items {
          refundTransactionId
          refundAmount
          automatedRefundStatus
          emailRefundStatus
          manualRefundStatus
          manualCSRefundStatus
          eGHLRefundStatus
          customerServiceStatus
          orderId
          refundTxnId
          orderNumber
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          paymentMethod
          paymentChannel
          refundReason
          paymentId
          completedBy
          refundDateTime
          redmineTicketNumber
          lastOrderTransitionDate
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const getOrderDetail = /* GraphQL */ `
  query GetOrderDetail($orderDetailId: ID!) {
    getOrderDetail(orderDetailId: $orderDetailId) {
      orderDetailId
      orderDetailOrderId
      orderDetailPriceGroupId
      orderDetailProductId
      orderDetailProductUOMId
      orderDetailProductUOMPriceGroupId
      quantity
      subtotal
      discount
      promoDiscount
      mandatoryItem
      isFreeItem
      outOfStock
      itemStatus
      productName
      productDescription
      productImage
      createdAt
      updatedAt
      totalPromoCodeDiscount
      totalDiscountAmount
      totalVoucherDiscount
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      orderPayment {
        items {
          orderPaymentId
          amount
          status
          paymentNumber
          paymentOrderNumber
          transactionType
          paymentMethod
          paymentResponseBase64
          eGHLTransactionType
          eGHLPymtMethod
          eGHLServiceID
          eGHLPaymentID
          eGHLOrderNumber
          eGHLAmount
          eGHLCurrencyCode
          eGHLHashValue
          eGHLHashValue2
          eGHLTxnID
          eGHLIssuingBank
          eGHLTxnStatus
          eGHLAuthCode
          eGHLBankRefNo
          eGHLRespTime
          eGHLTxnMessage
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const getOrderPayment = /* GraphQL */ `
  query GetOrderPayment($orderPaymentId: ID!) {
    getOrderPayment(orderPaymentId: $orderPaymentId) {
      orderPaymentId
      amount
      status
      paymentNumber
      paymentOrderNumber
      transactionType
      paymentMethod
      paymentResponseBase64
      eGHLTransactionType
      eGHLPymtMethod
      eGHLServiceID
      eGHLPaymentID
      eGHLOrderNumber
      eGHLAmount
      eGHLCurrencyCode
      eGHLHashValue
      eGHLHashValue2
      eGHLTxnID
      eGHLIssuingBank
      eGHLTxnStatus
      eGHLAuthCode
      eGHLBankRefNo
      eGHLRespTime
      eGHLTxnMessage
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getRefundTransaction = /* GraphQL */ `
  query GetRefundTransaction($refundTransactionId: ID!) {
    getRefundTransaction(refundTransactionId: $refundTransactionId) {
      refundTransactionId
      refundAmount
      automatedRefundStatus
      emailRefundStatus
      manualRefundStatus
      manualCSRefundStatus
      eGHLRefundStatus
      customerServiceStatus
      orderId
      refundTxnId
      orderNumber
      customerAccountNo
      customerFirstName
      customerLastName
      customerPhoneNumber
      paymentMethod
      paymentChannel
      refundReason
      paymentId
      completedBy
      refundDateTime
      redmineTicketNumber
      lastOrderTransitionDate
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getRefundProcess = /* GraphQL */ `
  query GetRefundProcess($refundProcessId: ID!) {
    getRefundProcess(refundProcessId: $refundProcessId) {
      refundProcessId
      paymentMethod
      paymentChannel
      isAutomatedRefund
      isEmailRefund
      isManualRefund
      isReverseRefundAvailable
      createdAt
      updatedAt
    }
  }
`
export const getCustomerWishList = /* GraphQL */ `
  query GetCustomerWishList($customerWishListId: ID!) {
    getCustomerWishList(customerWishListId: $customerWishListId) {
      customerWishListId
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getOrderReview = /* GraphQL */ `
  query GetOrderReview($orderReviewId: ID!) {
    getOrderReview(orderReviewId: $orderReviewId) {
      orderReviewId
      overallRating
      foodRating
      waitingTimeRating
      driverServiceRating
      staffServiceRating
      productAvailabilityRating
      comment
      attachment
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getUserMatrix = /* GraphQL */ `
  query GetUserMatrix($userMatrixId: ID!) {
    getUserMatrix(userMatrixId: $userMatrixId) {
      userMatrixId
      userGroup
      canEdit
      canList
      canView
      canDelete
      canAdd
      module
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getTrendingText = /* GraphQL */ `
  query GetTrendingText($trendingTextId: ID!) {
    getTrendingText(trendingTextId: $trendingTextId) {
      trendingTextId
      title
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getAppLaunchPromo = /* GraphQL */ `
  query GetAppLaunchPromo($appLaunchPromoId: ID!) {
    getAppLaunchPromo(appLaunchPromoId: $appLaunchPromoId) {
      appLaunchPromoId
      title
      image
      startDate
      endDate
      promoType
      promoEntityId
      isDisabled
      createdBy
      modifiedBy
      sequenceOrder
      buttonLabel
      createdAt
      updatedAt
    }
  }
`
export const getLandingPageBanner = /* GraphQL */ `
  query GetLandingPageBanner($landingPageBannerId: ID!) {
    getLandingPageBanner(landingPageBannerId: $landingPageBannerId) {
      landingPageBannerId
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      title
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
      promoEntityId
      promoType
      buttonLabel
      promoTitle
      platform
      shortLinkUrl
    }
  }
`
export const getHomePageBanner = /* GraphQL */ `
  query GetHomePageBanner($homePageBannerId: ID!) {
    getHomePageBanner(homePageBannerId: $homePageBannerId) {
      homePageBannerId
      bannerId
      bannerNumber
      image
      startDate
      endDate
      isDisabled
      sequenceOrder
      listBanners {
        id
        image
        title
        description
        sequence
      }
      title
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      pageLayout
      description
      validationPeriodId
      shortLinkUrl
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getStoreGroup = /* GraphQL */ `
  query GetStoreGroup($storeGroupId: ID!) {
    getStoreGroup(storeGroupId: $storeGroupId) {
      storeGroupId
      code
      description
      stores
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getStoreList = /* GraphQL */ `
  query GetStoreList($storeIdList: [ID], $sort: ElasticSearchSortDirection) {
    getStoreList(storeIdList: $storeIdList, sort: $sort) {
      storeList {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      statusCode
      message
    }
  }
`
export const getLandingPageProduct = /* GraphQL */ `
  query GetLandingPageProduct($landingPageProductId: ID!) {
    getLandingPageProduct(landingPageProductId: $landingPageProductId) {
      landingPageProductId
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      title
      productType
      productTypeId
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getDeliveryPartner = /* GraphQL */ `
  query GetDeliveryPartner($deliveryPartnerId: ID!) {
    getDeliveryPartner(deliveryPartnerId: $deliveryPartnerId) {
      deliveryPartnerId
      deliveryPartnerName
      apiEndpoint
      isCancelAfterAcceptAllowed
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getStoreDeliveryPartner = /* GraphQL */ `
  query GetStoreDeliveryPartner($partnerId: ID!) {
    getStoreDeliveryPartner(partnerId: $partnerId) {
      partnerId
      storeDeliveryPartnerStoreId
      partnerName
      sequence
      isPriority
      createdBy
      modifiedBy
      createdAt
      updatedAt
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const getOrderDeliveryOrder = /* GraphQL */ `
  query GetOrderDeliveryOrder($orderDeliveryOrderId: ID!) {
    getOrderDeliveryOrder(orderDeliveryOrderId: $orderDeliveryOrderId) {
      orderDeliveryOrderId
      deliveryPartner
      deliveryNumber
      orderNumber
      status
      totalFee
      createdBy
      modifiedBy
      createdAt
      updatedAt
      deliveryOrderDateTime
    }
  }
`
export const getPushNotification = /* GraphQL */ `
  query GetPushNotification($pushNotificationId: ID!) {
    getPushNotification(pushNotificationId: $pushNotificationId) {
      pushNotificationId
      scheduleDateTime
      pushedDateTime
      messageSent
      newUserEndDate
      newUserStartDate
      body
      title
      bannerImage
      thumbnailImage
      createdBy
      modifiedBy
      createdAt
      updatedAt
      status
      actionType
      pageLayout
      actionId
      targetAudience {
        method
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      readNotification
      senderMobileNumber
      senderName
      voucherExpiryDate
      voucherStatus
      voucherNumber
      receiverName
      voucherTitle
      inviteLink
    }
  }
`
export const getNotification = /* GraphQL */ `
  query GetNotification($notificationId: ID!) {
    getNotification(notificationId: $notificationId) {
      notificationId
      customerId
      title
      thumbnailImage
      summary
      bannerImage
      description
      link
      pageLayout
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getCustomer = /* GraphQL */ `
  query GetCustomer($customerId: ID!) {
    getCustomer(customerId: $customerId) {
      customerId
      accountNo
      clubCode
      firstName
      lastName
      primaryEmail
      secondaryEmail
      country
      address
      address2
      identityCard
      custClubCode
      custSchemeCode
      joinDate
      joinTime
      staffId
      staffJoinDate
      isBlocked
      actionDate
      actionReasonCode
      actionBy
      consentPersonalData
      city
      state
      postal
      mobileNumber
      password
      gender
      dateOfBirth
      maritalStatus
      race
      employmentStatus
      personalIncomeLevel
      activated
      facebookID
      isFacebook
      qrCode
      profilePicture
      nationality
      signedUpDate
      signedUpTime
      hasRewarded
      promoCode
      tagCode
      createdBy
      modifiedBy
      customerDeviceToken
      customerDeviceEndpoint
      areaOfInterest
      createdAt
      updatedAt
      hasCompletedProfile
      userPointTransaction {
        items {
          transactionId
          pointValue
          note
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerTagCode {
        items {
          customerTagCodeId
          tagCode
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      customerVouchers {
        voucherId
        rewardId
        status
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        createdAt
        updatedAt
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      wishList {
        items {
          customerWishListId
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderReview {
        items {
          orderReviewId
          overallRating
          foodRating
          waitingTimeRating
          driverServiceRating
          staffServiceRating
          productAvailabilityRating
          comment
          attachment
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerFavouriteAddress {
        items {
          customerFavouriteAddressId
          title
          country
          address
          address2
          city
          state
          postal
          isPrimary
          latitude
          longitude
          notes
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
        }
        nextToken
      }
      customerFavouritePayment {
        items {
          customerFavouritePaymentId
          creditCardNumber
          paymentToken
          paymentType
          creditCardType
          expiryDate
          country
          isPrimary
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
        }
        nextToken
      }
      customerFeedback {
        items {
          feedbackId
          searchLocation
          dateOfVisit
          timeOfVisit
          title
          description
          uploadPhoto
          createdBy
          modifiedBy
          redmineTicketNumber
          platform
          createdAt
          updatedAt
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          customerName
          customerMobileNo
          customerEmail
          issueStatus
        }
        nextToken
      }
    }
  }
`
export const getCustomerCart = /* GraphQL */ `
  query GetCustomerCart($customerCartId: ID!) {
    getCustomerCart(customerCartId: $customerCartId) {
      customerCartId
      quantity
      price
      subtotal
      discountAmount
      productName
      productTitle
      productCategories
      productTaggings
      productCode
      productImage
      mandatoryItem
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      product {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      store {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getCustomerTagCode = /* GraphQL */ `
  query GetCustomerTagCode($customerTagCodeId: ID!) {
    getCustomerTagCode(customerTagCodeId: $customerTagCodeId) {
      customerTagCodeId
      tagCode
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getCustomerFavouriteAddress = /* GraphQL */ `
  query GetCustomerFavouriteAddress($customerFavouriteAddressId: ID!) {
    getCustomerFavouriteAddress(
      customerFavouriteAddressId: $customerFavouriteAddressId
    ) {
      customerFavouriteAddressId
      title
      country
      address
      address2
      city
      state
      postal
      isPrimary
      latitude
      longitude
      notes
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
    }
  }
`
export const getCustomerFavouritePayment = /* GraphQL */ `
  query GetCustomerFavouritePayment($customerFavouritePaymentId: ID!) {
    getCustomerFavouritePayment(
      customerFavouritePaymentId: $customerFavouritePaymentId
    ) {
      customerFavouritePaymentId
      creditCardNumber
      paymentToken
      paymentType
      creditCardType
      expiryDate
      country
      isPrimary
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
    }
  }
`
export const getAppConfig = /* GraphQL */ `
  query GetAppConfig($appConfigId: ID!) {
    getAppConfig(appConfigId: $appConfigId) {
      appConfigId
      configKey
      configValue
      createdAt
      updatedAt
    }
  }
`
export const getIssue = /* GraphQL */ `
  query GetIssue($issueId: ID!) {
    getIssue(issueId: $issueId) {
      issueId
      orderNumber
      title
      hasNewMessage
      totalQuantity
      totalAmount
      totalMessage
      customerAccountNo
      issueDateTime
      issueStatus
      redMineTicketNumber
      platform
      trackerNumber
      parcelAmount
      createdAt
      updatedAt
    }
  }
`
export const getIssueDetail = /* GraphQL */ `
  query GetIssueDetail($issueDetailId: ID!) {
    getIssueDetail(issueDetailId: $issueDetailId) {
      issueDetailId
      message
      image1
      image2
      image3
      isCustomerMessage
      issueDetailDateTime
      issueDetailIssueId
      productImage
      platform
      createdAt
      updatedAt
    }
  }
`
export const getPromotionPartner = /* GraphQL */ `
  query GetPromotionPartner($promotionPartnerId: ID!) {
    getPromotionPartner(promotionPartnerId: $promotionPartnerId) {
      promotionPartnerId
      image
      name
      defaultPrefix
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getPromoCodeCampaign = /* GraphQL */ `
  query GetPromoCodeCampaign($promoCodeId: ID!) {
    getPromoCodeCampaign(promoCodeId: $promoCodeId) {
      promoCodeId
      title
      campaignId
      navPromoId
      promoCode
      promoType
      forPartner
      maxUserUsageLimit
      totalUsageLimit
      totalNumOfCodes
      forMultipleUsage
      orderType
      deliveryFree
      discountTypeDelivery
      discountTypeLevel
      discountDeliveryCap
      discountProductQuantityCap
      discountCap
      discountType
      discountValue
      discountOnProductType
      discountOnProductValue
      deliveryFeeDiscount
      promoStartDatetime
      promoEndDatetime
      promoRepeatDay
      promoRepeatStartTime
      promoRepeatEndTime
      totalCount
      activeCount
      redeemedCount
      deactivatedCount
      athenaQueryId
      promoCodeStatusFile
      usageCondition
      productCategoryCondition {
        type
        ids
      }
      productCategoryDiscount {
        type
        ids
      }
      stores
      freeProducts {
        productId
        quantity
      }
      status
      minimumSpent
      minimumQuantity
      jobStatus
      modifiedBy
      isPromoEnabled
      platform
      statusUpdatedAt
      codeUpdatedAt
      createdAt
      updatedAt
    }
  }
`
export const getPromoCodeTxn = /* GraphQL */ `
  query GetPromoCodeTxn($promoCodeTxnId: ID!) {
    getPromoCodeTxn(promoCodeTxnId: $promoCodeTxnId) {
      promoCodeTxnId
      redeemedDatetime
      voidDatetime
      customerId
      promoCodeId
      promoCode
      promoCodeLength
      lastModifiedBy
      deactivatedAt
      status
      createdAt
      updatedAt
    }
  }
`
export const getPromoCodeGeneric = /* GraphQL */ `
  query GetPromoCodeGeneric($promoCodeGenericId: ID!) {
    getPromoCodeGeneric(promoCodeGenericId: $promoCodeGenericId) {
      promoCodeGenericId
      campaignId
      promoCode
      promoCodeCampaignId
      lastModifiedBy
      deactivatedAt
      status
      redeemedCount
      createdAt
      updatedAt
    }
  }
`
export const getPromoCodeCampaignPartner = /* GraphQL */ `
  query GetPromoCodeCampaignPartner($promoCodeCampaignPartnerId: ID!) {
    getPromoCodeCampaignPartner(
      promoCodeCampaignPartnerId: $promoCodeCampaignPartnerId
    ) {
      promoCodeCampaignPartnerId
      promoCodeId
      partnerName
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($announcementId: ID!) {
    getAnnouncement(announcementId: $announcementId) {
      announcementId
      title
      startDate
      endDate
      scheduleDatetime
      body
      actionType
      actionId
      bannerImage
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getCustomerTag = /* GraphQL */ `
  query GetCustomerTag($tagId: ID!) {
    getCustomerTag(tagId: $tagId) {
      tagId
      title
      tagMode
      startDate
      endDate
      ruleName
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getCustomerTagRule = /* GraphQL */ `
  query GetCustomerTagRule($ruleId: ID!) {
    getCustomerTagRule(ruleId: $ruleId) {
      ruleId
      tagId
      ruleType
      ruleField
      ruleOperator
      ruleValue
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getPriceGroup = /* GraphQL */ `
  query GetPriceGroup($priceGroupId: ID!) {
    getPriceGroup(priceGroupId: $priceGroupId) {
      priceGroupId
      title
      modifiedBy
      createdAt
      updatedAt
      productUOMPriceGroup {
        items {
          productUOMPriceGroupId
          productUOMId
          priceGroupId
          promotionDescription
          pickupPromotionDescription
          discountedPrice
          discountPercentage
          discount
          pickupDiscountedPrice
          pickupPromotionDiscount
          pickupDiscountPercentage
          pickupDiscount
          pickupPrice
          pickupEffectiveStartDate
          pickupEffectiveEndDate
          price
          discountStartDate
          discountEndDate
          effectiveStartDate
          effectiveEndDate
          pickupDiscountStartDate
          pickupDiscountEndDate
          modifiedBy
          createdAt
          updatedAt
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          priceGroup {
            priceGroupId
            title
            modifiedBy
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`
export const getProductUOM = /* GraphQL */ `
  query GetProductUOM($productUOMId: ID!) {
    getProductUOM(productUOMId: $productUOMId) {
      productUOMId
      productId
      image
      isDisabled
      description
      minFoodPreparationDuration
      pickupMaximumQuantity
      sku
      ecommerceMaximumQuantity
      departmentLevel1
      departmentLevel2
      productGroupDesc
      productGroupCode
      divisionDesc
      itemCategoryDesc
      title
      uom
      minDeliveryDuration
      taggings
      itemPublishStartDate
      itemPublishEndDate
      modifiedBy
      createdAt
      updatedAt
      orderDetail {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productUOMPriceGroup {
        items {
          productUOMPriceGroupId
          productUOMId
          priceGroupId
          promotionDescription
          pickupPromotionDescription
          discountedPrice
          discountPercentage
          discount
          pickupDiscountedPrice
          pickupPromotionDiscount
          pickupDiscountPercentage
          pickupDiscount
          pickupPrice
          pickupEffectiveStartDate
          pickupEffectiveEndDate
          price
          discountStartDate
          discountEndDate
          effectiveStartDate
          effectiveEndDate
          pickupDiscountStartDate
          pickupDiscountEndDate
          modifiedBy
          createdAt
          updatedAt
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          priceGroup {
            priceGroupId
            title
            modifiedBy
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const getProductUOMPriceGroup = /* GraphQL */ `
  query GetProductUOMPriceGroup($productUOMPriceGroupId: ID!) {
    getProductUOMPriceGroup(productUOMPriceGroupId: $productUOMPriceGroupId) {
      productUOMPriceGroupId
      productUOMId
      priceGroupId
      promotionDescription
      pickupPromotionDescription
      discountedPrice
      discountPercentage
      discount
      pickupDiscountedPrice
      pickupPromotionDiscount
      pickupDiscountPercentage
      pickupDiscount
      pickupPrice
      pickupEffectiveStartDate
      pickupEffectiveEndDate
      price
      discountStartDate
      discountEndDate
      effectiveStartDate
      effectiveEndDate
      pickupDiscountStartDate
      pickupDiscountEndDate
      modifiedBy
      createdAt
      updatedAt
      productUOM {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      priceGroup {
        priceGroupId
        title
        modifiedBy
        createdAt
        updatedAt
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const getPromoCodeStatusJob = /* GraphQL */ `
  query GetPromoCodeStatusJob($promoCodeStatusJobId: ID!) {
    getPromoCodeStatusJob(promoCodeStatusJobId: $promoCodeStatusJobId) {
      promoCodeStatusJobId
      messageId
      athenaQueryId
      promoCodeStatusFile
      promoCodeList {
        promoCodeGenericId
        promoCodeTxnId
        promoCode
        status
        createdAt
        redeemedAt
        deactivatedAt
        redeemedCount
      }
      status
      totalCount
      activeCount
      redeemedCount
      deactivatedCount
      statusUpdatedAt
      codeUpdatedAt
      createdAt
      updatedAt
    }
  }
`
export const getFamiDeliveryTransaction = /* GraphQL */ `
  query GetFamiDeliveryTransaction($token: String) {
    getFamiDeliveryTransaction(token: $token) {
      famiTransactionId
      transactionType
      status
      deliveryAddress
      store
      createdAt
      updatedAt
      rewardType
      reward
      productList {
        image
        sku
      }
    }
  }
`
export const getInStoreTransaction = /* GraphQL */ `
  query GetInStoreTransaction($token: String) {
    getInStoreTransaction(token: $token) {
      inStoreTransactionList {
        storeName
        orderDate
        orderNumber
        grandTotal
        pointsCollected
        pointsRedeemed
        itemQuantity
      }
      statusCode
      message
    }
  }
`
export const getFamiPointsTransaction = /* GraphQL */ `
  query GetFamiPointsTransaction($token: String) {
    getFamiPointsTransaction(token: $token) {
      famiPointsTransactionList {
        benefitTriggerId
        title
        image
        mobileImage
        description
        points
        issueDate
      }
      statusCode
      message
    }
  }
`
export const getRewardsFamiPoints = /* GraphQL */ `
  query GetRewardsFamiPoints($token: String) {
    getRewardsFamiPoints(token: $token) {
      getRewardsFamiPointsList {
        benefitTriggerId
        title
        image
        mobileImage
        description
        startDate
        endDate
        triggerType
        points
      }
      statusCode
      message
    }
  }
`
export const getPromoCodeCampaignNumber = /* GraphQL */ `
  query GetPromoCodeCampaignNumber($orderId: String) {
    getPromoCodeCampaignNumber(orderId: $orderId)
  }
`
export const getAppDriveThruDepartmentList = /* GraphQL */ `
  query GetAppDriveThruDepartmentList($storeId: String) {
    getAppDriveThruDepartmentList(storeId: $storeId) {
      departmentId
      title
      image
    }
  }
`
export const getDriveThruDisplayData = /* GraphQL */ `
  query GetDriveThruDisplayData($storeId: String) {
    getDriveThruDisplayData(storeId: $storeId) {
      configId
      banner {
        image
        sequenceOrder
      }
      product {
        id
        image
        sequenceOrder
        title
      }
    }
  }
`
export const getAppDriveThruProductList = /* GraphQL */ `
  query GetAppDriveThruProductList($storeId: String, $departmentId: String) {
    getAppDriveThruProductList(storeId: $storeId, departmentId: $departmentId) {
      image
      price
      title
    }
  }
`
export const getDriveThruBannerList = /* GraphQL */ `
  query GetDriveThruBannerList($storeId: String, $screenNumber: Int) {
    getDriveThruBannerList(storeId: $storeId, screenNumber: $screenNumber) {
      sectionNumber
      sectionTitle
      layout
      bannerList {
        image
        sequenceOrder
      }
    }
  }
`
export const getDriveThruProductList = /* GraphQL */ `
  query GetDriveThruProductList($storeId: String, $screenNumber: Int) {
    getDriveThruProductList(storeId: $storeId, screenNumber: $screenNumber) {
      sectionNumber
      sectionTitle
      layout
      productList {
        image
        sequenceOrder
        title
        price
      }
    }
  }
`
export const searchDriveThruProductList = /* GraphQL */ `
  query SearchDriveThruProductList(
    $storeId: String
    $limit: Int
    $nextToken: String
    $keyword: String
  ) {
    searchDriveThruProductList(
      storeId: $storeId
      limit: $limit
      nextToken: $nextToken
      keyword: $keyword
    ) {
      nextToken
      product {
        driveThruProductStoreId
        image
        sku
        title
        taggings
        isOutOfStock
        categories
      }
    }
  }
`
export const getAppDriveThruBannerList = /* GraphQL */ `
  query GetAppDriveThruBannerList($storeId: String) {
    getAppDriveThruBannerList(storeId: $storeId) {
      image
      sequenceOrder
    }
  }
`
export const getDriveThruConfig = /* GraphQL */ `
  query GetDriveThruConfig($driveThruConfigId: ID!) {
    getDriveThruConfig(driveThruConfigId: $driveThruConfigId) {
      driveThruConfigId
      driveThruConfigStoreId
      screenNumber
      sectionNumber
      sectionTitle
      layout
      isDisabled
      effectiveStart
      effectiveEnd
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listDriveThruConfigs = /* GraphQL */ `
  query ListDriveThruConfigs(
    $driveThruConfigId: ID
    $filter: ModelDriveThruConfigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDriveThruConfigs(
      driveThruConfigId: $driveThruConfigId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        driveThruConfigId
        driveThruConfigStoreId
        screenNumber
        sectionNumber
        sectionTitle
        layout
        isDisabled
        effectiveStart
        effectiveEnd
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getDriveThruProduct = /* GraphQL */ `
  query GetDriveThruProduct($driveThruProductId: ID!) {
    getDriveThruProduct(driveThruProductId: $driveThruProductId) {
      driveThruProductId
      driveThruProductProductUOMId
      driveThruProductConfigId
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listDriveThruProducts = /* GraphQL */ `
  query ListDriveThruProducts(
    $driveThruProductId: ID
    $filter: ModelDriveThruProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDriveThruProducts(
      driveThruProductId: $driveThruProductId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        driveThruProductId
        driveThruProductProductUOMId
        driveThruProductConfigId
        sequenceOrder
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getDriveThruBanner = /* GraphQL */ `
  query GetDriveThruBanner($driveThruBannerId: ID!) {
    getDriveThruBanner(driveThruBannerId: $driveThruBannerId) {
      driveThruBannerId
      driveThruBannerConfigId
      image
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listDriveThruBanners = /* GraphQL */ `
  query ListDriveThruBanners(
    $driveThruBannerId: ID
    $filter: ModelDriveThruBannerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDriveThruBanners(
      driveThruBannerId: $driveThruBannerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        driveThruBannerId
        driveThruBannerConfigId
        image
        sequenceOrder
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getDriveThruProductStore = /* GraphQL */ `
  query GetDriveThruProductStore($driveThruProductStoreId: ID!) {
    getDriveThruProductStore(
      driveThruProductStoreId: $driveThruProductStoreId
    ) {
      driveThruProductStoreId
      driveThruProductStoreProductUOMId
      driveThruProductStoreStoreId
      isOutOfStock
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listDriveThruProductStores = /* GraphQL */ `
  query ListDriveThruProductStores(
    $driveThruProductStoreId: ID
    $filter: ModelDriveThruProductStoreFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDriveThruProductStores(
      driveThruProductStoreId: $driveThruProductStoreId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        driveThruProductStoreId
        driveThruProductStoreProductUOMId
        driveThruProductStoreStoreId
        isOutOfStock
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getDriveThruAppBanner = /* GraphQL */ `
  query GetDriveThruAppBanner($driveThruAppBannerId: ID!) {
    getDriveThruAppBanner(driveThruAppBannerId: $driveThruAppBannerId) {
      driveThruAppBannerId
      image
      sequenceOrder
      driveThruAppBannerStoreId
      createdBy
      createdAt
      modifiedBy
      updatedAt
    }
  }
`
export const driveThruConfigByStoreIdScreenNumber = /* GraphQL */ `
  query DriveThruConfigByStoreIdScreenNumber(
    $driveThruConfigStoreId: String
    $screenNumber: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDriveThruConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    driveThruConfigByStoreIdScreenNumber(
      driveThruConfigStoreId: $driveThruConfigStoreId
      screenNumber: $screenNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        driveThruConfigId
        driveThruConfigStoreId
        screenNumber
        sectionNumber
        sectionTitle
        layout
        isDisabled
        effectiveStart
        effectiveEnd
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const driveThruProductByConfigId = /* GraphQL */ `
  query DriveThruProductByConfigId(
    $driveThruProductConfigId: String
    $sortDirection: ModelSortDirection
    $filter: ModelDriveThruProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    driveThruProductByConfigId(
      driveThruProductConfigId: $driveThruProductConfigId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        driveThruProductId
        driveThruProductProductUOMId
        driveThruProductConfigId
        sequenceOrder
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const driveThruBannerByConfigId = /* GraphQL */ `
  query DriveThruBannerByConfigId(
    $driveThruBannerConfigId: String
    $sortDirection: ModelSortDirection
    $filter: ModelDriveThruBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    driveThruBannerByConfigId(
      driveThruBannerConfigId: $driveThruBannerConfigId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        driveThruBannerId
        driveThruBannerConfigId
        image
        sequenceOrder
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const driveThruProductStoreByStoreId = /* GraphQL */ `
  query DriveThruProductStoreByStoreId(
    $driveThruProductStoreStoreId: String
    $sortDirection: ModelSortDirection
    $filter: ModelDriveThruProductStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    driveThruProductStoreByStoreId(
      driveThruProductStoreStoreId: $driveThruProductStoreStoreId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        driveThruProductStoreId
        driveThruProductStoreProductUOMId
        driveThruProductStoreStoreId
        isOutOfStock
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getHomePage = /* GraphQL */ `
  query GetHomePage($token: String, $orderType: String, $storeId: String) {
    getHomePage(token: $token, orderType: $orderType, storeId: $storeId) {
      statusCode
      message
      newsTitle
      newsList {
        newsId
        pageLayout
        sequence
        image
        detailImage
        startDate
        endDate
        buttonType
        buttonAction
        actionTitle
        buttonLabel
        description
        title
        googleReviewLink
      }
      homePageLanding {
        pageLayout
        title
        sequence
        linkTo {
          title
          description
          image
          mobileImage
          sequence
          type
          endDate
          id
          buttonType
          actionTitle
          buttonAction
          buttonLabel
          price
          remainingQuantity
          purchaseLimit
          points
          countDown
          discountedPrice
          isDisabled
          ecommerceMaximumQuantity
          pickupMaximumQuantity
        }
      }
      homePageBannerList {
        homePageBannerId
        sequenceOrder
        pageLayout
        image
        buttonType
        buttonAction
        actionTitle
        buttonLabel
        description
        title
      }
    }
  }
`
export const getFamiDealsDetail = /* GraphQL */ `
  query GetFamiDealsDetail($token: String, $benefitTriggerId: String) {
    getFamiDealsDetail(token: $token, benefitTriggerId: $benefitTriggerId) {
      benefitTriggerId
      title
      image
      mobileImage
      description
      startDate
      endDate
      triggerType
      price
      remainingQuantity
      purchaseLimit
      countDown
    }
  }
`
export const getRewardsFamiPointsDetail = /* GraphQL */ `
  query GetRewardsFamiPointsDetail($benefitTriggerId: String) {
    getRewardsFamiPointsDetail(benefitTriggerId: $benefitTriggerId) {
      benefitTriggerId
      title
      image
      mobileImage
      description
      startDate
      endDate
      triggerType
      points
    }
  }
`
export const getHomePageLandingNewsDetail = /* GraphQL */ `
  query GetHomePageLandingNewsDetail($id: String) {
    getHomePageLandingNewsDetail(id: $id) {
      id
      schemeGroup
      detailImage
      title
      description
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      createdAt
    }
  }
`
export const getSurveyQuestionList = /* GraphQL */ `
  query GetSurveyQuestionList($token: String, $surveyId: String) {
    getSurveyQuestionList(token: $token, surveyId: $surveyId) {
      surveyId
      surveyName
      question {
        surveyQuestionId
        sequence
        questionImage
        description
        required
        questionType
        questionChoice
        question
        inputType
        choiceLabel {
          choice
          type
          label
        }
      }
    }
  }
`
export const getTotalPoint = /* GraphQL */ `
  query GetTotalPoint($customerId: String) {
    getTotalPoint(customerId: $customerId) {
      totalPoint
      customerId
    }
  }
`
export const getPointsAndVoucher = /* GraphQL */ `
  query GetPointsAndVoucher($customerId: String, $orderNumber: String) {
    getPointsAndVoucher(customerId: $customerId, orderNumber: $orderNumber) {
      customerId
      voucherId
      points
    }
  }
`
export const getCustomerStatus = /* GraphQL */ `
  query GetCustomerStatus($customerId: String) {
    getCustomerStatus(customerId: $customerId) {
      customerId
      status
    }
  }
`
export const getHomePageNewsDetail = /* GraphQL */ `
  query GetHomePageNewsDetail($newsId: String) {
    getHomePageNewsDetail(newsId: $newsId) {
      startDate
      title
      image
      description
      pageLayout
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      listNews {
        title
        image
        description
        buttonType
        buttonAction
        buttonLabel
        actionTitle
        detailImage
        startDate
        endDate
        enable
      }
    }
  }
`
export const getHomePageBannerDetail = /* GraphQL */ `
  query GetHomePageBannerDetail($homePageBannerId: String) {
    getHomePageBannerDetail(homePageBannerId: $homePageBannerId) {
      pageLayout
      image
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      description
      startDate
      title
      listBanners {
        image
        title
        description
        sequence
      }
    }
  }
`
export const getUserFamiPointsDetail = /* GraphQL */ `
  query GetUserFamiPointsDetail($token: String, $onlyPoint: Boolean) {
    getUserFamiPointsDetail(token: $token, onlyPoint: $onlyPoint) {
      memberId
      qrCode
      currentPoints
      expiringPoints
      expiryDate
    }
  }
`
export const getPostTransactionQuestion = /* GraphQL */ `
  query GetPostTransactionQuestion($token: String) {
    getPostTransactionQuestion(token: $token) {
      questionList {
        postTransactionSurveyQuestionId
        sequence
        image
        title
        type
      }
      storeName
      orderDate
      txnId
      statusCode
      message
    }
  }
`
export const getUserVoucherPurchaseList = /* GraphQL */ `
  query GetUserVoucherPurchaseList($token: String, $nextToken: String) {
    getUserVoucherPurchaseList(token: $token, nextToken: $nextToken) {
      voucherPurchaseList {
        grandTotal
        image
        orderNumber
        paymentDateTime
        totalOrderItems
        voucherOrderId
        pointEarned
      }
      statusCode
      message
      nextToken
    }
  }
`
export const getUserVoucherPurchaseDetail = /* GraphQL */ `
  query GetUserVoucherPurchaseDetail($token: String, $voucherOrderId: String) {
    getUserVoucherPurchaseDetail(
      token: $token
      voucherOrderId: $voucherOrderId
    ) {
      grandTotal
      image
      orderNumber
      paymentDateTime
      totalOrderItems
      voucherOrderId
      pointEarned
      voucherOrderDetails {
        quantity
        benefitTriggerId
        subtotal
        title
        description
        image
      }
    }
  }
`
export const getVoucherDetail = /* GraphQL */ `
  query GetVoucherDetail(
    $token: String
    $voucherId: String
    $expiryDate: String
    $userVoucher: Boolean
    $voucherStatus: String
    $senderMobileNo: String
  ) {
    getVoucherDetail(
      token: $token
      voucherId: $voucherId
      expiryDate: $expiryDate
      userVoucher: $userVoucher
      voucherStatus: $voucherStatus
      senderMobileNo: $senderMobileNo
    ) {
      voucherId
      description
      image
      voucherNumber
      voucherTitle
      endDate
      quantity
      redeemed
      entryNo
      isInStoreVoucher
      isRedeemable
      redeemedQuantity
      voucherQuantityPendingAccept
      voucherStatus
      allowVoucherSharing
      senderMobileNo
      senderName
      sharingMessage
      acceptedBy
    }
  }
`
export const listSchemeGroup = /* GraphQL */ `
  query ListSchemeGroup($staffFilter: Boolean) {
    listSchemeGroup(staffFilter: $staffFilter)
  }
`
export const listReasonCode = /* GraphQL */ `
  query ListReasonCode($calledBy: String) {
    listReasonCode(calledBy: $calledBy) {
      listReasonCode {
        reasonCode
        reasonDesc
      }
    }
  }
`
export const getFamiDealsCategoryList = /* GraphQL */ `
  query GetFamiDealsCategoryList($token: String) {
    getFamiDealsCategoryList(token: $token) {
      category
      voucherList {
        benefitTriggerId
        title
        description
        startDate
        endDate
        image
        price
        mobileImage
        remainingQuantity
        purchaseLimit
        countDown
      }
    }
  }
`
export const webGetPostTransactionQuestions = /* GraphQL */ `
  query WebGetPostTransactionQuestions {
    webGetPostTransactionQuestions {
      postTransactionSurveyQuestionId
      sequence
      image
      title
      type
    }
  }
`
export const checkVoucherCart = /* GraphQL */ `
  query CheckVoucherCart(
    $token: String
    $benefitTriggerList: [VoucherCartInput]
  ) {
    checkVoucherCart(token: $token, benefitTriggerList: $benefitTriggerList) {
      statusCode
      errorMessage
      benefitTriggerList {
        quantity
        price
        subtotal
        voucherId
        benefitTriggerId
        title
        image
        status
        isValid
      }
    }
  }
`
export const getVoucherHistory = /* GraphQL */ `
  query GetVoucherHistory(
    $filter: ModelVoucherHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    getVoucherHistory(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        dateOfAction
        orderNumber
        type
        voucherID
        storeCode
        rdsTerminal
        schemeGroup
        total
        status
        customerAccountNo
        entryNo
        receiptNo
        expirationDate
        memberLedgerEntryNo
        netVoucherValue
        benefitTriggerId
        benefitTriggerType
        nav
        receiverAccountNo
        receiverContactNo
        senderAccountNo
        isReceiverMember
      }
      nextToken
      total
    }
  }
`
export const getPointsHistory = /* GraphQL */ `
  query GetPointsHistory(
    $filter: ModelPointsHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    getPointsHistory(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        dateOfAction
        orderNumber
        type
        voucherID
        storeCode
        rdsTerminal
        description
        schemeGroup
        customerAccountNo
        points
        expiryDate
        reasonCode
        documentNo
        entryType
        entryNo
        createdDate
        navEntryNo
      }
      nextToken
      total
    }
  }
`
export const getUploadJob = /* GraphQL */ `
  query GetUploadJob(
    $filter: String
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    getUploadJob(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        uploadJobId
        module
        s3Bucket
        s3ObjectPath
        status
        recordsProcessed
        targetTable
        createdAt
        updatedAt
        requestor
        errorMessage
      }
      nextToken
      Limit
    }
  }
`
export const getCustomerPointsAndMembership = /* GraphQL */ `
  query GetCustomerPointsAndMembership($customerId: String) {
    getCustomerPointsAndMembership(customerId: $customerId) {
      currentPoints
      currentMembershipTier
    }
  }
`
export const getCognitoUserEmail = /* GraphQL */ `
  query GetCognitoUserEmail($cognitoId: String) {
    getCognitoUserEmail(cognitoId: $cognitoId)
  }
`
export const getFamiDealsList = /* GraphQL */ `
  query GetFamiDealsList($token: String, $sortBy: String, $filterBy: String) {
    getFamiDealsList(token: $token, sortBy: $sortBy, filterBy: $filterBy) {
      flashDealList {
        benefitTriggerId
        title
        description
        startDate
        endDate
        image
        price
        mobileImage
        remainingQuantity
        purchaseLimit
        countDown
      }
      voucherList {
        benefitTriggerId
        title
        description
        startDate
        endDate
        image
        price
        mobileImage
        remainingQuantity
        purchaseLimit
        countDown
      }
    }
  }
`
export const getUserVoucherList = /* GraphQL */ `
  query GetUserVoucherList(
    $token: String
    $orderType: String
    $storeId: String
  ) {
    getUserVoucherList(
      token: $token
      orderType: $orderType
      storeId: $storeId
    ) {
      userVoucherList {
        voucherId
        voucherNumber
        quantity
        voucherTitle
        voucherDescription
        voucherImage
        mobileImage
        voucherExpiryDate
        voucherStartDate
        redeemedQuantity
        entryNo
        isInStoreVoucher
        voucherCondition
        allowVoucherSharing
        voucherStatus
        voucherQuantityPendingAccept
        senderMobileNo
        senderName
        acceptedBy
      }
      statusCode
      message
    }
  }
`
export const getCustomerVoucherStampingList = /* GraphQL */ `
  query GetCustomerVoucherStampingList($token: String) {
    getCustomerVoucherStampingList(token: $token) {
      voucherId
      voucherStartDate
      voucherEndDate
      title
      description
      tnc
      quantityLeft
      voucherImage
      discountType
      discountValue
      voucherType
      progressType
      valueAccumulated
      valueRequired
    }
  }
`
export const getNewsAndAnnouncementList = /* GraphQL */ `
  query GetNewsAndAnnouncementList($token: String) {
    getNewsAndAnnouncementList(token: $token) {
      announcementId
      scheduleDatetime
      title
      description
      buttonType
      buttonAction
      buttonLabel
      image
    }
  }
`
export const getHomePageLandingList = /* GraphQL */ `
  query GetHomePageLandingList {
    getHomePageLandingList {
      banners {
        homePageBannerId
        bannerId
        bannerNumber
        image
        startDate
        endDate
        isDisabled
        sequenceOrder
        listBanners {
          id
          image
          title
          description
          sequence
        }
        title
        buttonType
        buttonAction
        actionTitle
        buttonLabel
        pageLayout
        description
        validationPeriodId
        shortLinkUrl
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      news {
        announcementId
        scheduleDatetime
        title
        description
        buttonType
        buttonAction
        buttonLabel
        image
      }
      homePageLanding {
        title
        records {
          voucherId
          voucherStartDate
          voucherEndDate
          title
          description
          tnc
          quantityLeft
          requiredPoint
          requiredPrice
          maxQuantityPerUser
          voucherImage
          discountType
          discountValue
          voucherType
          voucherTitle
          voucherCode
        }
      }
    }
  }
`
export const newGetPushNotificationList = /* GraphQL */ `
  query NewGetPushNotificationList {
    newGetPushNotificationList {
      title
      body
      thumbnailImage
      scheduleDateTime
      pushNotificationId
      actionType
      pageLayout
      actionId
    }
  }
`
export const listDepartments = /* GraphQL */ `
  query ListDepartments($parentId: String) {
    listDepartments(parentId: $parentId) {
      items {
        departmentId
        title
        image
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
      }
    }
  }
`
export const listUserMatrixs = /* GraphQL */ `
  query ListUserMatrixs($userGroup: String, $module: String) {
    listUserMatrixs(userGroup: $userGroup, module: $module) {
      items {
        userMatrixId
        userGroup
        canEdit
        canList
        canView
        canDelete
        canAdd
        module
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
    }
  }
`
export const listDeliveryPartners = /* GraphQL */ `
  query ListDeliveryPartners($limit: Int) {
    listDeliveryPartners(limit: $limit) {
      items {
        deliveryPartnerId
        deliveryPartnerName
        apiEndpoint
        isCancelAfterAcceptAllowed
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
    }
  }
`
export const listPromoCodeGenerics = /* GraphQL */ `
  query ListPromoCodeGenerics($promoCodeCampaignId: String) {
    listPromoCodeGenerics(promoCodeCampaignId: $promoCodeCampaignId) {
      items {
        promoCodeGenericId
        campaignId
        promoCode
        promoCodeCampaignId
        lastModifiedBy
        deactivatedAt
        status
        redeemedCount
        createdAt
        updatedAt
      }
    }
  }
`
export const listPriceGroups = /* GraphQL */ `
  query ListPriceGroups {
    listPriceGroups {
      items {
        priceGroupId
        title
        modifiedBy
        createdAt
        updatedAt
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const listLandingPageProducts = /* GraphQL */ `
  query ListLandingPageProducts($title: String, $isDisabled: Boolean) {
    listLandingPageProducts(title: $title, isDisabled: $isDisabled) {
      items {
        landingPageProductId
        image
        effectiveStart
        effectiveEnd
        isDisabled
        sequenceOrder
        title
        productType
        productTypeId
        description
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
    }
  }
`
export const getPromoCodeCount = /* GraphQL */ `
  query GetPromoCodeCount($promoCodeId: String) {
    getPromoCodeCount(promoCodeId: $promoCodeId) {
      total
      active
      redeemed
      deactivated
    }
  }
`
export const getUserSignInAttemptByUsername = /* GraphQL */ `
  query GetUserSignInAttemptByUsername($username: String) {
    getUserSignInAttemptByUsername(username: $username) {
      id
      username
      userId
      attemptCount
      loginStatus
      loginTimeout
      lastLoginTime
      accessToken
      ipAddress
      deviceKey
      createdAt
      updatedAt
    }
  }
`
export const getStoreByStoreCode = /* GraphQL */ `
  query GetStoreByStoreCode($storeCode: String) {
    getStoreByStoreCode(storeCode: $storeCode) {
      storeId
      driveThru
      visibleToEComm
      storeName
      address
      latitude
      longitude
      openHour
      closeHour
      deliveryDiscountType
      deliveryServiceAvailable
      pickupServiceAvailable
      minFoodPreparationDuration
      minDeliveryDuration
      deliveryFee
      isDisabled
      isOnline24Hour
      isOffline24Hour
      postalCode
      city
      state
      storeCode
      lastOrderTime
      minPurchaseAmount
      freeDeliveryWithMinPurchase
      acceptOrderWithMinPurchase
      maxOrderQty
      eCommStartDate
      eCommEndDate
      hasSeatingArea
      createdBy
      modifiedBy
      storeManagerName
      storeManagerContact
      priceGroupId
      storeGroup
      createdAt
      updatedAt
      minDistanceCharges
      minDistance
      pollingDeliveryTime
      riderTimeout
      deliveryGracePeriod
      routingOption
      priorityDeliveryPartner
      incrementDistanceCharges
      incrementDistanceUnit
      deliveryDiscountAmount
      deliveryDiscountBasketValue
      deliveryPartnerMaxRetry
      order {
        items {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        nextToken
      }
      operatingHour {
        items {
          storeOperatingHourId
          onlineStoreOpenHour
          onlineStoreCloseHour
          offlineStoreOpenHour
          offlineStoreCloseHour
          isOffline24Hour
          isOnline24Hour
          onlineIsOpen
          offlineIsOpen
          day
          modifiedBy
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      storeBlockedOutPeriods {
        items {
          storeBlockedOutPeriodId
          startDateTime
          endDateTime
          storeType
          createdBy
          modifiedBy
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      StoreDeliveryPartnerMapping {
        items {
          partnerId
          storeDeliveryPartnerStoreId
          partnerName
          sequence
          isPriority
          createdBy
          modifiedBy
          createdAt
          updatedAt
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const listSystemUser = /* GraphQL */ `
  query ListSystemUser($store: Boolean, $nextToken: String) {
    listSystemUser(store: $store, nextToken: $nextToken) {
      userList {
        id
        email
        group
        enabled
        status
        createdAt
      }
      nextToken
    }
  }
`
export const getSystemUser = /* GraphQL */ `
  query GetSystemUser($id: String) {
    getSystemUser(id: $id) {
      id
      email
      group
      enabled
      status
    }
  }
`
export const getUserNewMessage = /* GraphQL */ `
  query GetUserNewMessage($token: String, $platform: String) {
    getUserNewMessage(token: $token, platform: $platform) {
      issueId
      orderNumber
      title
      hasNewMessage
      totalQuantity
      totalAmount
      totalMessage
      customerAccountNo
      issueDateTime
      issueStatus
      redMineTicketNumber
      platform
      trackerNumber
      parcelAmount
      createdAt
      updatedAt
    }
  }
`
export const getKDSConfig = /* GraphQL */ `
  query GetKDSConfig($id: String) {
    getKDSConfig(id: $id) {
      id
      overallSLA
      newOrderSLA
      newOrderBeepSLA
      newOrderCallAMSLA
      pendingSLA
      pendingBeepSLA
      packingSLA
      packingBeepSLA
      readyPickupSLA
      readyPickupBeepSLA
      KDSCancelMsg
    }
  }
`
export const getHelpCenterOrderList = /* GraphQL */ `
  query GetHelpCenterOrderList(
    $accountNo: String
    $nextToken: Int
    $token: String
    $status: String
    $platform: String
  ) {
    getHelpCenterOrderList(
      accountNo: $accountNo
      nextToken: $nextToken
      token: $token
      status: $status
      platform: $platform
    ) {
      statusCode
      message
      nextToken
      order {
        orderId
        orderNumber
        orderDate
        productImage
        grandTotal
        discountAmount
        deliveryFee
        totalOrderItems
        status
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        reasonCode
        cancelReason
        collectedDateTime
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveTotalFee
        noteToRider
        floorOrUnit
        deliveryStatus
        intOrderNumber
        orderCompleteDateTime
        isReviewAvailable
        isRefunded
        refundAmount
        overallRating
        orderStatusMessage
        requiredCutlery
        totalOutOfStock
        totalDiscountAmount
        partialFulfilmentAmount
        partialFulfilmentDiscount
        deliveryDiscount
        promoDiscount
        promoCodeTitle
        promoCode
        voucherDiscount
        voucherTitle
        voucherNumber
        voucherReturned
        promoCodeReturned
        pointEarned
        promoCodeCampaignId
        netDiscountAmount
        deliverySurchargeAmount
        deliverySurchardeMessage
        deliverySurchargeMessage
        orderDetailProduct {
          productId
          image
          sku
          title
          mandatoryItem
          quantity
          outOfStock
          price
          discountedPrice
          ecommerceMaximumQuantity
          pickupMaximumQuantity
        }
      }
      voucherOrder {
        voucherOrderId
        orderNumber
        orderDateTime
        paymentDateTime
        paymentMethod
        voucherImage
        orderCustomerId
        grandTotal
        discountAmount
        totalOrderItems
        status
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        createdAt
        updatedAt
        txnId
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
      }
      pudoOrder {
        parcelOrderId
        orderNumber
        orderDate
        dropOffDateTime
        dropOffStoreName
        senderEmail
        senderAccountNumber
        originalSenderAccountNo
        originalReceiverAccountNo
        senderMobileNumber
        senderCustomerId
        senderName
        totalQuantity
        grandTotal
        paymentMethod
        paymentType
        promoCode
        transactionNumber
        flyerBagSize
        flyerBagPrice
        flyerBagQuantity
        flyerBagTotalPrice
        flyerBagStatus
        transactionId
        refundAmount
        refundStatus
        status
        parcelOrderDetail {
          parcelOrderDetailId
          parcelOrderId
          orderNumber
          orderDate
          transactionNumber
          orderTxnId
          transactionId
          collectionDateTime
          collectionStoreName
          collectionStoreCode
          dropOffDateTime
          dropOffStoreName
          dropOffStoreCode
          receiverName
          receiverEmail
          receiverMobileNumber
          receiverAccountNumber
          senderAccountNumber
          originalSenderAccountNo
          originalReceiverAccountNo
          senderMobileNumber
          parcelSize
          deliveryPrice
          parcelAmount
          trackerNumber
          refundReasonCode
          refundAmount
          refundReason
          parcelCategory
          parcelType
          status
          parcelStatusLog {
            parcelStatusId
            status
            location
            parcelOrderId
            parcelOrderDetailId
            kdsCreatedAt
            cancelReason
            refundReason
            refundAmount
            reasonCode
            cancelBy
            refundBy
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`
export const getIssueDetailList = /* GraphQL */ `
  query GetIssueDetailList($issueId: String) {
    getIssueDetailList(issueId: $issueId) {
      issueDetailId
      message
      image1
      image2
      image3
      isCustomerMessage
      issueDetailDateTime
      issueDetailIssueId
      productImage
      platform
      createdAt
      updatedAt
    }
  }
`
export const commentHistory = /* GraphQL */ `
  query CommentHistory($issueId: String) {
    commentHistory(issueId: $issueId) {
      orderNumber
      title
      customerAccountNo
      issueDateTime
      issueStatus
      totalQuantity
      totalAmount
      totalMessage
      message
      image1
      image2
      image3
      issueId
      commentList {
        issueDetailId
        message
        image1
        image2
        image3
        isCustomerMessage
        issueDetailDateTime
        issueDetailIssueId
        productImage
        platform
        createdAt
        updatedAt
      }
    }
  }
`
export const getCustomerTotalPoints = /* GraphQL */ `
  query GetCustomerTotalPoints($customerId: String) {
    getCustomerTotalPoints(customerId: $customerId)
  }
`
export const getNearbyStoreList = /* GraphQL */ `
  query GetNearbyStoreList(
    $latitude: String
    $longitude: String
    $customerAddressId: String
    $orderType: String
  ) {
    getNearbyStoreList(
      latitude: $latitude
      longitude: $longitude
      customerAddressId: $customerAddressId
      orderType: $orderType
    ) {
      storeId
      storeName
      address
      latitude
      longitude
      deliveryServiceAvailable
      pickupServiceAvailable
      minFoodPreparationDuration
      minDeliveryDuration
      deliveryFee
      isDisabled
      isOnline24Hour
      isOffline24Hour
      postalCode
      city
      state
      storeCode
      storeStatus
      storeStatusMsg
      distance
      minPurchaseAmount
      freeDeliveryWithMinPurchase
      acceptOrderWithMinPurchase
      maxOrderQty
      eCommerceLastOrder
      hasSeatingArea
      operatingHours
      storeOperatingHours
      ecomOperatingHours
    }
  }
`
export const searchStoreList = /* GraphQL */ `
  query SearchStoreList(
    $latitude: String
    $longitude: String
    $keyword: String
    $orderType: String
  ) {
    searchStoreList(
      latitude: $latitude
      longitude: $longitude
      keyword: $keyword
      orderType: $orderType
    ) {
      storeId
      storeName
      address
      latitude
      longitude
      deliveryServiceAvailable
      pickupServiceAvailable
      minFoodPreparationDuration
      minDeliveryDuration
      deliveryFee
      isDisabled
      isOnline24Hour
      isOffline24Hour
      postalCode
      city
      state
      storeCode
      storeStatus
      storeStatusMsg
      distance
      minPurchaseAmount
      freeDeliveryWithMinPurchase
      acceptOrderWithMinPurchase
      maxOrderQty
      eCommerceLastOrder
      hasSeatingArea
      operatingHours
      storeOperatingHours
      ecomOperatingHours
    }
  }
`
export const searchStoreProductList = /* GraphQL */ `
  query SearchStoreProductList(
    $storeId: String
    $limit: Int
    $nextToken: String
    $keyword: String
    $filter: [FilterInput]
  ) {
    searchStoreProductList(
      storeId: $storeId
      limit: $limit
      nextToken: $nextToken
      keyword: $keyword
      filter: $filter
    ) {
      nextToken
      total
      product {
        productStoreId
        image
        sku
        title
        taggings
        isOutOfStock
        categories
        updatedAt
        department
        subDepartment
        division
        productGroup
        divisionCode
        itemCategoryCode
        productGroupCode
      }
    }
  }
`
export const userGetProfile = /* GraphQL */ `
  query UserGetProfile($token: String, $refreshToken: String) {
    userGetProfile(token: $token, refreshToken: $refreshToken) {
      statusCode
      Message
      AccountNo
      ClubCode
      FirstName
      LastName
      PrimaryEmail
      SecondaryEmail
      Country
      Address
      Address2
      City
      State
      Postal
      MobileNumber
      Password
      Gender
      DateOfBirth
      MaritalStatus
      Race
      EmploymentStatus
      PersonalIncomeLevel
      Activated
      FacebookID
      IsFacebook
      AreaOfInterest
      QRCode
      ProfilePhoto
      Nationality
      SignUpDate
      SignUpTime
      HasRewarded
      PromoCode
      TAGCode
      HasCompletedProfile
      CustomerSentimentInfo {
        hasPendingCustomerSentiment
        issueId
      }
    }
  }
`
export const validateMobileNumber = /* GraphQL */ `
  query ValidateMobileNumber($mobileNo: String) {
    validateMobileNumber(mobileNo: $mobileNo) {
      conversionResetPassword
      statusCode
      message
    }
  }
`
export const getOrderRecord = /* GraphQL */ `
  query GetOrderRecord($orderId: String, $token: String) {
    getOrderRecord(orderId: $orderId, token: $token) {
      orderId
      orderNumber
      orderDate
      productImage
      grandTotal
      discountAmount
      deliveryFee
      totalOrderItems
      status
      customerAccountNo
      customerFirstName
      customerLastName
      customerPhoneNumber
      storeName
      storeCode
      orderType
      deliveryAddress
      deliveryAddressLatitude
      deliveryAddressLongitude
      remarks
      reasonCode
      cancelReason
      collectedDateTime
      isAdvancedOrder
      scheduledDateTime
      driverName
      driverPhone
      driverPlateNumber
      lalamoveOrderId
      lalamoveTotalFee
      noteToRider
      floorOrUnit
      deliveryStatus
      intOrderNumber
      orderCompleteDateTime
      isReviewAvailable
      isRefunded
      refundAmount
      overallRating
      orderStatusMessage
      requiredCutlery
      totalOutOfStock
      totalDiscountAmount
      partialFulfilmentAmount
      partialFulfilmentDiscount
      deliveryDiscount
      promoDiscount
      promoCodeTitle
      promoCode
      voucherDiscount
      voucherTitle
      voucherNumber
      voucherReturned
      promoCodeReturned
      pointEarned
      promoCodeCampaignId
      netDiscountAmount
      deliverySurchargeAmount
      deliverySurchardeMessage
      deliverySurchargeMessage
      orderDetailProduct {
        productId
        image
        sku
        title
        mandatoryItem
        quantity
        outOfStock
        price
        discountedPrice
        ecommerceMaximumQuantity
        pickupMaximumQuantity
      }
    }
  }
`
export const getOrderReviewList = /* GraphQL */ `
  query GetOrderReviewList($orderId: String, $token: String) {
    getOrderReviewList(orderId: $orderId, token: $token) {
      orderReviewId
      overallRating
      foodRating
      waitingTimeRating
      driverServiceRating
      staffServiceRating
      productAvailabilityRating
      comment
      attachment
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      order {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const getUserOrderList = /* GraphQL */ `
  query GetUserOrderList(
    $accountNo: String
    $nextToken: Int
    $token: String
    $status: String
  ) {
    getUserOrderList(
      accountNo: $accountNo
      nextToken: $nextToken
      token: $token
      status: $status
    ) {
      statusCode
      message
      nextToken
      order {
        orderId
        orderNumber
        orderDate
        productImage
        grandTotal
        discountAmount
        deliveryFee
        totalOrderItems
        status
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        reasonCode
        cancelReason
        collectedDateTime
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveTotalFee
        noteToRider
        floorOrUnit
        deliveryStatus
        intOrderNumber
        orderCompleteDateTime
        isReviewAvailable
        isRefunded
        refundAmount
        overallRating
        orderStatusMessage
        requiredCutlery
        totalOutOfStock
        totalDiscountAmount
        partialFulfilmentAmount
        partialFulfilmentDiscount
        deliveryDiscount
        promoDiscount
        promoCodeTitle
        promoCode
        voucherDiscount
        voucherTitle
        voucherNumber
        voucherReturned
        promoCodeReturned
        pointEarned
        promoCodeCampaignId
        netDiscountAmount
        deliverySurchargeAmount
        deliverySurchardeMessage
        deliverySurchargeMessage
        orderDetailProduct {
          productId
          image
          sku
          title
          mandatoryItem
          quantity
          outOfStock
          price
          discountedPrice
          ecommerceMaximumQuantity
          pickupMaximumQuantity
        }
      }
    }
  }
`
export const getOrderDetailList = /* GraphQL */ `
  query GetOrderDetailList($orderId: String, $token: String) {
    getOrderDetailList(orderId: $orderId, token: $token) {
      order {
        orderId
        orderNumber
        orderDate
        productImage
        grandTotal
        discountAmount
        deliveryFee
        totalOrderItems
        status
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        reasonCode
        cancelReason
        collectedDateTime
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveTotalFee
        noteToRider
        floorOrUnit
        deliveryStatus
        intOrderNumber
        orderCompleteDateTime
        isReviewAvailable
        isRefunded
        refundAmount
        overallRating
        orderStatusMessage
        requiredCutlery
        totalOutOfStock
        totalDiscountAmount
        partialFulfilmentAmount
        partialFulfilmentDiscount
        deliveryDiscount
        promoDiscount
        promoCodeTitle
        promoCode
        voucherDiscount
        voucherTitle
        voucherNumber
        voucherReturned
        promoCodeReturned
        pointEarned
        promoCodeCampaignId
        netDiscountAmount
        deliverySurchargeAmount
        deliverySurchardeMessage
        deliverySurchargeMessage
        orderDetailProduct {
          productId
          image
          sku
          title
          mandatoryItem
          quantity
          outOfStock
          price
          discountedPrice
          ecommerceMaximumQuantity
          pickupMaximumQuantity
        }
      }
      orderDetails {
        quantity
        subtotal
        discount
        orderDetailProductId
        orderDetailId
        orderDetailOrderId
        productImage
        productDescription
        productName
        promoDiscount
        voucherDiscount
        createdAt
        mandatoryItem
        isFreeItem
        itemStatus
        outOfStock
      }
    }
  }
`
export const getPlaceUserOrderMessage = /* GraphQL */ `
  query GetPlaceUserOrderMessage($msgId: String, $token: String) {
    getPlaceUserOrderMessage(msgId: $msgId, token: $token) {
      status
      orderId
      statusCode
      paymentUrl
      body
      httpMethod
      msgId
      message
      promoCodeMessage
    }
  }
`
export const getPromoNotifications = /* GraphQL */ `
  query GetPromoNotifications($notificationId: String) {
    getPromoNotifications(notificationId: $notificationId) {
      pushNotificationId
      scheduleDateTime
      pushedDateTime
      messageSent
      newUserEndDate
      newUserStartDate
      body
      title
      bannerImage
      thumbnailImage
      createdBy
      modifiedBy
      createdAt
      updatedAt
      status
      actionType
      pageLayout
      actionId
      targetAudience {
        method
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      readNotification
      senderMobileNumber
      senderName
      voucherExpiryDate
      voucherStatus
      voucherNumber
      receiverName
      voucherTitle
      inviteLink
    }
  }
`
export const searchItem = /* GraphQL */ `
  query SearchItem(
    $keyword: String
    $orderType: String
    $storeId: String
    $pageSize: Int
    $lastEvaluatedKey: Int
  ) {
    searchItem(
      keyword: $keyword
      orderType: $orderType
      storeId: $storeId
      pageSize: $pageSize
      lastEvaluatedKey: $lastEvaluatedKey
    ) {
      item {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isAvailable
        isDisabled
        description
        uom
        dimension
        temperature
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        itemCategoryCode
        promoCode
        priceGroupId
        productUOMId
        productUOMPriceGroupId
      }
      lastEvaluatedKey
      totalCount
    }
  }
`
export const getFavAddressList = /* GraphQL */ `
  query GetFavAddressList($token: String) {
    getFavAddressList(token: $token) {
      customerFavouriteAddressId
      title
      country
      address
      address2
      city
      state
      postal
      isPrimary
      latitude
      longitude
      notes
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
    }
  }
`
export const getUserFavPaymentList = /* GraphQL */ `
  query GetUserFavPaymentList($token: String) {
    getUserFavPaymentList(token: $token) {
      customerFavouritePaymentId
      creditCardNumber
      paymentToken
      paymentType
      creditCardType
      expiryDate
      country
      isPrimary
      createdAt
      updatedAt
      customer {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
    }
  }
`
export const getUserWishList = /* GraphQL */ `
  query GetUserWishList($token: String, $nextToken: String) {
    getUserWishList(token: $token, nextToken: $nextToken) {
      nextToken
      userWishList {
        customerWishListId
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        product {
          productId
          sku
          name
          title
          image
          price
          discount
          discountPercentage
          discountedPrice
          promotionDescription
          discountStartDate
          discountEndDate
          pickupPrice
          pickupDiscount
          pickupDiscountPercentage
          pickupDiscountedPrice
          pickupPromotionDescription
          pickupDiscountStartDate
          pickupDiscountEndDate
          category
          categories
          departments
          taggings
          minFoodPreparationDuration
          minDeliveryDuration
          createdBy
          modifiedBy
          isDisabled
          description
          uom
          dimension
          temperature
          itemPublishStartDate
          itemPublishEndDate
          ecommerceMaximumQuantity
          pickupMaximumQuantity
          createdAt
          updatedAt
          divisionCode
          divisionDesc
          itemCategoryCode
          itemCategoryDesc
          productGroupCode
          productGroupDesc
          departmentLevel1
          departmentLevel2
          productStore {
            nextToken
          }
          productCategory {
            nextToken
          }
          productDepartment {
            nextToken
          }
          orderDetail {
            nextToken
          }
          productTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          productImage {
            nextToken
          }
          customerWishList {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const getUserFavWishList = /* GraphQL */ `
  query GetUserFavWishList(
    $token: String
    $nextToken: String
    $orderType: String
  ) {
    getUserFavWishList(
      token: $token
      nextToken: $nextToken
      orderType: $orderType
    ) {
      nextToken
      productList {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`
export const getProductList = /* GraphQL */ `
  query GetProductList(
    $categoryId: String
    $departmentId: String
    $productTaggingId: String
    $orderType: String
    $storeId: String
    $pageSize: Int
    $lastEvaluatedKey: String
  ) {
    getProductList(
      categoryId: $categoryId
      departmentId: $departmentId
      productTaggingId: $productTaggingId
      orderType: $orderType
      storeId: $storeId
      pageSize: $pageSize
      lastEvaluatedKey: $lastEvaluatedKey
    ) {
      title
      mainDepartmentTitle
      departmentId
      productTaggingId
      lastEvaluatedKey
      totalCount
      products {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isAvailable
        isDisabled
        description
        uom
        dimension
        temperature
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        itemCategoryCode
        promoCode
        priceGroupId
        productUOMId
        productUOMPriceGroupId
      }
    }
  }
`
export const getProductListing = /* GraphQL */ `
  query GetProductListing(
    $departmentId: String
    $productTaggingId: String
    $orderType: String
    $storeId: String
    $pageSize: Int
    $page: Int
  ) {
    getProductListing(
      departmentId: $departmentId
      productTaggingId: $productTaggingId
      orderType: $orderType
      storeId: $storeId
      pageSize: $pageSize
      page: $page
    ) {
      title
      mainDepartmentTitle
      departmentId
      productTaggingId
      lastEvaluatedKey
      totalCount
      products {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isAvailable
        isDisabled
        description
        uom
        dimension
        temperature
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        itemCategoryCode
        promoCode
        priceGroupId
        productUOMId
        productUOMPriceGroupId
      }
    }
  }
`
export const getStoreProductList = /* GraphQL */ `
  query GetStoreProductList($storeId: String, $orderType: String) {
    getStoreProductList(storeId: $storeId, orderType: $orderType) {
      productId
      isOutOfStock
    }
  }
`
export const getReportStatus = /* GraphQL */ `
  query GetReportStatus($reportId: String) {
    getReportStatus(reportId: $reportId) {
      reportStatus
      reportUrl
    }
  }
`
export const generateReportRequest = /* GraphQL */ `
  query GenerateReportRequest(
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $reportType: String
  ) {
    generateReportRequest(
      startDate: $startDate
      endDate: $endDate
      reportType: $reportType
    ) {
      reportId
      startDate
      endDate
      reportType
    }
  }
`
export const appLoad = /* GraphQL */ `
  query AppLoad($deviceType: String, $version: String) {
    appLoad(deviceType: $deviceType, version: $version) {
      forceInstall
      forceUpdate
      forceMaintenance
      crmForceMaintenance
      maintenanceTitle
      maintenanceDescription
      showFamiPointIndicator
      banners {
        appLaunchPromoId
        title
        image
        startDate
        endDate
        promoType
        promoEntityId
        isDisabled
        createdBy
        modifiedBy
        sequenceOrder
        buttonLabel
        createdAt
        updatedAt
      }
    }
  }
`
export const newAppLoad = /* GraphQL */ `
  query NewAppLoad($deviceType: String, $version: String, $key: String) {
    newAppLoad(deviceType: $deviceType, version: $version, key: $key) {
      forceUpdate
      banners {
        appLaunchPromoId
        title
        image
        startDate
        endDate
        promoType
        promoEntityId
        isDisabled
        createdBy
        modifiedBy
        sequenceOrder
        buttonLabel
        createdAt
        updatedAt
      }
      configValue
    }
  }
`
export const checkCart = /* GraphQL */ `
  query CheckCart(
    $token: String
    $storeId: String
    $returnTotalNumber: Boolean
    $paymentType: String
    $productList: String
    $orderType: String
    $scheduledDateTime: String
    $deliveryAddress: String
    $addressLatitude: String
    $addressLongitude: String
    $distance: Float
    $deviceDateTime: String
    $promoCode: String
    $voucherNumber: String
    $voucherEntryNumber: String
    $voucherExpiryDate: String
  ) {
    checkCart(
      token: $token
      storeId: $storeId
      returnTotalNumber: $returnTotalNumber
      paymentType: $paymentType
      productList: $productList
      orderType: $orderType
      scheduledDateTime: $scheduledDateTime
      deliveryAddress: $deliveryAddress
      addressLatitude: $addressLatitude
      addressLongitude: $addressLongitude
      distance: $distance
      deviceDateTime: $deviceDateTime
      promoCode: $promoCode
      voucherNumber: $voucherNumber
      voucherEntryNumber: $voucherEntryNumber
      voucherExpiryDate: $voucherExpiryDate
    ) {
      statusCode
      errorMessage
      storeStatus
      storeStatusMessage
      returnTotalNumber
      deliveryFee
      basketValue
      deliveryDiscountAmount
      products {
        quantity
        price
        discountedPrice
        subtotal
        discountAmount
        customerCartProductId
        title
        pickupMaximumQuantity
        ecommerceMaximumQuantity
        isOutOfStock
        image
        status
        mandatoryItem
        isFreeItem
        promoDiscount
        voucherDiscount
      }
      promoApplicable
      promoCode
      promoTitle
      promoDiscount
      promoDeliveryDiscount
      freeDeliveryMessage
      orderSubtotal
      deliverySurchargeAmount
      deliverySurchargeMessage
      indicationMessage
      deliveryFree
      discountTypeLevel
      discountDeliveryCap
      discountProductQuantityCap
      discountCap
      totalDiscountAmount
      totalPromoDiscount
      totalVoucherDiscount
      voucherDeliveryDiscount
      voucherApplicable
      voucherNumber
      voucherTitle
    }
  }
`
export const getCartRecommendations = /* GraphQL */ `
  query GetCartRecommendations($orderType: String, $storeId: String) {
    getCartRecommendations(orderType: $orderType, storeId: $storeId) {
      productId
      sku
      name
      title
      image
      price
      discount
      discountPercentage
      discountedPrice
      promotionDescription
      discountStartDate
      discountEndDate
      category
      categories
      departments
      taggings
      minFoodPreparationDuration
      minDeliveryDuration
      createdBy
      modifiedBy
      isAvailable
      isDisabled
      description
      uom
      dimension
      temperature
      ecommerceMaximumQuantity
      pickupMaximumQuantity
      itemCategoryCode
      promoCode
      priceGroupId
      productUOMId
      productUOMPriceGroupId
    }
  }
`
export const getRecommendations = /* GraphQL */ `
  query GetRecommendations($orderType: String, $storeId: String) {
    getRecommendations(orderType: $orderType, storeId: $storeId) {
      productId
      sku
      name
      title
      image
      price
      discount
      discountPercentage
      discountedPrice
      promotionDescription
      discountStartDate
      discountEndDate
      category
      categories
      departments
      taggings
      minFoodPreparationDuration
      minDeliveryDuration
      createdBy
      modifiedBy
      isAvailable
      isDisabled
      description
      uom
      dimension
      temperature
      ecommerceMaximumQuantity
      pickupMaximumQuantity
      itemCategoryCode
      promoCode
      priceGroupId
      productUOMId
      productUOMPriceGroupId
    }
  }
`
export const getTrendingList = /* GraphQL */ `
  query GetTrendingList {
    getTrendingList {
      trendingTextId
      title
      sequenceOrder
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getLandingProductList = /* GraphQL */ `
  query GetLandingProductList(
    $orderType: String
    $storeId: String
    $pageSize: Int
    $lastEvaluatedKey: String
  ) {
    getLandingProductList(
      orderType: $orderType
      storeId: $storeId
      pageSize: $pageSize
      lastEvaluatedKey: $lastEvaluatedKey
    ) {
      title
      mainDepartmentTitle
      departmentId
      productTaggingId
      lastEvaluatedKey
      totalCount
      products {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isAvailable
        isDisabled
        description
        uom
        dimension
        temperature
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        itemCategoryCode
        promoCode
        priceGroupId
        productUOMId
        productUOMPriceGroupId
      }
    }
  }
`
export const getLandingPageBannerList = /* GraphQL */ `
  query GetLandingPageBannerList($platform: String) {
    getLandingPageBannerList(platform: $platform) {
      landingPageBannerId
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      title
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
      promoEntityId
      promoType
      buttonLabel
      promoTitle
      platform
      shortLinkUrl
    }
  }
`
export const getHomePageBannerList = /* GraphQL */ `
  query GetHomePageBannerList {
    getHomePageBannerList {
      homePageBannerId
      bannerId
      bannerNumber
      image
      startDate
      endDate
      isDisabled
      sequenceOrder
      listBanners {
        id
        image
        title
        description
        sequence
      }
      title
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      pageLayout
      description
      validationPeriodId
      shortLinkUrl
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getStoreGroupList = /* GraphQL */ `
  query GetStoreGroupList {
    getStoreGroupList {
      storeGroupId
      code
      description
      stores
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const getPushNotificationList = /* GraphQL */ `
  query GetPushNotificationList($token: String, $nextToken: String) {
    getPushNotificationList(token: $token, nextToken: $nextToken) {
      nextToken
      pushNotification {
        pushNotificationId
        scheduleDateTime
        pushedDateTime
        messageSent
        newUserEndDate
        newUserStartDate
        body
        title
        bannerImage
        thumbnailImage
        createdBy
        modifiedBy
        createdAt
        updatedAt
        status
        actionType
        pageLayout
        actionId
        targetAudience {
          method
          numberOfAudience
          csvFilePath
          csvLastUpdated
        }
        readNotification
        senderMobileNumber
        senderName
        voucherExpiryDate
        voucherStatus
        voucherNumber
        receiverName
        voucherTitle
        inviteLink
      }
    }
  }
`
export const getLandingMenuList = /* GraphQL */ `
  query GetLandingMenuList($version: String, $platform: String) {
    getLandingMenuList(version: $version, platform: $platform) {
      landingMenuId
      actionType
      actionId
      title
      image
      effectiveStart
      effectiveEnd
      isDisabled
      sequenceOrder
      modifiedBy
      updatedAt
      createdAt
      shortLinkUrl
    }
  }
`
export const getOrderTransitionList = /* GraphQL */ `
  query GetOrderTransitionList($orderId: String) {
    getOrderTransitionList(orderId: $orderId) {
      orderTransitionLogId
      status
      orderId
      createdAt
      updatedAt
      kdsCreatedAt
      cancelReason
      refundReason
      refundAmount
      reasonCode
      cancelBy
      refundBy
      modifiedBy
    }
  }
`
export const getMenuList = /* GraphQL */ `
  query GetMenuList {
    getMenuList {
      name
      path
    }
  }
`
export const getProductRecord = /* GraphQL */ `
  query GetProductRecord(
    $productId: String
    $storeId: String
    $orderType: String
  ) {
    getProductRecord(
      productId: $productId
      storeId: $storeId
      orderType: $orderType
    ) {
      productId
      sku
      name
      title
      image
      price
      discount
      discountPercentage
      discountedPrice
      promotionDescription
      discountStartDate
      discountEndDate
      pickupPrice
      pickupDiscount
      pickupDiscountPercentage
      pickupDiscountedPrice
      pickupPromotionDescription
      pickupDiscountStartDate
      pickupDiscountEndDate
      category
      categories
      departments
      taggings
      minFoodPreparationDuration
      minDeliveryDuration
      createdBy
      modifiedBy
      isDisabled
      description
      uom
      dimension
      temperature
      itemPublishStartDate
      itemPublishEndDate
      ecommerceMaximumQuantity
      pickupMaximumQuantity
      createdAt
      updatedAt
      divisionCode
      divisionDesc
      itemCategoryCode
      itemCategoryDesc
      productGroupCode
      productGroupDesc
      departmentLevel1
      departmentLevel2
      productStore {
        items {
          productStoreId
          productStoreProductId
          isOutOfStock
          modifiedBy
          productStoreProductUOMId
          productStoreStoreId
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productCategory {
        items {
          productCategoryId
          category {
            categoryId
            title
            image
            code
            createdBy
            modifiedBy
            isDisabled
            parentId
            createdAt
            updatedAt
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productDepartment {
        items {
          productDepartmentId
          productDepartmentProductUOMId
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      orderDetail {
        items {
          orderDetailId
          orderDetailOrderId
          orderDetailPriceGroupId
          orderDetailProductId
          orderDetailProductUOMId
          orderDetailProductUOMPriceGroupId
          quantity
          subtotal
          discount
          promoDiscount
          mandatoryItem
          isFreeItem
          outOfStock
          itemStatus
          productName
          productDescription
          productImage
          createdAt
          updatedAt
          totalPromoCodeDiscount
          totalDiscountAmount
          totalVoucherDiscount
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          order {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          orderPayment {
            nextToken
          }
        }
        nextToken
      }
      productTaggingMapping {
        items {
          productTaggingMappingId
          productTaggingMappingProductId
          productTaggingMappingProductTaggingId
          productTaggingMappingProductUOMId
          modifiedBy
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productUOM {
            productUOMId
            productId
            image
            isDisabled
            description
            minFoodPreparationDuration
            pickupMaximumQuantity
            sku
            ecommerceMaximumQuantity
            departmentLevel1
            departmentLevel2
            productGroupDesc
            productGroupCode
            divisionDesc
            itemCategoryDesc
            title
            uom
            minDeliveryDuration
            taggings
            itemPublishStartDate
            itemPublishEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerCart {
        items {
          customerCartId
          quantity
          price
          subtotal
          discountAmount
          productName
          productTitle
          productCategories
          productTaggings
          productCode
          productImage
          mandatoryItem
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTaggingMapping {
        items {
          productStoreTaggingMapping
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productImage {
        items {
          productImageId
          image
          sequence
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      customerWishList {
        items {
          customerWishListId
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreDepartment {
        items {
          productStoreDepartmentId
          productStoreDepartmentProductUOMId
          productStoreDepartmentStoreId
          productStoreDepartmentSubDepartmentId
          productStoreDepartmentProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          department {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          subDepartment {
            departmentId
            title
            image
            createdBy
            modifiedBy
            updatedAt
            createdAt
            isDisabled
            effectiveStartDate
            effectiveEndDate
            parentId
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
      productStoreTagging {
        items {
          productStoreTaggingId
          productStoreTaggingProductUOMId
          productStoreTaggingProductTaggingId
          productStoreTaggingStoreId
          productStoreTaggingProductUOMPriceGroupId
          product {
            productId
            sku
            name
            title
            image
            price
            discount
            discountPercentage
            discountedPrice
            promotionDescription
            discountStartDate
            discountEndDate
            pickupPrice
            pickupDiscount
            pickupDiscountPercentage
            pickupDiscountedPrice
            pickupPromotionDescription
            pickupDiscountStartDate
            pickupDiscountEndDate
            category
            categories
            departments
            taggings
            minFoodPreparationDuration
            minDeliveryDuration
            createdBy
            modifiedBy
            isDisabled
            description
            uom
            dimension
            temperature
            itemPublishStartDate
            itemPublishEndDate
            ecommerceMaximumQuantity
            pickupMaximumQuantity
            createdAt
            updatedAt
            divisionCode
            divisionDesc
            itemCategoryCode
            itemCategoryDesc
            productGroupCode
            productGroupDesc
            departmentLevel1
            departmentLevel2
          }
          productTagging {
            productTaggingId
            title
            image
            code
            effectiveStartDate
            effectiveEndDate
            isDisabled
            createdBy
            modifiedBy
            updatedAt
            createdAt
          }
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          isOutOfStock
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`
export const getS3FileUrl = /* GraphQL */ `
  query GetS3FileUrl($token: String, $key: String) {
    getS3FileUrl(token: $token, key: $key)
  }
`
export const getChatList = /* GraphQL */ `
  query GetChatList($token: String, $platform: String) {
    getChatList(token: $token, platform: $platform) {
      issueId
      customerAccountNo
      totalAmount
      issueStatus
      totalMessage
      createdAt
      orderNumber
      hasNewMessage
      updatedAt
      totalQuantity
      issueDateTime
      redMineTicketNumber
      title
      productImage
      platform
    }
  }
`
export const getConfigValue = /* GraphQL */ `
  query GetConfigValue($key: String, $password: String) {
    getConfigValue(key: $key, password: $password)
  }
`
export const customSearchCustomers = /* GraphQL */ `
  query CustomSearchCustomers(
    $filter: CustomSearchCustomerFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      total
      nextToken
    }
  }
`
export const customSearchStores = /* GraphQL */ `
  query CustomSearchStores(
    $filter: CustomSearchStoreFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchStores(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      total
      nextToken
    }
  }
`
export const customSearchOrders = /* GraphQL */ `
  query CustomSearchOrders(
    $filter: CustomSearchOrderFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      total
      nextToken
    }
  }
`
export const customSearchProducts = /* GraphQL */ `
  query CustomSearchProducts(
    $filter: CustomSearchProductFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      total
      nextToken
    }
  }
`
export const customSearchDepartment = /* GraphQL */ `
  query CustomSearchDepartment(
    $filter: CustomSearchDepartmentFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchDepartment(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      total
      nextToken
    }
  }
`
export const customSearchLandingPageBanner = /* GraphQL */ `
  query CustomSearchLandingPageBanner(
    $filter: CustomSearchLandingPageBannerFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchLandingPageBanner(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        landingPageBannerId
        image
        effectiveStart
        effectiveEnd
        isDisabled
        sequenceOrder
        title
        description
        createdBy
        modifiedBy
        createdAt
        updatedAt
        promoEntityId
        promoType
        buttonLabel
        promoTitle
        platform
        shortLinkUrl
      }
      total
      nextToken
    }
  }
`
export const customSearchHomePageBanner = /* GraphQL */ `
  query CustomSearchHomePageBanner(
    $filter: CustomSearchHomePageBannerFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchHomePageBanner(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        homePageBannerId
        bannerId
        bannerNumber
        image
        startDate
        endDate
        isDisabled
        sequenceOrder
        listBanners {
          id
          image
          title
          description
          sequence
        }
        title
        buttonType
        buttonAction
        actionTitle
        buttonLabel
        pageLayout
        description
        validationPeriodId
        shortLinkUrl
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchNews = /* GraphQL */ `
  query CustomSearchNews(
    $filter: CustomSearchNewsFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchNews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        newsId
        pageLayout
        title
        description
        sequenceOrder
        isEnable
        image
        startDate
        endDate
        listNews {
          id
          image
          title
          description
          sequence
          buttonType
          buttonAction
          buttonLabel
          actionTitle
          detailImage
          startDate
          endDate
          enable
        }
        listNewsTwo
        listNewsThree
        buttonType
        buttonAction
        actionTitle
        buttonLabel
        referenceId
        validationPeriodId
        createdBy
        createdByRole
        modifiedBy
        createdAt
        updatedAt
        schemeGroup
        status
        message
        shortLinkUrl
      }
      total
      nextToken
    }
  }
`
export const customSearchHomePageLanding = /* GraphQL */ `
  query CustomSearchHomePageLanding(
    $filter: CustomSearchHomePageLandingFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchHomePageLanding(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        homePageLandingId
        title
        startDate
        endDate
        sequenceOrder
        isEnable
        validationPeriodId
        linkTo {
          id
          title
          sequence
          type
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchValidationPeriod = /* GraphQL */ `
  query CustomSearchValidationPeriod(
    $filter: CustomSearchValidationPeriodFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchValidationPeriod(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        validationPeriodId
        title
        startDate
        endDate
        startTime
        endTime
        timeWithinBounds
        daysTimeList {
          day
          startTime
          endTime
          timeWithinBounds
          endAfterMidnight
        }
        endAfterMidnight
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchStoreGroup = /* GraphQL */ `
  query CustomSearchStoreGroup(
    $filter: CustomSearchStoreGroupFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchStoreGroup(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        storeGroupId
        code
        description
        stores
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchAppLaunchPromo = /* GraphQL */ `
  query CustomSearchAppLaunchPromo(
    $filter: CustomSearchAppLaunchPromoFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchAppLaunchPromo(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        appLaunchPromoId
        title
        image
        startDate
        endDate
        promoType
        promoEntityId
        isDisabled
        createdBy
        modifiedBy
        sequenceOrder
        buttonLabel
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchProductTagging = /* GraphQL */ `
  query CustomSearchProductTagging(
    $filter: CustomSearchProductTaggingFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchProductTagging(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      total
      nextToken
    }
  }
`
export const customSearchRefundTransaction = /* GraphQL */ `
  query CustomSearchRefundTransaction(
    $filter: CustomSearchRefundTransactionFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchRefundTransaction(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        refundTransactionId
        refundAmount
        automatedRefundStatus
        emailRefundStatus
        manualRefundStatus
        manualCSRefundStatus
        eGHLRefundStatus
        customerServiceStatus
        orderId
        refundTxnId
        orderNumber
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        paymentMethod
        paymentChannel
        refundReason
        paymentId
        completedBy
        refundDateTime
        redmineTicketNumber
        lastOrderTransitionDate
        order {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchPushNotification = /* GraphQL */ `
  query CustomSearchPushNotification(
    $filter: CustomSearchPushNotificationFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchPushNotification(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        pushNotificationId
        scheduleDateTime
        pushedDateTime
        messageSent
        newUserEndDate
        newUserStartDate
        body
        title
        bannerImage
        thumbnailImage
        createdBy
        modifiedBy
        createdAt
        updatedAt
        status
        actionType
        pageLayout
        actionId
        targetAudience {
          method
          numberOfAudience
          csvFilePath
          csvLastUpdated
        }
        readNotification
        senderMobileNumber
        senderName
        voucherExpiryDate
        voucherStatus
        voucherNumber
        receiverName
        voucherTitle
        inviteLink
      }
      total
      nextToken
    }
  }
`
export const customSearchTrendingText = /* GraphQL */ `
  query CustomSearchTrendingText(
    $filter: CustomSearchTrendingTextFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchTrendingText(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        trendingTextId
        title
        sequenceOrder
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchLandingMenu = /* GraphQL */ `
  query CustomSearchLandingMenu(
    $filter: CustomSearchLandingMenuFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchLandingMenu(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        landingMenuId
        actionType
        actionId
        title
        image
        effectiveStart
        effectiveEnd
        isDisabled
        sequenceOrder
        modifiedBy
        updatedAt
        createdAt
        shortLinkUrl
      }
      total
      nextToken
    }
  }
`
export const customSearchLandingPageProduct = /* GraphQL */ `
  query CustomSearchLandingPageProduct(
    $filter: CustomSearchLandingPageProductFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchLandingPageProduct(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        landingPageProductId
        image
        effectiveStart
        effectiveEnd
        isDisabled
        sequenceOrder
        title
        productType
        productTypeId
        description
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchFeedback = /* GraphQL */ `
  query CustomSearchFeedback(
    $filter: CustomSearchFeedbackFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchFeedback(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        feedbackId
        searchLocation
        dateOfVisit
        timeOfVisit
        title
        description
        uploadPhoto
        createdBy
        modifiedBy
        redmineTicketNumber
        platform
        createdAt
        updatedAt
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        customerName
        customerMobileNo
        customerEmail
        issueStatus
      }
      total
      nextToken
    }
  }
`
export const customSearchPromotionPartner = /* GraphQL */ `
  query CustomSearchPromotionPartner(
    $filter: CustomSearchPromotionPartnerFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchPromotionPartner(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        promotionPartnerId
        image
        name
        defaultPrefix
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchPromoCodeCampaign = /* GraphQL */ `
  query CustomSearchPromoCodeCampaign(
    $filter: CustomSearchPromoCodeCampaignFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchPromoCodeCampaign(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        promoCodeId
        title
        campaignId
        navPromoId
        promoCode
        promoType
        forPartner
        maxUserUsageLimit
        totalUsageLimit
        totalNumOfCodes
        forMultipleUsage
        orderType
        deliveryFree
        discountTypeDelivery
        discountTypeLevel
        discountDeliveryCap
        discountProductQuantityCap
        discountCap
        discountType
        discountValue
        discountOnProductType
        discountOnProductValue
        deliveryFeeDiscount
        promoStartDatetime
        promoEndDatetime
        promoRepeatDay
        promoRepeatStartTime
        promoRepeatEndTime
        totalCount
        activeCount
        redeemedCount
        deactivatedCount
        athenaQueryId
        promoCodeStatusFile
        usageCondition
        productCategoryCondition {
          type
          ids
        }
        productCategoryDiscount {
          type
          ids
        }
        stores
        freeProducts {
          productId
          quantity
        }
        status
        minimumSpent
        minimumQuantity
        jobStatus
        modifiedBy
        isPromoEnabled
        platform
        statusUpdatedAt
        codeUpdatedAt
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchPromoCodeCampaignPartner = /* GraphQL */ `
  query CustomSearchPromoCodeCampaignPartner(
    $filter: CustomSearchPromoCodeCampaignPartnerFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchPromoCodeCampaignPartner(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        promoCodeCampaignPartnerId
        promoCodeId
        partnerName
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchPushAnnouncement = /* GraphQL */ `
  query CustomSearchPushAnnouncement(
    $filter: CustomSearchPushAnnouncementFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchPushAnnouncement(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        announcementId
        title
        startDate
        endDate
        scheduleDatetime
        body
        actionType
        actionId
        bannerImage
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchCustomerTag = /* GraphQL */ `
  query CustomSearchCustomerTag(
    $filter: CustomSearchCustomerTagFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchCustomerTag(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        tagId
        title
        tagMode
        startDate
        endDate
        ruleName
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchPriceGroup = /* GraphQL */ `
  query CustomSearchPriceGroup(
    $filter: CustomSearchPriceGroupFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchPriceGroup(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        priceGroupId
        title
        modifiedBy
        createdAt
        updatedAt
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      total
      nextToken
    }
  }
`
export const customSearchProductUOM = /* GraphQL */ `
  query CustomSearchProductUOM(
    $filter: CustomSearchProductUOMFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchProductUOM(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      total
      nextToken
    }
  }
`
export const customSearchProductUOMPriceGroup = /* GraphQL */ `
  query CustomSearchProductUOMPriceGroup(
    $filter: CustomSearchProductUOMPriceGroupFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchProductUOMPriceGroup(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        productUOMPriceGroupId
        productUOMId
        priceGroupId
        promotionDescription
        pickupPromotionDescription
        discountedPrice
        discountPercentage
        discount
        pickupDiscountedPrice
        pickupPromotionDiscount
        pickupDiscountPercentage
        pickupDiscount
        pickupPrice
        pickupEffectiveStartDate
        pickupEffectiveEndDate
        price
        discountStartDate
        discountEndDate
        effectiveStartDate
        effectiveEndDate
        pickupDiscountStartDate
        pickupDiscountEndDate
        modifiedBy
        createdAt
        updatedAt
        productUOM {
          productUOMId
          productId
          image
          isDisabled
          description
          minFoodPreparationDuration
          pickupMaximumQuantity
          sku
          ecommerceMaximumQuantity
          departmentLevel1
          departmentLevel2
          productGroupDesc
          productGroupCode
          divisionDesc
          itemCategoryDesc
          title
          uom
          minDeliveryDuration
          taggings
          itemPublishStartDate
          itemPublishEndDate
          modifiedBy
          createdAt
          updatedAt
          orderDetail {
            nextToken
          }
          productTaggingMapping {
            nextToken
          }
          productUOMPriceGroup {
            nextToken
          }
          productStore {
            nextToken
          }
          productDepartment {
            nextToken
          }
        }
        priceGroup {
          priceGroupId
          title
          modifiedBy
          createdAt
          updatedAt
          productUOMPriceGroup {
            nextToken
          }
        }
      }
      total
      nextToken
    }
  }
`
export const customSearchDivision = /* GraphQL */ `
  query CustomSearchDivision(
    $filter: CustomSearchDivisionFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchDivision(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        divisionId
        divisionCode
        divisionName
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchItemCategory = /* GraphQL */ `
  query CustomSearchItemCategory(
    $filter: CustomSearchItemCategoryFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchItemCategory(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        itemCategoryId
        itemCategoryCode
        itemCategoryName
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchProductGroup = /* GraphQL */ `
  query CustomSearchProductGroup(
    $filter: CustomSearchProductGroupFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchProductGroup(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        productGroupId
        productGroupCode
        productGroupDescription
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const customSearchNavItemExclusion = /* GraphQL */ `
  query CustomSearchNavItemExclusion(
    $filter: CustomSearchNavItemExclusionFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    customSearchNavItemExclusion(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        navItemExclusionId
        excludeFrom
        itemExclusionId
        itemExclusionProductCondition
        itemExclusionCode
        itemExclusionTitle
        itemExclusionItemCategoryDesc
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const getLalamoveLog = /* GraphQL */ `
  query GetLalamoveLog($orderId: String, $limit: Int, $nextToken: String) {
    getLalamoveLog(orderId: $orderId, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        lalamoveLogId
        createdAt
        orderId
        updatedAt
        driverName
        driverPhone
        lalamoveRequestType
        type
        customerOrderId
        orderRef
        result
      }
    }
  }
`
export const getGrabLog = /* GraphQL */ `
  query GetGrabLog($merchantOrderID: String, $limit: Int, $nextToken: String) {
    getGrabLog(
      merchantOrderID: $merchantOrderID
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        grabLogId
        createdAt
        merchantOrderID
        updatedAt
        driverName
        driverPhone
        deliveryId
        responseLog
        requestLog
        status
        grabRequestType
        type
        customerOrderId
        orderRef
        result
        amount
        currency
        trackURL
        dropoff
        pickup
      }
    }
  }
`
export const getPandaLog = /* GraphQL */ `
  query GetPandaLog($clientOrderId: String, $limit: Int, $nextToken: String) {
    getPandaLog(
      clientOrderId: $clientOrderId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        pandaLogId
        clientOrderId
        type
        status
        result
        pandaRequestType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getOrderPaymentLog = /* GraphQL */ `
  query GetOrderPaymentLog(
    $orderId: String
    $voucherOrderId: String
    $limit: Int
    $nextToken: String
  ) {
    getOrderPaymentLog(
      orderId: $orderId
      voucherOrderId: $voucherOrderId
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        eGHLTxnStatus
        eGHLRespTime
        paymentNumber
        eGHLCurrencyCode
        eGHLTxnMessage
        eGHLPaymentID
        orderId
        createdAt
        eGHLOrderNumber
        eGHLHashValue2
        eGHLAuthCode
        eGHLIssuingBank
        eGHLTransactionType
        paymentResponse
        eGHLHashValue
        eGHLPymtMethod
        eGHLBankRefNo
        orderNumber
        updatedAt
        eGHLAmount
        eGHLServiceID
        eGHLTxnID
        orderPaymentLogId
      }
    }
  }
`
export const getLandingProductListing = /* GraphQL */ `
  query GetLandingProductListing(
    $orderType: String
    $storeId: String
    $page: Int
    $pageSize: Int
  ) {
    getLandingProductListing(
      orderType: $orderType
      storeId: $storeId
      page: $page
      pageSize: $pageSize
    ) {
      title
      mainDepartmentTitle
      departmentId
      productTaggingId
      lastEvaluatedKey
      totalCount
      products {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isAvailable
        isDisabled
        description
        uom
        dimension
        temperature
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        itemCategoryCode
        promoCode
        priceGroupId
        productUOMId
        productUOMPriceGroupId
      }
    }
  }
`
export const getStoreGroupUsage = /* GraphQL */ `
  query GetStoreGroupUsage($storeGroupId: String!, $type: String!) {
    getStoreGroupUsage(storeGroupId: $storeGroupId, type: $type) {
      statusCode
      errorMessage
      usedInVoucher
      voucherList {
        voucherId
        voucherStartDate
        voucherEndDate
        title
        description
        tnc
        quantityLeft
        requiredPoint
        requiredPrice
        maxQuantityPerUser
        voucherImage
        discountType
        discountValue
        voucherType
        voucherTitle
        voucherCode
      }
    }
  }
`
export const listLandingMenus = /* GraphQL */ `
  query ListLandingMenus(
    $landingMenuId: ID
    $filter: ModelLandingMenuFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLandingMenus(
      landingMenuId: $landingMenuId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        landingMenuId
        actionType
        actionId
        title
        image
        effectiveStart
        effectiveEnd
        isDisabled
        sequenceOrder
        modifiedBy
        updatedAt
        createdAt
        shortLinkUrl
      }
      nextToken
    }
  }
`
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $categoryId: ID
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategorys(
      categoryId: $categoryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        categoryId
        title
        image
        code
        createdBy
        modifiedBy
        isDisabled
        parentId
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listTrendingTexts = /* GraphQL */ `
  query ListTrendingTexts(
    $trendingTextId: ID
    $filter: ModelTrendingTextFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTrendingTexts(
      trendingTextId: $trendingTextId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        trendingTextId
        title
        sequenceOrder
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listStoreGroups = /* GraphQL */ `
  query ListStoreGroups(
    $storeGroupId: ID
    $filter: ModelStoreGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStoreGroups(
      storeGroupId: $storeGroupId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        storeGroupId
        code
        description
        stores
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listAppLaunchPromos = /* GraphQL */ `
  query ListAppLaunchPromos(
    $appLaunchPromoId: ID
    $filter: ModelAppLaunchPromoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppLaunchPromos(
      appLaunchPromoId: $appLaunchPromoId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        appLaunchPromoId
        title
        image
        startDate
        endDate
        promoType
        promoEntityId
        isDisabled
        createdBy
        modifiedBy
        sequenceOrder
        buttonLabel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getValidationPeriod = /* GraphQL */ `
  query GetValidationPeriod($validationPeriodId: ID!) {
    getValidationPeriod(validationPeriodId: $validationPeriodId) {
      validationPeriodId
      title
      startDate
      endDate
      startTime
      endTime
      timeWithinBounds
      daysTimeList {
        day
        startTime
        endTime
        timeWithinBounds
        endAfterMidnight
      }
      endAfterMidnight
      createdAt
      updatedAt
    }
  }
`
export const listValidationPeriods = /* GraphQL */ `
  query ListValidationPeriods(
    $validationPeriodId: ID
    $showExpired: Boolean
    $filter: ModelValidationPeriodFilterInput
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    listValidationPeriods(
      validationPeriodId: $validationPeriodId
      showExpired: $showExpired
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        validationPeriodId
        title
        startDate
        endDate
        startTime
        endTime
        timeWithinBounds
        daysTimeList {
          day
          startTime
          endTime
          timeWithinBounds
          endAfterMidnight
        }
        endAfterMidnight
        createdAt
        updatedAt
      }
      total
      nextToken
    }
  }
`
export const getNews = /* GraphQL */ `
  query GetNews($newsId: ID!) {
    getNews(newsId: $newsId) {
      newsId
      pageLayout
      title
      description
      sequenceOrder
      isEnable
      image
      startDate
      endDate
      listNews {
        id
        image
        title
        description
        sequence
        buttonType
        buttonAction
        buttonLabel
        actionTitle
        detailImage
        startDate
        endDate
        enable
      }
      listNewsTwo
      listNewsThree
      buttonType
      buttonAction
      actionTitle
      buttonLabel
      referenceId
      validationPeriodId
      createdBy
      createdByRole
      modifiedBy
      createdAt
      updatedAt
      schemeGroup
      status
      message
      shortLinkUrl
    }
  }
`
export const listNewss = /* GraphQL */ `
  query ListNewss(
    $newsId: ID
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNewss(
      newsId: $newsId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        newsId
        pageLayout
        title
        description
        sequenceOrder
        isEnable
        image
        startDate
        endDate
        listNews {
          id
          image
          title
          description
          sequence
          buttonType
          buttonAction
          buttonLabel
          actionTitle
          detailImage
          startDate
          endDate
          enable
        }
        listNewsTwo
        listNewsThree
        buttonType
        buttonAction
        actionTitle
        buttonLabel
        referenceId
        validationPeriodId
        createdBy
        createdByRole
        modifiedBy
        createdAt
        updatedAt
        schemeGroup
        status
        message
        shortLinkUrl
      }
      nextToken
    }
  }
`
export const getHomePageLanding = /* GraphQL */ `
  query GetHomePageLanding($homePageLandingId: ID!) {
    getHomePageLanding(homePageLandingId: $homePageLandingId) {
      homePageLandingId
      title
      startDate
      endDate
      sequenceOrder
      isEnable
      validationPeriodId
      linkTo {
        id
        title
        sequence
        type
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listHomePageLandings = /* GraphQL */ `
  query ListHomePageLandings(
    $homePageLandingId: ID
    $filter: ModelHomePageLandingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHomePageLandings(
      homePageLandingId: $homePageLandingId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        homePageLandingId
        title
        startDate
        endDate
        sequenceOrder
        isEnable
        validationPeriodId
        linkTo {
          id
          title
          sequence
          type
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getHomePageLandingNews = /* GraphQL */ `
  query GetHomePageLandingNews($homePageLandingNewsId: ID!) {
    getHomePageLandingNews(homePageLandingNewsId: $homePageLandingNewsId) {
      homePageLandingNewsId
      homePageLandingId
      schemeGroup
      landingImage
      detailImage
      actionTitle
      title
      description
      createdBy
      modifiedBy
      createdAt
      updatedAt
      buttonType
      buttonAction
      buttonLabel
    }
  }
`
export const listHomePageLandingNewss = /* GraphQL */ `
  query ListHomePageLandingNewss(
    $homePageLandingNewsId: ID
    $filter: ModelHomePageLandingNewsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHomePageLandingNewss(
      homePageLandingNewsId: $homePageLandingNewsId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        homePageLandingNewsId
        homePageLandingId
        schemeGroup
        landingImage
        detailImage
        actionTitle
        title
        description
        createdBy
        modifiedBy
        createdAt
        updatedAt
        buttonType
        buttonAction
        buttonLabel
      }
      nextToken
    }
  }
`
export const getMembershipPoint = /* GraphQL */ `
  query GetMembershipPoint($membershipPointId: ID!) {
    getMembershipPoint(membershipPointId: $membershipPointId) {
      membershipPointId
      club
      filterType
      schemeFilterType
      filterCode
      unitRate
      points
      schemeGroup
      minTransactionAmount
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`
export const listMembershipPoints = /* GraphQL */ `
  query ListMembershipPoints(
    $membershipPointId: ID
    $filter: ModelMembershipPointFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ElasticSearchSortDirection
  ) {
    listMembershipPoints(
      membershipPointId: $membershipPointId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        membershipPointId
        club
        filterType
        schemeFilterType
        filterCode
        unitRate
        points
        schemeGroup
        minTransactionAmount
        startDate
        endDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVoucher = /* GraphQL */ `
  query GetVoucher($voucherId: ID!) {
    getVoucher(voucherId: $voucherId) {
      voucherId
      voucherNumber
      image
      mobileImage
      startDate
      endDate
      isEnabled
      voucherTitle
      description
      validationPeriodId
      departmentTaggingList {
        id
        title
      }
      productExemption
      usageCondition
      orderType
      orderTypeCombined
      schemeGroup
      allowVoucherGrouping
      allowVoucherSharing
      shareToNonMember
      limitPerTransaction
      limitPerDay
      productExemptionList {
        id
        sku
        title
      }
      productConditionList {
        id
        productCondition
        title
        sku
        uom
        itemCategoryDesc
        exclude
      }
      targetOnlineStore
      targetOnlineStoresCombined
      targetOfflineStore
      targetOfflineStoresCombined
      targetOnlineStoreList {
        storeGroupId
        code
      }
      targetOfflineStoreList {
        storeGroupId
        code
      }
      discountType
      productDiscountList {
        id
        sku
        title
        uom
        itemCategoryDesc
        productCondition
        exclude
      }
      discountOnProduct
      productMechanics
      productMechanicsValue
      totalTransactionType
      totalTransactionValue
      maxDiscountType
      maxDiscountValue
      maxDiscountAmount
      discountOnDelivery
      deliveryDiscountType
      deliveryDiscountValue
      createdBy
      modifiedBy
      createdAt
      updatedAt
      voucherValue
      basketCondition
      basketValue
    }
  }
`
export const listVouchers = /* GraphQL */ `
  query ListVouchers(
    $voucherId: ID
    $filter: ModelVoucherFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVouchers(
      voucherId: $voucherId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        voucherId
        voucherNumber
        image
        mobileImage
        startDate
        endDate
        isEnabled
        voucherTitle
        description
        validationPeriodId
        departmentTaggingList {
          id
          title
        }
        productExemption
        usageCondition
        orderType
        orderTypeCombined
        schemeGroup
        allowVoucherGrouping
        allowVoucherSharing
        shareToNonMember
        limitPerTransaction
        limitPerDay
        productExemptionList {
          id
          sku
          title
        }
        productConditionList {
          id
          productCondition
          title
          sku
          uom
          itemCategoryDesc
          exclude
        }
        targetOnlineStore
        targetOnlineStoresCombined
        targetOfflineStore
        targetOfflineStoresCombined
        targetOnlineStoreList {
          storeGroupId
          code
        }
        targetOfflineStoreList {
          storeGroupId
          code
        }
        discountType
        productDiscountList {
          id
          sku
          title
          uom
          itemCategoryDesc
          productCondition
          exclude
        }
        discountOnProduct
        productMechanics
        productMechanicsValue
        totalTransactionType
        totalTransactionValue
        maxDiscountType
        maxDiscountValue
        maxDiscountAmount
        discountOnDelivery
        deliveryDiscountType
        deliveryDiscountValue
        createdBy
        modifiedBy
        createdAt
        updatedAt
        voucherValue
        basketCondition
        basketValue
      }
      nextToken
    }
  }
`
export const getVoucherOrder = /* GraphQL */ `
  query GetVoucherOrder($voucherOrderId: ID!) {
    getVoucherOrder(voucherOrderId: $voucherOrderId) {
      voucherOrderId
      orderNumber
      orderDateTime
      paymentDateTime
      paymentMethod
      voucherImage
      orderCustomerId
      grandTotal
      discountAmount
      totalOrderItems
      status
      customerAccountNo
      customerFirstName
      customerLastName
      customerPhoneNumber
      createdAt
      updatedAt
      txnId
      eGHLCardType
      eGHLIssuingBank
      eGHLPymtMethod
    }
  }
`
export const getVoucherOrderDetail = /* GraphQL */ `
  query GetVoucherOrderDetail($voucherOrderDetailId: ID!) {
    getVoucherOrderDetail(voucherOrderDetailId: $voucherOrderDetailId) {
      voucherOrderDetailId
      quantity
      voucherOrderId
      benefitTriggerId
      subtotal
      title
      expiryDate
      startDate
      description
      image
      createdAt
      updatedAt
    }
  }
`
export const getSurveyQuestion = /* GraphQL */ `
  query GetSurveyQuestion($surveyQuestionId: ID!) {
    getSurveyQuestion(surveyQuestionId: $surveyQuestionId) {
      surveyQuestionId
      sequence
      questionImage
      surveyId
      description
      required
      questionType
      questionChoice
      question
      inputType
      choiceLabel {
        choice
        type
        label
      }
      createdAt
      updatedAt
      createdBy
      updatedBy
    }
  }
`
export const listSurveyQuestions = /* GraphQL */ `
  query ListSurveyQuestions(
    $surveyQuestionId: ID
    $filter: ModelSurveyQuestionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveyQuestions(
      surveyQuestionId: $surveyQuestionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        surveyQuestionId
        sequence
        questionImage
        surveyId
        description
        required
        questionType
        questionChoice
        question
        inputType
        choiceLabel {
          choice
          type
          label
        }
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
      nextToken
    }
  }
`
export const getSurvey = /* GraphQL */ `
  query GetSurvey($surveyId: ID!) {
    getSurvey(surveyId: $surveyId) {
      surveyId
      surveyName
      createdAt
      updatedAt
      createdBy
      updatedBy
    }
  }
`
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $surveyId: ID
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveys(
      surveyId: $surveyId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        surveyId
        surveyName
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
      nextToken
    }
  }
`
export const getBenefitTrigger = /* GraphQL */ `
  query GetBenefitTrigger($benefitTriggerId: ID!) {
    getBenefitTrigger(benefitTriggerId: $benefitTriggerId) {
      benefitTriggerId
      title
      image
      mobileImage
      voucherList
      description
      triggerType
      rewardType
      expirationType
      expirationValue
      expirationDateValue
      startDate
      endDate
      isEnabled
      validationPeriodId
      price
      points
      reasonCode
      purchaseLimit
      redeemLimit
      voucher {
        voucherId
        voucherTitle
        voucherNumber
        quantity
      }
      targetAudience {
        method
        audience {
          firstName
          lastName
          accountNo
        }
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      limited
      voucherQuantity
      filter
      flashDeal
      signUpVoucher {
        voucherId
        voucherNumber
        voucherTitle
        broadcastType
        broadcastValue
      }
      signUpPoints {
        amount
        reasonCode
        broadcastType
        broadcastValue
      }
      famiDealsVoucher {
        voucherId
        voucherTitle
        voucherNumber
        quantity
      }
      initialMinExpirationType
      initialMinExpirationValue
      minTransactionAmount
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listBenefitTriggers = /* GraphQL */ `
  query ListBenefitTriggers(
    $benefitTriggerId: ID
    $filter: ModelBenefitTriggerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBenefitTriggers(
      benefitTriggerId: $benefitTriggerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        benefitTriggerId
        title
        image
        mobileImage
        voucherList
        description
        triggerType
        rewardType
        expirationType
        expirationValue
        expirationDateValue
        startDate
        endDate
        isEnabled
        validationPeriodId
        price
        points
        reasonCode
        purchaseLimit
        redeemLimit
        voucher {
          voucherId
          voucherTitle
          voucherNumber
          quantity
        }
        targetAudience {
          method
          audience {
            firstName
            lastName
            accountNo
          }
          numberOfAudience
          csvFilePath
          csvLastUpdated
        }
        limited
        voucherQuantity
        filter
        flashDeal
        signUpVoucher {
          voucherId
          voucherNumber
          voucherTitle
          broadcastType
          broadcastValue
        }
        signUpPoints {
          amount
          reasonCode
          broadcastType
          broadcastValue
        }
        famiDealsVoucher {
          voucherId
          voucherTitle
          voucherNumber
          quantity
        }
        initialMinExpirationType
        initialMinExpirationValue
        minTransactionAmount
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAdHocSurveyQuestion = /* GraphQL */ `
  query GetAdHocSurveyQuestion($adHocSurveyQuestionId: ID!) {
    getAdHocSurveyQuestion(adHocSurveyQuestionId: $adHocSurveyQuestionId) {
      adHocSurveyQuestionId
      title
      broadcastMessageSchedule
      isEnable
      targetAudience {
        method
        audience {
          name
          accountNo
        }
        numberOfAudience
        csvFilePath
      }
      questions {
        questionNo
        question
        answerType
        multipleChoice {
          option
          question
        }
        ranking {
          rankFrom
          rankTo
          question
        }
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listAdHocSurveyQuestions = /* GraphQL */ `
  query ListAdHocSurveyQuestions(
    $adHocSurveyQuestionId: ID
    $filter: ModelAdHocSurveyQuestionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAdHocSurveyQuestions(
      adHocSurveyQuestionId: $adHocSurveyQuestionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        adHocSurveyQuestionId
        title
        broadcastMessageSchedule
        isEnable
        targetAudience {
          method
          audience {
            name
            accountNo
          }
          numberOfAudience
          csvFilePath
        }
        questions {
          questionNo
          question
          answerType
          multipleChoice {
            option
            question
          }
          ranking {
            rankFrom
            rankTo
            question
          }
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAdHocSurveyResponse = /* GraphQL */ `
  query GetAdHocSurveyResponse($adHocSurveyResponseId: ID!) {
    getAdHocSurveyResponse(adHocSurveyResponseId: $adHocSurveyResponseId) {
      adHocSurveyResponseId
      adHocSurveyQuestionId
      customerId
      responses {
        questionNo
        openTextAnswer
        multipleChoiceAnswer {
          option
          answer
        }
        rankingAnswer {
          option
          rank
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const listAdHocSurveyResponses = /* GraphQL */ `
  query ListAdHocSurveyResponses(
    $adHocSurveyResponseId: ID
    $filter: ModelAdHocSurveyResponseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAdHocSurveyResponses(
      adHocSurveyResponseId: $adHocSurveyResponseId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        adHocSurveyResponseId
        adHocSurveyQuestionId
        customerId
        responses {
          questionNo
          openTextAnswer
          multipleChoiceAnswer {
            option
            answer
          }
          rankingAnswer {
            option
            rank
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getInStoreOrder = /* GraphQL */ `
  query GetInStoreOrder($inStoreOrderId: ID!) {
    getInStoreOrder(inStoreOrderId: $inStoreOrderId) {
      inStoreOrderId
      orderDate
      orderNumber
      storeCode
      posTerminal
      grandTotal
      pointsCollected
      pointsRedeemed
      orderId
      customerId
      customerAccountNo
      transactionDateTime
      transactionNo
      receiptNo
      createdAt
      updatedAt
    }
  }
`
export const listInStoreOrders = /* GraphQL */ `
  query ListInStoreOrders(
    $inStoreOrderId: ID
    $filter: ModelInStoreOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInStoreOrders(
      inStoreOrderId: $inStoreOrderId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        inStoreOrderId
        orderDate
        orderNumber
        storeCode
        posTerminal
        grandTotal
        pointsCollected
        pointsRedeemed
        orderId
        customerId
        customerAccountNo
        transactionDateTime
        transactionNo
        receiptNo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getInStoreOrderDetail = /* GraphQL */ `
  query GetInStoreOrderDetail($inStoreOrderDetailId: ID!) {
    getInStoreOrderDetail(inStoreOrderDetailId: $inStoreOrderDetailId) {
      inStoreOrderDetailId
      customerAccountNo
      divisionCode
      createdAt
      updatedAt
      receiptNo
      transactionNo
      txnReceiptNo
      transactionDate
      storeCode
      posTerminal
      itemCode
      quantity
      pricePerQuantity
      subTotal
      pointEarned
      refundedQuantity
      lineNo
      refundedTransactionNo
      refundedPOSTerminal
      refundedStoreCode
    }
  }
`
export const listInStoreOrderDetails = /* GraphQL */ `
  query ListInStoreOrderDetails(
    $inStoreOrderDetailId: ID
    $filter: ModelInStoreOrderDetailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInStoreOrderDetails(
      inStoreOrderDetailId: $inStoreOrderDetailId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        inStoreOrderDetailId
        customerAccountNo
        divisionCode
        createdAt
        updatedAt
        receiptNo
        transactionNo
        txnReceiptNo
        transactionDate
        storeCode
        posTerminal
        itemCode
        quantity
        pricePerQuantity
        subTotal
        pointEarned
        refundedQuantity
        lineNo
        refundedTransactionNo
        refundedPOSTerminal
        refundedStoreCode
      }
      nextToken
    }
  }
`
export const getStamping = /* GraphQL */ `
  query GetStamping($stampingId: ID!) {
    getStamping(stampingId: $stampingId) {
      stampingId
      stampId
      startDate
      endDate
      image
      schemeGroup
      isEnabled
      stampTitle
      description
      stampType
      stampValue
      minTransactionAmount
      collectionPeriodType
      collectionPeriodValue
      benefitExpirationType
      benefitExpirationValue
      targetOnlineStore
      targetOfflineStore
      targetOnlineStoreList {
        storeGroupId
        code
      }
      targetOfflineStoreList {
        storeGroupId
        code
      }
      stampRepeat
      validationPeriodId
      voucher {
        voucherId
        voucherNumber
        voucherTitle
        milestone
      }
      stampPromotionLines {
        filterType
        filterCode
        title
        exclude
        numberOfStamp
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listStampings = /* GraphQL */ `
  query ListStampings(
    $stampingId: ID
    $filter: ModelStampingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStampings(
      stampingId: $stampingId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        stampingId
        stampId
        startDate
        endDate
        image
        schemeGroup
        isEnabled
        stampTitle
        description
        stampType
        stampValue
        minTransactionAmount
        collectionPeriodType
        collectionPeriodValue
        benefitExpirationType
        benefitExpirationValue
        targetOnlineStore
        targetOfflineStore
        targetOnlineStoreList {
          storeGroupId
          code
        }
        targetOfflineStoreList {
          storeGroupId
          code
        }
        stampRepeat
        validationPeriodId
        voucher {
          voucherId
          voucherNumber
          voucherTitle
          milestone
        }
        stampPromotionLines {
          filterType
          filterCode
          title
          exclude
          numberOfStamp
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getStampPromotion = /* GraphQL */ `
  query GetStampPromotion($stampPromotionId: ID!) {
    getStampPromotion(stampPromotionId: $stampPromotionId) {
      stampPromotionId
      filterType
      filterCode
      numberOfStamp
      stampId
      validationId
      startDate
      endDate
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listStampPromotions = /* GraphQL */ `
  query ListStampPromotions(
    $stampPromotionId: ID
    $filter: ModelStampPromotionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStampPromotions(
      stampPromotionId: $stampPromotionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        stampPromotionId
        filterType
        filterCode
        numberOfStamp
        stampId
        validationId
        startDate
        endDate
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getNotificationSetup = /* GraphQL */ `
  query GetNotificationSetup($notificationSetupId: ID!) {
    getNotificationSetup(notificationSetupId: $notificationSetupId) {
      notificationSetupId
      notificationTitle
      pushNotificationMessage
      notificationType
      isEnabled
      entryType
      entryCode
      schedulerJobType
      expirationForecastType
      expirationForecastValue
      broadcastMessageSchedule
      showToNewUser
      targetAudience {
        method
        numberOfAudience
        csvFilePath
        csvLastUpdated
      }
      bannerImage
      thumbnailImage
      actionType
      actionId
      scheduleTime
      pageLayout
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listNotificationSetups = /* GraphQL */ `
  query ListNotificationSetups(
    $notificationSetupId: ID
    $filter: ModelNotificationSetupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotificationSetups(
      notificationSetupId: $notificationSetupId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        notificationSetupId
        notificationTitle
        pushNotificationMessage
        notificationType
        isEnabled
        entryType
        entryCode
        schedulerJobType
        expirationForecastType
        expirationForecastValue
        broadcastMessageSchedule
        showToNewUser
        targetAudience {
          method
          numberOfAudience
          csvFilePath
          csvLastUpdated
        }
        bannerImage
        thumbnailImage
        actionType
        actionId
        scheduleTime
        pageLayout
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getNavItemList = /* GraphQL */ `
  query GetNavItemList($navItemListId: ID!) {
    getNavItemList(navItemListId: $navItemListId) {
      navItemListId
      sku
      description
      uom
      divisionCode
      itemCategoryCode
      productGroupCode
      createdAt
      updatedAt
    }
  }
`
export const listNavItemLists = /* GraphQL */ `
  query ListNavItemLists(
    $navItemListId: ID
    $filter: ModelNavItemListFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNavItemLists(
      navItemListId: $navItemListId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        navItemListId
        sku
        description
        uom
        divisionCode
        itemCategoryCode
        productGroupCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listPushNotifications = /* GraphQL */ `
  query ListPushNotifications(
    $pushNotificationId: ID
    $filter: ModelPushNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPushNotifications(
      pushNotificationId: $pushNotificationId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pushNotificationId
        scheduleDateTime
        pushedDateTime
        messageSent
        newUserEndDate
        newUserStartDate
        body
        title
        bannerImage
        thumbnailImage
        createdBy
        modifiedBy
        createdAt
        updatedAt
        status
        actionType
        pageLayout
        actionId
        targetAudience {
          method
          numberOfAudience
          csvFilePath
          csvLastUpdated
        }
        readNotification
        senderMobileNumber
        senderName
        voucherExpiryDate
        voucherStatus
        voucherNumber
        receiverName
        voucherTitle
        inviteLink
      }
      nextToken
    }
  }
`
export const getUserSignInAttempt = /* GraphQL */ `
  query GetUserSignInAttempt($id: ID!) {
    getUserSignInAttempt(id: $id) {
      id
      username
      userId
      attemptCount
      loginStatus
      loginTimeout
      lastLoginTime
      accessToken
      ipAddress
      deviceKey
      createdAt
      updatedAt
    }
  }
`
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $announcementId: ID
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnnouncements(
      announcementId: $announcementId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        announcementId
        title
        startDate
        endDate
        scheduleDatetime
        body
        actionType
        actionId
        bannerImage
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const listCustomerTagRules = /* GraphQL */ `
  query ListCustomerTagRules(
    $ruleId: ID
    $filter: ModelCustomerTagRuleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCustomerTagRules(
      ruleId: $ruleId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ruleId
        tagId
        ruleType
        ruleField
        ruleOperator
        ruleValue
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getDivision = /* GraphQL */ `
  query GetDivision($divisionId: ID!) {
    getDivision(divisionId: $divisionId) {
      divisionId
      divisionCode
      divisionName
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listDivisions = /* GraphQL */ `
  query ListDivisions(
    $divisionId: ID
    $filter: ModelDivisionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDivisions(
      divisionId: $divisionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        divisionId
        divisionCode
        divisionName
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getItemCategory = /* GraphQL */ `
  query GetItemCategory($itemCategoryId: ID!) {
    getItemCategory(itemCategoryId: $itemCategoryId) {
      itemCategoryId
      itemCategoryCode
      itemCategoryName
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listItemCategorys = /* GraphQL */ `
  query ListItemCategorys(
    $itemCategoryId: ID
    $filter: ModelItemCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listItemCategorys(
      itemCategoryId: $itemCategoryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        itemCategoryId
        itemCategoryCode
        itemCategoryName
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getProductGroup = /* GraphQL */ `
  query GetProductGroup($productGroupId: ID!) {
    getProductGroup(productGroupId: $productGroupId) {
      productGroupId
      productGroupCode
      productGroupDescription
      createdBy
      modifiedBy
      createdAt
      updatedAt
    }
  }
`
export const listProductGroups = /* GraphQL */ `
  query ListProductGroups(
    $productGroupId: ID
    $filter: ModelProductGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProductGroups(
      productGroupId: $productGroupId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        productGroupId
        productGroupCode
        productGroupDescription
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getNavItemExclusion = /* GraphQL */ `
  query GetNavItemExclusion($navItemExclusionId: ID!) {
    getNavItemExclusion(navItemExclusionId: $navItemExclusionId) {
      navItemExclusionId
      excludeFrom
      itemExclusionId
      itemExclusionProductCondition
      itemExclusionCode
      itemExclusionTitle
      itemExclusionItemCategoryDesc
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`
export const searchStores = /* GraphQL */ `
  query SearchStores(
    $filter: SearchableStoreFilterInput
    $sort: SearchableStoreSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchStores(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        storeId
        driveThru
        visibleToEComm
        storeName
        address
        latitude
        longitude
        openHour
        closeHour
        deliveryDiscountType
        deliveryServiceAvailable
        pickupServiceAvailable
        minFoodPreparationDuration
        minDeliveryDuration
        deliveryFee
        isDisabled
        isOnline24Hour
        isOffline24Hour
        postalCode
        city
        state
        storeCode
        lastOrderTime
        minPurchaseAmount
        freeDeliveryWithMinPurchase
        acceptOrderWithMinPurchase
        maxOrderQty
        eCommStartDate
        eCommEndDate
        hasSeatingArea
        createdBy
        modifiedBy
        storeManagerName
        storeManagerContact
        priceGroupId
        storeGroup
        createdAt
        updatedAt
        minDistanceCharges
        minDistance
        pollingDeliveryTime
        riderTimeout
        deliveryGracePeriod
        routingOption
        priorityDeliveryPartner
        incrementDistanceCharges
        incrementDistanceUnit
        deliveryDiscountAmount
        deliveryDiscountBasketValue
        deliveryPartnerMaxRetry
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        operatingHour {
          items {
            storeOperatingHourId
            onlineStoreOpenHour
            onlineStoreCloseHour
            offlineStoreOpenHour
            offlineStoreCloseHour
            isOffline24Hour
            isOnline24Hour
            onlineIsOpen
            offlineIsOpen
            day
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        storeBlockedOutPeriods {
          items {
            storeBlockedOutPeriodId
            startDateTime
            endDateTime
            storeType
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        StoreDeliveryPartnerMapping {
          items {
            partnerId
            storeDeliveryPartnerStoreId
            partnerName
            sequence
            isPriority
            createdBy
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`
export const searchLandingMenus = /* GraphQL */ `
  query SearchLandingMenus(
    $filter: SearchableLandingMenuFilterInput
    $sort: SearchableLandingMenuSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchLandingMenus(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        landingMenuId
        actionType
        actionId
        title
        image
        effectiveStart
        effectiveEnd
        isDisabled
        sequenceOrder
        modifiedBy
        updatedAt
        createdAt
        shortLinkUrl
      }
      nextToken
      total
    }
  }
`
export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        productId
        sku
        name
        title
        image
        price
        discount
        discountPercentage
        discountedPrice
        promotionDescription
        discountStartDate
        discountEndDate
        pickupPrice
        pickupDiscount
        pickupDiscountPercentage
        pickupDiscountedPrice
        pickupPromotionDescription
        pickupDiscountStartDate
        pickupDiscountEndDate
        category
        categories
        departments
        taggings
        minFoodPreparationDuration
        minDeliveryDuration
        createdBy
        modifiedBy
        isDisabled
        description
        uom
        dimension
        temperature
        itemPublishStartDate
        itemPublishEndDate
        ecommerceMaximumQuantity
        pickupMaximumQuantity
        createdAt
        updatedAt
        divisionCode
        divisionDesc
        itemCategoryCode
        itemCategoryDesc
        productGroupCode
        productGroupDesc
        departmentLevel1
        departmentLevel2
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productCategory {
          items {
            productCategoryId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productImage {
          items {
            productImageId
            image
            sequence
            createdAt
            updatedAt
          }
          nextToken
        }
        customerWishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`
export const searchProductStoreDepartments = /* GraphQL */ `
  query SearchProductStoreDepartments(
    $filter: SearchableProductStoreDepartmentFilterInput
    $sort: SearchableProductStoreDepartmentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductStoreDepartments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        productStoreDepartmentId
        productStoreDepartmentProductUOMId
        productStoreDepartmentStoreId
        productStoreDepartmentSubDepartmentId
        productStoreDepartmentProductUOMPriceGroupId
        product {
          productId
          sku
          name
          title
          image
          price
          discount
          discountPercentage
          discountedPrice
          promotionDescription
          discountStartDate
          discountEndDate
          pickupPrice
          pickupDiscount
          pickupDiscountPercentage
          pickupDiscountedPrice
          pickupPromotionDescription
          pickupDiscountStartDate
          pickupDiscountEndDate
          category
          categories
          departments
          taggings
          minFoodPreparationDuration
          minDeliveryDuration
          createdBy
          modifiedBy
          isDisabled
          description
          uom
          dimension
          temperature
          itemPublishStartDate
          itemPublishEndDate
          ecommerceMaximumQuantity
          pickupMaximumQuantity
          createdAt
          updatedAt
          divisionCode
          divisionDesc
          itemCategoryCode
          itemCategoryDesc
          productGroupCode
          productGroupDesc
          departmentLevel1
          departmentLevel2
          productStore {
            nextToken
          }
          productCategory {
            nextToken
          }
          productDepartment {
            nextToken
          }
          orderDetail {
            nextToken
          }
          productTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          productImage {
            nextToken
          }
          customerWishList {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        department {
          departmentId
          title
          image
          createdBy
          modifiedBy
          updatedAt
          createdAt
          isDisabled
          effectiveStartDate
          effectiveEndDate
          parentId
          productDepartment {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreSubDepartment {
            nextToken
          }
        }
        subDepartment {
          departmentId
          title
          image
          createdBy
          modifiedBy
          updatedAt
          createdAt
          isDisabled
          effectiveStartDate
          effectiveEndDate
          parentId
          productDepartment {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreSubDepartment {
            nextToken
          }
        }
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        isOutOfStock
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchProductStoreTaggings = /* GraphQL */ `
  query SearchProductStoreTaggings(
    $filter: SearchableProductStoreTaggingFilterInput
    $sort: SearchableProductStoreTaggingSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductStoreTaggings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        productStoreTaggingId
        productStoreTaggingProductUOMId
        productStoreTaggingProductTaggingId
        productStoreTaggingStoreId
        productStoreTaggingProductUOMPriceGroupId
        product {
          productId
          sku
          name
          title
          image
          price
          discount
          discountPercentage
          discountedPrice
          promotionDescription
          discountStartDate
          discountEndDate
          pickupPrice
          pickupDiscount
          pickupDiscountPercentage
          pickupDiscountedPrice
          pickupPromotionDescription
          pickupDiscountStartDate
          pickupDiscountEndDate
          category
          categories
          departments
          taggings
          minFoodPreparationDuration
          minDeliveryDuration
          createdBy
          modifiedBy
          isDisabled
          description
          uom
          dimension
          temperature
          itemPublishStartDate
          itemPublishEndDate
          ecommerceMaximumQuantity
          pickupMaximumQuantity
          createdAt
          updatedAt
          divisionCode
          divisionDesc
          itemCategoryCode
          itemCategoryDesc
          productGroupCode
          productGroupDesc
          departmentLevel1
          departmentLevel2
          productStore {
            nextToken
          }
          productCategory {
            nextToken
          }
          productDepartment {
            nextToken
          }
          orderDetail {
            nextToken
          }
          productTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          productImage {
            nextToken
          }
          customerWishList {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        productTagging {
          productTaggingId
          title
          image
          code
          effectiveStartDate
          effectiveEndDate
          isDisabled
          createdBy
          modifiedBy
          updatedAt
          createdAt
          productTaggingMapping {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        isOutOfStock
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchProductStores = /* GraphQL */ `
  query SearchProductStores(
    $filter: SearchableProductStoreFilterInput
    $sort: SearchableProductStoreSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductStores(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        productStoreId
        productStoreProductId
        isOutOfStock
        modifiedBy
        productStoreProductUOMId
        productStoreStoreId
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        product {
          productId
          sku
          name
          title
          image
          price
          discount
          discountPercentage
          discountedPrice
          promotionDescription
          discountStartDate
          discountEndDate
          pickupPrice
          pickupDiscount
          pickupDiscountPercentage
          pickupDiscountedPrice
          pickupPromotionDescription
          pickupDiscountStartDate
          pickupDiscountEndDate
          category
          categories
          departments
          taggings
          minFoodPreparationDuration
          minDeliveryDuration
          createdBy
          modifiedBy
          isDisabled
          description
          uom
          dimension
          temperature
          itemPublishStartDate
          itemPublishEndDate
          ecommerceMaximumQuantity
          pickupMaximumQuantity
          createdAt
          updatedAt
          divisionCode
          divisionDesc
          itemCategoryCode
          itemCategoryDesc
          productGroupCode
          productGroupDesc
          departmentLevel1
          departmentLevel2
          productStore {
            nextToken
          }
          productCategory {
            nextToken
          }
          productDepartment {
            nextToken
          }
          orderDetail {
            nextToken
          }
          productTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          productImage {
            nextToken
          }
          customerWishList {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        productUOM {
          productUOMId
          productId
          image
          isDisabled
          description
          minFoodPreparationDuration
          pickupMaximumQuantity
          sku
          ecommerceMaximumQuantity
          departmentLevel1
          departmentLevel2
          productGroupDesc
          productGroupCode
          divisionDesc
          itemCategoryDesc
          title
          uom
          minDeliveryDuration
          taggings
          itemPublishStartDate
          itemPublishEndDate
          modifiedBy
          createdAt
          updatedAt
          orderDetail {
            nextToken
          }
          productTaggingMapping {
            nextToken
          }
          productUOMPriceGroup {
            nextToken
          }
          productStore {
            nextToken
          }
          productDepartment {
            nextToken
          }
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchFeedbacks = /* GraphQL */ `
  query SearchFeedbacks(
    $filter: SearchableFeedbackFilterInput
    $sort: SearchableFeedbackSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchFeedbacks(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        feedbackId
        searchLocation
        dateOfVisit
        timeOfVisit
        title
        description
        uploadPhoto
        createdBy
        modifiedBy
        redmineTicketNumber
        platform
        createdAt
        updatedAt
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        customerName
        customerMobileNo
        customerEmail
        issueStatus
      }
      nextToken
      total
    }
  }
`
export const searchDepartments = /* GraphQL */ `
  query SearchDepartments(
    $filter: SearchableDepartmentFilterInput
    $sort: SearchableDepartmentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDepartments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        departmentId
        title
        image
        createdBy
        modifiedBy
        updatedAt
        createdAt
        isDisabled
        effectiveStartDate
        effectiveEndDate
        parentId
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreSubDepartment {
          items {
            productStoreDepartmentId
            productStoreDepartmentProductUOMId
            productStoreDepartmentStoreId
            productStoreDepartmentSubDepartmentId
            productStoreDepartmentProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`
export const searchProductTaggings = /* GraphQL */ `
  query SearchProductTaggings(
    $filter: SearchableProductTaggingFilterInput
    $sort: SearchableProductTaggingSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductTaggings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        productTaggingId
        title
        image
        code
        effectiveStartDate
        effectiveEndDate
        isDisabled
        createdBy
        modifiedBy
        updatedAt
        createdAt
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTaggingMapping {
          items {
            productStoreTaggingMapping
            createdAt
            updatedAt
          }
          nextToken
        }
        productStoreTagging {
          items {
            productStoreTaggingId
            productStoreTaggingProductUOMId
            productStoreTaggingProductTaggingId
            productStoreTaggingStoreId
            productStoreTaggingProductUOMPriceGroupId
            isOutOfStock
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`
export const searchVoucherRewards = /* GraphQL */ `
  query SearchVoucherRewards(
    $filter: SearchableVoucherRewardFilterInput
    $sort: SearchableVoucherRewardSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchVoucherRewards(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        rewardId
        image
        startDate
        endDate
        point
        description
        title
        tnc
        cashValueAmount
        skuCashDiscount
        voucherType
        voucherStore {
          items {
            voucherRewardStoreId
            rewardId
            latitude
            longitude
            storeId
            createdAt
            updatedAt
          }
          nextToken
        }
        voucherSKU {
          items {
            voucherRewardSKUId
            rewardId
            entityId
            entityType
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        orderId
        orderNumber
        voucherNumber
        voucherTitle
        voucherEntryNumber
        orderDate
        paymentDateTime
        productImage
        grandTotal
        discountAmount
        deliveryFee
        voucherDiscount
        totalOrderItems
        status
        noteToRider
        floorOrUnit
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        storeName
        storeCode
        orderType
        deliveryAddress
        deliveryAddressLatitude
        deliveryAddressLongitude
        remarks
        routingOption
        reasonCode
        cancelReason
        cancelBy
        orderCancellationDateTime
        collectedDateTime
        createdAt
        updatedAt
        isAdvancedOrder
        scheduledDateTime
        driverName
        driverPhone
        driverPlateNumber
        lalamoveOrderId
        lalamoveOrderDate
        lalamoveTotalFee
        deliveryStatus
        intOrderNumber
        isRefunded
        refundAmount
        refundReason
        txnId
        refundCompleteDateTime
        orderCompleteDateTime
        cancelOrderDateTime
        requiredCutlery
        deliveryOrderDateTime
        deliveryOrderId
        deliveryPartner
        deliveryPartnerFee
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
        riderJobNumber
        deliveryNumber
        totalOutOfStock
        partialFulfilmentAmount
        partialFulfilmentDiscount
        accumulatedRefundAmount
        promoDiscount
        promoCodeTitle
        promoCode
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
        voucher {
          rewardId
          image
          startDate
          endDate
          point
          description
          title
          tnc
          cashValueAmount
          skuCashDiscount
          voucherType
          voucherStore {
            nextToken
          }
          voucherSKU {
            nextToken
          }
          order {
            nextToken
          }
          createdAt
          updatedAt
        }
        totalVoucherDiscount
        voucherRefunded
        promoCodeCampaignId
        netDiscountAmount
        oriTotalVoucherDiscount
        deliveryDiscount
        netDeliveryDiscount
        deliverySurchargeAmount
        orderDetails {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        customer {
          customerId
          accountNo
          clubCode
          firstName
          lastName
          primaryEmail
          secondaryEmail
          country
          address
          address2
          identityCard
          custClubCode
          custSchemeCode
          joinDate
          joinTime
          staffId
          staffJoinDate
          isBlocked
          actionDate
          actionReasonCode
          actionBy
          consentPersonalData
          city
          state
          postal
          mobileNumber
          password
          gender
          dateOfBirth
          maritalStatus
          race
          employmentStatus
          personalIncomeLevel
          activated
          facebookID
          isFacebook
          qrCode
          profilePicture
          nationality
          signedUpDate
          signedUpTime
          hasRewarded
          promoCode
          tagCode
          createdBy
          modifiedBy
          customerDeviceToken
          customerDeviceEndpoint
          areaOfInterest
          createdAt
          updatedAt
          hasCompletedProfile
          userPointTransaction {
            nextToken
          }
          customerTagCode {
            nextToken
          }
          order {
            nextToken
          }
          customerVouchers {
            voucherId
            rewardId
            status
            createdAt
            updatedAt
          }
          customerCart {
            nextToken
          }
          wishList {
            nextToken
          }
          orderReview {
            nextToken
          }
          customerFavouriteAddress {
            nextToken
          }
          customerFavouritePayment {
            nextToken
          }
          customerFeedback {
            nextToken
          }
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        orderPayment {
          items {
            orderPaymentId
            amount
            status
            paymentNumber
            paymentOrderNumber
            transactionType
            paymentMethod
            paymentResponseBase64
            eGHLTransactionType
            eGHLPymtMethod
            eGHLServiceID
            eGHLPaymentID
            eGHLOrderNumber
            eGHLAmount
            eGHLCurrencyCode
            eGHLHashValue
            eGHLHashValue2
            eGHLTxnID
            eGHLIssuingBank
            eGHLTxnStatus
            eGHLAuthCode
            eGHLBankRefNo
            eGHLRespTime
            eGHLTxnMessage
            createdAt
            updatedAt
          }
          nextToken
        }
        refundTransaction {
          items {
            refundTransactionId
            refundAmount
            automatedRefundStatus
            emailRefundStatus
            manualRefundStatus
            manualCSRefundStatus
            eGHLRefundStatus
            customerServiceStatus
            orderId
            refundTxnId
            orderNumber
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            paymentMethod
            paymentChannel
            refundReason
            paymentId
            completedBy
            refundDateTime
            redmineTicketNumber
            lastOrderTransitionDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`
export const customSearchVoucherOrder = /* GraphQL */ `
  query CustomSearchVoucherOrder(
    $filter: SearchableVoucherOrderFilterInput
    $sort: SearchableVoucherOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchVoucherOrder(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        voucherOrderId
        orderNumber
        orderDateTime
        paymentDateTime
        paymentMethod
        voucherImage
        orderCustomerId
        grandTotal
        discountAmount
        totalOrderItems
        status
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        createdAt
        updatedAt
        txnId
        eGHLCardType
        eGHLIssuingBank
        eGHLPymtMethod
      }
      nextToken
      total
    }
  }
`
export const searchRefundTransactions = /* GraphQL */ `
  query SearchRefundTransactions(
    $filter: SearchableRefundTransactionFilterInput
    $sort: SearchableRefundTransactionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchRefundTransactions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        refundTransactionId
        refundAmount
        automatedRefundStatus
        emailRefundStatus
        manualRefundStatus
        manualCSRefundStatus
        eGHLRefundStatus
        customerServiceStatus
        orderId
        refundTxnId
        orderNumber
        customerAccountNo
        customerFirstName
        customerLastName
        customerPhoneNumber
        paymentMethod
        paymentChannel
        refundReason
        paymentId
        completedBy
        refundDateTime
        redmineTicketNumber
        lastOrderTransitionDate
        order {
          orderId
          orderNumber
          voucherNumber
          voucherTitle
          voucherEntryNumber
          orderDate
          paymentDateTime
          productImage
          grandTotal
          discountAmount
          deliveryFee
          voucherDiscount
          totalOrderItems
          status
          noteToRider
          floorOrUnit
          customerAccountNo
          customerFirstName
          customerLastName
          customerPhoneNumber
          storeName
          storeCode
          orderType
          deliveryAddress
          deliveryAddressLatitude
          deliveryAddressLongitude
          remarks
          routingOption
          reasonCode
          cancelReason
          cancelBy
          orderCancellationDateTime
          collectedDateTime
          createdAt
          updatedAt
          isAdvancedOrder
          scheduledDateTime
          driverName
          driverPhone
          driverPlateNumber
          lalamoveOrderId
          lalamoveOrderDate
          lalamoveTotalFee
          deliveryStatus
          intOrderNumber
          isRefunded
          refundAmount
          refundReason
          txnId
          refundCompleteDateTime
          orderCompleteDateTime
          cancelOrderDateTime
          requiredCutlery
          deliveryOrderDateTime
          deliveryOrderId
          deliveryPartner
          deliveryPartnerFee
          eGHLCardType
          eGHLIssuingBank
          eGHLPymtMethod
          riderJobNumber
          deliveryNumber
          totalOutOfStock
          partialFulfilmentAmount
          partialFulfilmentDiscount
          accumulatedRefundAmount
          promoDiscount
          promoCodeTitle
          promoCode
          store {
            storeId
            driveThru
            visibleToEComm
            storeName
            address
            latitude
            longitude
            openHour
            closeHour
            deliveryDiscountType
            deliveryServiceAvailable
            pickupServiceAvailable
            minFoodPreparationDuration
            minDeliveryDuration
            deliveryFee
            isDisabled
            isOnline24Hour
            isOffline24Hour
            postalCode
            city
            state
            storeCode
            lastOrderTime
            minPurchaseAmount
            freeDeliveryWithMinPurchase
            acceptOrderWithMinPurchase
            maxOrderQty
            eCommStartDate
            eCommEndDate
            hasSeatingArea
            createdBy
            modifiedBy
            storeManagerName
            storeManagerContact
            priceGroupId
            storeGroup
            createdAt
            updatedAt
            minDistanceCharges
            minDistance
            pollingDeliveryTime
            riderTimeout
            deliveryGracePeriod
            routingOption
            priorityDeliveryPartner
            incrementDistanceCharges
            incrementDistanceUnit
            deliveryDiscountAmount
            deliveryDiscountBasketValue
            deliveryPartnerMaxRetry
          }
          voucher {
            rewardId
            image
            startDate
            endDate
            point
            description
            title
            tnc
            cashValueAmount
            skuCashDiscount
            voucherType
            createdAt
            updatedAt
          }
          totalVoucherDiscount
          voucherRefunded
          promoCodeCampaignId
          netDiscountAmount
          oriTotalVoucherDiscount
          deliveryDiscount
          netDeliveryDiscount
          deliverySurchargeAmount
          orderDetails {
            nextToken
          }
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          orderReview {
            nextToken
          }
          orderPayment {
            nextToken
          }
          refundTransaction {
            nextToken
          }
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchPostTransactionSurveys = /* GraphQL */ `
  query SearchPostTransactionSurveys(
    $filter: SearchablePostTransactionSurveyFilterInput
    $sort: SearchablePostTransactionSurveySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPostTransactionSurveys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        postTransactionSurveyId
        dateOfVisit
        timeOfVisit
        storeName
        customerName
        createdAt
        updatedAt
      }
      answers
      nextToken
      total
    }
  }
`
export const customSearchPostTransactionSurvey = /* GraphQL */ `
  query CustomSearchPostTransactionSurvey(
    $filter: SearchablePostTransactionSurveyFilterInput
    $sort: SearchablePostTransactionSurveySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchPostTransactionSurvey(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        postTransactionSurveyId
        dateOfVisit
        timeOfVisit
        storeName
        customerName
        createdAt
        updatedAt
      }
      answers
      nextToken
      total
    }
  }
`
export const searchTrendingTexts = /* GraphQL */ `
  query SearchTrendingTexts(
    $filter: SearchableTrendingTextFilterInput
    $sort: SearchableTrendingTextSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTrendingTexts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        trendingTextId
        title
        sequenceOrder
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchStoreGroups = /* GraphQL */ `
  query SearchStoreGroups(
    $filter: SearchableStoreGroupFilterInput
    $sort: SearchableStoreGroupSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchStoreGroups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        storeGroupId
        code
        description
        stores
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchAppLaunchPromos = /* GraphQL */ `
  query SearchAppLaunchPromos(
    $filter: SearchableAppLaunchPromoFilterInput
    $sort: SearchableAppLaunchPromoSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAppLaunchPromos(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        appLaunchPromoId
        title
        image
        startDate
        endDate
        promoType
        promoEntityId
        isDisabled
        createdBy
        modifiedBy
        sequenceOrder
        buttonLabel
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchValidationPeriods = /* GraphQL */ `
  query SearchValidationPeriods(
    $filter: SearchableValidationPeriodFilterInput
    $sort: SearchableValidationPeriodSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchValidationPeriods(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        validationPeriodId
        title
        startDate
        endDate
        startTime
        endTime
        timeWithinBounds
        daysTimeList {
          day
          startTime
          endTime
          timeWithinBounds
          endAfterMidnight
        }
        endAfterMidnight
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchNewss = /* GraphQL */ `
  query SearchNewss(
    $filter: SearchableNewsFilterInput
    $sort: SearchableNewsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchNewss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        newsId
        pageLayout
        title
        description
        sequenceOrder
        isEnable
        image
        startDate
        endDate
        listNews {
          id
          image
          title
          description
          sequence
          buttonType
          buttonAction
          buttonLabel
          actionTitle
          detailImage
          startDate
          endDate
          enable
        }
        listNewsTwo
        listNewsThree
        buttonType
        buttonAction
        actionTitle
        buttonLabel
        referenceId
        validationPeriodId
        createdBy
        createdByRole
        modifiedBy
        createdAt
        updatedAt
        schemeGroup
        status
        message
        shortLinkUrl
      }
      nextToken
      total
    }
  }
`
export const searchHomePageLandings = /* GraphQL */ `
  query SearchHomePageLandings(
    $filter: SearchableHomePageLandingFilterInput
    $sort: SearchableHomePageLandingSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchHomePageLandings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        homePageLandingId
        title
        startDate
        endDate
        sequenceOrder
        isEnable
        validationPeriodId
        linkTo {
          id
          title
          sequence
          type
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchVouchers = /* GraphQL */ `
  query SearchVouchers(
    $filter: SearchableVoucherFilterInput
    $sort: SearchableVoucherSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchVouchers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        voucherId
        voucherNumber
        image
        mobileImage
        startDate
        endDate
        isEnabled
        voucherTitle
        description
        validationPeriodId
        departmentTaggingList {
          id
          title
        }
        productExemption
        usageCondition
        orderType
        orderTypeCombined
        schemeGroup
        allowVoucherGrouping
        allowVoucherSharing
        shareToNonMember
        limitPerTransaction
        limitPerDay
        productExemptionList {
          id
          sku
          title
        }
        productConditionList {
          id
          productCondition
          title
          sku
          uom
          itemCategoryDesc
          exclude
        }
        targetOnlineStore
        targetOnlineStoresCombined
        targetOfflineStore
        targetOfflineStoresCombined
        targetOnlineStoreList {
          storeGroupId
          code
        }
        targetOfflineStoreList {
          storeGroupId
          code
        }
        discountType
        productDiscountList {
          id
          sku
          title
          uom
          itemCategoryDesc
          productCondition
          exclude
        }
        discountOnProduct
        productMechanics
        productMechanicsValue
        totalTransactionType
        totalTransactionValue
        maxDiscountType
        maxDiscountValue
        maxDiscountAmount
        discountOnDelivery
        deliveryDiscountType
        deliveryDiscountValue
        createdBy
        modifiedBy
        createdAt
        updatedAt
        voucherValue
        basketCondition
        basketValue
      }
      nextToken
      total
    }
  }
`
export const searchBenefitTriggers = /* GraphQL */ `
  query SearchBenefitTriggers(
    $filter: SearchableBenefitTriggerFilterInput
    $sort: SearchableBenefitTriggerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBenefitTriggers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        benefitTriggerId
        title
        image
        mobileImage
        voucherList
        description
        triggerType
        rewardType
        expirationType
        expirationValue
        expirationDateValue
        startDate
        endDate
        isEnabled
        validationPeriodId
        price
        points
        reasonCode
        purchaseLimit
        redeemLimit
        voucher {
          voucherId
          voucherTitle
          voucherNumber
          quantity
        }
        targetAudience {
          method
          audience {
            firstName
            lastName
            accountNo
          }
          numberOfAudience
          csvFilePath
          csvLastUpdated
        }
        limited
        voucherQuantity
        filter
        flashDeal
        signUpVoucher {
          voucherId
          voucherNumber
          voucherTitle
          broadcastType
          broadcastValue
        }
        signUpPoints {
          amount
          reasonCode
          broadcastType
          broadcastValue
        }
        famiDealsVoucher {
          voucherId
          voucherTitle
          voucherNumber
          quantity
        }
        initialMinExpirationType
        initialMinExpirationValue
        minTransactionAmount
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchAdHocSurveyQuestions = /* GraphQL */ `
  query SearchAdHocSurveyQuestions(
    $filter: SearchableAdHocSurveyQuestionFilterInput
    $sort: SearchableAdHocSurveyQuestionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAdHocSurveyQuestions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        adHocSurveyQuestionId
        title
        broadcastMessageSchedule
        isEnable
        targetAudience {
          method
          audience {
            name
            accountNo
          }
          numberOfAudience
          csvFilePath
        }
        questions {
          questionNo
          question
          answerType
          multipleChoice {
            option
            question
          }
          ranking {
            rankFrom
            rankTo
            question
          }
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchInStoreOrders = /* GraphQL */ `
  query SearchInStoreOrders(
    $filter: SearchableInStoreOrderFilterInput
    $sort: SearchableInStoreOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchInStoreOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        inStoreOrderId
        orderDate
        orderNumber
        storeCode
        posTerminal
        grandTotal
        pointsCollected
        pointsRedeemed
        orderId
        customerId
        customerAccountNo
        transactionDateTime
        transactionNo
        receiptNo
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const customSearchInStoreOrder = /* GraphQL */ `
  query CustomSearchInStoreOrder(
    $filter: SearchableInStoreOrderFilterInput
    $sort: SearchableInStoreOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchInStoreOrder(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        inStoreOrderId
        orderDate
        orderNumber
        storeCode
        posTerminal
        grandTotal
        pointsCollected
        pointsRedeemed
        orderId
        customerId
        customerAccountNo
        transactionDateTime
        transactionNo
        receiptNo
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchInStoreOrderDetails = /* GraphQL */ `
  query SearchInStoreOrderDetails(
    $filter: SearchableInStoreOrderDetailFilterInput
    $sort: SearchableInStoreOrderDetailSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchInStoreOrderDetails(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        inStoreOrderDetailId
        customerAccountNo
        divisionCode
        createdAt
        updatedAt
        receiptNo
        transactionNo
        txnReceiptNo
        transactionDate
        storeCode
        posTerminal
        itemCode
        quantity
        pricePerQuantity
        subTotal
        pointEarned
        refundedQuantity
        lineNo
        refundedTransactionNo
        refundedPOSTerminal
        refundedStoreCode
      }
      nextToken
      total
    }
  }
`
export const customSearchInStoreOrderDetail = /* GraphQL */ `
  query CustomSearchInStoreOrderDetail(
    $filter: SearchableInStoreOrderDetailFilterInput
    $sort: SearchableInStoreOrderDetailSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchInStoreOrderDetail(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        inStoreOrderDetailId
        customerAccountNo
        divisionCode
        createdAt
        updatedAt
        receiptNo
        transactionNo
        txnReceiptNo
        transactionDate
        storeCode
        posTerminal
        itemCode
        quantity
        pricePerQuantity
        subTotal
        pointEarned
        refundedQuantity
        lineNo
        refundedTransactionNo
        refundedPOSTerminal
        refundedStoreCode
      }
      nextToken
      total
    }
  }
`
export const searchStampings = /* GraphQL */ `
  query SearchStampings(
    $filter: SearchableStampingFilterInput
    $sort: SearchableStampingSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchStampings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        stampingId
        stampId
        startDate
        endDate
        image
        schemeGroup
        isEnabled
        stampTitle
        description
        stampType
        stampValue
        minTransactionAmount
        collectionPeriodType
        collectionPeriodValue
        benefitExpirationType
        benefitExpirationValue
        targetOnlineStore
        targetOfflineStore
        targetOnlineStoreList {
          storeGroupId
          code
        }
        targetOfflineStoreList {
          storeGroupId
          code
        }
        stampRepeat
        validationPeriodId
        voucher {
          voucherId
          voucherNumber
          voucherTitle
          milestone
        }
        stampPromotionLines {
          filterType
          filterCode
          title
          exclude
          numberOfStamp
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const customSearchStamping = /* GraphQL */ `
  query CustomSearchStamping(
    $filter: SearchableStampingFilterInput
    $sort: SearchableStampingSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchStamping(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        stampingId
        stampId
        startDate
        endDate
        image
        schemeGroup
        isEnabled
        stampTitle
        description
        stampType
        stampValue
        minTransactionAmount
        collectionPeriodType
        collectionPeriodValue
        benefitExpirationType
        benefitExpirationValue
        targetOnlineStore
        targetOfflineStore
        targetOnlineStoreList {
          storeGroupId
          code
        }
        targetOfflineStoreList {
          storeGroupId
          code
        }
        stampRepeat
        validationPeriodId
        voucher {
          voucherId
          voucherNumber
          voucherTitle
          milestone
        }
        stampPromotionLines {
          filterType
          filterCode
          title
          exclude
          numberOfStamp
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchStampPromotions = /* GraphQL */ `
  query SearchStampPromotions(
    $filter: SearchableStampPromotionFilterInput
    $sort: SearchableStampPromotionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchStampPromotions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        stampPromotionId
        filterType
        filterCode
        numberOfStamp
        stampId
        validationId
        startDate
        endDate
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchNotificationSetups = /* GraphQL */ `
  query SearchNotificationSetups(
    $filter: SearchableNotificationSetupFilterInput
    $sort: SearchableNotificationSetupSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchNotificationSetups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        notificationSetupId
        notificationTitle
        pushNotificationMessage
        notificationType
        isEnabled
        entryType
        entryCode
        schedulerJobType
        expirationForecastType
        expirationForecastValue
        broadcastMessageSchedule
        showToNewUser
        targetAudience {
          method
          numberOfAudience
          csvFilePath
          csvLastUpdated
        }
        bannerImage
        thumbnailImage
        actionType
        actionId
        scheduleTime
        pageLayout
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchNavItemLists = /* GraphQL */ `
  query SearchNavItemLists(
    $filter: SearchableNavItemListFilterInput
    $sort: SearchableNavItemListSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchNavItemLists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        navItemListId
        sku
        description
        uom
        divisionCode
        itemCategoryCode
        productGroupCode
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const customSearchNavItemList = /* GraphQL */ `
  query CustomSearchNavItemList(
    $filter: SearchableNavItemListFilterInput
    $sort: SearchableNavItemListSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchNavItemList(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        navItemListId
        sku
        description
        uom
        divisionCode
        itemCategoryCode
        productGroupCode
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const customSearchNotificationSetup = /* GraphQL */ `
  query CustomSearchNotificationSetup(
    $filter: SearchableNotificationSetupFilterInput
    $sort: SearchableNotificationSetupSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchNotificationSetup(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        notificationSetupId
        notificationTitle
        pushNotificationMessage
        notificationType
        isEnabled
        entryType
        entryCode
        schedulerJobType
        expirationForecastType
        expirationForecastValue
        broadcastMessageSchedule
        showToNewUser
        targetAudience {
          method
          numberOfAudience
          csvFilePath
          csvLastUpdated
        }
        bannerImage
        thumbnailImage
        actionType
        actionId
        scheduleTime
        pageLayout
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const customSearchStampPromotion = /* GraphQL */ `
  query CustomSearchStampPromotion(
    $filter: SearchableStampPromotionFilterInput
    $sort: SearchableStampPromotionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchStampPromotion(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        stampPromotionId
        filterType
        filterCode
        numberOfStamp
        stampId
        validationId
        startDate
        endDate
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const customSearchAdHocSurveyQuestion = /* GraphQL */ `
  query CustomSearchAdHocSurveyQuestion(
    $filter: SearchableAdHocSurveyQuestionFilterInput
    $sort: SearchableAdHocSurveyQuestionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchAdHocSurveyQuestion(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        adHocSurveyQuestionId
        title
        broadcastMessageSchedule
        isEnable
        targetAudience {
          method
          audience {
            name
            accountNo
          }
          numberOfAudience
          csvFilePath
        }
        questions {
          questionNo
          question
          answerType
          multipleChoice {
            option
            question
          }
          ranking {
            rankFrom
            rankTo
            question
          }
        }
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const customSearchBenefitTrigger = /* GraphQL */ `
  query CustomSearchBenefitTrigger(
    $filter: SearchableBenefitTriggerFilterInput
    $sort: SearchableBenefitTriggerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchBenefitTrigger(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        benefitTriggerId
        title
        image
        mobileImage
        voucherList
        description
        triggerType
        rewardType
        expirationType
        expirationValue
        expirationDateValue
        startDate
        endDate
        isEnabled
        validationPeriodId
        price
        points
        reasonCode
        purchaseLimit
        redeemLimit
        voucher {
          voucherId
          voucherTitle
          voucherNumber
          quantity
        }
        targetAudience {
          method
          audience {
            firstName
            lastName
            accountNo
          }
          numberOfAudience
          csvFilePath
          csvLastUpdated
        }
        limited
        voucherQuantity
        filter
        flashDeal
        signUpVoucher {
          voucherId
          voucherNumber
          voucherTitle
          broadcastType
          broadcastValue
        }
        signUpPoints {
          amount
          reasonCode
          broadcastType
          broadcastValue
        }
        famiDealsVoucher {
          voucherId
          voucherTitle
          voucherNumber
          quantity
        }
        initialMinExpirationType
        initialMinExpirationValue
        minTransactionAmount
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const customSearchVoucher = /* GraphQL */ `
  query CustomSearchVoucher(
    $filter: SearchableVoucherFilterInput
    $sort: SearchableVoucherSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchVoucher(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        voucherId
        voucherNumber
        image
        mobileImage
        startDate
        endDate
        isEnabled
        voucherTitle
        description
        validationPeriodId
        departmentTaggingList {
          id
          title
        }
        productExemption
        usageCondition
        orderType
        orderTypeCombined
        schemeGroup
        allowVoucherGrouping
        allowVoucherSharing
        shareToNonMember
        limitPerTransaction
        limitPerDay
        productExemptionList {
          id
          sku
          title
        }
        productConditionList {
          id
          productCondition
          title
          sku
          uom
          itemCategoryDesc
          exclude
        }
        targetOnlineStore
        targetOnlineStoresCombined
        targetOfflineStore
        targetOfflineStoresCombined
        targetOnlineStoreList {
          storeGroupId
          code
        }
        targetOfflineStoreList {
          storeGroupId
          code
        }
        discountType
        productDiscountList {
          id
          sku
          title
          uom
          itemCategoryDesc
          productCondition
          exclude
        }
        discountOnProduct
        productMechanics
        productMechanicsValue
        totalTransactionType
        totalTransactionValue
        maxDiscountType
        maxDiscountValue
        maxDiscountAmount
        discountOnDelivery
        deliveryDiscountType
        deliveryDiscountValue
        createdBy
        modifiedBy
        createdAt
        updatedAt
        voucherValue
        basketCondition
        basketValue
      }
      nextToken
      total
    }
  }
`
export const searchLandingPageProducts = /* GraphQL */ `
  query SearchLandingPageProducts(
    $filter: SearchableLandingPageProductFilterInput
    $sort: SearchableLandingPageProductSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchLandingPageProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        landingPageProductId
        image
        effectiveStart
        effectiveEnd
        isDisabled
        sequenceOrder
        title
        productType
        productTypeId
        description
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchDeliveryPartners = /* GraphQL */ `
  query SearchDeliveryPartners(
    $filter: SearchableDeliveryPartnerFilterInput
    $sort: SearchableDeliveryPartnerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDeliveryPartners(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        deliveryPartnerId
        deliveryPartnerName
        apiEndpoint
        isCancelAfterAcceptAllowed
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchStoreDeliveryPartners = /* GraphQL */ `
  query SearchStoreDeliveryPartners(
    $filter: SearchableStoreDeliveryPartnerFilterInput
    $sort: SearchableStoreDeliveryPartnerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchStoreDeliveryPartners(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        partnerId
        storeDeliveryPartnerStoreId
        partnerName
        sequence
        isPriority
        createdBy
        modifiedBy
        createdAt
        updatedAt
        store {
          storeId
          driveThru
          visibleToEComm
          storeName
          address
          latitude
          longitude
          openHour
          closeHour
          deliveryDiscountType
          deliveryServiceAvailable
          pickupServiceAvailable
          minFoodPreparationDuration
          minDeliveryDuration
          deliveryFee
          isDisabled
          isOnline24Hour
          isOffline24Hour
          postalCode
          city
          state
          storeCode
          lastOrderTime
          minPurchaseAmount
          freeDeliveryWithMinPurchase
          acceptOrderWithMinPurchase
          maxOrderQty
          eCommStartDate
          eCommEndDate
          hasSeatingArea
          createdBy
          modifiedBy
          storeManagerName
          storeManagerContact
          priceGroupId
          storeGroup
          createdAt
          updatedAt
          minDistanceCharges
          minDistance
          pollingDeliveryTime
          riderTimeout
          deliveryGracePeriod
          routingOption
          priorityDeliveryPartner
          incrementDistanceCharges
          incrementDistanceUnit
          deliveryDiscountAmount
          deliveryDiscountBasketValue
          deliveryPartnerMaxRetry
          order {
            nextToken
          }
          operatingHour {
            nextToken
          }
          productStore {
            nextToken
          }
          storeBlockedOutPeriods {
            nextToken
          }
          productStoreTaggingMapping {
            nextToken
          }
          customerCart {
            nextToken
          }
          StoreDeliveryPartnerMapping {
            nextToken
          }
          productStoreDepartment {
            nextToken
          }
          productStoreTagging {
            nextToken
          }
        }
      }
      nextToken
      total
    }
  }
`
export const searchOrderDeliveryOrders = /* GraphQL */ `
  query SearchOrderDeliveryOrders(
    $filter: SearchableOrderDeliveryOrderFilterInput
    $sort: SearchableOrderDeliveryOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrderDeliveryOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        orderDeliveryOrderId
        deliveryPartner
        deliveryNumber
        orderNumber
        status
        totalFee
        createdBy
        modifiedBy
        createdAt
        updatedAt
        deliveryOrderDateTime
      }
      nextToken
      total
    }
  }
`
export const searchPushNotifications = /* GraphQL */ `
  query SearchPushNotifications(
    $filter: SearchablePushNotificationFilterInput
    $sort: SearchablePushNotificationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPushNotifications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        pushNotificationId
        scheduleDateTime
        pushedDateTime
        messageSent
        newUserEndDate
        newUserStartDate
        body
        title
        bannerImage
        thumbnailImage
        createdBy
        modifiedBy
        createdAt
        updatedAt
        status
        actionType
        pageLayout
        actionId
        targetAudience {
          method
          numberOfAudience
          csvFilePath
          csvLastUpdated
        }
        readNotification
        senderMobileNumber
        senderName
        voucherExpiryDate
        voucherStatus
        voucherNumber
        receiverName
        voucherTitle
        inviteLink
      }
      nextToken
      total
    }
  }
`
export const searchNotifications = /* GraphQL */ `
  query SearchNotifications(
    $filter: SearchableNotificationFilterInput
    $sort: SearchableNotificationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchNotifications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        notificationId
        customerId
        title
        thumbnailImage
        summary
        bannerImage
        description
        link
        pageLayout
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomerFilterInput
    $sort: SearchableCustomerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        customerId
        accountNo
        clubCode
        firstName
        lastName
        primaryEmail
        secondaryEmail
        country
        address
        address2
        identityCard
        custClubCode
        custSchemeCode
        joinDate
        joinTime
        staffId
        staffJoinDate
        isBlocked
        actionDate
        actionReasonCode
        actionBy
        consentPersonalData
        city
        state
        postal
        mobileNumber
        password
        gender
        dateOfBirth
        maritalStatus
        race
        employmentStatus
        personalIncomeLevel
        activated
        facebookID
        isFacebook
        qrCode
        profilePicture
        nationality
        signedUpDate
        signedUpTime
        hasRewarded
        promoCode
        tagCode
        createdBy
        modifiedBy
        customerDeviceToken
        customerDeviceEndpoint
        areaOfInterest
        createdAt
        updatedAt
        hasCompletedProfile
        userPointTransaction {
          items {
            transactionId
            pointValue
            note
            createdAt
            updatedAt
          }
          nextToken
        }
        customerTagCode {
          items {
            customerTagCodeId
            tagCode
            createdAt
            updatedAt
          }
          nextToken
        }
        order {
          items {
            orderId
            orderNumber
            voucherNumber
            voucherTitle
            voucherEntryNumber
            orderDate
            paymentDateTime
            productImage
            grandTotal
            discountAmount
            deliveryFee
            voucherDiscount
            totalOrderItems
            status
            noteToRider
            floorOrUnit
            customerAccountNo
            customerFirstName
            customerLastName
            customerPhoneNumber
            storeName
            storeCode
            orderType
            deliveryAddress
            deliveryAddressLatitude
            deliveryAddressLongitude
            remarks
            routingOption
            reasonCode
            cancelReason
            cancelBy
            orderCancellationDateTime
            collectedDateTime
            createdAt
            updatedAt
            isAdvancedOrder
            scheduledDateTime
            driverName
            driverPhone
            driverPlateNumber
            lalamoveOrderId
            lalamoveOrderDate
            lalamoveTotalFee
            deliveryStatus
            intOrderNumber
            isRefunded
            refundAmount
            refundReason
            txnId
            refundCompleteDateTime
            orderCompleteDateTime
            cancelOrderDateTime
            requiredCutlery
            deliveryOrderDateTime
            deliveryOrderId
            deliveryPartner
            deliveryPartnerFee
            eGHLCardType
            eGHLIssuingBank
            eGHLPymtMethod
            riderJobNumber
            deliveryNumber
            totalOutOfStock
            partialFulfilmentAmount
            partialFulfilmentDiscount
            accumulatedRefundAmount
            promoDiscount
            promoCodeTitle
            promoCode
            totalVoucherDiscount
            voucherRefunded
            promoCodeCampaignId
            netDiscountAmount
            oriTotalVoucherDiscount
            deliveryDiscount
            netDeliveryDiscount
            deliverySurchargeAmount
          }
          nextToken
        }
        customerVouchers {
          voucherId
          rewardId
          status
          customer {
            customerId
            accountNo
            clubCode
            firstName
            lastName
            primaryEmail
            secondaryEmail
            country
            address
            address2
            identityCard
            custClubCode
            custSchemeCode
            joinDate
            joinTime
            staffId
            staffJoinDate
            isBlocked
            actionDate
            actionReasonCode
            actionBy
            consentPersonalData
            city
            state
            postal
            mobileNumber
            password
            gender
            dateOfBirth
            maritalStatus
            race
            employmentStatus
            personalIncomeLevel
            activated
            facebookID
            isFacebook
            qrCode
            profilePicture
            nationality
            signedUpDate
            signedUpTime
            hasRewarded
            promoCode
            tagCode
            createdBy
            modifiedBy
            customerDeviceToken
            customerDeviceEndpoint
            areaOfInterest
            createdAt
            updatedAt
            hasCompletedProfile
          }
          createdAt
          updatedAt
        }
        customerCart {
          items {
            customerCartId
            quantity
            price
            subtotal
            discountAmount
            productName
            productTitle
            productCategories
            productTaggings
            productCode
            productImage
            mandatoryItem
            createdAt
            updatedAt
          }
          nextToken
        }
        wishList {
          items {
            customerWishListId
            createdAt
            updatedAt
          }
          nextToken
        }
        orderReview {
          items {
            orderReviewId
            overallRating
            foodRating
            waitingTimeRating
            driverServiceRating
            staffServiceRating
            productAvailabilityRating
            comment
            attachment
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouriteAddress {
          items {
            customerFavouriteAddressId
            title
            country
            address
            address2
            city
            state
            postal
            isPrimary
            latitude
            longitude
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFavouritePayment {
          items {
            customerFavouritePaymentId
            creditCardNumber
            paymentToken
            paymentType
            creditCardType
            expiryDate
            country
            isPrimary
            createdAt
            updatedAt
          }
          nextToken
        }
        customerFeedback {
          items {
            feedbackId
            searchLocation
            dateOfVisit
            timeOfVisit
            title
            description
            uploadPhoto
            createdBy
            modifiedBy
            redmineTicketNumber
            platform
            createdAt
            updatedAt
            customerName
            customerMobileNo
            customerEmail
            issueStatus
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`
export const searchPromotionPartners = /* GraphQL */ `
  query SearchPromotionPartners(
    $filter: SearchablePromotionPartnerFilterInput
    $sort: SearchablePromotionPartnerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPromotionPartners(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        promotionPartnerId
        image
        name
        defaultPrefix
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchPromoCodeCampaigns = /* GraphQL */ `
  query SearchPromoCodeCampaigns(
    $filter: SearchablePromoCodeCampaignFilterInput
    $sort: SearchablePromoCodeCampaignSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPromoCodeCampaigns(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        promoCodeId
        title
        campaignId
        navPromoId
        promoCode
        promoType
        forPartner
        maxUserUsageLimit
        totalUsageLimit
        totalNumOfCodes
        forMultipleUsage
        orderType
        deliveryFree
        discountTypeDelivery
        discountTypeLevel
        discountDeliveryCap
        discountProductQuantityCap
        discountCap
        discountType
        discountValue
        discountOnProductType
        discountOnProductValue
        deliveryFeeDiscount
        promoStartDatetime
        promoEndDatetime
        promoRepeatDay
        promoRepeatStartTime
        promoRepeatEndTime
        totalCount
        activeCount
        redeemedCount
        deactivatedCount
        athenaQueryId
        promoCodeStatusFile
        usageCondition
        productCategoryCondition {
          type
          ids
        }
        productCategoryDiscount {
          type
          ids
        }
        stores
        freeProducts {
          productId
          quantity
        }
        status
        minimumSpent
        minimumQuantity
        jobStatus
        modifiedBy
        isPromoEnabled
        platform
        statusUpdatedAt
        codeUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchPromoCodeGenerics = /* GraphQL */ `
  query SearchPromoCodeGenerics(
    $filter: SearchablePromoCodeGenericFilterInput
    $sort: SearchablePromoCodeGenericSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPromoCodeGenerics(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        promoCodeGenericId
        campaignId
        promoCode
        promoCodeCampaignId
        lastModifiedBy
        deactivatedAt
        status
        redeemedCount
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const customSearchPromoCodeGeneric = /* GraphQL */ `
  query CustomSearchPromoCodeGeneric(
    $filter: SearchablePromoCodeGenericFilterInput
    $sort: SearchablePromoCodeGenericSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchPromoCodeGeneric(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        promoCodeGenericId
        campaignId
        promoCode
        promoCodeCampaignId
        lastModifiedBy
        deactivatedAt
        status
        redeemedCount
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchPromoCodeCampaignPartners = /* GraphQL */ `
  query SearchPromoCodeCampaignPartners(
    $filter: SearchablePromoCodeCampaignPartnerFilterInput
    $sort: SearchablePromoCodeCampaignPartnerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPromoCodeCampaignPartners(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        promoCodeCampaignPartnerId
        promoCodeId
        partnerName
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchAnnouncements = /* GraphQL */ `
  query SearchAnnouncements(
    $filter: SearchableAnnouncementFilterInput
    $sort: SearchableAnnouncementSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAnnouncements(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        announcementId
        title
        startDate
        endDate
        scheduleDatetime
        body
        actionType
        actionId
        bannerImage
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchCustomerTags = /* GraphQL */ `
  query SearchCustomerTags(
    $filter: SearchableCustomerTagFilterInput
    $sort: SearchableCustomerTagSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomerTags(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        tagId
        title
        tagMode
        startDate
        endDate
        ruleName
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchCustomerTagRules = /* GraphQL */ `
  query SearchCustomerTagRules(
    $filter: SearchableCustomerTagRuleFilterInput
    $sort: SearchableCustomerTagRuleSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomerTagRules(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        ruleId
        tagId
        ruleType
        ruleField
        ruleOperator
        ruleValue
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchPriceGroups = /* GraphQL */ `
  query SearchPriceGroups(
    $filter: SearchablePriceGroupFilterInput
    $sort: SearchablePriceGroupSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPriceGroups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        priceGroupId
        title
        modifiedBy
        createdAt
        updatedAt
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`
export const searchProductUOMs = /* GraphQL */ `
  query SearchProductUOMs(
    $filter: SearchableProductUOMFilterInput
    $sort: SearchableProductUOMSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductUOMs(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        productUOMId
        productId
        image
        isDisabled
        description
        minFoodPreparationDuration
        pickupMaximumQuantity
        sku
        ecommerceMaximumQuantity
        departmentLevel1
        departmentLevel2
        productGroupDesc
        productGroupCode
        divisionDesc
        itemCategoryDesc
        title
        uom
        minDeliveryDuration
        taggings
        itemPublishStartDate
        itemPublishEndDate
        modifiedBy
        createdAt
        updatedAt
        orderDetail {
          items {
            orderDetailId
            orderDetailOrderId
            orderDetailPriceGroupId
            orderDetailProductId
            orderDetailProductUOMId
            orderDetailProductUOMPriceGroupId
            quantity
            subtotal
            discount
            promoDiscount
            mandatoryItem
            isFreeItem
            outOfStock
            itemStatus
            productName
            productDescription
            productImage
            createdAt
            updatedAt
            totalPromoCodeDiscount
            totalDiscountAmount
            totalVoucherDiscount
          }
          nextToken
        }
        productTaggingMapping {
          items {
            productTaggingMappingId
            productTaggingMappingProductId
            productTaggingMappingProductTaggingId
            productTaggingMappingProductUOMId
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productUOMPriceGroup {
          items {
            productUOMPriceGroupId
            productUOMId
            priceGroupId
            promotionDescription
            pickupPromotionDescription
            discountedPrice
            discountPercentage
            discount
            pickupDiscountedPrice
            pickupPromotionDiscount
            pickupDiscountPercentage
            pickupDiscount
            pickupPrice
            pickupEffectiveStartDate
            pickupEffectiveEndDate
            price
            discountStartDate
            discountEndDate
            effectiveStartDate
            effectiveEndDate
            pickupDiscountStartDate
            pickupDiscountEndDate
            modifiedBy
            createdAt
            updatedAt
          }
          nextToken
        }
        productStore {
          items {
            productStoreId
            productStoreProductId
            isOutOfStock
            modifiedBy
            productStoreProductUOMId
            productStoreStoreId
            createdAt
            updatedAt
          }
          nextToken
        }
        productDepartment {
          items {
            productDepartmentId
            productDepartmentProductUOMId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`
export const searchProductUOMPriceGroups = /* GraphQL */ `
  query SearchProductUOMPriceGroups(
    $filter: SearchableProductUOMPriceGroupFilterInput
    $sort: SearchableProductUOMPriceGroupSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductUOMPriceGroups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        productUOMPriceGroupId
        productUOMId
        priceGroupId
        promotionDescription
        pickupPromotionDescription
        discountedPrice
        discountPercentage
        discount
        pickupDiscountedPrice
        pickupPromotionDiscount
        pickupDiscountPercentage
        pickupDiscount
        pickupPrice
        pickupEffectiveStartDate
        pickupEffectiveEndDate
        price
        discountStartDate
        discountEndDate
        effectiveStartDate
        effectiveEndDate
        pickupDiscountStartDate
        pickupDiscountEndDate
        modifiedBy
        createdAt
        updatedAt
        productUOM {
          productUOMId
          productId
          image
          isDisabled
          description
          minFoodPreparationDuration
          pickupMaximumQuantity
          sku
          ecommerceMaximumQuantity
          departmentLevel1
          departmentLevel2
          productGroupDesc
          productGroupCode
          divisionDesc
          itemCategoryDesc
          title
          uom
          minDeliveryDuration
          taggings
          itemPublishStartDate
          itemPublishEndDate
          modifiedBy
          createdAt
          updatedAt
          orderDetail {
            nextToken
          }
          productTaggingMapping {
            nextToken
          }
          productUOMPriceGroup {
            nextToken
          }
          productStore {
            nextToken
          }
          productDepartment {
            nextToken
          }
        }
        priceGroup {
          priceGroupId
          title
          modifiedBy
          createdAt
          updatedAt
          productUOMPriceGroup {
            nextToken
          }
        }
      }
      nextToken
      total
    }
  }
`
export const searchDivisions = /* GraphQL */ `
  query SearchDivisions(
    $filter: SearchableDivisionFilterInput
    $sort: SearchableDivisionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchDivisions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        divisionId
        divisionCode
        divisionName
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchItemCategorys = /* GraphQL */ `
  query SearchItemCategorys(
    $filter: SearchableItemCategoryFilterInput
    $sort: SearchableItemCategorySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchItemCategorys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        itemCategoryId
        itemCategoryCode
        itemCategoryName
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchProductGroups = /* GraphQL */ `
  query SearchProductGroups(
    $filter: SearchableProductGroupFilterInput
    $sort: SearchableProductGroupSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProductGroups(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        productGroupId
        productGroupCode
        productGroupDescription
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const searchNavItemExclusions = /* GraphQL */ `
  query SearchNavItemExclusions(
    $filter: SearchableNavItemExclusionFilterInput
    $sort: SearchableNavItemExclusionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchNavItemExclusions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        navItemExclusionId
        excludeFrom
        itemExclusionId
        itemExclusionProductCondition
        itemExclusionCode
        itemExclusionTitle
        itemExclusionItemCategoryDesc
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const listLandingPageBanners = /* GraphQL */ `
  query ListLandingPageBanners(
    $landingPageBannerId: ID
    $filter: ModelLandingPageBannerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLandingPageBanners(
      landingPageBannerId: $landingPageBannerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        landingPageBannerId
        image
        effectiveStart
        effectiveEnd
        isDisabled
        sequenceOrder
        title
        description
        createdBy
        modifiedBy
        createdAt
        updatedAt
        promoEntityId
        promoType
        buttonLabel
        promoTitle
        platform
        shortLinkUrl
      }
      nextToken
    }
  }
`
export const searchLandingPageBanners = /* GraphQL */ `
  query SearchLandingPageBanners(
    $filter: SearchableLandingPageBannerFilterInput
    $sort: SearchableLandingPageBannerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchLandingPageBanners(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        landingPageBannerId
        image
        effectiveStart
        effectiveEnd
        isDisabled
        sequenceOrder
        title
        description
        createdBy
        modifiedBy
        createdAt
        updatedAt
        promoEntityId
        promoType
        buttonLabel
        promoTitle
        platform
        shortLinkUrl
      }
      nextToken
      total
    }
  }
`
export const listHomePageBanners = /* GraphQL */ `
  query ListHomePageBanners(
    $homePageBannerId: ID
    $filter: ModelHomePageBannerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHomePageBanners(
      homePageBannerId: $homePageBannerId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        homePageBannerId
        bannerId
        bannerNumber
        image
        startDate
        endDate
        isDisabled
        sequenceOrder
        listBanners {
          id
          image
          title
          description
          sequence
        }
        title
        buttonType
        buttonAction
        actionTitle
        buttonLabel
        pageLayout
        description
        validationPeriodId
        shortLinkUrl
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const searchHomePageBanners = /* GraphQL */ `
  query SearchHomePageBanners(
    $filter: SearchableHomePageBannerFilterInput
    $sort: SearchableHomePageBannerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchHomePageBanners(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        homePageBannerId
        bannerId
        bannerNumber
        image
        startDate
        endDate
        isDisabled
        sequenceOrder
        listBanners {
          id
          image
          title
          description
          sequence
        }
        title
        buttonType
        buttonAction
        actionTitle
        buttonLabel
        pageLayout
        description
        validationPeriodId
        shortLinkUrl
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const getFaq = /* GraphQL */ `
  query GetFaq($faqId: ID!) {
    getFaq(faqId: $faqId) {
      faqId
      faqCategory
      question
      parentId
      answer
      faqType
      sequence
      platform
      createdAt
      updatedAt
    }
  }
`
export const listFaqs = /* GraphQL */ `
  query ListFaqs(
    $faqId: ID
    $filter: ModelFaqFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFaqs(
      faqId: $faqId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        faqId
        faqCategory
        question
        parentId
        answer
        faqType
        sequence
        platform
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getSupportStatement = /* GraphQL */ `
  query GetSupportStatement($statementId: ID!) {
    getSupportStatement(statementId: $statementId) {
      statementId
      parentId
      statement
      messageToCustomer
      supportType
      statementType
      priority
      sequence
      platform
      senderView
      receiverView
      createdAt
      updatedAt
    }
  }
`
export const listSupportStatements = /* GraphQL */ `
  query ListSupportStatements(
    $statementId: ID
    $filter: ModelSupportStatementFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSupportStatements(
      statementId: $statementId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        statementId
        parentId
        statement
        messageToCustomer
        supportType
        statementType
        priority
        sequence
        platform
        senderView
        receiverView
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getVoucherOrderDetailList = /* GraphQL */ `
  query GetVoucherOrderDetailList($voucherOrderId: ID!) {
    getVoucherOrderDetailList(voucherOrderId: $voucherOrderId) {
      voucherOrderDetailId
      quantity
      voucherOrderId
      benefitTriggerId
      subtotal
      title
      expiryDate
      startDate
      description
      image
      createdAt
      updatedAt
    }
  }
`
export const getInStoreHistory = /* GraphQL */ `
  query GetInStoreHistory(
    $accountNo: String
    $sortDirection: ModelSortDirection
    $sortField: String
    $nextToken: String
    $filter: String
    $limit: Int
  ) {
    getInStoreHistory(
      accountNo: $accountNo
      sortDirection: $sortDirection
      sortField: $sortField
      nextToken: $nextToken
      filter: $filter
      limit: $limit
    ) {
      statusCode
      message
      items {
        transactionDate
        transactionTime
        transactionNo
        receiptNo
        storeCode
        posTerminal
        grandTotal
        pointsCollected
        pointsRedeemed
        voucherDiscountAmount
        totalDiscountAmount
        refundReceiptNo
        navEntryNo
      }
      nextToken
      total
    }
  }
`
export const getInStoreHistorySalesEntry = /* GraphQL */ `
  query GetInStoreHistorySalesEntry(
    $accountNo: String
    $sortDirection: ModelSortDirection
    $sortField: String
    $nextToken: String
    $filter: String
    $limit: Int
    $executionId: String
  ) {
    getInStoreHistorySalesEntry(
      accountNo: $accountNo
      sortDirection: $sortDirection
      sortField: $sortField
      nextToken: $nextToken
      filter: $filter
      limit: $limit
      executionId: $executionId
    ) {
      items {
        transactionDate
        transactionTime
        transactionNo
        receiptNo
        storeCode
        posTerminal
        itemCode
        quantity
        pricePerQuantity
        subtotal
        pointsEarned
        refundedQuantity
        refundedLineNo
        refundedTransactionNo
        refundedPosTerminal
        refundedStoreCode
        uom
        grossAmount
        voucherDiscountAmount
        discountAmount
        voucherCode
      }
      nextToken
      total
      executionId
    }
  }
`
export const getVoucherStatus = /* GraphQL */ `
  query GetVoucherStatus(
    $token: String
    $voucherNumber: String
    $memberLedgerEntryNo: String
  ) {
    getVoucherStatus(
      token: $token
      voucherNumber: $voucherNumber
      memberLedgerEntryNo: $memberLedgerEntryNo
    ) {
      voucherIsUsed
      voucherNumber
      memberLedgerEntryNo
      statusCode
      message
    }
  }
`
export const getHelpCenterFaqCache = /* GraphQL */ `
  query GetHelpCenterFaqCache($platform: String) {
    getHelpCenterFaqCache(platform: $platform) {
      items {
        faqId
        faqCategory
        question
        parentId
        answer
        faqType
        sequence
        platform
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getDownloadJobStatus = /* GraphQL */ `
  query GetDownloadJobStatus($downloadJobId: String!) {
    getDownloadJobStatus(downloadJobId: $downloadJobId) {
      downloadJobId
      module
      status
      message
      objectPresignedUrl
      createdAt
      updatedAt
    }
  }
`
export const getStampingCampaign = /* GraphQL */ `
  query GetStampingCampaign($stampingCampaignId: String) {
    getStampingCampaign(stampingCampaignId: $stampingCampaignId) {
      statusCode
      message
      stampingCampaignId
      stampingCampaignTitle
      stampingCampaignCode
      stampingCampaignDescription
      platform
      orderType
      schemeCode
      isEnable
      stampingCampaignListingImage
      stampingCampaignImage
      startDate
      endDate
      stampingCampaignType
      minSpend
      storeGroup
      storeGroupCode
      rewardVoucher {
        id
        voucherNumber
        voucherTitle
        quantity
      }
      stampingCampaignCycle
      stampingCampaignCycleDurationType
      stampingCampaignCycleDurationValue
      voucherExpiryDateType
      voucherExpiryDateValue
      stampingMilestone
      productCondition {
        id
        type
        productCondition
        title
        itemCode
        uom
        exclude
        stamp
      }
      extraProductCondition {
        id
        type
        productCondition
        title
        itemCode
        uom
        exclude
      }
      extraProductStamp
      stampingReminder {
        reminderMilestone
        reminderTitle
        reminderMessage
      }
    }
  }
`
export const customSearchStampingCampaign = /* GraphQL */ `
  query CustomSearchStampingCampaign(
    $filter: SearchableStampingCampaignFilterInput
    $sort: SearchableStampingCampaignSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchStampingCampaign(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        stampingCampaignTitle
        stampingCampaignCode
        stampingCampaignDescription
        stampingCampaignId
        storeGroup
        storeGroupCode
        platform
        orderType
        schemeCode
        isEnable
        stampingCampaignListingImage
        stampingCampaignImage
        startDate
        endDate
        stampingCampaignType
        minSpend
        targetOnlineStore
        targetOnlineStoreList {
          storeGroupId
          code
        }
        targetOfflineStore
        targetOfflineStoreList {
          storeGroupId
          code
        }
        rewardVoucher
        stampingCampaignCycle
        stampingCampaignCycleDurationType
        stampingCampaignCycleDurationValue
        voucherExpiryDateType
        voucherExpiryDateValue
        stampingMilestone
        productCondition {
          id
          title
          type
          itemCategoryDesc
          productCondition
          stamp
        }
        extraProductCondition {
          id
          title
          type
          itemCategoryDesc
          productCondition
        }
        extraProductStamp
        modifiedBy
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`
export const getStampingList = /* GraphQL */ `
  query GetStampingList($token: String, $platform: String) {
    getStampingList(token: $token, platform: $platform) {
      statusCode
      message
      userCampaignData {
        stampingCampaignId
        stampingCampaignTitle
        stampingCampaignListingImage
        stampingCampaignImage
        stampingCampaignType
        stampingMilestone
        stampCollected
        stampValueCollected
        stampValueBasedCollected
        userStampingProgressId
        stampingCampaignCycleDurationValue
        stampingMilestoneRemaining
        startDate
        endDate
        stampClaimCount
      }
    }
  }
`
export const getStampingDetail = /* GraphQL */ `
  query GetStampingDetail(
    $token: String
    $platform: String
    $stampingCampaignId: String
    $userStampingProgressId: String
  ) {
    getStampingDetail(
      token: $token
      platform: $platform
      stampingCampaignId: $stampingCampaignId
      userStampingProgressId: $userStampingProgressId
    ) {
      statusCode
      message
      stampingCampaignId
      stampingCampaignTitle
      stampingCampaignImage
      stampingCampaignDescription
      stampingCampaignType
      userStampingProgressId
      stampingMilestone
      startDate
      endDate
      rewardVoucher {
        id
        voucherNumber
        voucherTitle
        voucherImage
        quantity
      }
      stampingCollected
      stampValueCollected
      stampingCampaignCycleDurationValue
      stampingMilestoneRemaining
      stampClaimCount
    }
  }
`
export const customSearchStampingLog = /* GraphQL */ `
  query CustomSearchStampingLog(
    $filter: SearchableStampingCampaignLogFilterInput
    $sort: SearchableStampingCampaignLogSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchStampingLog(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        userStampingProgressLogId
        userStampingProgressId
        stampingCampaignId
        action
        orderNumber
        createdAt
        stampingCollected
        stampingValueBasedCollected
        accountNo
        stampingCode
        stampingTitle
        issueDate
        issueTime
        stampingType
        storeCode
        txnId
        hasRedeemed
        memberLedgerEntryNo
        detailedMemberLedgerEntryNo
        rewardVoucherCode
        voucherEntryNo
        createdBy
      }
      nextToken
      total
    }
  }
`
export const customSearchOrderSyncHeader = /* GraphQL */ `
  query CustomSearchOrderSyncHeader(
    $filter: SearchableOrderSyncHeaderFilterInput
    $sort: SearchableOrderSyncHeaderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchOrderSyncHeader(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        orderSyncHeaderListingId
        orderId
        merchantName
        tenderId
        receiptNo
        transactionId
        storeNo
        deliveryType
        amount
        status
        orderCreatedDateTime
        orderDateTime
        orderCancelDateTime
        orderCompletedDateTime
        lastStatusUpdatedAt
        navisionSent
        memberNo
        terminalId
        oriTransNo
        fullRefundInd
      }
      nextToken
      total
    }
  }
`
export const customSearchOrderSyncDetails = /* GraphQL */ `
  query CustomSearchOrderSyncDetails(
    $filter: SearchableOrderSyncDetailsFilterInput
    $sort: SearchableOrderSyncDetailsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchOrderSyncDetails(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        orderSyncDetailsListingId
        orderId
        orderDateTime
        orderCompletedDateTime
        storeNo
        tenderId
        receiptNo
        transactionId
        itemLineNo
        itemNo
        unitOfMeasure
        qty
        price
        netAmount
        discAmount
        gstAmount
        navPromoId
        promoDiscAmount
        memberNo
        navisionSent
      }
      nextToken
      total
    }
  }
`
export const navPromoIdDuplicationCheck = /* GraphQL */ `
  query NavPromoIdDuplicationCheck($navPromoId: String) {
    navPromoIdDuplicationCheck(navPromoId: $navPromoId) {
      statusCode
      message
    }
  }
`
