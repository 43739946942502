import React from 'react'
import * as Icons from './icons'
import { makeStyles } from '@material-ui/core'

const useIconClasses = makeStyles(({ palette }) => ({
  root: ({ width = '1em', height = '1em', color = palette.primary.main }) => ({
    width,
    height,
    color
  })
}))

export default function Icon(props) {
  function RenderIcon(props) {
    const Icon = Icons[props.name]
    const iconClasses = useIconClasses(props)

    if (Icon) {
      return <Icon {...props} classes={iconClasses} />
    }

    return null
  }

  return <>{RenderIcon(props)}</>
}
