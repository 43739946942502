import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, withStyles } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { format } from 'date-fns'
import { Paper } from 'src/components'
import formatter from 'src/utils/formatter'
import helper from 'src/utils/helper'

const useStyles = makeStyles(({ palette, spacing }) => ({
  image: {
    width: 100,
    height: 100,
    borderRadius: '15px'
    // boxShadow: '1px 1px 1px 1px #9E9E9E'
  },
  status: {
    marginTop: spacing(2),
    marginBottom: spacing(0.5),
    color: palette.primary.main,
    textTransform: 'uppercase'
  },
  icon: {
    marginLeft: 7.5,
    marginRight: 7.5,
    fontSize: '0.35rem',
    color: palette.grey[100],
    alignItems: 'center'
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row'
  }
})(Box)

export default function OrderItem({ type, order, history }) {
  const styles = useStyles()
  const [nextPath, setNextPath] = useState()
  // function getOrderStatus(status) {
  //   switch (status) {
  //     default:
  //       return 'Order Complete'
  //   }
  // }
  useEffect(() => {
    if (type === 'ecommerce') {
      setNextPath('order')
    } else {
      setNextPath('voucher-order')
    }
  }, [])

  return (
    <Box ml={1.5}>
      <Typography variant="h4">#{order.orderNumber}</Typography>
      <Row alignItems="center" mt={0.5}>
        <Typography variant="body2" className={styles.quantity}>
          {order.totalOrderItems} item[s]
        </Typography>
      </Row>
      <Typography variant="h4" className={styles.status}>
        {order.status}
      </Typography>
      {type === 'ecommerce' && order.orderType !== 'Parcel' ? (
        <Typography variant="body2">{`${format(
          new Date(order.scheduledDateTime),
          'dd MMMM y hh:mm a'
        )}`}</Typography>
      ) : order.orderType === 'Parcel' ? (
        <Typography variant="body2">{`${format(
          new Date(order.orderDate),
          'dd MMMM y hh:mm a'
        )}`}</Typography>
      ) : (
        <Typography variant="body2">{`${format(
          new Date(order.orderDateTime),
          'dd MMMM y hh:mm a'
        )}`}</Typography>
      )}
    </Box>
  )
}
