import React, { useState, useContext, useEffect } from 'react'
import DesktopContentPaper from 'src/components/DesktopComponents/DesktopContentPaper/DesktopContentPaper'
import { Spinner, Notification } from 'src/components'
import { makeStyles, Typography, Backdrop, Box } from '@material-ui/core'
import ParcelOrderItem from './components/ParcelOrderItem'
import RightDetail from './components/RightDetail'
import './OrderPageDesktop.scss'
import useOrderPageLogic from '../hooks/useOrderPageLogic'
import useOrderListLogic from '../hooks/useOrderListLogic'
import OrderItem from 'src/components/OrderItem/OrderItem'
import { DesktopNavigationContext } from 'src/context/DesktopNavigationContext'
import _ from 'lodash'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: palette.secondary.light
  },
  divider: {
    // Theme Color, or use css color in quote
    background: '#000'
  },
  image: {
    width: '100%',
    height: '45%',
    zIndex: -1,
    objectFit: 'cover'
  },
  status: {
    marginTop: spacing(2),
    marginBottom: spacing(0.5),
    color: palette.primary.main,
    textTransform: 'uppercase'
  },
  icon: {
    marginLeft: 7.5,
    marginRight: 7.5,
    fontSize: '0.35rem',
    color: palette.grey[100],
    alignItems: 'center'
  },
  faq: {
    borderBottom: `2px solid ${palette.primary.light}`
  },
  parcelSupportStatementBox: {
    border: `1px solid${palette.border.light}`,
    borderRadius: '12px',
    margin: '0 10px'
  },
  parcelSupportStatement: {
    color: palette.primary.main,
    fontWeight: 'bold'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  topic: {
    fontWeight: 'bold',
    color: palette.grey['100']
  },
  faqMsg: {
    margin: spacing(2.5, 0),
    textAlign: 'justify',
    textJustify: 'inter-word'
  },
  backButton: {
    padding: spacing(0.5, 1.5),
    marginTop: '12px',
    marginLeft: '4px',
    background: 'white',
    borderRadius: '20px'
  },
  modalimg: {
    width: 300,
    height: 530
  },
  backdrop: {
    zIndex: 10,
    color: '#fff'
  },
  remove: {
    textAlign: 'center',
    marginTop: spacing(1),
    padding: spacing(1, 2),
    backgroundColor: palette.warning.main,
    color: palette.common.white
  },
  backText: {
    color: palette.primary.main
  },
  box_container: {
    marginTop: '60px'
  },
  orderDetailBox: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    width: '50%'
  },
  orderDetailTitle: {
    color: '#B1B1B1'
  },
  orderDetailDesc: {
    fontWeight: 'bold',
    marginTop: '5px'
  },
  orderDetailBlueText: {
    color: palette.primary.main,
    marginTop: '5px'
  },
  recipientTitle: {
    marginTop: '5px',
    color: '#B1B1B1'
  },
  orderDetailStatus: {
    color: palette.primary.main,
    marginTop: '5px',
    textTransform: 'uppercase'
  }
}))

const OrderPageDesktop = (props) => {
  const NavigationContext = useContext(DesktopNavigationContext)
  const styles = useStyles()
  const { orderType, location, history } = props
  const { state } = location
  const token = global.jwtToken
    ? global.jwtToken
    : sessionStorage.getItem('token') !== null &&
      sessionStorage.getItem('token') !== 'TOKEN_HERE'
    ? sessionStorage.getItem('token')
    : null
  let platform = state.platform

  // console.log('orderpagedesktop props', props)

  const [selectedOrder, setSelectedOrder] = useState({})

  const [activeOrder, setActiveOrder] = useState({
    type: '',
    id: '',
    orderInfo: null
  })

  const isTngMp = platform === 'tngmp' ? true : false

  const { orderList, isOrderListLoading, alert, setAlert } = useOrderListLogic(
    'desktop',
    token,
    platform,
    orderType
  )

  const {
    currTopic,
    images,
    setImages,
    data,
    setData,
    contact,
    setContact,
    isSaving,
    parentStatements,
    isChildStatement,
    isChatSupport,
    message,
    notify,
    setNotify,
    highPriority,
    handleSubmit,
    onSelectTopic,
    resetParents,
    onImageClick,
    removePreviewImg,
    resetState,
    showImgModal,
    setShowImgModal
  } = useOrderPageLogic(
    selectedOrder.orderType ? selectedOrder.orderType.toLowerCase() : '',
    selectedOrder.orderType === 'Parcel'
      ? activeOrder.orderInfo
      : selectedOrder,
    platform,
    location
  )

  function navigateTo(type, orderItem) {
    let id = ''
    if (type === 'parcelOrderDetail') {
      id = orderItem.parcelOrderDetailId
    } else {
      id = orderItem.orderId
    }
    if (id !== activeOrder.id) {
      setActiveOrder({
        type,
        id,
        orderInfo: orderItem
      })
    }
  }

  const handleSelectOrder = (orderItem) => {
    NavigationContext.handleUpdateBreadcrumbs([
      'Report Issue',
      `Order #${orderItem.orderNumber}`
    ])
    setSelectedOrder(orderItem)
    if (orderItem.orderType !== 'Parcel') {
      navigateTo('order', orderItem)
    }
  }

  useEffect(() => {
    if (NavigationContext.navigationList.length === 1) {
      NavigationContext.handleUpdateBreadcrumbs(['Report Issue'])
    }
  }, [])

  // useEffect(() => {
  //   if (orderList.length && !isOrderListLoading) {
  //     setSelectedOrder(orderList[0])
  //   }
  // }, [orderList, isOrderListLoading])

  // console.log({ orderList })

  return (
    <div>
      <DesktopContentPaper>
        <div className="container-order-page">
          <div className="left-col">
            {isOrderListLoading && <Spinner />}
            {orderList.map((orderItem, idx) => {
              if (orderItem.orderType === 'Parcel') {
                return (
                  <div
                    key={idx}
                    className={`container-order-item ${
                      selectedOrder.orderNumber === orderItem.orderNumber
                        ? 'selected'
                        : null
                    }`}
                    onClick={() => handleSelectOrder(orderItem)}>
                    <ParcelOrderItem
                      key={idx}
                      type={orderType}
                      order={orderItem}
                      navigateTo={navigateTo}
                      {...props}
                    />
                  </div>
                )
              } else {
                return (
                  <div
                    key={idx}
                    className={`container-order-item ${
                      selectedOrder.orderNumber === orderItem.orderNumber
                        ? 'selected'
                        : null
                    }`}
                    onClick={() => handleSelectOrder(orderItem)}>
                    <OrderItem
                      key={idx}
                      type={'ecommerce'}
                      order={orderItem}
                      history={history}
                    />
                  </div>
                )
              }
            })}
          </div>
          <div className="right-col">
            {!_.isEmpty(selectedOrder) || !_.isEmpty(activeOrder.id) ? (
              <RightDetail
                selectedOrder={selectedOrder}
                activeOrder={activeOrder}
                styles={styles}
                currTopic={currTopic}
                parentStatements={parentStatements}
                isChatSupport={isChatSupport}
                isChildStatement={isChildStatement}
                resetParents={resetParents}
                resetState={resetState}
                data={data}
                setData={setData}
                contact={contact}
                setContact={setContact}
                images={images}
                setImages={setImages}
                isSaving={isSaving}
                handleSubmit={handleSubmit}
                onImageClick={onImageClick}
                onSelectTopic={onSelectTopic}
                isTngMp={isTngMp}
                setNotify={setNotify}
                highPriority={highPriority}
                message={message}
                navigateTo={navigateTo}
              />
            ) : (
              <div className="empty">
                <Typography variant="h2">
                  Please select an order to report any issue faced.
                </Typography>
              </div>
            )}
          </div>
        </div>
        <Notification
          show={notify.show}
          severity={notify.severity}
          message={notify.message}
          onClose={() => setNotify({ show: false, severity: '', message: '' })}
        />
        <Backdrop
          open={showImgModal.show}
          onClick={() =>
            setShowImgModal({ show: false, image: '', currIdx: null })
          }
          className={styles.backdrop}>
          <Box>
            <img src={showImgModal.image} className={styles.modalimg} />
            <Typography
              className={styles.remove}
              onClick={() => removePreviewImg(showImgModal.currIdx)}>
              remove image
            </Typography>
          </Box>
        </Backdrop>
      </DesktopContentPaper>
    </div>
  )
}

export default OrderPageDesktop
